import { SearchResultType, SearchResultUnionType } from '@t5s/shared/gql';
import { ApolloFetchPolicy } from '@t5s/shared/gql-config';
import { Observable } from 'rxjs';
import { GlobalSearchResultFragment } from '../../fragments/global-search';
import { search } from '../../generated/graphql-queries';
import { GqlService } from '../../gql-service';

export class GqlGlobalSearchService extends GqlService {
  search(query: string, searchResultTypes?: SearchResultType[]): Observable<SearchResultUnionType[]> {
    return this.apollo.defaultQuery({
      ...search({ query, searchResultTypes }, [GlobalSearchResultFragment]),
      fetchPolicy: ApolloFetchPolicy.NETWORK_ONLY,
    });
  }

  watchSearch(query: string, searchResultTypes?: SearchResultType[]): Observable<SearchResultUnionType[]> {
    return this.apollo.defaultWatchQuery({
      ...search({ query, searchResultTypes }, [GlobalSearchResultFragment]),
    });
  }
}
