import { color } from '@t5s/shared/ui/color';
import { fontFamily } from './constants';
import { darken, rgba } from './util';

export interface ThemeColorObject {
  primary: string;
  primary5: string;
  primary10: string;
  primary20: string;
  primary30: string;
  primary35: string;
  primary57: string;
  primarydark10: string;
  primarydark20: string;
  lightest: string;
  lightest20: string;
  lightest50: string;
  lightest90: string;
  lighter: string;
  mediumlight: string;
  light: string;
  light68: string;
  dark: string;
  grey1: string;
  grey160: string;
  grey2: string;
  grey3: string;
  grey4: string;
  grey5: string;
  grey6: string;
  grey7: string;
  grey8: string;
  grey9: string;
  darker: string;
  darker3: string;
  darker4: string;
  darker6: string;
  darker8: string;
  darker9: string;
  darker12: string;
  darker16: string;
  darker20: string;
  darker25: string;
  darker35: string;
  darker40: string;
  darker43: string;
  darker45: string;
  darker50: string;
  darker51: string;
  darker60: string;
  darker65: string;
  darker70: string;
  darker80: string;
  darker85: string;
  darkest: string;
  blackGlossy: string;
  blackGlossy10: string;
  blackBright1: string;
  blackBright2: string;
  warning: string;
  warning10: string;
  danger: string;
  danger10: string;
  danger20: string;
  danger30: string;
  dangerdark10: string;
  dangerdark20: string;
  success: string;
  success10: string;
  gray9197A3: string;
  grayBDC1C9: string;
}

export class Theme {
  constructor(readonly color: ThemeColorObject, readonly fontFamily: string) {}
}

export const themes = {
  default: {
    color: {
      lightest: color.white,
      lightest20: rgba(color.white, 0.2),
      lightest50: rgba(color.white, 0.5),
      lightest90: rgba(color.white, 0.9),
      lighter: rgba(color.T_black, 0.03), //Changed from color.gray100
      mediumlight: color.gray150,
      light: color.T_black9, //Changed from color.gray200
      light68: rgba(color.gray200, 0.68),
      dark: color.gray400,
      grey1: color.T_grey1, // New theme
      grey160: rgba(color.T_grey1, 0.6), // New theme
      grey2: color.T_grey2, // New theme
      grey3: color.T_grey3, // New theme
      grey4: color.T_grey4, // New theme
      grey5: color.T_grey5, // New theme
      grey6: color.T_grey6, // New theme
      grey7: color.T_grey7, // New theme
      grey8: color.T_grey8, // New theme
      grey9: color.T_grey9, // New theme
      darker3: rgba(color.T_black, 0.03),
      darker4: rgba(color.T_black, 0.04),
      darker6: rgba(color.T_black, 0.06),
      darker8: rgba(color.T_black, 0.08),
      darker9: rgba(color.T_black, 0.09),
      darker12: rgba(color.T_black, 0.12),
      darker16: rgba(color.T_black, 0.16),
      darker20: rgba(color.T_black, 0.2),
      darker25: rgba(color.T_black, 0.25),
      darker35: rgba(color.T_black, 0.35),
      darker43: rgba(color.T_black, 0.43),
      darker40: rgba(color.T_black, 0.4),
      darker45: rgba(color.T_black, 0.45),
      darker50: rgba(color.T_black, 0.5),
      darker51: rgba(color.T_black, 0.51),
      darker60: rgba(color.T_black, 0.6),
      darker65: rgba(color.T_black, 0.65),
      darker70: rgba(color.T_black, 0.7),
      darker80: rgba(color.T_black, 0.8),
      darker85: rgba(color.T_black, 0.85),
      darker: rgba(color.T_black, 0.85), //Changed from color.gray900
      darkest: color.T_black,
      blackGlossy: color.T_blackGlossy, // New theme
      blackGlossy10: rgba(color.T_blackGlossy, 0.1), // New theme
      blackBright1: color.T_blackBright1, // New theme
      blackBright2: color.T_blackBright2, // New theme
      primary: color.blue,
      primary5: rgba(color.blue, 0.05),
      primary10: rgba(color.blue, 0.1),
      primary20: rgba(color.blue, 0.2),
      primary30: rgba(color.blue, 0.3),
      primary35: rgba(color.blue, 0.35),
      primary57: rgba(color.blue, 0.57),
      primarydark10: darken(color.blue, 0.1),
      primarydark20: darken(color.blue, 0.2),
      warning: color.orange100,
      warning10: rgba(color.orange100, 0.1),
      danger: color.red,
      danger10: rgba(color.red, 0.1),
      danger20: rgba(color.red, 0.2),
      danger30: rgba(color.red, 0.3),
      dangerdark10: darken(color.red, 0.1),
      dangerdark20: darken(color.red, 0.2),
      success: color.green,
      success10: rgba(color.green, 0.1),
      grayBDC1C9: color.gray500,
      gray9197A3: color.gray700,
    },
    fontFamily: fontFamily.default,
  },
} as const;
