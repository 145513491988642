
/*
 * ------------------------------------------------------
 * THIS FILE WAS AUTOMATICALLY GENERATED (DO NOT MODIFY)
 * -------------------------------------------------------
 */

/* tslint:disable */
/* eslint-disable */
export enum _OrganizationJobStatus {
    RUNNING = "RUNNING",
    WAITING = "WAITING",
    SUCCESS = "SUCCESS",
    FAILURE = "FAILURE",
    STALLED = "STALLED"
}

export enum ActiveUserAudioRoomPresenceType {
    USER_PRESENT = "USER_PRESENT",
    USER_NOT_PRESENT = "USER_NOT_PRESENT",
    AUDIO_ROOM_NOT_EXIST = "AUDIO_ROOM_NOT_EXIST"
}

export enum ActiveUserBlabItemEngagementType {
    BLAB_ITEM_VIEWED = "BLAB_ITEM_VIEWED",
    BLAB_ITEM_CREATED = "BLAB_ITEM_CREATED",
    BLAB_ITEM_COMMENTED = "BLAB_ITEM_COMMENTED",
    BLAB_ITEM_UPDATED = "BLAB_ITEM_UPDATED"
}

export enum ActiveUserExploreCategory {
    NEW_USER = "NEW_USER",
    GENERIC_INTEREST = "GENERIC_INTEREST",
    NEW_CONTENT = "NEW_CONTENT"
}

export enum ActiveUserExploreSuggestionType {
    EXPLORE_BLAB_ITEM = "EXPLORE_BLAB_ITEM"
}

export enum AudioRoomActivityType {
    AUDIO_ROOM_CREATED = "AUDIO_ROOM_CREATED"
}

export enum AudioRoomTransportVoiceDirection {
    RECEIVE = "RECEIVE",
    SEND = "SEND"
}

export enum AuthenticateWithCredentialsRejectionReason {
    INVALID_CREDENTIALS = "INVALID_CREDENTIALS",
    USER_DEACTIVATED = "USER_DEACTIVATED"
}

export enum BillingCountry {
    AF = "AF",
    AX = "AX",
    AL = "AL",
    DZ = "DZ",
    AS = "AS",
    AD = "AD",
    AO = "AO",
    AI = "AI",
    AQ = "AQ",
    AG = "AG",
    AR = "AR",
    AM = "AM",
    AW = "AW",
    AU = "AU",
    AT = "AT",
    AZ = "AZ",
    BS = "BS",
    BH = "BH",
    BD = "BD",
    BB = "BB",
    BY = "BY",
    BE = "BE",
    BZ = "BZ",
    BJ = "BJ",
    BM = "BM",
    BT = "BT",
    BO = "BO",
    BA = "BA",
    BW = "BW",
    BV = "BV",
    BR = "BR",
    IO = "IO",
    BN = "BN",
    BG = "BG",
    BF = "BF",
    BI = "BI",
    KH = "KH",
    CM = "CM",
    CA = "CA",
    IC = "IC",
    CV = "CV",
    BQ = "BQ",
    KY = "KY",
    CF = "CF",
    EA = "EA",
    TD = "TD",
    CL = "CL",
    CN = "CN",
    CX = "CX",
    CC = "CC",
    CO = "CO",
    KM = "KM",
    CD = "CD",
    CG = "CG",
    CK = "CK",
    CR = "CR",
    CI = "CI",
    HR = "HR",
    CW = "CW",
    CY = "CY",
    CZ = "CZ",
    DK = "DK",
    DJ = "DJ",
    DM = "DM",
    DO = "DO",
    TL = "TL",
    EC = "EC",
    EG = "EG",
    SV = "SV",
    GQ = "GQ",
    ER = "ER",
    EE = "EE",
    ET = "ET",
    FK = "FK",
    FO = "FO",
    FJ = "FJ",
    FI = "FI",
    FR = "FR",
    GF = "GF",
    PF = "PF",
    TF = "TF",
    GA = "GA",
    GM = "GM",
    GE = "GE",
    DE = "DE",
    GH = "GH",
    GI = "GI",
    GR = "GR",
    GL = "GL",
    GD = "GD",
    GP = "GP",
    GU = "GU",
    GT = "GT",
    GG = "GG",
    GN = "GN",
    GW = "GW",
    GY = "GY",
    HT = "HT",
    HM = "HM",
    VA = "VA",
    HN = "HN",
    HK = "HK",
    HU = "HU",
    IS = "IS",
    IN = "IN",
    ID = "ID",
    IQ = "IQ",
    IE = "IE",
    IM = "IM",
    IL = "IL",
    IT = "IT",
    JM = "JM",
    JP = "JP",
    JE = "JE",
    JO = "JO",
    KZ = "KZ",
    KE = "KE",
    KI = "KI",
    KR = "KR",
    XK = "XK",
    KW = "KW",
    KG = "KG",
    LA = "LA",
    LV = "LV",
    LB = "LB",
    LS = "LS",
    LR = "LR",
    LY = "LY",
    LI = "LI",
    LT = "LT",
    LU = "LU",
    MO = "MO",
    MK = "MK",
    MG = "MG",
    MW = "MW",
    MY = "MY",
    MV = "MV",
    ML = "ML",
    MT = "MT",
    MH = "MH",
    MQ = "MQ",
    MR = "MR",
    MU = "MU",
    YT = "YT",
    MX = "MX",
    FM = "FM",
    MD = "MD",
    MC = "MC",
    MN = "MN",
    ME = "ME",
    MS = "MS",
    MA = "MA",
    MZ = "MZ",
    MM = "MM",
    NA = "NA",
    NR = "NR",
    NP = "NP",
    NL = "NL",
    NC = "NC",
    NZ = "NZ",
    NI = "NI",
    NE = "NE",
    NG = "NG",
    NU = "NU",
    NF = "NF",
    MP = "MP",
    NO = "NO",
    OM = "OM",
    PK = "PK",
    PW = "PW",
    PS = "PS",
    PA = "PA",
    PG = "PG",
    PY = "PY",
    PE = "PE",
    PH = "PH",
    PN = "PN",
    PL = "PL",
    PT = "PT",
    PR = "PR",
    QA = "QA",
    RE = "RE",
    RO = "RO",
    RU = "RU",
    RW = "RW",
    BL = "BL",
    SH = "SH",
    KN = "KN",
    LC = "LC",
    MF = "MF",
    PM = "PM",
    VC = "VC",
    WS = "WS",
    SM = "SM",
    ST = "ST",
    SA = "SA",
    SN = "SN",
    RS = "RS",
    SC = "SC",
    SL = "SL",
    SG = "SG",
    SX = "SX",
    SK = "SK",
    SI = "SI",
    SB = "SB",
    SO = "SO",
    ZA = "ZA",
    GS = "GS",
    SS = "SS",
    ES = "ES",
    LK = "LK",
    SD = "SD",
    SR = "SR",
    SJ = "SJ",
    SZ = "SZ",
    SE = "SE",
    CH = "CH",
    TW = "TW",
    TJ = "TJ",
    TZ = "TZ",
    TH = "TH",
    TG = "TG",
    TK = "TK",
    TO = "TO",
    TT = "TT",
    TN = "TN",
    TR = "TR",
    TM = "TM",
    TC = "TC",
    TV = "TV",
    UG = "UG",
    UA = "UA",
    AE = "AE",
    GB = "GB",
    US = "US",
    UM = "UM",
    UY = "UY",
    UZ = "UZ",
    VU = "VU",
    VE = "VE",
    VN = "VN",
    VG = "VG",
    VI = "VI",
    WF = "WF",
    EH = "EH",
    YE = "YE",
    ZM = "ZM",
    ZW = "ZW"
}

export enum BillingCurrency {
    EUR = "EUR",
    USD = "USD"
}

export enum BillingPeriod {
    MONTHLY = "MONTHLY",
    YEARLY = "YEARLY"
}

export enum BillingStateCanada {
    AB = "AB",
    BC = "BC",
    MB = "MB",
    NB = "NB",
    NL = "NL",
    NT = "NT",
    NS = "NS",
    NU = "NU",
    ON = "ON",
    PE = "PE",
    QC = "QC",
    SK = "SK",
    YT = "YT"
}

export enum BillingStateUsa {
    AL = "AL",
    AK = "AK",
    AZ = "AZ",
    AR = "AR",
    CA = "CA",
    CO = "CO",
    CT = "CT",
    DE = "DE",
    DC = "DC",
    FL = "FL",
    GA = "GA",
    HI = "HI",
    ID = "ID",
    IL = "IL",
    IN = "IN",
    IA = "IA",
    KS = "KS",
    KY = "KY",
    LA = "LA",
    ME = "ME",
    MD = "MD",
    MA = "MA",
    MI = "MI",
    MN = "MN",
    MS = "MS",
    MO = "MO",
    MT = "MT",
    NE = "NE",
    NV = "NV",
    NH = "NH",
    NJ = "NJ",
    NM = "NM",
    NY = "NY",
    NC = "NC",
    ND = "ND",
    OH = "OH",
    OK = "OK",
    OR = "OR",
    PA = "PA",
    RI = "RI",
    SC = "SC",
    SD = "SD",
    TN = "TN",
    TX = "TX",
    UT = "UT",
    VT = "VT",
    VA = "VA",
    WA = "WA",
    WV = "WV",
    WI = "WI",
    WY = "WY"
}

export enum BillingSubscriptionType {
    NONE = "NONE",
    ACTIVE = "ACTIVE",
    ACTIVE_ACTION_REQUIRED = "ACTIVE_ACTION_REQUIRED",
    ACTIVE_INVALID_PAYMENT = "ACTIVE_INVALID_PAYMENT",
    INCOMPLETE_ACTION_REQUIRED = "INCOMPLETE_ACTION_REQUIRED",
    INCOMPLETE_INVALID_PAYMENT = "INCOMPLETE_INVALID_PAYMENT"
}

export enum BlabDefActivityType {
    BLAB_DEF_INVITATION = "BLAB_DEF_INVITATION",
    BLAB_DEF_CREATED = "BLAB_DEF_CREATED"
}

export enum BlabDefBlabItemIdFilterMatchType {
    SMALLER = "SMALLER",
    SMALLER_OR_EQUAL = "SMALLER_OR_EQUAL",
    LARGER = "LARGER",
    LARGER_OR_EQUAL = "LARGER_OR_EQUAL",
    EQUAL = "EQUAL",
    NOT_EQUAL = "NOT_EQUAL",
    EMPTY = "EMPTY",
    NOT_EMPTY = "NOT_EMPTY"
}

export enum BlabDefDataExportFormat {
    CSV = "CSV",
    XLSX = "XLSX"
}

export enum BlabDefDataImportCharacterCode {
    ISO_8859_1 = "ISO_8859_1",
    UTF_8 = "UTF_8",
    UTF_16 = "UTF_16"
}

export enum BlabDefDataImportCsvValueSeparator {
    COMMA = "COMMA",
    SEMICOLON = "SEMICOLON",
    TAB = "TAB"
}

export enum BlabDefDataImportFileType {
    CSV = "CSV",
    XLSX = "XLSX"
}

export enum BlabDefDeveloperInfoSubscriptionEventType {
    BLAB_DEF_WEBHOOK_CREATED = "BLAB_DEF_WEBHOOK_CREATED",
    BLAB_DEF_WEBHOOK_UPDATED = "BLAB_DEF_WEBHOOK_UPDATED",
    BLAB_DEF_WEBHOOK_DELETED = "BLAB_DEF_WEBHOOK_DELETED"
}

export enum BlabDefIcon {
    account_circle_selectable = "account_circle_selectable",
    analytics = "analytics",
    article = "article",
    assignment = "assignment",
    assignment_turned_in = "assignment_turned_in",
    bookmarks = "bookmarks",
    bug_report = "bug_report",
    build = "build",
    business = "business",
    cake = "cake",
    check_circle = "check_circle",
    eco = "eco",
    emoji_emotions = "emoji_emotions",
    emoji_objects = "emoji_objects",
    error = "error",
    event_available = "event_available",
    face = "face",
    favorite = "favorite",
    group_work = "group_work",
    info = "info",
    lock = "lock",
    lock_open = "lock_open",
    monetization = "monetization",
    money = "money",
    offline = "offline",
    people_alt = "people_alt",
    person = "person",
    receipt = "receipt",
    reply = "reply",
    schedule = "schedule",
    smartphone = "smartphone",
    star = "star",
    vpn_key = "vpn_key",
    widgets = "widgets",
    content_paste = "content_paste",
    cloud = "cloud",
    emoji_events = "emoji_events",
    sports_baseball = "sports_baseball",
    sports_basketball = "sports_basketball",
    sports_football = "sports_football",
    sports_motorsports = "sports_motorsports",
    sports_rugby = "sports_rugby",
    sports_soccer = "sports_soccer",
    sports_volleyball = "sports_volleyball",
    alternate_email = "alternate_email",
    corporate_fare = "corporate_fare",
    insights = "insights",
    kitchen = "kitchen",
    bedtime = "bedtime",
    casino = "casino",
    contact_page = "contact_page",
    description = "description",
    flag = "flag",
    free_breakfast = "free_breakfast",
    inventory = "inventory",
    nature = "nature",
    page = "page",
    palette = "palette",
    panorama = "panorama",
    sports_bar = "sports_bar",
    storefront = "storefront",
    wb_sunny = "wb_sunny",
    work = "work",
    car = "car",
    savings = "savings",
    policy = "policy",
    copyright = "copyright",
    stories = "stories",
    outbound = "outbound",
    inbound = "inbound",
    explore = "explore",
    support = "support",
    summarize = "summarize",
    menu_book = "menu_book",
    pin = "pin",
    podcasts = "podcasts",
    verified = "verified",
    flaky = "flaky",
    water_drop = "water_drop",
    keyboard = "keyboard",
    cruelty_free = "cruelty_free",
    flag_circle = "flag_circle",
    play_circle = "play_circle",
    toys = "toys",
    movie = "movie",
    badge = "badge",
    sports_esports = "sports_esports",
    school = "school",
    headset = "headset",
    medal = "medal",
    language = "language",
    local_shipping = "local_shipping",
    local_taxi = "local_taxi",
    directions_boat = "directions_boat",
    moped = "moped",
    airport_shuttle = "airport_shuttle",
    "public" = "public"
}

export enum BlabDefWebhookType {
    BLAB_ITEM_CREATE = "BLAB_ITEM_CREATE",
    BLAB_ITEM_UPDATE = "BLAB_ITEM_UPDATE",
    BLAB_ITEM_DELETE = "BLAB_ITEM_DELETE",
    BLAB_ITEM_RESTORE = "BLAB_ITEM_RESTORE",
    BLAB_ITEM_COMMENT_CREATE = "BLAB_ITEM_COMMENT_CREATE",
    BLAB_ITEM_COMMENT_DELETE = "BLAB_ITEM_COMMENT_DELETE",
    BLAB_DEF_UPDATE = "BLAB_DEF_UPDATE",
    BLAB_DEF_DELETE = "BLAB_DEF_DELETE"
}

export enum BlabItemActivityCreatedViaType {
    IMPORT = "IMPORT",
    ONBOARDING = "ONBOARDING",
    TEMPLATE = "TEMPLATE",
    MANUAL = "MANUAL",
    API = "API",
    WORKFLOW = "WORKFLOW",
    BLAB_DEF_DUPLICATION = "BLAB_DEF_DUPLICATION",
    INBOUND_PUBLIC_EMAIL = "INBOUND_PUBLIC_EMAIL",
    INBOUND_PRIVATE_EMAIL = "INBOUND_PRIVATE_EMAIL"
}

export enum BlabItemActivityType {
    BLAB_ITEM_CREATED = "BLAB_ITEM_CREATED",
    BLAB_ITEM_COMMENT = "BLAB_ITEM_COMMENT",
    BLAB_ITEM_REVISION = "BLAB_ITEM_REVISION",
    BLAB_ITEM_DELETED = "BLAB_ITEM_DELETED",
    BLAB_ITEM_RESTORED = "BLAB_ITEM_RESTORED",
    BLAB_ITEM_INVITATION = "BLAB_ITEM_INVITATION"
}

export enum BlabItemCreatedViaType {
    EMAIL_TO_APP = "EMAIL_TO_APP",
    WEB_CLIENT = "WEB_CLIENT"
}

export enum BlabItemCreationType {
    IMPORT = "IMPORT",
    ONBOARDING = "ONBOARDING",
    TEMPLATE = "TEMPLATE",
    MANUAL = "MANUAL",
    API = "API",
    WORKFLOW = "WORKFLOW",
    BLAB_DEF_DUPLICATION = "BLAB_DEF_DUPLICATION",
    INBOUND_PUBLIC_EMAIL = "INBOUND_PUBLIC_EMAIL",
    INBOUND_PRIVATE_EMAIL = "INBOUND_PRIVATE_EMAIL"
}

export enum BlabItemIsTemplateEnum {
    PRIVATE = "PRIVATE",
    PUBLIC = "PUBLIC"
}

export enum BlabItemLastModifiedViaType {
    EMAIL_TO_APP = "EMAIL_TO_APP",
    WEB_CLIENT = "WEB_CLIENT"
}

export enum BlabItemMetaType {
    ID = "ID",
    CREATED_AT = "CREATED_AT",
    ACL = "ACL",
    BLAB_DEF_BLAB_ITEM_ID = "BLAB_DEF_BLAB_ITEM_ID"
}

export enum BlabItemRevisionType {
    SINGLE_TEXT = "SINGLE_TEXT",
    MULTI_TEXT = "MULTI_TEXT",
    SINGLE_CATEGORY = "SINGLE_CATEGORY",
    MULTI_CATEGORY = "MULTI_CATEGORY",
    SINGLE_RELATION = "SINGLE_RELATION",
    MULTI_RELATION = "MULTI_RELATION",
    INCOMING_RELATION = "INCOMING_RELATION",
    SINGLE_ATTACHMENT = "SINGLE_ATTACHMENT",
    MULTI_ATTACHMENT = "MULTI_ATTACHMENT",
    MULTI_IMAGE = "MULTI_IMAGE",
    SINGLE_USER = "SINGLE_USER",
    MULTI_USER = "MULTI_USER",
    SINGLE_DATE = "SINGLE_DATE",
    RANGE_DATE = "RANGE_DATE",
    CHECKLIST = "CHECKLIST",
    MULTI_LINK = "MULTI_LINK",
    NUMBER = "NUMBER",
    STATUS = "STATUS",
    MULTI_EMAIL = "MULTI_EMAIL",
    MULTI_PHONE = "MULTI_PHONE",
    SINGLE_LOCATION = "SINGLE_LOCATION",
    CALCULATION = "CALCULATION"
}

export enum BlabItemsSortingProperty {
    CHECKLIST_STATUS = "CHECKLIST_STATUS",
    CHECKLIST_TITLE = "CHECKLIST_TITLE",
    CHECKLIST_ASSIGNEE = "CHECKLIST_ASSIGNEE",
    CHECKLIST_DUE_AT = "CHECKLIST_DUE_AT"
}

export enum BlabViewBoardCardSizeType {
    SMALL = "SMALL",
    MEDIUM = "MEDIUM",
    LARGE = "LARGE"
}

export enum BlabViewLayout {
    BADGES = "BADGES",
    TABLE = "TABLE",
    LIST = "LIST",
    BOARD = "BOARD"
}

export enum BlabViewShowProgressType {
    NONE = "NONE",
    BLAB_VIEW = "BLAB_VIEW",
    SPLIT_BY = "SPLIT_BY",
    ALL = "ALL"
}

export enum BlabViewSplitByPeriod {
    DAY = "DAY",
    WEEKDAY = "WEEKDAY",
    WEEK = "WEEK",
    MONTH = "MONTH",
    YEAR = "YEAR"
}

export enum BlabViewSplitBySorting {
    LABEL_ASC = "LABEL_ASC",
    LABEL_DESC = "LABEL_DESC",
    VALUE_ASC = "VALUE_ASC",
    VALUE_DESC = "VALUE_DESC"
}

export enum CalculationFilterMatchType {
    EMPTY = "EMPTY",
    NOT_EMPTY = "NOT_EMPTY",
    EQUAL = "EQUAL",
    NOT_EQUAL = "NOT_EQUAL",
    CONTAINS = "CONTAINS",
    NOT_CONTAINS = "NOT_CONTAINS",
    STARTS_WITH = "STARTS_WITH",
    ENDS_WITH = "ENDS_WITH",
    SMALLER = "SMALLER",
    SMALLER_OR_EQUAL = "SMALLER_OR_EQUAL",
    LARGER = "LARGER",
    LARGER_OR_EQUAL = "LARGER_OR_EQUAL",
    IS_WITHIN = "IS_WITHIN",
    IS_BEFORE = "IS_BEFORE",
    IS_AFTER = "IS_AFTER",
    IS_ON_OR_BEFORE = "IS_ON_OR_BEFORE",
    IS_ON_OR_AFTER = "IS_ON_OR_AFTER",
    IS_ON_WEEKDAY = "IS_ON_WEEKDAY",
    IS_IN_QUARTER_OF_YEAR = "IS_IN_QUARTER_OF_YEAR",
    IS_IN_MONTH_OF_YEAR = "IS_IN_MONTH_OF_YEAR"
}

export enum CalculationFlimStatType {
    COUNT = "COUNT",
    COUNT_NOT_EMPTY = "COUNT_NOT_EMPTY",
    COUNT_EMPTY = "COUNT_EMPTY",
    PERCENT_EMPTY = "PERCENT_EMPTY",
    PERCENT_NOT_EMPTY = "PERCENT_NOT_EMPTY",
    SUM = "SUM",
    AVG = "AVG",
    MEDIAN = "MEDIAN",
    MIN = "MIN",
    MAX = "MAX"
}

export enum CalculationRelativeDateRangeType {
    CURRENT_WEEK = "CURRENT_WEEK",
    CURRENT_MONTH = "CURRENT_MONTH",
    CURRENT_YEAR = "CURRENT_YEAR",
    PAST_WEEK = "PAST_WEEK",
    PAST_MONTH = "PAST_MONTH",
    PAST_YEAR = "PAST_YEAR",
    NEXT_WEEK = "NEXT_WEEK",
    NEXT_MONTH = "NEXT_MONTH",
    NEXT_YEAR = "NEXT_YEAR",
    NEXT_NUM_DAYS = "NEXT_NUM_DAYS",
    PAST_NUM_DAYS = "PAST_NUM_DAYS",
    EXACT_RANGE = "EXACT_RANGE"
}

export enum CalculationRelativeDateType {
    TODAY = "TODAY",
    TOMORROW = "TOMORROW",
    YESTERDAY = "YESTERDAY",
    NUM_DAYS_AGO = "NUM_DAYS_AGO",
    NUM_DAYS_FROM_NOW = "NUM_DAYS_FROM_NOW",
    NUM_WEEKS_AGO = "NUM_WEEKS_AGO",
    NUM_MONTHS_AGO = "NUM_MONTHS_AGO",
    NUM_WEEKS_FROM_NOW = "NUM_WEEKS_FROM_NOW",
    NUM_MONTHS_FROM_NOW = "NUM_MONTHS_FROM_NOW",
    EXACT_DATE = "EXACT_DATE"
}

export enum CalculationScriptResultRejectionReason {
    ONLY_STATIC_VALUES = "ONLY_STATIC_VALUES",
    UNDEFINED_RETURN_VALUE = "UNDEFINED_RETURN_VALUE",
    TIMEOUT_EXCEEDED = "TIMEOUT_EXCEEDED",
    SYNTAX_ERROR = "SYNTAX_ERROR",
    REFERENCE_ERROR = "REFERENCE_ERROR",
    RESULT_INFINITY = "RESULT_INFINITY",
    RESULT_INVALID_NUMBER = "RESULT_INVALID_NUMBER",
    UNKNOWN_VARIABLE = "UNKNOWN_VARIABLE",
    UNHANDLED_EXCEPTION = "UNHANDLED_EXCEPTION",
    UNHANDLED_RESULT_TYPE = "UNHANDLED_RESULT_TYPE",
    CIRCULAR_DEPENDENCY = "CIRCULAR_DEPENDENCY",
    RECURSION_DEPTH_LIMIT_EXCEEDED = "RECURSION_DEPTH_LIMIT_EXCEEDED",
    UNIQUE_REFERENCES_LIMIT_EXCEEDED = "UNIQUE_REFERENCES_LIMIT_EXCEEDED",
    RESULT_TYPE_CHANGED = "RESULT_TYPE_CHANGED"
}

export enum CalendarEventType {
    BLAB_ITEM_EVENT = "BLAB_ITEM_EVENT",
    REMINDER_EVENT = "REMINDER_EVENT",
    CHECKLIST_ENTRY_EVENT = "CHECKLIST_ENTRY_EVENT"
}

export enum CallLinkProtocol {
    CALLTO = "CALLTO",
    TEL = "TEL"
}

export enum CategoryFlimLayout {
    INLINE = "INLINE",
    LIST = "LIST",
    DROPDOWN = "DROPDOWN"
}

export enum ChangeUserPasswordResultType {
    SUCCEEDED = "SUCCEEDED",
    INVALID_CURRENT_PASSWORD = "INVALID_CURRENT_PASSWORD"
}

export enum ChatActivityType {
    MESSAGE = "MESSAGE",
    USER_JOINED = "USER_JOINED",
    USER_LEFT = "USER_LEFT",
    CONVERSATION_CREATED = "CONVERSATION_CREATED",
    GROUP_NAME_CHANGED = "GROUP_NAME_CHANGED",
    GROUP_THUMBNAIL_CHANGED = "GROUP_THUMBNAIL_CHANGED",
    VIDEO_CALL = "VIDEO_CALL"
}

export enum ChecklistFilterEntryProperty {
    TITLE = "TITLE",
    ASSIGNEE = "ASSIGNEE",
    STATUS = "STATUS",
    DUE_DATE = "DUE_DATE"
}

export enum ChecklistFilterMatchType {
    EMPTY = "EMPTY",
    NOT_EMPTY = "NOT_EMPTY",
    ANY_CONTAINS = "ANY_CONTAINS",
    ANY_DOES_NOT_CONTAIN = "ANY_DOES_NOT_CONTAIN",
    ANY_IS_EMPTY = "ANY_IS_EMPTY",
    ANY_IS_NOT_EMPTY = "ANY_IS_NOT_EMPTY",
    ALL_ARE = "ALL_ARE",
    ANY_IS = "ANY_IS",
    NONE_IS = "NONE_IS",
    ANY_IS_NOT = "ANY_IS_NOT",
    ANY_IS_WITHIN = "ANY_IS_WITHIN",
    ANY_IS_BEFORE = "ANY_IS_BEFORE",
    ANY_IS_AFTER = "ANY_IS_AFTER",
    ANY_IS_ON_OR_BEFORE = "ANY_IS_ON_OR_BEFORE",
    ANY_IS_ON_OR_AFTER = "ANY_IS_ON_OR_AFTER",
    ANY_IS_ANY_OF = "ANY_IS_ANY_OF",
    ANY_IS_NONE_OF = "ANY_IS_NONE_OF"
}

export enum ChecklistFlimStatType {
    COUNT = "COUNT",
    COUNT_NOT_EMPTY = "COUNT_NOT_EMPTY",
    COUNT_EMPTY = "COUNT_EMPTY",
    PERCENT_EMPTY = "PERCENT_EMPTY",
    PERCENT_NOT_EMPTY = "PERCENT_NOT_EMPTY",
    COUNT_COMPLETE = "COUNT_COMPLETE",
    COUNT_INCOMPLETE = "COUNT_INCOMPLETE"
}

export enum ChecklistRelativeDateRangeType {
    CURRENT_WEEK = "CURRENT_WEEK",
    CURRENT_MONTH = "CURRENT_MONTH",
    CURRENT_YEAR = "CURRENT_YEAR",
    PAST_WEEK = "PAST_WEEK",
    PAST_MONTH = "PAST_MONTH",
    PAST_YEAR = "PAST_YEAR",
    NEXT_WEEK = "NEXT_WEEK",
    NEXT_MONTH = "NEXT_MONTH",
    NEXT_YEAR = "NEXT_YEAR",
    NEXT_NUM_DAYS = "NEXT_NUM_DAYS",
    PAST_NUM_DAYS = "PAST_NUM_DAYS"
}

export enum ChecklistRelativeDateType {
    TODAY = "TODAY",
    TOMORROW = "TOMORROW",
    YESTERDAY = "YESTERDAY",
    NUM_DAYS_AGO = "NUM_DAYS_AGO",
    NUM_DAYS_FROM_NOW = "NUM_DAYS_FROM_NOW",
    NUM_WEEKS_AGO = "NUM_WEEKS_AGO",
    NUM_MONTHS_AGO = "NUM_MONTHS_AGO",
    NUM_WEEKS_FROM_NOW = "NUM_WEEKS_FROM_NOW",
    NUM_MONTHS_FROM_NOW = "NUM_MONTHS_FROM_NOW",
    EXACT_DATE = "EXACT_DATE"
}

export enum ConversationType {
    DIALOG = "DIALOG",
    GROUP = "GROUP"
}

export enum CreatedAtFilterMatchType {
    IS = "IS",
    IS_NOT = "IS_NOT",
    IS_WITHIN = "IS_WITHIN",
    IS_BEFORE = "IS_BEFORE",
    IS_AFTER = "IS_AFTER",
    IS_ON_OR_BEFORE = "IS_ON_OR_BEFORE",
    IS_ON_OR_AFTER = "IS_ON_OR_AFTER",
    EMPTY = "EMPTY",
    NOT_EMPTY = "NOT_EMPTY",
    IS_ON_WEEKDAY = "IS_ON_WEEKDAY",
    IS_IN_QUARTER_OF_YEAR = "IS_IN_QUARTER_OF_YEAR",
    IS_IN_MONTH_OF_YEAR = "IS_IN_MONTH_OF_YEAR"
}

export enum CreatedAtFlimStatType {
    COUNT = "COUNT",
    COUNT_NOT_EMPTY = "COUNT_NOT_EMPTY",
    COUNT_EMPTY = "COUNT_EMPTY",
    PERCENT_EMPTY = "PERCENT_EMPTY",
    PERCENT_NOT_EMPTY = "PERCENT_NOT_EMPTY"
}

export enum CreatedAtRelativeDateRangeType {
    CURRENT_WEEK = "CURRENT_WEEK",
    CURRENT_MONTH = "CURRENT_MONTH",
    CURRENT_YEAR = "CURRENT_YEAR",
    PAST_WEEK = "PAST_WEEK",
    PAST_MONTH = "PAST_MONTH",
    PAST_YEAR = "PAST_YEAR",
    NEXT_WEEK = "NEXT_WEEK",
    NEXT_MONTH = "NEXT_MONTH",
    NEXT_YEAR = "NEXT_YEAR",
    NEXT_NUM_DAYS = "NEXT_NUM_DAYS",
    PAST_NUM_DAYS = "PAST_NUM_DAYS",
    EXACT_RANGE = "EXACT_RANGE"
}

export enum CreatedAtRelativeDateType {
    TODAY = "TODAY",
    TOMORROW = "TOMORROW",
    YESTERDAY = "YESTERDAY",
    NUM_DAYS_AGO = "NUM_DAYS_AGO",
    NUM_DAYS_FROM_NOW = "NUM_DAYS_FROM_NOW",
    NUM_WEEKS_AGO = "NUM_WEEKS_AGO",
    NUM_MONTHS_AGO = "NUM_MONTHS_AGO",
    NUM_WEEKS_FROM_NOW = "NUM_WEEKS_FROM_NOW",
    NUM_MONTHS_FROM_NOW = "NUM_MONTHS_FROM_NOW",
    EXACT_DATE = "EXACT_DATE"
}

export enum CreatedByFilterMatchType {
    IS = "IS",
    IS_NOT = "IS_NOT",
    IS_ANY = "IS_ANY",
    IS_NONE = "IS_NONE",
    EMPTY = "EMPTY",
    NOT_EMPTY = "NOT_EMPTY"
}

export enum CreatedByFlimStatType {
    COUNT = "COUNT",
    COUNT_NOT_EMPTY = "COUNT_NOT_EMPTY",
    COUNT_EMPTY = "COUNT_EMPTY",
    PERCENT_EMPTY = "PERCENT_EMPTY",
    PERCENT_NOT_EMPTY = "PERCENT_NOT_EMPTY"
}

export enum CreateOrganizationInvitationResult {
    INVITATION_CREATED = "INVITATION_CREATED",
    EMAIL_ALREADY_IN_USE = "EMAIL_ALREADY_IN_USE",
    EMAIL_ALREADY_INVITED = "EMAIL_ALREADY_INVITED"
}

export enum DateFormat {
    SLASH_MM_DD_YYYY = "SLASH_MM_DD_YYYY",
    SLASH_DD_MM_YYYY = "SLASH_DD_MM_YYYY",
    DOT_DD_MM_YYYY = "DOT_DD_MM_YYYY"
}

export enum DecimalFormat {
    DECIMAL_SEPARATOR_COMMA = "DECIMAL_SEPARATOR_COMMA",
    DECIMAL_SEPARATOR_DOT = "DECIMAL_SEPARATOR_DOT"
}

export enum DirectNotificationType {
    USER_MENTION = "USER_MENTION",
    USER_REFERENCE = "USER_REFERENCE",
    WORKSPACE_USER_ADDED = "WORKSPACE_USER_ADDED",
    BLAB_DEF_INVITATION_CREATED = "BLAB_DEF_INVITATION_CREATED",
    USER_ASSIGN = "USER_ASSIGN",
    USER_GROUP_MEMBERSHIP_ADDED = "USER_GROUP_MEMBERSHIP_ADDED"
}

export enum DuplicateSharedWorkspaceResultRejectionReason {
    INSUFFICIENT_ORG_PERMISSION = "INSUFFICIENT_ORG_PERMISSION",
    DUPLICATE_DISABLED = "DUPLICATE_DISABLED"
}

export enum EmailAddressType {
    WORK = "WORK",
    HOME = "HOME",
    OTHER = "OTHER"
}

export enum EmailResultType {
    VALID_COMPANY_EMAIL = "VALID_COMPANY_EMAIL",
    VALID_EMAIL_PROVIDER = "VALID_EMAIL_PROVIDER",
    INVALID = "INVALID"
}

export enum FirstWeekday {
    SATURDAY = "SATURDAY",
    SUNDAY = "SUNDAY",
    MONDAY = "MONDAY"
}

export enum FlimType {
    SINGLE_TEXT = "SINGLE_TEXT",
    MULTI_TEXT = "MULTI_TEXT",
    SINGLE_CATEGORY = "SINGLE_CATEGORY",
    MULTI_CATEGORY = "MULTI_CATEGORY",
    SINGLE_RELATION = "SINGLE_RELATION",
    MULTI_RELATION = "MULTI_RELATION",
    SINGLE_ATTACHMENT = "SINGLE_ATTACHMENT",
    MULTI_ATTACHMENT = "MULTI_ATTACHMENT",
    SINGLE_USER = "SINGLE_USER",
    MULTI_USER = "MULTI_USER",
    SINGLE_DATE = "SINGLE_DATE",
    RANGE_DATE = "RANGE_DATE",
    CHECKLIST = "CHECKLIST",
    MULTI_LINK = "MULTI_LINK",
    CREATED_AT = "CREATED_AT",
    CREATED_BY = "CREATED_BY",
    LAST_MODIFIED_AT = "LAST_MODIFIED_AT",
    LAST_MODIFIED_BY = "LAST_MODIFIED_BY",
    NUMBER = "NUMBER",
    STATUS = "STATUS",
    CALCULATION = "CALCULATION",
    MULTI_EMAIL = "MULTI_EMAIL",
    MULTI_PHONE = "MULTI_PHONE",
    MULTI_IMAGE = "MULTI_IMAGE",
    SINGLE_LOCATION = "SINGLE_LOCATION",
    UNIQUE_ID = "UNIQUE_ID"
}

export enum FlimValAttachmentCreatedViaType {
    EMAIL_TO_APP = "EMAIL_TO_APP",
    WEB_CLIENT = "WEB_CLIENT"
}

export enum FlimValImageCreatedViaType {
    EMAIL_TO_APP = "EMAIL_TO_APP",
    WEB_CLIENT = "WEB_CLIENT"
}

export enum FocusCompletedFilteringType {
    ALL = "ALL",
    ONLY_INCOMPLETE = "ONLY_INCOMPLETE",
    ONLY_COMPLETED_ALL = "ONLY_COMPLETED_ALL",
    ONLY_COMPLETED_TODAY = "ONLY_COMPLETED_TODAY",
    ONLY_COMPLETED_ONE_WEEK = "ONLY_COMPLETED_ONE_WEEK",
    ONLY_COMPLETED_TWO_WEEKS = "ONLY_COMPLETED_TWO_WEEKS"
}

export enum FocusDueDateSectionType {
    DUE_DATE = "DUE_DATE",
    NO_DUE_DATE = "NO_DUE_DATE"
}

export enum FocusEntryType {
    FOCUS_BLAB_ITEM = "FOCUS_BLAB_ITEM",
    FOCUS_REMINDER = "FOCUS_REMINDER",
    FOCUS_CHECKLIST_ENTRY = "FOCUS_CHECKLIST_ENTRY"
}

export enum FocusSortingType {
    NONE = "NONE",
    DUE_DATE = "DUE_DATE"
}

export enum GetBlabItemActivityFilterType {
    UPDATES_ONLY = "UPDATES_ONLY",
    COMMENTS_ONLY = "COMMENTS_ONLY"
}

export enum IdFilterMatchType {
    IS = "IS",
    IS_NOT = "IS_NOT"
}

export enum ImageFlimLayout {
    SIZE_SMALL = "SIZE_SMALL",
    SIZE_MEDIUM = "SIZE_MEDIUM",
    SIZE_LARGE = "SIZE_LARGE"
}

export enum InboundEmailField {
    EMAIL_FROM = "EMAIL_FROM",
    EMAIL_CC = "EMAIL_CC",
    EMAIL_SUBJECT = "EMAIL_SUBJECT",
    EMAIL_BODY = "EMAIL_BODY",
    EMAIL_ATTACHMENTS = "EMAIL_ATTACHMENTS"
}

export enum InitialFocusSectionType {
    RECENTLY_ASSIGNED = "RECENTLY_ASSIGNED",
    TODAY = "TODAY",
    UPCOMING = "UPCOMING",
    LATER = "LATER"
}

export enum InvoiceItemType {
    LICENSE = "LICENSE",
    PRORATION = "PRORATION",
    DISCOUNT = "DISCOUNT",
    TAX = "TAX",
    CREDIT = "CREDIT"
}

export enum Language {
    EN = "EN",
    DE = "DE"
}

export enum LastModifiedAtFilterMatchType {
    IS = "IS",
    IS_NOT = "IS_NOT",
    IS_WITHIN = "IS_WITHIN",
    IS_BEFORE = "IS_BEFORE",
    IS_AFTER = "IS_AFTER",
    IS_ON_OR_BEFORE = "IS_ON_OR_BEFORE",
    IS_ON_OR_AFTER = "IS_ON_OR_AFTER",
    EMPTY = "EMPTY",
    NOT_EMPTY = "NOT_EMPTY",
    IS_ON_WEEKDAY = "IS_ON_WEEKDAY",
    IS_IN_QUARTER_OF_YEAR = "IS_IN_QUARTER_OF_YEAR",
    IS_IN_MONTH_OF_YEAR = "IS_IN_MONTH_OF_YEAR"
}

export enum LastModifiedAtFlimStatType {
    COUNT = "COUNT",
    COUNT_NOT_EMPTY = "COUNT_NOT_EMPTY",
    COUNT_EMPTY = "COUNT_EMPTY",
    PERCENT_EMPTY = "PERCENT_EMPTY",
    PERCENT_NOT_EMPTY = "PERCENT_NOT_EMPTY"
}

export enum LastModifiedAtRelativeDateRangeType {
    CURRENT_WEEK = "CURRENT_WEEK",
    CURRENT_MONTH = "CURRENT_MONTH",
    CURRENT_YEAR = "CURRENT_YEAR",
    PAST_WEEK = "PAST_WEEK",
    PAST_MONTH = "PAST_MONTH",
    PAST_YEAR = "PAST_YEAR",
    NEXT_WEEK = "NEXT_WEEK",
    NEXT_MONTH = "NEXT_MONTH",
    NEXT_YEAR = "NEXT_YEAR",
    NEXT_NUM_DAYS = "NEXT_NUM_DAYS",
    PAST_NUM_DAYS = "PAST_NUM_DAYS",
    EXACT_RANGE = "EXACT_RANGE"
}

export enum LastModifiedAtRelativeDateType {
    TODAY = "TODAY",
    TOMORROW = "TOMORROW",
    YESTERDAY = "YESTERDAY",
    NUM_DAYS_AGO = "NUM_DAYS_AGO",
    NUM_DAYS_FROM_NOW = "NUM_DAYS_FROM_NOW",
    NUM_WEEKS_AGO = "NUM_WEEKS_AGO",
    NUM_MONTHS_AGO = "NUM_MONTHS_AGO",
    NUM_WEEKS_FROM_NOW = "NUM_WEEKS_FROM_NOW",
    NUM_MONTHS_FROM_NOW = "NUM_MONTHS_FROM_NOW",
    EXACT_DATE = "EXACT_DATE"
}

export enum LastModifiedByFilterMatchType {
    IS = "IS",
    IS_NOT = "IS_NOT",
    IS_ANY = "IS_ANY",
    IS_NONE = "IS_NONE",
    EMPTY = "EMPTY",
    NOT_EMPTY = "NOT_EMPTY"
}

export enum LastModifiedByFlimStatType {
    COUNT = "COUNT",
    COUNT_NOT_EMPTY = "COUNT_NOT_EMPTY",
    COUNT_EMPTY = "COUNT_EMPTY",
    PERCENT_EMPTY = "PERCENT_EMPTY",
    PERCENT_NOT_EMPTY = "PERCENT_NOT_EMPTY"
}

export enum MeasurementSystem {
    METRIC = "METRIC",
    IMPERIAL_US = "IMPERIAL_US"
}

export enum MonthOfYear {
    JANUARY = "JANUARY",
    FEBRUARY = "FEBRUARY",
    MARCH = "MARCH",
    APRIL = "APRIL",
    MAY = "MAY",
    JUNE = "JUNE",
    JULY = "JULY",
    AUGUST = "AUGUST",
    SEPTEMBER = "SEPTEMBER",
    OCTOBER = "OCTOBER",
    NOVEMBER = "NOVEMBER",
    DECEMBER = "DECEMBER"
}

export enum MultiAttachmentFilterMatchType {
    CONTAINS = "CONTAINS",
    NOT_CONTAINS = "NOT_CONTAINS",
    ONE = "ONE",
    NONE = "NONE",
    EMPTY = "EMPTY",
    NOT_EMPTY = "NOT_EMPTY",
    STARTS_WITH = "STARTS_WITH",
    ENDS_WITH = "ENDS_WITH"
}

export enum MultiAttachmentFlimStatType {
    COUNT = "COUNT",
    COUNT_NOT_EMPTY = "COUNT_NOT_EMPTY",
    COUNT_EMPTY = "COUNT_EMPTY",
    PERCENT_EMPTY = "PERCENT_EMPTY",
    PERCENT_NOT_EMPTY = "PERCENT_NOT_EMPTY"
}

export enum MultiCategoryFilterMatchType {
    ONE = "ONE",
    ALL = "ALL",
    EXACT = "EXACT",
    NONE = "NONE",
    EMPTY = "EMPTY",
    NOT_EMPTY = "NOT_EMPTY"
}

export enum MultiCategoryFlimStatType {
    COUNT = "COUNT",
    COUNT_NOT_EMPTY = "COUNT_NOT_EMPTY",
    COUNT_EMPTY = "COUNT_EMPTY",
    PERCENT_EMPTY = "PERCENT_EMPTY",
    PERCENT_NOT_EMPTY = "PERCENT_NOT_EMPTY"
}

export enum MultiEmailFilterMatchType {
    FULLY_INCLUDES = "FULLY_INCLUDES",
    CONTAINS = "CONTAINS",
    NOT_CONTAINS = "NOT_CONTAINS",
    EMPTY = "EMPTY",
    NOT_EMPTY = "NOT_EMPTY",
    STARTS_WITH = "STARTS_WITH",
    ENDS_WITH = "ENDS_WITH"
}

export enum MultiEmailFlimStatType {
    COUNT = "COUNT",
    COUNT_NOT_EMPTY = "COUNT_NOT_EMPTY",
    COUNT_EMPTY = "COUNT_EMPTY",
    PERCENT_EMPTY = "PERCENT_EMPTY",
    PERCENT_NOT_EMPTY = "PERCENT_NOT_EMPTY"
}

export enum MultiImageFilterMatchType {
    CONTAINS = "CONTAINS",
    NOT_CONTAINS = "NOT_CONTAINS",
    ONE = "ONE",
    NONE = "NONE",
    EMPTY = "EMPTY",
    NOT_EMPTY = "NOT_EMPTY",
    STARTS_WITH = "STARTS_WITH",
    ENDS_WITH = "ENDS_WITH"
}

export enum MultiImageFlimStatType {
    COUNT = "COUNT",
    COUNT_NOT_EMPTY = "COUNT_NOT_EMPTY",
    COUNT_EMPTY = "COUNT_EMPTY",
    PERCENT_EMPTY = "PERCENT_EMPTY",
    PERCENT_NOT_EMPTY = "PERCENT_NOT_EMPTY"
}

export enum MultiLinkFilterMatchType {
    CONTAINS = "CONTAINS",
    NOT_CONTAINS = "NOT_CONTAINS",
    EMPTY = "EMPTY",
    NOT_EMPTY = "NOT_EMPTY",
    STARTS_WITH = "STARTS_WITH",
    ENDS_WITH = "ENDS_WITH"
}

export enum MultiLinkFlimStatType {
    COUNT = "COUNT",
    COUNT_NOT_EMPTY = "COUNT_NOT_EMPTY",
    COUNT_EMPTY = "COUNT_EMPTY",
    PERCENT_EMPTY = "PERCENT_EMPTY",
    PERCENT_NOT_EMPTY = "PERCENT_NOT_EMPTY"
}

export enum MultiPhoneFilterMatchType {
    FULLY_INCLUDES = "FULLY_INCLUDES",
    CONTAINS = "CONTAINS",
    NOT_CONTAINS = "NOT_CONTAINS",
    EMPTY = "EMPTY",
    NOT_EMPTY = "NOT_EMPTY",
    STARTS_WITH = "STARTS_WITH",
    ENDS_WITH = "ENDS_WITH"
}

export enum MultiPhoneFlimStatType {
    COUNT = "COUNT",
    COUNT_NOT_EMPTY = "COUNT_NOT_EMPTY",
    COUNT_EMPTY = "COUNT_EMPTY",
    PERCENT_EMPTY = "PERCENT_EMPTY",
    PERCENT_NOT_EMPTY = "PERCENT_NOT_EMPTY"
}

export enum MultiRelationFilterMatchType {
    ONE = "ONE",
    ALL = "ALL",
    EXACT = "EXACT",
    NONE = "NONE",
    EMPTY = "EMPTY",
    NOT_EMPTY = "NOT_EMPTY"
}

export enum MultiRelationFlimStatType {
    COUNT = "COUNT",
    COUNT_NOT_EMPTY = "COUNT_NOT_EMPTY",
    COUNT_EMPTY = "COUNT_EMPTY",
    PERCENT_EMPTY = "PERCENT_EMPTY",
    PERCENT_NOT_EMPTY = "PERCENT_NOT_EMPTY"
}

export enum MultiTextFilterMatchType {
    CONTAINS = "CONTAINS",
    NOT_CONTAINS = "NOT_CONTAINS",
    IS = "IS",
    IS_NOT = "IS_NOT",
    EMPTY = "EMPTY",
    NOT_EMPTY = "NOT_EMPTY",
    STARTS_WITH = "STARTS_WITH",
    ENDS_WITH = "ENDS_WITH"
}

export enum MultiTextFlimStatType {
    COUNT = "COUNT",
    COUNT_NOT_EMPTY = "COUNT_NOT_EMPTY",
    COUNT_EMPTY = "COUNT_EMPTY",
    PERCENT_EMPTY = "PERCENT_EMPTY",
    PERCENT_NOT_EMPTY = "PERCENT_NOT_EMPTY"
}

export enum MultiTextFlimValRevisionChangeType {
    ADDED = "ADDED",
    REMOVED = "REMOVED",
    ADDED_AND_REMOVED = "ADDED_AND_REMOVED"
}

export enum MultiUserFilterMatchType {
    ONE = "ONE",
    ALL = "ALL",
    EXACT = "EXACT",
    NONE = "NONE",
    EMPTY = "EMPTY",
    NOT_EMPTY = "NOT_EMPTY"
}

export enum MultiUserFlimStatType {
    COUNT = "COUNT",
    COUNT_NOT_EMPTY = "COUNT_NOT_EMPTY",
    COUNT_EMPTY = "COUNT_EMPTY",
    PERCENT_EMPTY = "PERCENT_EMPTY",
    PERCENT_NOT_EMPTY = "PERCENT_NOT_EMPTY"
}

export enum NewsfeedActivityType {
    BLAB_ITEM_ACTIVITY = "BLAB_ITEM_ACTIVITY",
    BLAB_DEF_ACTIVITY = "BLAB_DEF_ACTIVITY",
    WORKSPACE_ACTIVITY = "WORKSPACE_ACTIVITY",
    ORG_ACTIVITY = "ORG_ACTIVITY",
    USER_ACTIVITY = "USER_ACTIVITY"
}

export enum NotificationType {
    BLAB_ITEM_NOTIFICATION = "BLAB_ITEM_NOTIFICATION",
    BLAB_DEF_NOTIFICATION = "BLAB_DEF_NOTIFICATION",
    USER_NOTIFICATION = "USER_NOTIFICATION",
    ORGANIZATION_NOTIFICATION = "ORGANIZATION_NOTIFICATION",
    WORKSPACE_NOTIFICATION = "WORKSPACE_NOTIFICATION",
    AUDIO_ROOM_NOTIFICATION = "AUDIO_ROOM_NOTIFICATION",
    WORKFLOW_DEF_NOTIFICATION = "WORKFLOW_DEF_NOTIFICATION",
    USER_GROUP_NOTIFICATION = "USER_GROUP_NOTIFICATION"
}

export enum NumberFilterMatchType {
    SMALLER = "SMALLER",
    SMALLER_OR_EQUAL = "SMALLER_OR_EQUAL",
    LARGER = "LARGER",
    LARGER_OR_EQUAL = "LARGER_OR_EQUAL",
    EQUAL = "EQUAL",
    NOT_EQUAL = "NOT_EQUAL",
    EMPTY = "EMPTY",
    NOT_EMPTY = "NOT_EMPTY"
}

export enum NumberFlimDefUnitLocation {
    PREFIX = "PREFIX",
    SUFFIX = "SUFFIX"
}

export enum NumberFlimStatType {
    COUNT = "COUNT",
    COUNT_NOT_EMPTY = "COUNT_NOT_EMPTY",
    COUNT_EMPTY = "COUNT_EMPTY",
    PERCENT_EMPTY = "PERCENT_EMPTY",
    PERCENT_NOT_EMPTY = "PERCENT_NOT_EMPTY",
    SUM = "SUM",
    AVG = "AVG",
    MEDIAN = "MEDIAN",
    MIN = "MIN",
    MAX = "MAX"
}

export enum OrganizationActivityType {
    ORG_CREATED = "ORG_CREATED",
    ORG_LOGO_CHANGED = "ORG_LOGO_CHANGED"
}

export enum OrganizationAuthenticationProviderRejectionReason {
    INVALID_CREDENTIALS = "INVALID_CREDENTIALS"
}

export enum OrganizationInvitationStatus {
    REQUESTED = "REQUESTED",
    PENDING = "PENDING",
    ACCEPTED = "ACCEPTED",
    DENIED = "DENIED"
}

export enum OrganizationPlan {
    BASIC = "BASIC",
    STANDARD = "STANDARD",
    ENTERPRISE = "ENTERPRISE"
}

export enum OrganizationRole {
    ORG_GUEST = "ORG_GUEST",
    ORG_MEMBER = "ORG_MEMBER",
    ORG_ADMIN = "ORG_ADMIN",
    ORG_OWNER = "ORG_OWNER"
}

export enum OrganizationSetupIntentionType {
    TASK_MANAGEMENT = "TASK_MANAGEMENT",
    MARKETING = "MARKETING",
    PROJECT_MANAGEMENT = "PROJECT_MANAGEMENT",
    DEVELOPMENT = "DEVELOPMENT",
    STARTUP = "STARTUP",
    SALES = "SALES",
    CUSTOMER_DEVELOPMENT = "CUSTOMER_DEVELOPMENT",
    REAL_ESTATE = "REAL_ESTATE",
    TAPE_PARTNER_PROGRAM = "TAPE_PARTNER_PROGRAM"
}

export enum OrganizationSmtpSettingsRejectionReason {
    INVALID_CREDENTIALS = "INVALID_CREDENTIALS"
}

export enum PayloadContentType {
    APPLICATION_JSON = "APPLICATION_JSON",
    TEXT_PLAIN = "TEXT_PLAIN",
    FORM_URL_ENCODED = "FORM_URL_ENCODED",
    MULTIPART_FORM_DATA = "MULTIPART_FORM_DATA",
    UNKNOWN = "UNKNOWN",
    XML = "XML"
}

export enum PaymentMethodErrorType {
    ERROR = "ERROR"
}

export enum PaymentMethodType {
    CARD = "CARD",
    SEPA_DEBIT = "SEPA_DEBIT"
}

export enum PerformChangeUserEmailResultType {
    SUCCESS = "SUCCESS",
    CODE_INCORRECT = "CODE_INCORRECT",
    SESSION_EXPIRED = "SESSION_EXPIRED",
    MAX_NUM_TRIES_REACHED = "MAX_NUM_TRIES_REACHED"
}

export enum PhoneNumberType {
    MOBILE = "MOBILE",
    WORK = "WORK",
    HOME = "HOME",
    MAIN = "MAIN",
    WORK_FAX = "WORK_FAX",
    HOME_FAX = "HOME_FAX",
    OTHER = "OTHER"
}

export enum PrivateUrlSharePermissionType {
    URL_SHARE_NOT_SHARED = "URL_SHARE_NOT_SHARED",
    URL_SHARE_ALL_EVENT_INFO = "URL_SHARE_ALL_EVENT_INFO"
}

export enum PublicUrlSharePermissionType {
    URL_SHARE_NOT_SHARED = "URL_SHARE_NOT_SHARED",
    URL_SHARE_ONLY_FREE_OR_BUSY = "URL_SHARE_ONLY_FREE_OR_BUSY",
    URL_SHARE_ALL_EVENT_INFO = "URL_SHARE_ALL_EVENT_INFO"
}

export enum QuarterOfYear {
    Q1 = "Q1",
    Q2 = "Q2",
    Q3 = "Q3",
    Q4 = "Q4"
}

export enum RangeDateFilterMatchType {
    IS = "IS",
    IS_NOT = "IS_NOT",
    IS_WITHIN = "IS_WITHIN",
    IS_BEFORE = "IS_BEFORE",
    IS_AFTER = "IS_AFTER",
    IS_ON_OR_BEFORE = "IS_ON_OR_BEFORE",
    IS_ON_OR_AFTER = "IS_ON_OR_AFTER",
    EMPTY = "EMPTY",
    NOT_EMPTY = "NOT_EMPTY",
    IS_ON_WEEKDAY = "IS_ON_WEEKDAY",
    IS_IN_QUARTER_OF_YEAR = "IS_IN_QUARTER_OF_YEAR",
    IS_IN_MONTH_OF_YEAR = "IS_IN_MONTH_OF_YEAR"
}

export enum RangeDateFlimStatType {
    COUNT = "COUNT",
    COUNT_NOT_EMPTY = "COUNT_NOT_EMPTY",
    COUNT_EMPTY = "COUNT_EMPTY",
    PERCENT_EMPTY = "PERCENT_EMPTY",
    PERCENT_NOT_EMPTY = "PERCENT_NOT_EMPTY"
}

export enum RangeDateRelativeDateRangeType {
    CURRENT_WEEK = "CURRENT_WEEK",
    CURRENT_MONTH = "CURRENT_MONTH",
    CURRENT_YEAR = "CURRENT_YEAR",
    PAST_WEEK = "PAST_WEEK",
    PAST_MONTH = "PAST_MONTH",
    PAST_YEAR = "PAST_YEAR",
    NEXT_WEEK = "NEXT_WEEK",
    NEXT_MONTH = "NEXT_MONTH",
    NEXT_YEAR = "NEXT_YEAR",
    NEXT_NUM_DAYS = "NEXT_NUM_DAYS",
    PAST_NUM_DAYS = "PAST_NUM_DAYS",
    EXACT_RANGE = "EXACT_RANGE"
}

export enum RangeDateRelativeDateType {
    TODAY = "TODAY",
    TOMORROW = "TOMORROW",
    YESTERDAY = "YESTERDAY",
    NUM_DAYS_AGO = "NUM_DAYS_AGO",
    NUM_DAYS_FROM_NOW = "NUM_DAYS_FROM_NOW",
    NUM_WEEKS_AGO = "NUM_WEEKS_AGO",
    NUM_MONTHS_AGO = "NUM_MONTHS_AGO",
    NUM_WEEKS_FROM_NOW = "NUM_WEEKS_FROM_NOW",
    NUM_MONTHS_FROM_NOW = "NUM_MONTHS_FROM_NOW",
    EXACT_DATE = "EXACT_DATE"
}

export enum ReminderStatus {
    INCOMPLETE = "INCOMPLETE",
    COMPLETED = "COMPLETED",
    ARCHIVED = "ARCHIVED"
}

export enum RemoveWorkspaceMembershipRejectionReason {
    LAST_WORKSPACE_ADMIN = "LAST_WORKSPACE_ADMIN"
}

export enum RequestChangeUserEmailVerificationRejectionType {
    INVALID_CURRENT_PASSWORD = "INVALID_CURRENT_PASSWORD",
    EMAIL_UNAVAILABLE = "EMAIL_UNAVAILABLE"
}

export enum ScriptResultType {
    NUMBER = "NUMBER",
    MULTI_TEXT = "MULTI_TEXT",
    SINGLE_DATE = "SINGLE_DATE"
}

export enum SearchHistoryItemType {
    RESULT = "RESULT",
    QUERY = "QUERY"
}

export enum SearchResultType {
    BLAB_DEF = "BLAB_DEF",
    BLAB_ITEM = "BLAB_ITEM",
    USER_PROFILE = "USER_PROFILE",
    WORKSPACE = "WORKSPACE",
    CHAT_MESSAGE = "CHAT_MESSAGE",
    CHAT_GROUP = "CHAT_GROUP"
}

export enum SelectableColor {
    DEFAULT = "DEFAULT",
    GRAY = "GRAY",
    BROWN = "BROWN",
    ORANGE = "ORANGE",
    YELLOW = "YELLOW",
    GREEN = "GREEN",
    BLUE = "BLUE",
    PURPLE = "PURPLE",
    PINK = "PINK",
    RED = "RED"
}

export enum ShareContextRejectionReason {
    INVALID_TOKEN = "INVALID_TOKEN"
}

export enum ShareContextType {
    ORGANIZATION_SHARE = "ORGANIZATION_SHARE",
    WORKSPACE_SHARE = "WORKSPACE_SHARE",
    BLAB_DEF_SHARE = "BLAB_DEF_SHARE",
    BLAB_ITEM_SHARE = "BLAB_ITEM_SHARE"
}

export enum SingleAttachmentFilterMatchType {
    CONTAINS = "CONTAINS",
    NOT_CONTAINS = "NOT_CONTAINS",
    IS = "IS",
    IS_NOT = "IS_NOT",
    EMPTY = "EMPTY",
    NOT_EMPTY = "NOT_EMPTY",
    STARTS_WITH = "STARTS_WITH",
    ENDS_WITH = "ENDS_WITH"
}

export enum SingleAttachmentFlimStatType {
    COUNT = "COUNT",
    COUNT_NOT_EMPTY = "COUNT_NOT_EMPTY",
    COUNT_EMPTY = "COUNT_EMPTY",
    PERCENT_EMPTY = "PERCENT_EMPTY",
    PERCENT_NOT_EMPTY = "PERCENT_NOT_EMPTY"
}

export enum SingleCategoryFilterMatchType {
    IS = "IS",
    IS_NOT = "IS_NOT",
    IS_ANY = "IS_ANY",
    IS_NONE = "IS_NONE",
    EMPTY = "EMPTY",
    NOT_EMPTY = "NOT_EMPTY"
}

export enum SingleCategoryFlimStatType {
    COUNT = "COUNT",
    COUNT_NOT_EMPTY = "COUNT_NOT_EMPTY",
    COUNT_EMPTY = "COUNT_EMPTY",
    PERCENT_EMPTY = "PERCENT_EMPTY",
    PERCENT_NOT_EMPTY = "PERCENT_NOT_EMPTY"
}

export enum SingleDateFilterMatchType {
    IS = "IS",
    IS_NOT = "IS_NOT",
    IS_WITHIN = "IS_WITHIN",
    IS_BEFORE = "IS_BEFORE",
    IS_AFTER = "IS_AFTER",
    IS_ON_OR_BEFORE = "IS_ON_OR_BEFORE",
    IS_ON_OR_AFTER = "IS_ON_OR_AFTER",
    IS_ON_WEEKDAY = "IS_ON_WEEKDAY",
    IS_IN_QUARTER_OF_YEAR = "IS_IN_QUARTER_OF_YEAR",
    IS_IN_MONTH_OF_YEAR = "IS_IN_MONTH_OF_YEAR",
    EMPTY = "EMPTY",
    NOT_EMPTY = "NOT_EMPTY"
}

export enum SingleDateFlimStatType {
    COUNT = "COUNT",
    COUNT_NOT_EMPTY = "COUNT_NOT_EMPTY",
    COUNT_EMPTY = "COUNT_EMPTY",
    PERCENT_EMPTY = "PERCENT_EMPTY",
    PERCENT_NOT_EMPTY = "PERCENT_NOT_EMPTY"
}

export enum SingleDateRelativeDateRangeType {
    CURRENT_WEEK = "CURRENT_WEEK",
    CURRENT_MONTH = "CURRENT_MONTH",
    CURRENT_YEAR = "CURRENT_YEAR",
    PAST_WEEK = "PAST_WEEK",
    PAST_MONTH = "PAST_MONTH",
    PAST_YEAR = "PAST_YEAR",
    NEXT_WEEK = "NEXT_WEEK",
    NEXT_MONTH = "NEXT_MONTH",
    NEXT_YEAR = "NEXT_YEAR",
    NEXT_NUM_DAYS = "NEXT_NUM_DAYS",
    PAST_NUM_DAYS = "PAST_NUM_DAYS",
    EXACT_RANGE = "EXACT_RANGE"
}

export enum SingleDateRelativeDateType {
    TODAY = "TODAY",
    TOMORROW = "TOMORROW",
    YESTERDAY = "YESTERDAY",
    NUM_DAYS_AGO = "NUM_DAYS_AGO",
    NUM_DAYS_FROM_NOW = "NUM_DAYS_FROM_NOW",
    NUM_WEEKS_AGO = "NUM_WEEKS_AGO",
    NUM_MONTHS_AGO = "NUM_MONTHS_AGO",
    NUM_WEEKS_FROM_NOW = "NUM_WEEKS_FROM_NOW",
    NUM_MONTHS_FROM_NOW = "NUM_MONTHS_FROM_NOW",
    EXACT_DATE = "EXACT_DATE"
}

export enum SingleLocationFilterMatchType {
    CONTAINS = "CONTAINS",
    NOT_CONTAINS = "NOT_CONTAINS",
    EMPTY = "EMPTY",
    NOT_EMPTY = "NOT_EMPTY"
}

export enum SingleLocationFlimStatType {
    COUNT = "COUNT",
    COUNT_NOT_EMPTY = "COUNT_NOT_EMPTY",
    COUNT_EMPTY = "COUNT_EMPTY",
    PERCENT_EMPTY = "PERCENT_EMPTY",
    PERCENT_NOT_EMPTY = "PERCENT_NOT_EMPTY"
}

export enum SingleRelationFilterMatchType {
    IS = "IS",
    IS_NOT = "IS_NOT",
    IS_ANY = "IS_ANY",
    IS_NONE = "IS_NONE",
    EMPTY = "EMPTY",
    NOT_EMPTY = "NOT_EMPTY"
}

export enum SingleRelationFlimStatType {
    COUNT = "COUNT",
    COUNT_NOT_EMPTY = "COUNT_NOT_EMPTY",
    COUNT_EMPTY = "COUNT_EMPTY",
    PERCENT_EMPTY = "PERCENT_EMPTY",
    PERCENT_NOT_EMPTY = "PERCENT_NOT_EMPTY"
}

export enum SingleTextFilterMatchType {
    CONTAINS = "CONTAINS",
    NOT_CONTAINS = "NOT_CONTAINS",
    IS = "IS",
    IS_NOT = "IS_NOT",
    EMPTY = "EMPTY",
    NOT_EMPTY = "NOT_EMPTY",
    STARTS_WITH = "STARTS_WITH",
    ENDS_WITH = "ENDS_WITH"
}

export enum SingleTextFlimStatType {
    COUNT = "COUNT",
    COUNT_NOT_EMPTY = "COUNT_NOT_EMPTY",
    COUNT_EMPTY = "COUNT_EMPTY",
    PERCENT_EMPTY = "PERCENT_EMPTY",
    PERCENT_NOT_EMPTY = "PERCENT_NOT_EMPTY"
}

export enum SingleUserFilterMatchType {
    IS = "IS",
    IS_NOT = "IS_NOT",
    IS_ANY = "IS_ANY",
    IS_NONE = "IS_NONE",
    EMPTY = "EMPTY",
    NOT_EMPTY = "NOT_EMPTY"
}

export enum SingleUserFlimStatType {
    COUNT = "COUNT",
    COUNT_NOT_EMPTY = "COUNT_NOT_EMPTY",
    COUNT_EMPTY = "COUNT_EMPTY",
    PERCENT_EMPTY = "PERCENT_EMPTY",
    PERCENT_NOT_EMPTY = "PERCENT_NOT_EMPTY"
}

export enum SmtpEncryptionType {
    NONE = "NONE",
    STARTTLS = "STARTTLS",
    TLS = "TLS"
}

export enum SortingDirection {
    ASC = "ASC",
    DESC = "DESC"
}

export enum StatusFilterMatchType {
    IS = "IS",
    IS_NOT = "IS_NOT",
    IS_ANY = "IS_ANY",
    IS_NONE = "IS_NONE",
    EMPTY = "EMPTY",
    NOT_EMPTY = "NOT_EMPTY",
    COMPLETED = "COMPLETED",
    INCOMPLETE = "INCOMPLETE"
}

export enum StatusFlimLayout {
    INLINE = "INLINE",
    LIST = "LIST",
    DROPDOWN = "DROPDOWN",
    PROCESS = "PROCESS"
}

export enum StatusFlimStatType {
    COUNT = "COUNT",
    COUNT_NOT_EMPTY = "COUNT_NOT_EMPTY",
    COUNT_EMPTY = "COUNT_EMPTY",
    PERCENT_EMPTY = "PERCENT_EMPTY",
    PERCENT_NOT_EMPTY = "PERCENT_NOT_EMPTY",
    COUNT_COMPLETE = "COUNT_COMPLETE",
    COUNT_INCOMPLETE = "COUNT_INCOMPLETE"
}

export enum SubscribeToExportBlabDefDataJobFailureErrorType {
    UNKNOWN = "UNKNOWN",
    TIMEOUT = "TIMEOUT"
}

export enum SubscriptionInvoiceStatus {
    UNPAID = "UNPAID",
    PAID = "PAID",
    VOID = "VOID"
}

export enum TableRowHeight {
    SHORT = "SHORT",
    MEDIUM = "MEDIUM",
    TALL = "TALL",
    EXTRA_TALL = "EXTRA_TALL"
}

export enum TaxIdVerificationResult {
    VALID = "VALID",
    INVALID = "INVALID",
    SERVICE_UNAVAILABLE = "SERVICE_UNAVAILABLE"
}

export enum TaxInvoiceItemReason {
    B2C_CUSTOMER = "B2C_CUSTOMER",
    REVERSE_CHARGE = "REVERSE_CHARGE",
    MISSING_TAX_ID = "MISSING_TAX_ID",
    B2B_TAXABLE_CUSTOMER = "B2B_TAXABLE_CUSTOMER"
}

export enum TestJobQueue {
    BLAB_ITEM_EXPLORE_CATEGORY_TRANSITION_JOB = "BLAB_ITEM_EXPLORE_CATEGORY_TRANSITION_JOB",
    USER_ONBOARDING_ADD_POST_TO_NEWSFEED_JOB = "USER_ONBOARDING_ADD_POST_TO_NEWSFEED_JOB",
    AUDIO_ROOM_CLEANUP_JOB = "AUDIO_ROOM_CLEANUP_JOB",
    ACTIVE_USER_AUDIO_ROOM_PRESENCE_CLEANUP_JOB = "ACTIVE_USER_AUDIO_ROOM_PRESENCE_CLEANUP_JOB",
    WORKFLOW_DATE_ARRIVED_TRIGGER_SYNC_JOB = "WORKFLOW_DATE_ARRIVED_TRIGGER_SYNC_JOB",
    WORKFLOW_DATE_ARRIVED_TRIGGER_SCHEDULE_JOB_NAME = "WORKFLOW_DATE_ARRIVED_TRIGGER_SCHEDULE_JOB_NAME",
    WORKFLOW_PERIODIC_TRIGGER_SCHEDULE_JOB_NAME = "WORKFLOW_PERIODIC_TRIGGER_SCHEDULE_JOB_NAME",
    OAUTH_INTGRATION_REFRESH_TOKEN_JOB = "OAUTH_INTGRATION_REFRESH_TOKEN_JOB"
}

export enum TimeFormat {
    HOURS_12 = "HOURS_12",
    HOURS_24 = "HOURS_24"
}

export enum TimeSetting {
    REQUIRE = "REQUIRE",
    INCLUDE = "INCLUDE"
}

export enum Timezone {
    Africa__Abidjan = "Africa__Abidjan",
    Africa__Accra = "Africa__Accra",
    Africa__Addis_Ababa = "Africa__Addis_Ababa",
    Africa__Algiers = "Africa__Algiers",
    Africa__Asmara = "Africa__Asmara",
    Africa__Bamako = "Africa__Bamako",
    Africa__Bangui = "Africa__Bangui",
    Africa__Banjul = "Africa__Banjul",
    Africa__Bissau = "Africa__Bissau",
    Africa__Blantyre = "Africa__Blantyre",
    Africa__Brazzaville = "Africa__Brazzaville",
    Africa__Bujumbura = "Africa__Bujumbura",
    Africa__Cairo = "Africa__Cairo",
    Africa__Casablanca = "Africa__Casablanca",
    Africa__Ceuta = "Africa__Ceuta",
    Africa__Conakry = "Africa__Conakry",
    Africa__Dakar = "Africa__Dakar",
    Africa__Dar_es_Salaam = "Africa__Dar_es_Salaam",
    Africa__Djibouti = "Africa__Djibouti",
    Africa__Douala = "Africa__Douala",
    Africa__El_Aaiun = "Africa__El_Aaiun",
    Africa__Freetown = "Africa__Freetown",
    Africa__Gaborone = "Africa__Gaborone",
    Africa__Harare = "Africa__Harare",
    Africa__Johannesburg = "Africa__Johannesburg",
    Africa__Juba = "Africa__Juba",
    Africa__Kampala = "Africa__Kampala",
    Africa__Khartoum = "Africa__Khartoum",
    Africa__Kigali = "Africa__Kigali",
    Africa__Kinshasa = "Africa__Kinshasa",
    Africa__Lagos = "Africa__Lagos",
    Africa__Libreville = "Africa__Libreville",
    Africa__Lome = "Africa__Lome",
    Africa__Luanda = "Africa__Luanda",
    Africa__Lubumbashi = "Africa__Lubumbashi",
    Africa__Lusaka = "Africa__Lusaka",
    Africa__Malabo = "Africa__Malabo",
    Africa__Maputo = "Africa__Maputo",
    Africa__Maseru = "Africa__Maseru",
    Africa__Mbabane = "Africa__Mbabane",
    Africa__Mogadishu = "Africa__Mogadishu",
    Africa__Monrovia = "Africa__Monrovia",
    Africa__Nairobi = "Africa__Nairobi",
    Africa__Ndjamena = "Africa__Ndjamena",
    Africa__Niamey = "Africa__Niamey",
    Africa__Nouakchott = "Africa__Nouakchott",
    Africa__Ouagadougou = "Africa__Ouagadougou",
    Africa__Porto0Novo = "Africa__Porto0Novo",
    Africa__Sao_Tome = "Africa__Sao_Tome",
    Africa__Tripoli = "Africa__Tripoli",
    Africa__Tunis = "Africa__Tunis",
    Africa__Windhoek = "Africa__Windhoek",
    America__Adak = "America__Adak",
    America__Anchorage = "America__Anchorage",
    America__Anguilla = "America__Anguilla",
    America__Antigua = "America__Antigua",
    America__Araguaina = "America__Araguaina",
    America__Argentina__Buenos_Aires = "America__Argentina__Buenos_Aires",
    America__Argentina__Catamarca = "America__Argentina__Catamarca",
    America__Argentina__Cordoba = "America__Argentina__Cordoba",
    America__Argentina__Jujuy = "America__Argentina__Jujuy",
    America__Argentina__La_Rioja = "America__Argentina__La_Rioja",
    America__Argentina__Mendoza = "America__Argentina__Mendoza",
    America__Argentina__Rio_Gallegos = "America__Argentina__Rio_Gallegos",
    America__Argentina__Salta = "America__Argentina__Salta",
    America__Argentina__San_Juan = "America__Argentina__San_Juan",
    America__Argentina__San_Luis = "America__Argentina__San_Luis",
    America__Argentina__Tucuman = "America__Argentina__Tucuman",
    America__Argentina__Ushuaia = "America__Argentina__Ushuaia",
    America__Aruba = "America__Aruba",
    America__Asuncion = "America__Asuncion",
    America__Atikokan = "America__Atikokan",
    America__Bahia = "America__Bahia",
    America__Bahia_Banderas = "America__Bahia_Banderas",
    America__Barbados = "America__Barbados",
    America__Belem = "America__Belem",
    America__Belize = "America__Belize",
    America__Blanc0Sablon = "America__Blanc0Sablon",
    America__Boa_Vista = "America__Boa_Vista",
    America__Bogota = "America__Bogota",
    America__Boise = "America__Boise",
    America__Cambridge_Bay = "America__Cambridge_Bay",
    America__Campo_Grande = "America__Campo_Grande",
    America__Cancun = "America__Cancun",
    America__Caracas = "America__Caracas",
    America__Cayenne = "America__Cayenne",
    America__Cayman = "America__Cayman",
    America__Chicago = "America__Chicago",
    America__Chihuahua = "America__Chihuahua",
    America__Costa_Rica = "America__Costa_Rica",
    America__Creston = "America__Creston",
    America__Cuiaba = "America__Cuiaba",
    America__Curacao = "America__Curacao",
    America__Danmarkshavn = "America__Danmarkshavn",
    America__Dawson = "America__Dawson",
    America__Dawson_Creek = "America__Dawson_Creek",
    America__Denver = "America__Denver",
    America__Detroit = "America__Detroit",
    America__Dominica = "America__Dominica",
    America__Edmonton = "America__Edmonton",
    America__Eirunepe = "America__Eirunepe",
    America__El_Salvador = "America__El_Salvador",
    America__Fort_Nelson = "America__Fort_Nelson",
    America__Fortaleza = "America__Fortaleza",
    America__Glace_Bay = "America__Glace_Bay",
    America__Godthab = "America__Godthab",
    America__Goose_Bay = "America__Goose_Bay",
    America__Grand_Turk = "America__Grand_Turk",
    America__Grenada = "America__Grenada",
    America__Guadeloupe = "America__Guadeloupe",
    America__Guatemala = "America__Guatemala",
    America__Guayaquil = "America__Guayaquil",
    America__Guyana = "America__Guyana",
    America__Halifax = "America__Halifax",
    America__Havana = "America__Havana",
    America__Hermosillo = "America__Hermosillo",
    America__Indiana__Indianapolis = "America__Indiana__Indianapolis",
    America__Indiana__Knox = "America__Indiana__Knox",
    America__Indiana__Marengo = "America__Indiana__Marengo",
    America__Indiana__Petersburg = "America__Indiana__Petersburg",
    America__Indiana__Tell_City = "America__Indiana__Tell_City",
    America__Indiana__Vevay = "America__Indiana__Vevay",
    America__Indiana__Vincennes = "America__Indiana__Vincennes",
    America__Indiana__Winamac = "America__Indiana__Winamac",
    America__Inuvik = "America__Inuvik",
    America__Iqaluit = "America__Iqaluit",
    America__Jamaica = "America__Jamaica",
    America__Juneau = "America__Juneau",
    America__Kentucky__Louisville = "America__Kentucky__Louisville",
    America__Kentucky__Monticello = "America__Kentucky__Monticello",
    America__Kralendijk = "America__Kralendijk",
    America__La_Paz = "America__La_Paz",
    America__Lima = "America__Lima",
    America__Los_Angeles = "America__Los_Angeles",
    America__Lower_Princes = "America__Lower_Princes",
    America__Maceio = "America__Maceio",
    America__Managua = "America__Managua",
    America__Manaus = "America__Manaus",
    America__Marigot = "America__Marigot",
    America__Martinique = "America__Martinique",
    America__Matamoros = "America__Matamoros",
    America__Mazatlan = "America__Mazatlan",
    America__Menominee = "America__Menominee",
    America__Merida = "America__Merida",
    America__Metlakatla = "America__Metlakatla",
    America__Mexico_City = "America__Mexico_City",
    America__Miquelon = "America__Miquelon",
    America__Moncton = "America__Moncton",
    America__Monterrey = "America__Monterrey",
    America__Montevideo = "America__Montevideo",
    America__Montserrat = "America__Montserrat",
    America__Nassau = "America__Nassau",
    America__New_York = "America__New_York",
    America__Nipigon = "America__Nipigon",
    America__Nome = "America__Nome",
    America__Noronha = "America__Noronha",
    America__North_Dakota__Beulah = "America__North_Dakota__Beulah",
    America__North_Dakota__Center = "America__North_Dakota__Center",
    America__North_Dakota__New_Salem = "America__North_Dakota__New_Salem",
    America__Ojinaga = "America__Ojinaga",
    America__Panama = "America__Panama",
    America__Pangnirtung = "America__Pangnirtung",
    America__Paramaribo = "America__Paramaribo",
    America__Phoenix = "America__Phoenix",
    America__Port0au0Prince = "America__Port0au0Prince",
    America__Port_of_Spain = "America__Port_of_Spain",
    America__Porto_Velho = "America__Porto_Velho",
    America__Puerto_Rico = "America__Puerto_Rico",
    America__Punta_Arenas = "America__Punta_Arenas",
    America__Rainy_River = "America__Rainy_River",
    America__Rankin_Inlet = "America__Rankin_Inlet",
    America__Recife = "America__Recife",
    America__Regina = "America__Regina",
    America__Resolute = "America__Resolute",
    America__Rio_Branco = "America__Rio_Branco",
    America__Santarem = "America__Santarem",
    America__Santiago = "America__Santiago",
    America__Santo_Domingo = "America__Santo_Domingo",
    America__Sao_Paulo = "America__Sao_Paulo",
    America__Scoresbysund = "America__Scoresbysund",
    America__Sitka = "America__Sitka",
    America__St_Barthelemy = "America__St_Barthelemy",
    America__St_Johns = "America__St_Johns",
    America__St_Kitts = "America__St_Kitts",
    America__St_Lucia = "America__St_Lucia",
    America__St_Thomas = "America__St_Thomas",
    America__St_Vincent = "America__St_Vincent",
    America__Swift_Current = "America__Swift_Current",
    America__Tegucigalpa = "America__Tegucigalpa",
    America__Thule = "America__Thule",
    America__Thunder_Bay = "America__Thunder_Bay",
    America__Tijuana = "America__Tijuana",
    America__Toronto = "America__Toronto",
    America__Tortola = "America__Tortola",
    America__Vancouver = "America__Vancouver",
    America__Whitehorse = "America__Whitehorse",
    America__Winnipeg = "America__Winnipeg",
    America__Yakutat = "America__Yakutat",
    America__Yellowknife = "America__Yellowknife",
    Antarctica__Casey = "Antarctica__Casey",
    Antarctica__Davis = "Antarctica__Davis",
    Antarctica__DumontDUrville = "Antarctica__DumontDUrville",
    Antarctica__Macquarie = "Antarctica__Macquarie",
    Antarctica__Mawson = "Antarctica__Mawson",
    Antarctica__McMurdo = "Antarctica__McMurdo",
    Antarctica__Palmer = "Antarctica__Palmer",
    Antarctica__Rothera = "Antarctica__Rothera",
    Antarctica__Syowa = "Antarctica__Syowa",
    Antarctica__Troll = "Antarctica__Troll",
    Antarctica__Vostok = "Antarctica__Vostok",
    Arctic__Longyearbyen = "Arctic__Longyearbyen",
    Asia__Aden = "Asia__Aden",
    Asia__Almaty = "Asia__Almaty",
    Asia__Amman = "Asia__Amman",
    Asia__Anadyr = "Asia__Anadyr",
    Asia__Aqtau = "Asia__Aqtau",
    Asia__Aqtobe = "Asia__Aqtobe",
    Asia__Ashgabat = "Asia__Ashgabat",
    Asia__Atyrau = "Asia__Atyrau",
    Asia__Baghdad = "Asia__Baghdad",
    Asia__Bahrain = "Asia__Bahrain",
    Asia__Baku = "Asia__Baku",
    Asia__Bangkok = "Asia__Bangkok",
    Asia__Barnaul = "Asia__Barnaul",
    Asia__Beirut = "Asia__Beirut",
    Asia__Bishkek = "Asia__Bishkek",
    Asia__Brunei = "Asia__Brunei",
    Asia__Chita = "Asia__Chita",
    Asia__Choibalsan = "Asia__Choibalsan",
    Asia__Colombo = "Asia__Colombo",
    Asia__Damascus = "Asia__Damascus",
    Asia__Dhaka = "Asia__Dhaka",
    Asia__Dili = "Asia__Dili",
    Asia__Dubai = "Asia__Dubai",
    Asia__Dushanbe = "Asia__Dushanbe",
    Asia__Famagusta = "Asia__Famagusta",
    Asia__Gaza = "Asia__Gaza",
    Asia__Hebron = "Asia__Hebron",
    Asia__Ho_Chi_Minh = "Asia__Ho_Chi_Minh",
    Asia__Hong_Kong = "Asia__Hong_Kong",
    Asia__Hovd = "Asia__Hovd",
    Asia__Irkutsk = "Asia__Irkutsk",
    Asia__Jakarta = "Asia__Jakarta",
    Asia__Jayapura = "Asia__Jayapura",
    Asia__Jerusalem = "Asia__Jerusalem",
    Asia__Kabul = "Asia__Kabul",
    Asia__Kamchatka = "Asia__Kamchatka",
    Asia__Karachi = "Asia__Karachi",
    Asia__Kathmandu = "Asia__Kathmandu",
    Asia__Khandyga = "Asia__Khandyga",
    Asia__Kolkata = "Asia__Kolkata",
    Asia__Krasnoyarsk = "Asia__Krasnoyarsk",
    Asia__Kuala_Lumpur = "Asia__Kuala_Lumpur",
    Asia__Kuching = "Asia__Kuching",
    Asia__Kuwait = "Asia__Kuwait",
    Asia__Macau = "Asia__Macau",
    Asia__Magadan = "Asia__Magadan",
    Asia__Makassar = "Asia__Makassar",
    Asia__Manila = "Asia__Manila",
    Asia__Muscat = "Asia__Muscat",
    Asia__Nicosia = "Asia__Nicosia",
    Asia__Novokuznetsk = "Asia__Novokuznetsk",
    Asia__Novosibirsk = "Asia__Novosibirsk",
    Asia__Omsk = "Asia__Omsk",
    Asia__Oral = "Asia__Oral",
    Asia__Phnom_Penh = "Asia__Phnom_Penh",
    Asia__Pontianak = "Asia__Pontianak",
    Asia__Pyongyang = "Asia__Pyongyang",
    Asia__Qatar = "Asia__Qatar",
    Asia__Qostanay = "Asia__Qostanay",
    Asia__Qyzylorda = "Asia__Qyzylorda",
    Asia__Riyadh = "Asia__Riyadh",
    Asia__Sakhalin = "Asia__Sakhalin",
    Asia__Samarkand = "Asia__Samarkand",
    Asia__Seoul = "Asia__Seoul",
    Asia__Shanghai = "Asia__Shanghai",
    Asia__Singapore = "Asia__Singapore",
    Asia__Srednekolymsk = "Asia__Srednekolymsk",
    Asia__Taipei = "Asia__Taipei",
    Asia__Tashkent = "Asia__Tashkent",
    Asia__Tbilisi = "Asia__Tbilisi",
    Asia__Tehran = "Asia__Tehran",
    Asia__Thimphu = "Asia__Thimphu",
    Asia__Tokyo = "Asia__Tokyo",
    Asia__Tomsk = "Asia__Tomsk",
    Asia__Ulaanbaatar = "Asia__Ulaanbaatar",
    Asia__Urumqi = "Asia__Urumqi",
    Asia__Ust0Nera = "Asia__Ust0Nera",
    Asia__Vientiane = "Asia__Vientiane",
    Asia__Vladivostok = "Asia__Vladivostok",
    Asia__Yakutsk = "Asia__Yakutsk",
    Asia__Yangon = "Asia__Yangon",
    Asia__Yekaterinburg = "Asia__Yekaterinburg",
    Asia__Yerevan = "Asia__Yerevan",
    Atlantic__Azores = "Atlantic__Azores",
    Atlantic__Bermuda = "Atlantic__Bermuda",
    Atlantic__Canary = "Atlantic__Canary",
    Atlantic__Cape_Verde = "Atlantic__Cape_Verde",
    Atlantic__Faroe = "Atlantic__Faroe",
    Atlantic__Madeira = "Atlantic__Madeira",
    Atlantic__Reykjavik = "Atlantic__Reykjavik",
    Atlantic__South_Georgia = "Atlantic__South_Georgia",
    Atlantic__St_Helena = "Atlantic__St_Helena",
    Atlantic__Stanley = "Atlantic__Stanley",
    Australia__Adelaide = "Australia__Adelaide",
    Australia__Brisbane = "Australia__Brisbane",
    Australia__Broken_Hill = "Australia__Broken_Hill",
    Australia__Currie = "Australia__Currie",
    Australia__Darwin = "Australia__Darwin",
    Australia__Eucla = "Australia__Eucla",
    Australia__Hobart = "Australia__Hobart",
    Australia__Lindeman = "Australia__Lindeman",
    Australia__Lord_Howe = "Australia__Lord_Howe",
    Australia__Melbourne = "Australia__Melbourne",
    Australia__Perth = "Australia__Perth",
    Australia__Sydney = "Australia__Sydney",
    Europe__Amsterdam = "Europe__Amsterdam",
    Europe__Andorra = "Europe__Andorra",
    Europe__Astrakhan = "Europe__Astrakhan",
    Europe__Athens = "Europe__Athens",
    Europe__Belgrade = "Europe__Belgrade",
    Europe__Berlin = "Europe__Berlin",
    Europe__Bratislava = "Europe__Bratislava",
    Europe__Brussels = "Europe__Brussels",
    Europe__Bucharest = "Europe__Bucharest",
    Europe__Budapest = "Europe__Budapest",
    Europe__Busingen = "Europe__Busingen",
    Europe__Chisinau = "Europe__Chisinau",
    Europe__Copenhagen = "Europe__Copenhagen",
    Europe__Dublin = "Europe__Dublin",
    Europe__Gibraltar = "Europe__Gibraltar",
    Europe__Guernsey = "Europe__Guernsey",
    Europe__Helsinki = "Europe__Helsinki",
    Europe__Isle_of_Man = "Europe__Isle_of_Man",
    Europe__Istanbul = "Europe__Istanbul",
    Europe__Jersey = "Europe__Jersey",
    Europe__Kaliningrad = "Europe__Kaliningrad",
    Europe__Kiev = "Europe__Kiev",
    Europe__Kirov = "Europe__Kirov",
    Europe__Lisbon = "Europe__Lisbon",
    Europe__Ljubljana = "Europe__Ljubljana",
    Europe__London = "Europe__London",
    Europe__Luxembourg = "Europe__Luxembourg",
    Europe__Madrid = "Europe__Madrid",
    Europe__Malta = "Europe__Malta",
    Europe__Mariehamn = "Europe__Mariehamn",
    Europe__Minsk = "Europe__Minsk",
    Europe__Monaco = "Europe__Monaco",
    Europe__Moscow = "Europe__Moscow",
    Europe__Oslo = "Europe__Oslo",
    Europe__Paris = "Europe__Paris",
    Europe__Podgorica = "Europe__Podgorica",
    Europe__Prague = "Europe__Prague",
    Europe__Riga = "Europe__Riga",
    Europe__Rome = "Europe__Rome",
    Europe__Samara = "Europe__Samara",
    Europe__San_Marino = "Europe__San_Marino",
    Europe__Sarajevo = "Europe__Sarajevo",
    Europe__Saratov = "Europe__Saratov",
    Europe__Simferopol = "Europe__Simferopol",
    Europe__Skopje = "Europe__Skopje",
    Europe__Sofia = "Europe__Sofia",
    Europe__Stockholm = "Europe__Stockholm",
    Europe__Tallinn = "Europe__Tallinn",
    Europe__Tirane = "Europe__Tirane",
    Europe__Ulyanovsk = "Europe__Ulyanovsk",
    Europe__Uzhgorod = "Europe__Uzhgorod",
    Europe__Vaduz = "Europe__Vaduz",
    Europe__Vatican = "Europe__Vatican",
    Europe__Vienna = "Europe__Vienna",
    Europe__Vilnius = "Europe__Vilnius",
    Europe__Volgograd = "Europe__Volgograd",
    Europe__Warsaw = "Europe__Warsaw",
    Europe__Zagreb = "Europe__Zagreb",
    Europe__Zaporozhye = "Europe__Zaporozhye",
    Europe__Zurich = "Europe__Zurich",
    Indian__Antananarivo = "Indian__Antananarivo",
    Indian__Chagos = "Indian__Chagos",
    Indian__Christmas = "Indian__Christmas",
    Indian__Cocos = "Indian__Cocos",
    Indian__Comoro = "Indian__Comoro",
    Indian__Kerguelen = "Indian__Kerguelen",
    Indian__Mahe = "Indian__Mahe",
    Indian__Maldives = "Indian__Maldives",
    Indian__Mauritius = "Indian__Mauritius",
    Indian__Mayotte = "Indian__Mayotte",
    Indian__Reunion = "Indian__Reunion",
    Pacific__Apia = "Pacific__Apia",
    Pacific__Auckland = "Pacific__Auckland",
    Pacific__Bougainville = "Pacific__Bougainville",
    Pacific__Chatham = "Pacific__Chatham",
    Pacific__Chuuk = "Pacific__Chuuk",
    Pacific__Easter = "Pacific__Easter",
    Pacific__Efate = "Pacific__Efate",
    Pacific__Enderbury = "Pacific__Enderbury",
    Pacific__Fakaofo = "Pacific__Fakaofo",
    Pacific__Fiji = "Pacific__Fiji",
    Pacific__Funafuti = "Pacific__Funafuti",
    Pacific__Galapagos = "Pacific__Galapagos",
    Pacific__Gambier = "Pacific__Gambier",
    Pacific__Guadalcanal = "Pacific__Guadalcanal",
    Pacific__Guam = "Pacific__Guam",
    Pacific__Honolulu = "Pacific__Honolulu",
    Pacific__Kiritimati = "Pacific__Kiritimati",
    Pacific__Kosrae = "Pacific__Kosrae",
    Pacific__Kwajalein = "Pacific__Kwajalein",
    Pacific__Majuro = "Pacific__Majuro",
    Pacific__Marquesas = "Pacific__Marquesas",
    Pacific__Midway = "Pacific__Midway",
    Pacific__Nauru = "Pacific__Nauru",
    Pacific__Niue = "Pacific__Niue",
    Pacific__Norfolk = "Pacific__Norfolk",
    Pacific__Noumea = "Pacific__Noumea",
    Pacific__Pago_Pago = "Pacific__Pago_Pago",
    Pacific__Palau = "Pacific__Palau",
    Pacific__Pitcairn = "Pacific__Pitcairn",
    Pacific__Pohnpei = "Pacific__Pohnpei",
    Pacific__Port_Moresby = "Pacific__Port_Moresby",
    Pacific__Rarotonga = "Pacific__Rarotonga",
    Pacific__Saipan = "Pacific__Saipan",
    Pacific__Tahiti = "Pacific__Tahiti",
    Pacific__Tarawa = "Pacific__Tarawa",
    Pacific__Tongatapu = "Pacific__Tongatapu",
    Pacific__Wake = "Pacific__Wake",
    Pacific__Wallis = "Pacific__Wallis"
}

export enum UniqueIdFilterMatchType {
    SMALLER = "SMALLER",
    SMALLER_OR_EQUAL = "SMALLER_OR_EQUAL",
    LARGER = "LARGER",
    LARGER_OR_EQUAL = "LARGER_OR_EQUAL",
    EQUAL = "EQUAL",
    NOT_EQUAL = "NOT_EQUAL",
    EMPTY = "EMPTY",
    NOT_EMPTY = "NOT_EMPTY"
}

export enum UniqueIdFlimStatType {
    COUNT = "COUNT",
    COUNT_NOT_EMPTY = "COUNT_NOT_EMPTY",
    COUNT_EMPTY = "COUNT_EMPTY",
    PERCENT_EMPTY = "PERCENT_EMPTY",
    PERCENT_NOT_EMPTY = "PERCENT_NOT_EMPTY"
}

export enum UpdateOrganizationSlugResultType {
    SLUG_UPDATED = "SLUG_UPDATED",
    SLUG_ALREADY_TAKEN = "SLUG_ALREADY_TAKEN"
}

export enum UpdateWorkspaceSlugResultType {
    SLUG_UPDATED = "SLUG_UPDATED",
    SLUG_ALREADY_TAKEN = "SLUG_ALREADY_TAKEN"
}

export enum UserActivityType {
    USER_CREATED = "USER_CREATED",
    USER_POST = "USER_POST"
}

export enum UserCalendarEventBusyStatus {
    FREE = "FREE",
    BUSY = "BUSY"
}

export enum UserCalendarSubscriptionType {
    ALL_BLAB_ITEMS = "ALL_BLAB_ITEMS",
    FOLLOWING_BLAB_ITEMS = "FOLLOWING_BLAB_ITEMS",
    REMINDER = "REMINDER",
    CHECKLIST_ENTRIES = "CHECKLIST_ENTRIES"
}

export enum UserClientAppBubbleType {
    NEWSFEED = "NEWSFEED",
    NOTIFICATION = "NOTIFICATION",
    CHAT = "CHAT",
    REMINDER = "REMINDER"
}

export enum UserClientAppPlatform {
    IOS = "IOS",
    ANDROID = "ANDROID",
    WEB = "WEB"
}

export enum UserFeatureAccessLevel {
    STABLE = "STABLE",
    BETA = "BETA",
    INTERNAL = "INTERNAL",
    DEV = "DEV"
}

export enum UserGroupActivityType {
    USER_ADDED = "USER_ADDED"
}

export enum UserPartnerStatus {
    PARTNER = "PARTNER"
}

export enum UserPasswordValidityResultType {
    VALID = "VALID",
    TOO_SHORT = "TOO_SHORT",
    TOO_COMMON = "TOO_COMMON"
}

export enum UserPresenceType {
    ONLINE = "ONLINE",
    OFFLINE = "OFFLINE"
}

export enum UserSettingsMicrosoftIntegrationStatus {
    ACTIVE = "ACTIVE",
    INACTIVE = "INACTIVE"
}

export enum ValidateOrganizationSignupVerificationCodeResponseType {
    CORRECT = "CORRECT",
    INCORRECT = "INCORRECT",
    SESSION_EXPIRED = "SESSION_EXPIRED",
    MAX_NUM_TRIES_REACHED = "MAX_NUM_TRIES_REACHED"
}

export enum Weekday {
    MONDAY = "MONDAY",
    TUESDAY = "TUESDAY",
    WEDNESDAY = "WEDNESDAY",
    THURSDAY = "THURSDAY",
    FRIDAY = "FRIDAY",
    SATURDAY = "SATURDAY",
    SUNDAY = "SUNDAY"
}

export enum WorkflowCenterWebhookSubscriptionEventType {
    WORKFLOW_CENTER_WEBHOOK_CREATED = "WORKFLOW_CENTER_WEBHOOK_CREATED",
    WORKFLOW_CENTER_WEBHOOK_UPDATED = "WORKFLOW_CENTER_WEBHOOK_UPDATED",
    WORKFLOW_CENTER_WEBHOOK_DELETED = "WORKFLOW_CENTER_WEBHOOK_DELETED"
}

export enum WorkflowCenterWebhookType {
    WORKFLOW_RUN_FAILED = "WORKFLOW_RUN_FAILED",
    WORKFLOW_ACTION_FAILED = "WORKFLOW_ACTION_FAILED",
    WORKFLOW_RUNS_THROTTLED = "WORKFLOW_RUNS_THROTTLED"
}

export enum WorkflowDefActivityType {
    RUN_FAILED = "RUN_FAILED"
}

export enum WorkflowDefIncomingType {
    CALLED_BY_WORKFLOW = "CALLED_BY_WORKFLOW",
    WEB_LINK = "WEB_LINK"
}

export enum WorkflowExecutionType {
    ALL_BLAB_ITEMS_OF_BLAB_DEF = "ALL_BLAB_ITEMS_OF_BLAB_DEF",
    SELECTED_BLAB_ITEMS = "SELECTED_BLAB_ITEMS",
    NUM_MOST_RECENT_BLAB_ITEMS = "NUM_MOST_RECENT_BLAB_ITEMS",
    CUSTOM_WEBHHOOK_PAYLOAD = "CUSTOM_WEBHHOOK_PAYLOAD",
    LAST_RECEIVED_WEBHOOK_PAYLOAD = "LAST_RECEIVED_WEBHOOK_PAYLOAD",
    SKIP_SCHEDULE = "SKIP_SCHEDULE"
}

export enum WorkflowHomeTimeInterval {
    CURRENT_MONTH = "CURRENT_MONTH",
    LAST_MONTH = "LAST_MONTH"
}

export enum WorkflowRunFailureReason {
    FILTERS_SETUP_FAILED = "FILTERS_SETUP_FAILED",
    FILTERS_FAILED = "FILTERS_FAILED",
    ACTIONS_SETUP_FAILED = "ACTIONS_SETUP_FAILED",
    ACTIONS_FAILED = "ACTIONS_FAILED",
    ACTIONS_TIMEOUT = "ACTIONS_TIMEOUT",
    ACTIONS_RESOURCE_LIMIT_EXCEEDED = "ACTIONS_RESOURCE_LIMIT_EXCEEDED",
    EXIT_ON_PURPOSE = "EXIT_ON_PURPOSE"
}

export enum WorkflowRunFilteringStatusType {
    SCHEDULED = "SCHEDULED",
    RUNNING = "RUNNING",
    SUCCEEDED = "SUCCEEDED",
    THROTTLED = "THROTTLED",
    FAILED = "FAILED",
    CANCELLED = "CANCELLED"
}

export enum WorkflowRunLogEventType {
    FILTERS_SETUP_FAILED = "FILTERS_SETUP_FAILED",
    FILTERS_EXECUTION_FAILED = "FILTERS_EXECUTION_FAILED",
    FILTER_USER_LOG = "FILTER_USER_LOG",
    ACTIONS_SETUP_FAILED = "ACTIONS_SETUP_FAILED",
    ACTIONS_SYNTAX_ERROR = "ACTIONS_SYNTAX_ERROR",
    ACTION_SKIPPING = "ACTION_SKIPPING",
    ACTION_STARTED = "ACTION_STARTED",
    ACTION_SUCCEEDED = "ACTION_SUCCEEDED",
    ACTION_FAILED = "ACTION_FAILED",
    ACTION_EXCEPTION_CAUGHT = "ACTION_EXCEPTION_CAUGHT",
    ACTION_USER_LOG = "ACTION_USER_LOG",
    ACTION_DEBUG_LOG = "ACTION_DEBUG_LOG",
    CONDITIONAL_ACTION_LOG = "CONDITIONAL_ACTION_LOG",
    FOR_LOOP_ACTION_BREAK_RESULT = "FOR_LOOP_ACTION_BREAK_RESULT",
    FOR_LOOP_ACTION_CONTINUE_RESULT = "FOR_LOOP_ACTION_CONTINUE_RESULT",
    FOR_LOOP_ACTION_BREAK_EXCEPTION = "FOR_LOOP_ACTION_BREAK_EXCEPTION",
    FOR_LOOP_ACTION_CONTINUE_EXCEPTION = "FOR_LOOP_ACTION_CONTINUE_EXCEPTION",
    BLAB_ITEM_CREATE_ACTION_SUCCEEDED = "BLAB_ITEM_CREATE_ACTION_SUCCEEDED",
    BLAB_ITEM_COMMENT_CREATE_ACTION_SUCCEEDED = "BLAB_ITEM_COMMENT_CREATE_ACTION_SUCCEEDED",
    COLLECT_BLAB_ITEMS_ACTION_SUCCEEDED = "COLLECT_BLAB_ITEMS_ACTION_SUCCEEDED",
    HTTP_CALL_ACTION_SUCCEEDED = "HTTP_CALL_ACTION_SUCCEEDED",
    COLLECTED_BLAB_ITEMS_COMMENT_CREATE_ACTION_COMMENT_CREATE_SUCCEEDED = "COLLECTED_BLAB_ITEMS_COMMENT_CREATE_ACTION_COMMENT_CREATE_SUCCEEDED",
    COLLECTED_BLAB_ITEMS_UPDATE_ACTION_UPDATE_SUCCEEDED = "COLLECTED_BLAB_ITEMS_UPDATE_ACTION_UPDATE_SUCCEEDED",
    REFERENCED_BLAB_ITEMS_UPDATE_ACTION_UPDATE_SUCCEEDED = "REFERENCED_BLAB_ITEMS_UPDATE_ACTION_UPDATE_SUCCEEDED",
    SEND_EMAIL_ACTION_SEND_ATTEMPT = "SEND_EMAIL_ACTION_SEND_ATTEMPT",
    GENERATE_WEBLINK_ACTION_SUCCEEDED = "GENERATE_WEBLINK_ACTION_SUCCEEDED",
    CREATE_PDF_ACTION_SUCCEEDED = "CREATE_PDF_ACTION_SUCCEEDED",
    CURRENT_BLAB_ITEM_CALL_WORKFLOW = "CURRENT_BLAB_ITEM_CALL_WORKFLOW",
    COLLECTED_BLAB_ITEMS_CALL_WORKFLOW = "COLLECTED_BLAB_ITEMS_CALL_WORKFLOW",
    PREVIEW_RUN_ACTION_CONTEXT_OUTPUT = "PREVIEW_RUN_ACTION_CONTEXT_OUTPUT"
}

export enum WorkflowRunlogMessageLevel {
    INFO = "INFO",
    WARN = "WARN",
    ERROR = "ERROR",
    SUCCESS = "SUCCESS"
}

export enum WorkflowRunsSubscriptionUpdateType {
    RUN_CREATED = "RUN_CREATED",
    RUN_LOGS_APPENDED = "RUN_LOGS_APPENDED",
    RUN_UPDATED = "RUN_UPDATED"
}

export enum WorkflowRunStatusType {
    SCHEDULED = "SCHEDULED",
    RUNNING = "RUNNING",
    SUCCEEDED = "SUCCEEDED",
    FAILED = "FAILED",
    CANCELLED = "CANCELLED"
}

export enum WorkflowRunType {
    SIMULATION = "SIMULATION",
    REGULAR = "REGULAR",
    MANUAL = "MANUAL"
}

export enum WorkflowTriggerType {
    BLAB_ITEM_CREATED = "BLAB_ITEM_CREATED",
    BLAB_ITEM_UPDATED = "BLAB_ITEM_UPDATED",
    BLAB_ITEM_DELETED = "BLAB_ITEM_DELETED",
    DATE_FLIM_VAL_ARRIVED = "DATE_FLIM_VAL_ARRIVED",
    PERIODIC = "PERIODIC",
    WEBHOOK_RECEIVE = "WEBHOOK_RECEIVE",
    BLAB_ITEM_COMMENT_OR_REPLY_CREATED = "BLAB_ITEM_COMMENT_OR_REPLY_CREATED",
    WORKFLOW_CALLED = "WORKFLOW_CALLED",
    WEBLINK_CLICKED = "WEBLINK_CLICKED"
}

export enum WorkspaceActivityType {
    WORKSPACE_CREATED = "WORKSPACE_CREATED",
    WORKSPACE_USER_ADDED = "WORKSPACE_USER_ADDED"
}

export enum WorkspaceIcon {
    account_circle_selectable = "account_circle_selectable",
    analytics = "analytics",
    article = "article",
    assignment = "assignment",
    assignment_turned_in = "assignment_turned_in",
    bookmarks = "bookmarks",
    bug_report = "bug_report",
    build = "build",
    cake = "cake",
    code = "code",
    check_circle = "check_circle",
    eco = "eco",
    emoji_emotions = "emoji_emotions",
    emoji_objects = "emoji_objects",
    error = "error",
    event_available = "event_available",
    face = "face",
    favorite = "favorite",
    group_work = "group_work",
    info = "info",
    lock = "lock",
    lock_open = "lock_open",
    monetization = "monetization",
    money = "money",
    offline = "offline",
    receipt = "receipt",
    reply = "reply",
    schedule = "schedule",
    star = "star",
    vpn_key = "vpn_key",
    widgets = "widgets",
    cloud = "cloud",
    emoji_events = "emoji_events",
    sports_baseball = "sports_baseball",
    sports_basketball = "sports_basketball",
    sports_football = "sports_football",
    sports_motorsports = "sports_motorsports",
    sports_rugby = "sports_rugby",
    sports_soccer = "sports_soccer",
    sports_volleyball = "sports_volleyball",
    bedtime = "bedtime",
    casino = "casino",
    contact_page = "contact_page",
    description = "description",
    flag = "flag",
    free_breakfast = "free_breakfast",
    inventory = "inventory",
    nature = "nature",
    page = "page",
    palette = "palette",
    panorama = "panorama",
    sports_bar = "sports_bar",
    storefront = "storefront",
    wb_sunny = "wb_sunny",
    work = "work",
    car = "car",
    savings = "savings",
    policy = "policy",
    stories = "stories",
    outbound = "outbound",
    inbound = "inbound",
    explore = "explore",
    summarize = "summarize",
    menu_book = "menu_book",
    pin = "pin",
    verified = "verified",
    water_drop = "water_drop",
    keyboard = "keyboard",
    cruelty_free = "cruelty_free",
    flag_circle = "flag_circle",
    play_circle = "play_circle",
    toys = "toys",
    movie = "movie",
    badge = "badge",
    sports_esports = "sports_esports",
    school = "school",
    headset = "headset",
    medal = "medal",
    local_shipping = "local_shipping",
    local_taxi = "local_taxi",
    directions_boat = "directions_boat",
    moped = "moped",
    airport_shuttle = "airport_shuttle"
}

export enum WorkspaceRole {
    WS_GUEST = "WS_GUEST",
    WS_MEMBER = "WS_MEMBER",
    WS_ADMIN = "WS_ADMIN"
}

export enum WorkspaceTemplateCategory {
    WORKFLOW_AUTOMATIONS = "WORKFLOW_AUTOMATIONS",
    DASHBOARD_AND_REPORTS = "DASHBOARD_AND_REPORTS",
    PROJECT_MANAGEMENT = "PROJECT_MANAGEMENT",
    GUIDES_SNIPPETS = "GUIDES_SNIPPETS",
    SALES_AND_CRM = "SALES_AND_CRM",
    MARKETING = "MARKETING",
    OPERATIONS = "OPERATIONS",
    HUMAN_RESOURCES = "HUMAN_RESOURCES",
    PRODUCT_DESIGN_UX = "PRODUCT_DESIGN_UX",
    STARTUP = "STARTUP",
    ENGINEERING = "ENGINEERING",
    REAL_ESTATE = "REAL_ESTATE",
    IT_MANAGEMENT = "IT_MANAGEMENT",
    LEGAL_FINANCE = "LEGAL_FINANCE",
    SOFTWARE_DEVELOPMENT = "SOFTWARE_DEVELOPMENT",
    PR_COMMS = "PR_COMMS",
    HEALTHCARE = "HEALTHCARE",
    VENTURE_CAPITAL = "VENTURE_CAPITAL",
    CONSULTING = "CONSULTING",
    AGENCY = "AGENCY",
    RECOMMENDED_FOR_YOU = "RECOMMENDED_FOR_YOU",
    START_FROM_SCRATCH = "START_FROM_SCRATCH",
    CONTENT_PRODUCTION = "CONTENT_PRODUCTION",
    FREELANCERS = "FREELANCERS",
    DESIGN = "DESIGN",
    MANUFACTURING = "MANUFACTURING",
    EDUCATION = "EDUCATION",
    CONSTRUCTION = "CONSTRUCTION"
}

export enum WorkspaceTemplateType {
    TASK_MANAGEMENT = "TASK_MANAGEMENT",
    FUNDING = "FUNDING",
    CONTACT_DIRECTORY = "CONTACT_DIRECTORY",
    CONTACT_DIRECTORY_STARTUP = "CONTACT_DIRECTORY_STARTUP",
    SAAS = "SAAS",
    PITCH_DECKS = "PITCH_DECKS",
    STRATEGY = "STRATEGY",
    SOFTWARE_DEVELOPMENT = "SOFTWARE_DEVELOPMENT",
    SALES = "SALES",
    MARKETING = "MARKETING"
}

export enum WorkspaceType {
    WS_PRIVATE = "WS_PRIVATE",
    WS_OPEN = "WS_OPEN",
    WS_DEFAULT = "WS_DEFAULT",
    WS_CLOSED = "WS_CLOSED"
}

export interface _GetBlabItemPersistedTitleInput {
    blabItemId: number;
}

export interface _GetDateArrivedJobsBetweenDatesInput {
    startDate: DateTime;
    endDate: DateTime;
}

export interface _GetDatePeriodicJobsBetweenDatesInput {
    startDate: DateTime;
    endDate: DateTime;
}

export interface _GetOrganizationJobStatusInput {
    jobId: number;
}

export interface _OrganizationWorkflowUsageReportInput {
    organizationId: number;
    from: DateTime;
    to: DateTime;
}

export interface _RemoveDateArrivedJobsInput {
    jobIds: number[];
}

export interface _RemoveDatePeriodicJobsInput {
    jobIds: number[];
}

export interface _SetScheduleAtOfDateArrivedJobsInput {
    jobId: number;
    scheduleAt: DateTime;
}

export interface _SetScheduleAtOfDatePeriodicJobsInput {
    jobId: number;
    scheduleAt: DateTime;
}

export interface _TestJobQueueExecuteImmediatelyInput {
    jobId: string;
}

export interface _TestJobQueueExistsInput {
    jobId: string;
}

export interface _TestJobQueueTriggerInput {
    job: TestJobQueue;
}

export interface _TestTimeMockFreezeInput {
    freezeToDate: DateTime;
}

export interface _TestTimeMockTravelInput {
    travelToDate: DateTime;
}

export interface AcceptOrganizationInvitationInput {
    name: string;
    password: string;
    optInPromotionalEmails: boolean;
    language?: Language;
    timezone?: Timezone;
    preserveExistingSessions?: boolean;
}

export interface ActivateActiveUserCalendarSubscriptionInput {
    id: number;
}

export interface ActivateBlabItemNotificationsInput {
    blabItemId: number;
}

export interface ActivateOrganizationUserInput {
    id: number;
    role: OrganizationRole;
}

export interface ActivateUserNotificationsInput {
    userId: number;
}

export interface AddActiveUserCalendarSubscriptionInput {
    active: boolean;
    type: UserCalendarSubscriptionType;
}

export interface AddActiveUserQuickAddBlabDefInput {
    blabDefId: number;
    position: string;
}

export interface AddBlabDefActivityReactionInput {
    activityId: number;
    emoji: string;
}

export interface AddBlabDefActivityReplyInput {
    activityId: number;
    content: string;
}

export interface AddBlabDefActivityReplyReactionInput {
    activityReplyId: number;
    emoji: string;
}

export interface AddBlabDefsFromWorkspaceTemplateInput {
    workspaceTemplateId: number;
    targetWorkspaceId: number;
}

export interface AddBlabItemActivityReactionInput {
    activityId: number;
    emoji: string;
}

export interface AddBlabItemActivityReminderInput {
    blabItemActivityId: number;
    title: string;
    description?: string;
    dueAt?: DateTime;
    dueAtHasTime?: boolean;
    isBeingNotified?: boolean;
}

export interface AddBlabItemActivityReplyInput {
    activityId: number;
    content: string;
    attachmentIds?: number[];
    linkPreviewId?: number;
    clientSideId?: string;
}

export interface AddBlabItemActivityReplyReactionInput {
    activityReplyId: number;
    emoji: string;
}

export interface AddBlabItemCommentInput {
    blabItemId: number;
    content?: string;
    attachmentIds?: number[];
    clientSideId?: string;
    linkPreviewId?: number;
}

export interface AddBlabItemReminderInput {
    referencedBlabItemId: number;
    title?: string;
    description?: string;
    dueAt?: DateTime;
    dueAtHasTime?: boolean;
    isBeingNotified?: boolean;
}

export interface AddChatActivityReactionInput {
    activityId: number;
    emoji: string;
}

export interface AddDeviceRegistrationTokenInput {
    token: string;
}

export interface AddOrganizationActivityReactionInput {
    activityId: number;
    emoji: string;
}

export interface AddOrganizationActivityReplyInput {
    activityId: number;
    content: string;
}

export interface AddOrganizationActivityReplyReactionInput {
    activityReplyId: number;
    emoji: string;
}

export interface AddPaymentMethodInput {
    id: string;
}

export interface AddReminderFocusEntryInput {
    title?: string;
    sectionId: number;
    position: string;
}

export interface AddReminderInput {
    title?: string;
    description?: string;
    dueAt?: DateTime;
    dueAtHasTime?: boolean;
    isBeingNotified?: boolean;
}

export interface AddSearchHistoryItemBlabDefInput {
    blabDefId: number;
}

export interface AddSearchHistoryItemBlabItemInput {
    blabItemId: number;
}

export interface AddSearchHistoryItemQueryInput {
    query: string;
}

export interface AddSearchHistoryItemUserInput {
    userId: number;
}

export interface AddSearchHistoryItemWorkspaceInput {
    workspaceId: number;
}

export interface AddUserActivityReactionInput {
    activityId: number;
    emoji: string;
}

export interface AddUserActivityReplyInput {
    activityId: number;
    content: string;
}

export interface AddUserActivityReplyReactionInput {
    activityReplyId: number;
    emoji: string;
}

export interface AddUserPostInput {
    content: string;
    attachmentIds?: number[];
    linkPreviewId?: number;
}

export interface AddUserToChatGroupInput {
    conversationId: number;
    userId: number;
}

export interface AddWebPushSubscriptionInput {
    endpoint: string;
    keys: WebPushSubscriptionKeysInput;
}

export interface AddWorkspaceActivityReactionInput {
    activityId: number;
    emoji: string;
}

export interface AddWorkspaceActivityReplyInput {
    activityId: number;
    content: string;
}

export interface AddWorkspaceActivityReplyReactionInput {
    activityReplyId: number;
    emoji: string;
}

export interface AddWorkspaceFromWorkspaceTemplateInput {
    workspaceTemplateId: number;
}

export interface AddWorkspaceMembershipsInput {
    workspaceId: number;
    userIds?: number[];
    emails?: string[];
    organizationRole: OrganizationRole;
    workspaceRole?: WorkspaceRole;
}

export interface AddWorkspaceTemplateInput {
    template?: WorkspaceTemplateType;
}

export interface ApproveOrganizationInvitationInput {
    id: number;
}

export interface ArchiveBlabDefInput {
    id: number;
}

export interface ArchiveOrganizationWorkspaceInput {
    id: number;
}

export interface AttachmentReferenceCreateInput {
    attachmentId: number;
    position: string;
    cardThumbnailPositionX?: number;
    cardThumbnailPositionY?: number;
}

export interface AttachmentReferenceUpsertInput {
    attachmentId: number;
    position: string;
    cardThumbnailPositionX?: number;
    cardThumbnailPositionY?: number;
}

export interface AuthenticateWithCredentialsInput {
    orgId: number;
    credentials: UserCredentialsInput;
    preserveExistingSessions?: boolean;
}

export interface AuthenticateWithFindMyOrgsTokenOptionsInput {
    preserveExistingSessions?: boolean;
}

export interface BlabDefBlabItemIdMetaFilterInput {
    blabDefBlabItemId: number;
    match: BlabDefBlabItemIdFilterMatchType;
}

export interface BlabDefDataImportCreateBlabDefInput {
    blabName: string;
    itemName: string;
    icon: BlabDefIcon;
}

export interface BlabDefDataImportCreateChecklistFlimDefInput {
    clientSideId: number;
    name: string;
    position: string;
    required?: boolean;
    hiddenIfEmpty?: boolean;
    alwaysHidden?: boolean;
    showHelpText?: boolean;
    helpText?: string;
    showInFocus?: boolean;
    showCompletedDefault?: boolean;
}

export interface BlabDefDataImportCreateFlimDefsInput {
    singleText: BlabDefDataImportCreateSingleTextFlimDefInput[];
    multiText: BlabDefDataImportCreateMultiTextFlimDefInput[];
    singleCategory: BlabDefDataImportCreateSingleCategoryFlimDefInput[];
    multiCategory: BlabDefDataImportCreateMultiCategoryFlimDefInput[];
    singleUser: BlabDefDataImportCreateSingleUserFlimDefInput[];
    multiUser: BlabDefDataImportCreateMultiUserFlimDefInput[];
    singleDate: BlabDefDataImportCreateSingleDateFlimDefInput[];
    rangeDate: BlabDefDataImportCreateRangeDateFlimDefInput[];
    checklist: BlabDefDataImportCreateChecklistFlimDefInput[];
    multiLink: BlabDefDataImportCreateMultiLinkFlimDefInput[];
    number: BlabDefDataImportCreateNumberFlimDefInput[];
    status: BlabDefDataImportCreateStatusFlimDefInput[];
    multiEmail: BlabDefDataImportCreateMultiEmailFlimDefInput[];
    multiPhone: BlabDefDataImportCreateMultiPhoneFlimDefInput[];
    singleLocation: BlabDefDataImportCreateSingleLocationFlimDefInput[];
}

export interface BlabDefDataImportCreateMultiCategoryFlimDefInput {
    clientSideId: number;
    name: string;
    position: string;
    required?: boolean;
    hiddenIfEmpty?: boolean;
    alwaysHidden?: boolean;
    showHelpText?: boolean;
    helpText?: string;
    layout?: CategoryFlimLayout;
    categoryOptionDefs?: CreateMultiCategoryOptionDefInput[];
}

export interface BlabDefDataImportCreateMultiCategoryOptionDefInput {
    flimDefId: number;
    label: string;
    color?: SelectableColor;
    position: string;
}

export interface BlabDefDataImportCreateMultiEmailFlimDefInput {
    clientSideId: number;
    name: string;
    position: string;
    required?: boolean;
    hiddenIfEmpty?: boolean;
    alwaysHidden?: boolean;
    showHelpText?: boolean;
    helpText?: string;
    allowOnlySingleEntry?: boolean;
}

export interface BlabDefDataImportCreateMultiLinkFlimDefInput {
    clientSideId: number;
    name: string;
    position: string;
    required?: boolean;
    hiddenIfEmpty?: boolean;
    alwaysHidden?: boolean;
    showHelpText?: boolean;
    helpText?: string;
}

export interface BlabDefDataImportCreateMultiPhoneFlimDefInput {
    clientSideId: number;
    name: string;
    position: string;
    required?: boolean;
    hiddenIfEmpty?: boolean;
    alwaysHidden?: boolean;
    showHelpText?: boolean;
    helpText?: string;
    callLinkProtocol: CallLinkProtocol;
    allowOnlySingleEntry?: boolean;
}

export interface BlabDefDataImportCreateMultiTextFlimDefInput {
    clientSideId: number;
    name: string;
    position: string;
    required?: boolean;
    hiddenIfEmpty?: boolean;
    alwaysHidden?: boolean;
    showHelpText?: boolean;
    helpText?: string;
    disableRichTextFormatting?: boolean;
    defaultValue?: string;
}

export interface BlabDefDataImportCreateMultiUserFlimDefInput {
    clientSideId: number;
    name: string;
    position: string;
    required?: boolean;
    hiddenIfEmpty?: boolean;
    alwaysHidden?: boolean;
    showHelpText?: boolean;
    helpText?: string;
    doNotNotify?: boolean;
    showInFocus?: boolean;
}

export interface BlabDefDataImportCreateNumberFlimDefInput {
    clientSideId: number;
    name: string;
    precision?: number;
    position: string;
    required?: boolean;
    hiddenIfEmpty?: boolean;
    alwaysHidden?: boolean;
    showHelpText?: boolean;
    helpText?: string;
    unitLocation?: NumberFlimDefUnitLocation;
    unitLabel?: string;
    hideThousandSeparator?: boolean;
}

export interface BlabDefDataImportCreateRangeDateFlimDefInput {
    clientSideId: number;
    name: string;
    position: string;
    required?: boolean;
    hiddenIfEmpty?: boolean;
    alwaysHidden?: boolean;
    showHelpText?: boolean;
    helpText?: string;
    defaultStartDate?: DateTime;
    defaultHasTime?: boolean;
    defaultEndDate?: DateTime;
    requireEndDate?: boolean;
    timeSetting?: TimeSetting;
    showInCalendar?: boolean;
    isDueDateOfStatusField?: boolean;
}

export interface BlabDefDataImportCreateSingleCategoryFlimDefInput {
    clientSideId: number;
    name: string;
    position: string;
    required?: boolean;
    hiddenIfEmpty?: boolean;
    alwaysHidden?: boolean;
    showHelpText?: boolean;
    helpText?: string;
    layout?: CategoryFlimLayout;
    categoryOptionDefs?: CreateSingleCategoryOptionDefInput[];
    showColorInCalendar?: boolean;
    defaultCategoryOptionDefId?: number;
}

export interface BlabDefDataImportCreateSingleCategoryOptionDefInput {
    flimDefId: number;
    label: string;
    color?: SelectableColor;
    position: string;
}

export interface BlabDefDataImportCreateSingleDateFlimDefInput {
    clientSideId: number;
    name: string;
    position: string;
    required?: boolean;
    hiddenIfEmpty?: boolean;
    alwaysHidden?: boolean;
    showHelpText?: boolean;
    helpText?: string;
    defaultDate?: DateTime;
    defaultHasTime?: boolean;
    timeSetting?: TimeSetting;
    showInCalendar?: boolean;
    isDueDateOfStatusField?: boolean;
}

export interface BlabDefDataImportCreateSingleLocationFlimDefInput {
    clientSideId: number;
    name: string;
    position: string;
    required?: boolean;
    hiddenIfEmpty?: boolean;
    alwaysHidden?: boolean;
    showHelpText?: boolean;
    helpText?: string;
    showMap?: boolean;
}

export interface BlabDefDataImportCreateSingleTextFlimDefInput {
    clientSideId: number;
    name: string;
    position: string;
    required?: boolean;
    hiddenIfEmpty?: boolean;
    alwaysHidden?: boolean;
    helpText?: string;
    showHelpText?: boolean;
    defaultValue?: string;
}

export interface BlabDefDataImportCreateSingleUserFlimDefInput {
    clientSideId: number;
    name: string;
    position: string;
    required?: boolean;
    hiddenIfEmpty?: boolean;
    alwaysHidden?: boolean;
    showHelpText?: boolean;
    helpText?: string;
    doNotNotify?: boolean;
    showInFocus?: boolean;
}

export interface BlabDefDataImportCreateStatusFlimDefInput {
    clientSideId: number;
    name: string;
    position: string;
    required?: boolean;
    hiddenIfEmpty?: boolean;
    alwaysHidden?: boolean;
    showHelpText?: boolean;
    helpText?: string;
    statusLayout?: StatusFlimLayout;
    statusOptionDefs?: CreateStatusOptionDefInput[];
    showColorInCalendar?: boolean;
    defaultStatusOptionDefId?: number;
}

export interface BlabDefDataImportCreateStatusOptionDefInput {
    flimDefId: number;
    label: string;
    color?: SelectableColor;
    meansCompleted: boolean;
    position: string;
}

export interface BlabItemReferenceCreateInput {
    blabItemId: number;
    position: string;
}

export interface BlabItemReferenceUpsertInput {
    blabItemId: number;
    position: string;
}

export interface BlabItemsFilteringInput {
    singleTextFilters?: SingleTextFilterInput[];
    multiTextFilters?: MultiTextFilterInput[];
    singleCategoryFilters?: SingleCategoryFilterInput[];
    multiCategoryFilters?: MultiCategoryFilterInput[];
    singleRelationFilters?: SingleRelationFilterInput[];
    multiRelationFilters?: MultiRelationFilterInput[];
    singleUserFilters?: SingleUserFilterInput[];
    multiUserFilters?: MultiUserFilterInput[];
    singleAttachmentFilters?: SingleAttachmentFilterInput[];
    multiAttachmentFilters?: MultiAttachmentFilterInput[];
    rangeDateFilters?: RangeDateFilterInput[];
    singleDateFilters?: SingleDateFilterInput[];
    createdAtFilters?: CreatedAtFilterInput[];
    createdByFilters?: CreatedByFilterInput[];
    lastModifiedAtFilters?: LastModifiedAtFilterInput[];
    lastModifiedByFilters?: LastModifiedByFilterInput[];
    numberFilters?: NumberFilterInput[];
    statusFilters?: StatusFilterInput[];
    calculationFilters?: CalculationFilterInput[];
    multiLinkFilters?: MultiLinkFilterInput[];
    multiEmailFilters?: MultiEmailFilterInput[];
    multiPhoneFilters?: MultiPhoneFilterInput[];
    singleLocationFilters?: SingleLocationFilterInput[];
    multiImageFilters?: MultiImageFilterInput[];
    uniqueIdFilters?: UniqueIdFilterInput[];
    checklistFilters?: ChecklistFilterInput[];
    searchQuery?: string;
    idFilter?: IdMetaFilterInput;
    blabDefBlabItemIdFilter?: BlabDefBlabItemIdMetaFilterInput;
}

export interface BlabItemsFlimStatsInput {
    singleTextFlimStats?: SingleTextFlimStatInput[];
    multiTextFlimStats?: MultiTextFlimStatInput[];
    singleAttachmentFlimStats?: SingleAttachmentFlimStatInput[];
    multiAttachmentFlimStats?: MultiAttachmentFlimStatInput[];
    singleRelationFlimStats?: SingleRelationFlimStatInput[];
    multiRelationFlimStats?: MultiRelationFlimStatInput[];
    singleDateFlimStats?: SingleDateFlimStatInput[];
    rangeDateFlimStats?: RangeDateFlimStatInput[];
    numberFlimStats?: NumberFlimStatInput[];
    checklistFlimStats?: ChecklistFlimStatInput[];
    lastModifiedAtFlimStats?: LastModifiedAtFlimStatInput[];
    lastModifiedByFlimStats?: LastModifiedByFlimStatInput[];
    createdAtFlimStats?: CreatedAtFlimStatInput[];
    createdByFlimStats?: CreatedByFlimStatInput[];
    singleCategoryFlimStats?: SingleCategoryFlimStatInput[];
    multiCategoryFlimStats?: MultiCategoryFlimStatInput[];
    singleUserFlimStats?: SingleUserFlimStatInput[];
    multiUserFlimStats?: MultiUserFlimStatInput[];
    multiLinkFlimStats?: MultiLinkFlimStatInput[];
    statusFlimStats?: StatusFlimStatInput[];
    calculationFlimStats?: CalculationFlimStatInput[];
    multiEmailFlimStats?: MultiEmailFlimStatInput[];
    multiPhoneFlimStats?: MultiPhoneFlimStatInput[];
    multiImageFlimStats?: MultiImageFlimStatInput[];
    singleLocationFlimStats?: SingleLocationFlimStatInput[];
    uniqueIdFlimStats?: UniqueIdFlimStatInput[];
}

export interface BlabItemsSortingInput {
    flimDefId: number;
    direction: SortingDirection;
    sortingProperty?: BlabItemsSortingProperty;
}

export interface BlabReferenceInput {
    blabDefId: number;
    blabViewId?: number;
    position: string;
}

export interface BlabViewBoardColumnInput {
    singleCategory: BlabViewBoardSingleCategoryColumnInput[];
    status: BlabViewBoardStatusColumnInput[];
}

export interface BlabViewBoardPropertyInput {
    flimDefId: number;
    position: string;
    shown?: boolean;
}

export interface BlabViewBoardSingleCategoryColumnInput {
    flimDefId: number;
    singleCategoryOptionDefId?: number;
    position: string;
    hidden?: boolean;
}

export interface BlabViewBoardStatusColumnInput {
    flimDefId: number;
    statusOptionDefId: number;
    position: string;
    hidden?: boolean;
}

export interface BlabViewColumnInput {
    flimDefId: number;
    position: string;
    hidden?: boolean;
    width?: number;
}

export interface BlabViewListPropertyInput {
    flimDefId: number;
    position: string;
    shown?: boolean;
}

export interface BulkFollowBlabItemsInput {
    blabDefId: number;
    filtering: BlabItemsFilteringInput;
}

export interface BulkUnfollowBlabItemsInput {
    blabDefId: number;
    filtering: BlabItemsFilteringInput;
}

export interface CalculationFilterInput {
    flimDefId: number;
    position?: string;
    searchText?: string;
    decimal?: number;
    calculationMatchType: CalculationFilterMatchType;
    calculationRelativeDate?: CalculationRelativeDateType;
    calculationRelativeDateRange?: CalculationRelativeDateRangeType;
    numDays?: number;
    numWeeks?: number;
    numMonths?: number;
    exactDate?: DateTime;
    exactEndDate?: DateTime;
    weekday?: Weekday;
    quarterOfYear?: QuarterOfYear;
    monthOfYear?: MonthOfYear;
}

export interface CalculationFlimStatInput {
    statType: CalculationFlimStatType;
    flimDefId: number;
}

export interface CancelBillingSubscriptionInput {
    immediately: boolean;
}

export interface CancelWorkflowRunsOfWorkflowDefInput {
    workflowDefId: number;
}

export interface ChangeActiveUserFeatureAccessInput {
    featureAccessLevel: UserFeatureAccessLevel;
}

export interface ChangeBillingSubscriptionBillingPeriodInput {
    billingPeriod: BillingPeriod;
    paymentMethodId: string;
    billingCustomer: UpdateBillingCustomerInput;
}

export interface ChangeBillingSubscriptionPlanInput {
    plan: OrganizationPlan;
    paymentMethodId: string;
    billingCustomer: UpdateBillingCustomerInput;
}

export interface ChangeOrganizationUserRoleInput {
    id: number;
    role: OrganizationRole;
}

export interface ChangePasswordWithForgotPasswordTokenOptionsInput {
    preserveExistingSessions?: boolean;
}

export interface ChangeUserPasswordInput {
    currentPassword: string;
    newPassword: string;
}

export interface ChangeWorkspaceMembershipRoleInput {
    userId?: number;
    organizationInvitationId?: number;
    workspaceId: number;
    role: WorkspaceRole;
}

export interface ChangeWorkspaceUserExplicitRoleInput {
    workspaceId: number;
    userId: number;
    explicitRole: WorkspaceRole;
}

export interface CheckEmailInput {
    email: string;
}

export interface ChecklistFilterInput {
    flimDefId: number;
    position?: string;
    checklistMatchType: ChecklistFilterMatchType;
    checklistEntryProperty?: ChecklistFilterEntryProperty;
    searchText?: string;
    completed?: boolean;
    checklistDueDateRelativeDateType?: ChecklistRelativeDateType;
    checklistDueDateRelativeDateRangeType?: ChecklistRelativeDateRangeType;
    date?: DateTime;
    numDays?: number;
    numWeeks?: number;
    numMonths?: number;
    referencedUserIds?: number[];
    includeActiveUser?: boolean;
    includeActiveUserIndex?: number;
}

export interface ChecklistFlimStatInput {
    statType: ChecklistFlimStatType;
    flimDefId: number;
}

export interface CheckUserPasswordValidityInput {
    password: string;
}

export interface CollapseFocusSectionInput {
    id: number;
}

export interface ConnectAudioRoomTransportInput {
    roomId: number;
    direction: AudioRoomTransportVoiceDirection;
    dtlsParameters: string;
}

export interface ConvertMultiAttachmentFlimToSingleAttachmentFlimInput {
    flimDefId: number;
    name: string;
    required?: boolean;
    hiddenIfEmpty?: boolean;
    alwaysHidden?: boolean;
    showHelpText?: boolean;
    helpText?: string;
}

export interface ConvertMultiCategoryFlimToSingleCategoryFlimInput {
    flimDefId: number;
    name: string;
    required?: boolean;
    hiddenIfEmpty?: boolean;
    alwaysHidden?: boolean;
    showHelpText?: boolean;
    helpText?: string;
    layout?: CategoryFlimLayout;
    showColorInCalendar?: boolean;
    defaultCategoryOptionDefId?: number;
    categoryOptionDefs: ConvertMultiCategoryOptionDefToSingleCategoryOptionDefInput[];
}

export interface ConvertMultiCategoryOptionDefToSingleCategoryOptionDefInput {
    id?: number;
    label?: string;
    color?: SelectableColor;
    position: string;
}

export interface ConvertMultiRelationFlimToSingleRelationFlimInput {
    flimDefId: number;
    name: string;
    required?: boolean;
    hiddenIfEmpty?: boolean;
    alwaysHidden?: boolean;
    showHelpText?: boolean;
    helpText?: string;
}

export interface ConvertMultiTextFlimToSingleTextFlimInput {
    flimDefId: number;
    name: string;
    required?: boolean;
    hiddenIfEmpty?: boolean;
    alwaysHidden?: boolean;
    helpText?: string;
    showHelpText?: boolean;
    defaultValue?: string;
}

export interface ConvertMultiUserFlimToSingleUserFlimInput {
    flimDefId: number;
    name: string;
    required?: boolean;
    hiddenIfEmpty?: boolean;
    alwaysHidden?: boolean;
    helpText?: string;
    showHelpText?: boolean;
    defaultValue?: string;
    doNotNotify?: boolean;
    showInFocus?: boolean;
}

export interface ConvertRangeDateFlimToSingleDateFlimInput {
    flimDefId: number;
    name: string;
    required?: boolean;
    hiddenIfEmpty?: boolean;
    alwaysHidden?: boolean;
    showHelpText?: boolean;
    helpText?: string;
    defaultDate?: DateTime;
    defaultHasTime?: boolean;
    timeSetting?: TimeSetting;
    showInCalendar?: boolean;
    isDueDateOfStatusField?: boolean;
}

export interface ConvertSingleAttachmentFlimToMultiAttachmentFlimInput {
    flimDefId: number;
    name: string;
    required?: boolean;
    hiddenIfEmpty?: boolean;
    alwaysHidden?: boolean;
    showHelpText?: boolean;
    helpText?: string;
}

export interface ConvertSingleCategoryFlimToMultiCategoryFlimInput {
    flimDefId: number;
    name: string;
    required?: boolean;
    hiddenIfEmpty?: boolean;
    alwaysHidden?: boolean;
    showHelpText?: boolean;
    helpText?: string;
    layout?: CategoryFlimLayout;
    categoryOptionDefs: ConvertSingleCategoryOptionDefToMultiCategoryOptionDefInput[];
}

export interface ConvertSingleCategoryFlimToSingleTextFlimInput {
    flimDefId: number;
    name: string;
    required?: boolean;
    hiddenIfEmpty?: boolean;
    alwaysHidden?: boolean;
    helpText?: string;
    showHelpText?: boolean;
}

export interface ConvertSingleCategoryFlimToStatusFlimInput {
    flimDefId: number;
    name: string;
    required?: boolean;
    hiddenIfEmpty?: boolean;
    alwaysHidden?: boolean;
    helpText?: string;
    showHelpText?: boolean;
    statusLayout?: StatusFlimLayout;
    categoryOptionDefStatusOptionDefMapping: ConvertSingleCategoryOptionDefToStatusOptionDefInput[];
}

export interface ConvertSingleCategoryOptionDefToMultiCategoryOptionDefInput {
    id?: number;
    label?: string;
    color?: SelectableColor;
    position: string;
}

export interface ConvertSingleCategoryOptionDefToStatusOptionDefInput {
    categoryOptionDefId?: number;
    label: string;
    color?: SelectableColor;
    position: string;
    meansCompleted: boolean;
}

export interface ConvertSingleDateFlimToRangeDateFlimInput {
    flimDefId: number;
    name: string;
    required?: boolean;
    hiddenIfEmpty?: boolean;
    alwaysHidden?: boolean;
    showHelpText?: boolean;
    helpText?: string;
    defaultStartDate?: DateTime;
    defaultHasTime?: boolean;
    defaultEndDate?: DateTime;
    requireEndDate?: boolean;
    timeSetting?: TimeSetting;
    showInCalendar?: boolean;
    isDueDateOfStatusField?: boolean;
}

export interface ConvertSingleRelationFlimToMultiRelationFlimInput {
    flimDefId: number;
    name: string;
    required?: boolean;
    hiddenIfEmpty?: boolean;
    alwaysHidden?: boolean;
    showHelpText?: boolean;
    helpText?: string;
}

export interface ConvertSingleTextFlimToMultiLinkFlimInput {
    flimDefId: number;
    name: string;
    required?: boolean;
    hiddenIfEmpty?: boolean;
    alwaysHidden?: boolean;
    showHelpText?: boolean;
    helpText?: string;
}

export interface ConvertSingleTextFlimToMultiTextFlimInput {
    flimDefId: number;
    name: string;
    required?: boolean;
    hiddenIfEmpty?: boolean;
    alwaysHidden?: boolean;
    helpText?: string;
    showHelpText?: boolean;
    disableRichTextFormatting?: boolean;
    defaultValue?: string;
}

export interface ConvertSingleTextFlimToNumberFlimInput {
    flimDefId: number;
    name: string;
    required?: boolean;
    hiddenIfEmpty?: boolean;
    alwaysHidden?: boolean;
    helpText?: string;
    showHelpText?: boolean;
    precision?: number;
    unitLocation?: NumberFlimDefUnitLocation;
    unitLabel?: string;
    hideThousandSeparator?: boolean;
}

export interface ConvertSingleTextFlimToSingleCategoryFlimInput {
    flimDefId: number;
    name: string;
    required?: boolean;
    hiddenIfEmpty?: boolean;
    alwaysHidden?: boolean;
    helpText?: string;
    showHelpText?: boolean;
    showColorInCalendar?: boolean;
}

export interface ConvertSingleUserFlimToMultiUserFlimInput {
    flimDefId: number;
    name: string;
    required?: boolean;
    hiddenIfEmpty?: boolean;
    alwaysHidden?: boolean;
    helpText?: string;
    showHelpText?: boolean;
    defaultValue?: string;
    doNotNotify?: boolean;
    showInFocus?: boolean;
}

export interface CopyOrganizationInvitationLinkInput {
    id: number;
}

export interface CreateAudioRoomInput {
    name: string;
}

export interface CreateBillingSubscriptionInput {
    plan: OrganizationPlan;
    billingPeriod: BillingPeriod;
    paymentMethodId: string;
    billingCustomer: UpdateBillingCustomerInput;
}

export interface CreateBlabDefInput {
    workspaceId: number;
    blabName: string;
    itemName: string;
    icon?: BlabDefIcon;
    populate?: boolean;
}

export interface CreateBlabDefWebhookInput {
    blabDefId: number;
    position: string;
    url?: string;
    webhookType?: BlabDefWebhookType;
}

export interface CreateBlabItemInput {
    blabDefId: number;
    isTemplate?: BlabItemIsTemplateEnum;
    templateTitle?: string;
    viewPosition?: string;
    blabViewId?: number;
    singleTextFlimVals?: CreateSingleTextFlimValInput[];
    multiTextFlimVals?: CreateMultiTextFlimValInput[];
    singleCategoryFlimVals?: CreateSingleCategoryFlimValInput[];
    multiCategoryFlimVals?: CreateMultiCategoryFlimValInput[];
    singleRelationFlimVals?: CreateSingleRelationFlimValInput[];
    multiRelationFlimVals?: CreateMultiRelationFlimValInput[];
    singleUserFlimVals?: CreateSingleUserFlimValInput[];
    multiUserFlimVals?: CreateMultiUserFlimValInput[];
    singleAttachmentFlimVals?: CreateSingleAttachmentFlimValInput[];
    multiAttachmentFlimVals?: CreateMultiAttachmentFlimValInput[];
    singleDateFlimVals?: CreateSingleDateFlimValInput[];
    rangeDateFlimVals?: CreateRangeDateFlimValInput[];
    checklistFlimVals?: CreateChecklistFlimValInput[];
    multiLinkFlimVals?: CreateMultiLinkFlimValInput[];
    numberFlimVals?: CreateNumberFlimValInput[];
    statusFlimVals?: CreateStatusFlimValInput[];
    multiEmailFlimVals?: CreateMultiEmailFlimValInput[];
    multiPhoneFlimVals?: CreateMultiPhoneFlimValInput[];
    multiImageFlimVals?: CreateMultiImageFlimValInput[];
    singleLocationFlimVals?: CreateSingleLocationFlimValInput[];
}

export interface CreateBlabViewInput {
    blabDefId: number;
    name?: string;
    layout?: BlabViewLayout;
    tableRowHeight?: TableRowHeight;
    tableWrapCells?: boolean;
    sorting?: BlabItemsSortingInput;
    filtering?: BlabItemsFilteringInput;
    columns?: BlabViewColumnInput[];
    listProperties?: BlabViewListPropertyInput[];
    flimStatDefs?: BlabItemsFlimStatsInput;
    isPublic?: boolean;
    boardProperties?: BlabViewBoardPropertyInput[];
    boardColumns?: BlabViewBoardColumnInput;
    boardCardSize?: BlabViewBoardCardSizeType;
    boardLargeText?: boolean;
    boardGroupByFlimDefId?: number;
    boardCardPreviewFlimDefId?: number;
    boardHideEmptyColumns?: boolean;
    boardColorColumns?: boolean;
    boardCardPreviewFitImage?: boolean;
    originBlabViewId?: number;
    hasOriginAutoSaveBlabView?: boolean;
}

export interface CreateCalculationFlimDefInput {
    blabDefId: number;
    name: string;
    position: string;
    required?: boolean;
    showInRecord?: boolean;
    hiddenIfEmpty?: boolean;
    alwaysHidden?: boolean;
    showHelpText?: boolean;
    helpText?: string;
    largeEditor?: boolean;
    script: string;
    scriptResultType?: ScriptResultType;
    precision?: number;
    unitLocation?: NumberFlimDefUnitLocation;
    unitLabel?: string;
    hideThousandSeparator?: boolean;
    showInCalendar?: boolean;
    timeSetting?: TimeSetting;
}

export interface CreateCallableWorkflowDefInput {
    blabDefId: number;
    triggerType: WorkflowTriggerType;
    triggeringBlabDefId?: number;
    name?: string;
}

export interface CreateChatGroupInput {
    userIds: number[];
    name: string;
}

export interface CreateChecklistFlimDefInput {
    blabDefId: number;
    name: string;
    position: string;
    required?: boolean;
    hiddenIfEmpty?: boolean;
    alwaysHidden?: boolean;
    showHelpText?: boolean;
    helpText?: string;
    showInFocus?: boolean;
    showCompletedDefault?: boolean;
}

export interface CreateChecklistFlimValEntryInput {
    blabItemId: number;
    flimDefId: number;
    position: string;
}

export interface CreateChecklistFlimValEntryNestedCreationInput {
    position: string;
    completed?: boolean;
    title?: string;
    description?: string;
    dueAt?: DateTime;
    dueAtHasTime?: boolean;
    assigneeUserId?: number;
}

export interface CreateChecklistFlimValInput {
    flimDefId: number;
    checklistEntries: CreateChecklistFlimValEntryNestedCreationInput[];
}

export interface CreateCreatedAtFlimDefInput {
    blabDefId: number;
    name: string;
    position: string;
    required?: boolean;
    showInRecord?: boolean;
    hiddenIfEmpty?: boolean;
    alwaysHidden?: boolean;
    showHelpText?: boolean;
    helpText?: string;
    showTime?: boolean;
}

export interface CreateCreatedByFlimDefInput {
    blabDefId: number;
    name: string;
    position: string;
    required?: boolean;
    showInRecord?: boolean;
    hiddenIfEmpty?: boolean;
    alwaysHidden?: boolean;
    showHelpText?: boolean;
    helpText?: string;
}

export interface CreatedAtFilterInput {
    flimDefId: number;
    position?: string;
    createdAtMatchType: CreatedAtFilterMatchType;
    exactDate?: DateTime;
    exactEndDate?: DateTime;
    weekday?: Weekday;
    quarterOfYear?: QuarterOfYear;
    monthOfYear?: MonthOfYear;
    createdAtRelativeDateRange?: CreatedAtRelativeDateRangeType;
    createdAtRelativeDate?: CreatedAtRelativeDateType;
    numDays?: number;
    numWeeks?: number;
    numMonths?: number;
}

export interface CreatedAtFlimStatInput {
    statType: CreatedAtFlimStatType;
    flimDefId: number;
}

export interface CreatedByFilterInput {
    flimDefId: number;
    position?: string;
    referencedUserIds: number[];
    includeActiveUser?: boolean;
    includeActiveUserIndex?: number;
    createdByMatchType: CreatedByFilterMatchType;
}

export interface CreatedByFlimStatInput {
    statType: CreatedByFlimStatType;
    flimDefId: number;
}

export interface CreateEmptyWorkflowDefInput {
    blabDefId: number;
    createAsNeverSavedByUser?: boolean;
}

export interface CreateFlimDefsInput {
    singleText: CreateSingleTextFlimDefInput[];
    multiText: CreateMultiTextFlimDefInput[];
    singleCategory: CreateSingleCategoryFlimDefInput[];
    multiCategory: CreateMultiCategoryFlimDefInput[];
    singleRelation: CreateSingleRelationFlimDefInput[];
    multiRelation: CreateMultiRelationFlimDefInput[];
    singleAttachment: CreateSingleAttachmentFlimDefInput[];
    multiAttachment: CreateMultiAttachmentFlimDefInput[];
    singleUser: CreateSingleUserFlimDefInput[];
    multiUser: CreateMultiUserFlimDefInput[];
    singleDate: CreateSingleDateFlimDefInput[];
    rangeDate: CreateRangeDateFlimDefInput[];
    checklist: CreateChecklistFlimDefInput[];
    multiLink: CreateMultiLinkFlimDefInput[];
    createdAt: CreateCreatedAtFlimDefInput[];
    createdBy: CreateCreatedByFlimDefInput[];
    lastModifiedAt: CreateLastModifiedAtFlimDefInput[];
    lastModifiedBy: CreateLastModifiedByFlimDefInput[];
    number: CreateNumberFlimDefInput[];
    status: CreateStatusFlimDefInput[];
    calculation: CreateCalculationFlimDefInput[];
    multiEmail: CreateMultiEmailFlimDefInput[];
    multiPhone: CreateMultiPhoneFlimDefInput[];
    multiImage: CreateMultiImageFlimDefInput[];
    singleLocation: CreateSingleLocationFlimDefInput[];
    uniqueId: CreateUniqueIdFlimDefInput[];
}

export interface CreateFlimValAttachmentInput {
    flimDefId: number;
}

export interface CreateFlimValImageInput {
    flimDefId: number;
}

export interface CreateFocusSectionInput {
    position: string;
    name?: string;
}

export interface CreateLastModifiedAtFlimDefInput {
    blabDefId: number;
    name: string;
    position: string;
    required?: boolean;
    showInRecord?: boolean;
    hiddenIfEmpty?: boolean;
    alwaysHidden?: boolean;
    showHelpText?: boolean;
    helpText?: string;
    showTime?: boolean;
}

export interface CreateLastModifiedByFlimDefInput {
    blabDefId: number;
    name: string;
    position: string;
    required?: boolean;
    showInRecord?: boolean;
    hiddenIfEmpty?: boolean;
    alwaysHidden?: boolean;
    showHelpText?: boolean;
    helpText?: string;
}

export interface CreateLinkPreviewInput {
    url: string;
}

export interface CreateMultiAttachmentFlimDefInput {
    blabDefId: number;
    name: string;
    position: string;
    required?: boolean;
    hiddenIfEmpty?: boolean;
    alwaysHidden?: boolean;
    showHelpText?: boolean;
    helpText?: string;
}

export interface CreateMultiAttachmentFlimValInput {
    flimDefId: number;
    attachmentReferences: AttachmentReferenceCreateInput[];
}

export interface CreateMultiCategoryFlimDefInput {
    blabDefId: number;
    name: string;
    position: string;
    required?: boolean;
    hiddenIfEmpty?: boolean;
    alwaysHidden?: boolean;
    showHelpText?: boolean;
    helpText?: string;
    layout?: CategoryFlimLayout;
    categoryOptionDefs?: CreateMultiCategoryOptionDefInput[];
}

export interface CreateMultiCategoryFlimValInput {
    flimDefId: number;
    categoryOptionDefIds: number[];
}

export interface CreateMultiCategoryOptionDefInput {
    label: string;
    color?: SelectableColor;
    position: string;
}

export interface CreateMultiEmailFlimDefInput {
    blabDefId: number;
    name: string;
    position: string;
    required?: boolean;
    hiddenIfEmpty?: boolean;
    alwaysHidden?: boolean;
    showHelpText?: boolean;
    helpText?: string;
    allowOnlySingleEntry?: boolean;
}

export interface CreateMultiEmailFlimValEntryInput {
    blabItemId: number;
    flimDefId: number;
    position: string;
    emailAddress: string;
    emailAddressType: EmailAddressType;
}

export interface CreateMultiEmailFlimValEntryNestedCreationInput {
    position: string;
    emailAddress: string;
    emailAddressType: EmailAddressType;
}

export interface CreateMultiEmailFlimValInput {
    flimDefId: number;
    multiEmailEntries: CreateMultiEmailFlimValEntryNestedCreationInput[];
}

export interface CreateMultiImageFlimDefInput {
    blabDefId: number;
    name: string;
    position: string;
    required?: boolean;
    hiddenIfEmpty?: boolean;
    alwaysHidden?: boolean;
    showHelpText?: boolean;
    helpText?: string;
    imageFlimLayout?: ImageFlimLayout;
}

export interface CreateMultiImageFlimValInput {
    flimDefId: number;
    imageReferences: ImageReferenceCreateInput[];
}

export interface CreateMultiLinkFlimDefInput {
    blabDefId: number;
    name: string;
    position: string;
    required?: boolean;
    hiddenIfEmpty?: boolean;
    alwaysHidden?: boolean;
    showHelpText?: boolean;
    helpText?: string;
}

export interface CreateMultiLinkFlimValEntryInput {
    blabItemId: number;
    flimDefId: number;
    position: string;
    url: string;
}

export interface CreateMultiLinkFlimValEntryNestedCreationInput {
    position: string;
    linkPreviewId: number;
}

export interface CreateMultiLinkFlimValInput {
    flimDefId: number;
    multiLinkEntries: CreateMultiLinkFlimValEntryNestedCreationInput[];
}

export interface CreateMultiPhoneFlimDefInput {
    blabDefId: number;
    name: string;
    position: string;
    required?: boolean;
    hiddenIfEmpty?: boolean;
    alwaysHidden?: boolean;
    showHelpText?: boolean;
    helpText?: string;
    callLinkProtocol: CallLinkProtocol;
    allowOnlySingleEntry?: boolean;
}

export interface CreateMultiPhoneFlimValEntryInput {
    blabItemId: number;
    flimDefId: number;
    position: string;
    phoneNumber: string;
    phoneNumberType: PhoneNumberType;
}

export interface CreateMultiPhoneFlimValEntryNestedCreationInput {
    phoneNumber: string;
    phoneNumberType: PhoneNumberType;
    position: string;
}

export interface CreateMultiPhoneFlimValInput {
    flimDefId: number;
    multiPhoneEntries: CreateMultiPhoneFlimValEntryNestedCreationInput[];
}

export interface CreateMultiRelationFlimDefInput {
    blabDefId: number;
    name: string;
    position: string;
    required?: boolean;
    hiddenIfEmpty?: boolean;
    alwaysHidden?: boolean;
    showHelpText?: boolean;
    helpText?: string;
    blabReferences?: BlabReferenceInput[];
}

export interface CreateMultiRelationFlimValInput {
    flimDefId: number;
    blabItemReferences: BlabItemReferenceCreateInput[];
}

export interface CreateMultiTextFlimDefInput {
    blabDefId: number;
    name: string;
    position: string;
    required?: boolean;
    hiddenIfEmpty?: boolean;
    alwaysHidden?: boolean;
    showHelpText?: boolean;
    helpText?: string;
    disableRichTextFormatting?: boolean;
    defaultValue?: string;
}

export interface CreateMultiTextFlimValInput {
    flimDefId: number;
    value: string;
}

export interface CreateMultiUserFlimDefInput {
    blabDefId: number;
    name: string;
    position: string;
    required?: boolean;
    hiddenIfEmpty?: boolean;
    alwaysHidden?: boolean;
    showHelpText?: boolean;
    helpText?: string;
    doNotNotify?: boolean;
    showInFocus?: boolean;
}

export interface CreateMultiUserFlimValInput {
    flimDefId: number;
    userReferences: UserReferenceCreateInput[];
}

export interface CreateNumberFlimDefInput {
    blabDefId: number;
    name: string;
    precision?: number;
    position: string;
    required?: boolean;
    hiddenIfEmpty?: boolean;
    alwaysHidden?: boolean;
    showHelpText?: boolean;
    helpText?: string;
    unitLocation?: NumberFlimDefUnitLocation;
    unitLabel?: string;
    hideThousandSeparator?: boolean;
}

export interface CreateNumberFlimValInput {
    flimDefId: number;
    decimal: number;
}

export interface CreateOrganizationAuthenticationProviderInput {
    hostAddress?: string;
    hostPort?: number;
    userName?: string;
    userPassword?: string;
    encryption?: SmtpEncryptionType;
    label: string;
    isTapeInternalSmtp: boolean;
}

export interface CreateOrganizationInvitationsInput {
    emails: string[];
    role: OrganizationRole;
}

export interface CreateOrganizationSmtpSettingsInput {
    hostAddress?: string;
    hostPort?: number;
    userName?: string;
    userPassword?: string;
    encryption?: SmtpEncryptionType;
    label: string;
    isTapeInternalSmtp: boolean;
}

export interface CreateOrganizationWorkspaceInput {
    name: string;
    icon?: WorkspaceIcon;
    description?: string;
    path?: string[];
    type?: WorkspaceType;
    defaultRole?: WorkspaceRole;
    populate?: boolean;
}

export interface CreateRangeDateFlimDefInput {
    blabDefId: number;
    name: string;
    position: string;
    required?: boolean;
    hiddenIfEmpty?: boolean;
    alwaysHidden?: boolean;
    showHelpText?: boolean;
    helpText?: string;
    defaultStartDate?: DateTime;
    defaultHasTime?: boolean;
    defaultEndDate?: DateTime;
    requireEndDate?: boolean;
    timeSetting?: TimeSetting;
    showInCalendar?: boolean;
    isDueDateOfStatusField?: boolean;
}

export interface CreateRangeDateFlimValInput {
    flimDefId: number;
    startDate: DateTime;
    hasTime?: boolean;
    endDate?: DateTime;
}

export interface CreateSingleAttachmentFlimDefInput {
    blabDefId: number;
    name: string;
    position: string;
    required?: boolean;
    hiddenIfEmpty?: boolean;
    alwaysHidden?: boolean;
    showHelpText?: boolean;
    helpText?: string;
}

export interface CreateSingleAttachmentFlimValInput {
    flimDefId: number;
    attachmentId: number;
}

export interface CreateSingleCategoryFlimDefInput {
    blabDefId: number;
    name: string;
    position: string;
    required?: boolean;
    hiddenIfEmpty?: boolean;
    alwaysHidden?: boolean;
    showHelpText?: boolean;
    helpText?: string;
    layout?: CategoryFlimLayout;
    categoryOptionDefs?: CreateSingleCategoryOptionDefInput[];
    showColorInCalendar?: boolean;
    defaultCategoryOptionDefId?: number;
}

export interface CreateSingleCategoryFlimValInput {
    flimDefId: number;
    categoryOptionDefId: number;
}

export interface CreateSingleCategoryOptionDefInput {
    label: string;
    color?: SelectableColor;
    position: string;
}

export interface CreateSingleDateFlimDefInput {
    blabDefId: number;
    name: string;
    position: string;
    required?: boolean;
    hiddenIfEmpty?: boolean;
    alwaysHidden?: boolean;
    showHelpText?: boolean;
    helpText?: string;
    defaultDate?: DateTime;
    defaultHasTime?: boolean;
    timeSetting?: TimeSetting;
    showInCalendar?: boolean;
    isDueDateOfStatusField?: boolean;
}

export interface CreateSingleDateFlimValInput {
    flimDefId: number;
    date: DateTime;
    hasTime?: boolean;
}

export interface CreateSingleLocationFlimDefInput {
    blabDefId: number;
    name: string;
    position: string;
    required?: boolean;
    hiddenIfEmpty?: boolean;
    alwaysHidden?: boolean;
    showHelpText?: boolean;
    helpText?: string;
    showMap?: boolean;
}

export interface CreateSingleLocationFlimValInput {
    flimDefId: number;
    originalFormattedAddress?: string;
    city?: string;
    country?: string;
    state?: string;
    postalCode?: string;
    streetAddress?: string;
    hideMapEmbed?: boolean;
    locationId?: string;
    latitude?: number;
    longitude?: number;
}

export interface CreateSingleRelationFlimDefInput {
    blabDefId: number;
    name: string;
    position: string;
    required?: boolean;
    hiddenIfEmpty?: boolean;
    alwaysHidden?: boolean;
    showHelpText?: boolean;
    helpText?: string;
    blabReferences?: BlabReferenceInput[];
}

export interface CreateSingleRelationFlimValInput {
    flimDefId: number;
    referencedBlabItemId: number;
}

export interface CreateSingleTextFlimDefInput {
    blabDefId: number;
    name: string;
    position: string;
    required?: boolean;
    hiddenIfEmpty?: boolean;
    alwaysHidden?: boolean;
    helpText?: string;
    showHelpText?: boolean;
    defaultValue?: string;
}

export interface CreateSingleTextFlimValInput {
    flimDefId: number;
    value: string;
}

export interface CreateSingleUserFlimDefInput {
    blabDefId: number;
    name: string;
    position: string;
    required?: boolean;
    hiddenIfEmpty?: boolean;
    alwaysHidden?: boolean;
    showHelpText?: boolean;
    helpText?: string;
    doNotNotify?: boolean;
    showInFocus?: boolean;
}

export interface CreateSingleUserFlimValInput {
    flimDefId: number;
    userId: number;
}

export interface CreateStatusFlimDefInput {
    blabDefId: number;
    name: string;
    position: string;
    required?: boolean;
    hiddenIfEmpty?: boolean;
    alwaysHidden?: boolean;
    showHelpText?: boolean;
    helpText?: string;
    statusLayout?: StatusFlimLayout;
    statusOptionDefs?: CreateStatusOptionDefInput[];
    showColorInCalendar?: boolean;
    defaultStatusOptionDefId?: number;
}

export interface CreateStatusFlimValInput {
    flimDefId: number;
    statusOptionDefId: number;
}

export interface CreateStatusOptionDefInput {
    label: string;
    color?: SelectableColor;
    meansCompleted: boolean;
    position: string;
}

export interface CreateUniqueIdFlimDefInput {
    blabDefId: number;
    name: string;
    position: string;
    required?: boolean;
    hiddenIfEmpty?: boolean;
    alwaysHidden?: boolean;
    showHelpText?: boolean;
    helpText?: string;
    minDigits: number;
    uniqueIdPrefix?: string;
}

export interface CreateWorkflowCenterWebhookInput {
    position: string;
    url?: string;
    webhookType?: WorkflowCenterWebhookType;
}

export interface CreateWorkflowDefInput {
    name: string;
    description?: string;
    triggerType: WorkflowTriggerType;
    filterDefinition: JSONObject;
    actionDefinition: JSONObject;
    blabDefId: number;
}

export interface DeactivateActiveUserCalendarSubscriptionInput {
    id: number;
}

export interface DeactivateBlabItemNotificationsInput {
    blabItemId: number;
}

export interface DeactivateOrganizationUserInput {
    id: number;
}

export interface DeactivateUserNotificationsInput {
    userId: number;
}

export interface DeleteBlabDefInput {
    id: number;
}

export interface DeleteBlabDefWebhookInput {
    id: number;
}

export interface DeleteBlabItemCommentInput {
    activityId: number;
}

export interface DeleteBlabItemInput {
    blabItemId: number;
}

export interface DeleteBlabItemsInput {
    blabDefId: number;
    filtering: BlabItemsFilteringInput;
}

export interface DeleteBlabItemTemplateInput {
    id: number;
}

export interface DeleteBlabViewInput {
    id: number;
}

export interface DeleteChatMessageInput {
    id: number;
}

export interface DeleteChecklistFlimValEntryInput {
    id: number;
}

export interface DeleteFlimDefInput {
    flimDefId: number;
}

export interface DeleteFlimValAttachmentInput {
    attachmentId: number;
}

export interface DeleteFlimValImageInput {
    imageId: number;
}

export interface DeleteLinkPreviewInput {
    id: number;
}

export interface DeleteMultiEmailFlimValEntryInput {
    id: number;
}

export interface DeleteMultiLinkFlimValEntryInput {
    id: number;
}

export interface DeleteMultiPhoneFlimValEntryInput {
    id: number;
}

export interface DeleteOrganizationWorkspaceInput {
    id: number;
}

export interface DeleteUserPostInput {
    activityId: number;
}

export interface DeleteWorkflowCenterWebhookInput {
    id: number;
}

export interface DeleteWorkflowDefInput {
    id: number;
}

export interface DenyOrganizationInvitationInput {
    id: number;
}

export interface DestroyAudioRoomInput {
    roomId: number;
}

export interface DisableActiveUserBlabDefInboundEmailInput {
    blabDefId: number;
}

export interface DisableBlabDefInboundPublicEmailInput {
    blabDefId: number;
}

export interface DisableWorkspaceShareLinkDuplicationInput {
    workspaceId: number;
}

export interface DisableWorkspaceShareLinkInput {
    workspaceId: number;
}

export interface DuplicateBlabDefInput {
    blabDefId: number;
    duplicateBlabItems: boolean;
}

export interface DuplicateBlabViewInput {
    id: number;
}

export interface DuplicateSharedWorkspaceInput {
    sharelinkToken?: string;
    timezone?: Timezone;
}

export interface DuplicateWorkflowDefInput {
    workflowDefId: number;
}

export interface DuplicateWorkspaceInput {
    workspaceId: number;
    duplicateBlabItems: boolean;
}

export interface EditBlabDefActivityReplyInput {
    activityReplyId: number;
    content: string;
}

export interface EditBlabItemActivityReplyInput {
    activityReplyId: number;
    content: string;
}

export interface EditBlabItemCommentInput {
    activityId: number;
    content?: string;
}

export interface EditIncompleteReminderInput {
    id: number;
    title?: string;
    description?: string;
    dueAt?: DateTime;
    dueAtHasTime?: boolean;
    isBeingNotified?: boolean;
}

export interface EditOrganizationActivityReplyInput {
    activityReplyId: number;
    content: string;
}

export interface EditUserActivityReplyInput {
    activityReplyId: number;
    content: string;
}

export interface EditUserPostInput {
    activityId: number;
    content: string;
}

export interface EditWorkspaceActivityReplyInput {
    activityReplyId: number;
    content: string;
}

export interface EnableActiveUserBlabDefInboundEmailInput {
    blabDefId: number;
}

export interface EnableBlabDefInboundPublicEmailInput {
    blabDefId: number;
}

export interface EnableWorkspaceShareLinkDuplicationInput {
    workspaceId: number;
}

export interface EnableWorkspaceShareLinkInput {
    workspaceId: number;
}

export interface ExpandFocusSectionInput {
    id: number;
}

export interface ExportBlabDefDataInput {
    blabDefId: number;
    sorting?: BlabItemsSortingInput;
    filtering?: BlabItemsFilteringInput;
    includeFlimDefIds?: number[];
    blabViewId?: number;
    exportFormat: BlabDefDataExportFormat;
    includeBlabItemComments?: boolean;
    includeAttachments?: boolean;
    includeChecklistFlimValEntries?: boolean;
}

export interface FindMyOrgsInput {
    email: string;
}

export interface FollowBlabDefInput {
    blabDefId: number;
}

export interface FollowBlabItemInput {
    blabItemId: number;
}

export interface FollowUserInput {
    userId: number;
}

export interface FollowWorkflowDefInput {
    workflowDefId: number;
}

export interface GainAdminAccessToOrganizationWorkspaceInput {
    id: number;
}

export interface GenerateWorkflowDefCodeInput {
    workflowDefId: number;
}

export interface GenerateWorkspaceShareLinkTokenInput {
    workspaceId: number;
}

export interface GetActiveUserBlabDefInboundEmailSettingsInput {
    blabDefId: number;
}

export interface GetActiveUserBlabDefInfoInput {
    blabDefId: number;
}

export interface GetActiveUserBlabItemInfoInput {
    blabItemId: number;
}

export interface GetActiveUserBlabItemTemplatesInput {
    blabDefId: number;
}

export interface GetActiveUserBlabViewPreviewsInput {
    blabDefId: number;
}

export interface GetActiveUserCalendarSettingsInput {
    _void?: boolean;
}

export interface GetActiveUserCalendarStaticSharesInput {
    _void?: boolean;
}

export interface GetActiveUserCalendarSubscriptionsInput {
    _void?: boolean;
}

export interface GetActiveUserExploreSuggestionsForCategoryInput {
    category: ActiveUserExploreCategory;
}

export interface GetActiveUserExploreSuggestionsInput {
    _void?: boolean;
}

export interface GetActiveUserQuickAddBlabDefsInput {
    _void?: boolean;
}

export interface GetActiveUserSuggestedQuickAddBlabDefsInput {
    searchQuery?: string;
}

export interface GetActiveUserUserInfoInput {
    userId: number;
}

export interface GetActiveUserWorkflowDefInfoInput {
    workflowDefId: number;
}

export interface GetActiveUserWorkspaceInfoInput {
    workspaceId: number;
}

export interface GetAllIncomingAndOutgoingBlabDefsInput {
    blabDefId: number;
}

export interface GetAudioRoomInput {
    roomId: number;
}

export interface GetAudioRoomPreviewsInput {
    _void?: boolean;
}

export interface GetBlabDefActivityInput {
    blabDefId: number;
}

export interface GetBlabDefActivityRepliesInput {
    activityId: number;
}

export interface GetBlabDefBySlugInput {
    slug: string;
    workspaceId: number;
}

export interface GetBlabDefDataImportActivitiesInput {
    blabDefId: number;
}

export interface GetBlabDefDataImportActivityInput {
    id: number;
}

export interface GetBlabDefDataImportToExistingBlabDefConfigPreviewInput {
    blabDefId: number;
    fileId: number;
    characterCode?: BlabDefDataImportCharacterCode;
    csvValueSeparator?: BlabDefDataImportCsvValueSeparator;
}

export interface GetBlabDefDataImportToExistingBlabDefConfigPreviewLookupInput {
    blabDefId: number;
}

export interface GetBlabDefDataImportToNewBlabDefConfigPreviewInput {
    workspaceId: number;
    fileId: number;
    characterCode?: BlabDefDataImportCharacterCode;
    csvValueSeparator?: BlabDefDataImportCsvValueSeparator;
}

export interface GetBlabDefDataImportToNewBlabDefConfigPreviewLookupInput {
    _void?: boolean;
}

export interface GetBlabDefDeveloperInfoInput {
    blabDefId: number;
}

export interface GetBlabDefFollowingInput {
    blabDefId: number;
}

export interface GetBlabDefRelationsWithFlimDefsInput {
    blabDefIds: number[];
}

export interface GetBlabDefSettingsInput {
    blabDefId: number;
}

export interface GetBlabItemActivityCountsInput {
    blabItemId: number;
}

export interface GetBlabItemActivityInput {
    blabItemId: number;
    filter?: GetBlabItemActivityFilterType;
}

export interface GetBlabItemActivityRepliesInput {
    activityId: number;
}

export interface GetBlabItemFocusSectionInput {
    blabItemId: number;
}

export interface GetBlabItemFollowersInput {
    blabItemId: number;
}

export interface GetBlabItemRemindersInput {
    blabItemId: number;
}

export interface GetBlabItemsForBlabDefInput {
    blabDefId: number;
    sorting?: BlabItemsSortingInput;
    filtering?: BlabItemsFilteringInput;
    blabViewId?: number;
}

export interface GetBlabItemStatsInput {
    blabDefId: number;
    filtering?: BlabItemsFilteringInput;
    flimStats: BlabItemsFlimStatsInput;
}

export interface GetBlabViewInput {
    id: number;
}

export interface GetCalculationScriptResultPreviewInput {
    script: string;
    blabDefId: number;
    flimDefId?: number;
}

export interface GetCalendarEventsInput {
    from: DateTime;
    to: DateTime;
}

export interface GetChangeSubscriptionBillingPeriodInvoicePreviewInput {
    billingPeriod: BillingPeriod;
    country?: BillingCountry;
    postalCode?: string;
    businessCustomer?: boolean;
    taxId?: string;
}

export interface GetChangeSubscriptionPlanInvoicePreviewInput {
    plan: OrganizationPlan;
    country?: BillingCountry;
    postalCode?: string;
    businessCustomer?: boolean;
    taxId?: string;
}

export interface GetChatActivitiesInput {
    conversationId: number;
}

export interface GetChatGroupInput {
    conversationId: number;
}

export interface GetChatGroupUsersInput {
    conversationId: number;
}

export interface GetChecklistFlimValEntryFocusSectionInput {
    checklistFlimValEntryId: number;
}

export interface GetChronologicalNewsfeedInput {
    _void?: boolean;
}

export interface GetConfiguredWorkflowPreviewsByBlabDefIdInput {
    blabDefId: number;
}

export interface GetConversationInput {
    conversationId: number;
}

export interface GetConversationsInput {
    _void?: boolean;
}

export interface GetCreateSubscriptionInvoicePreviewInput {
    plan: OrganizationPlan;
    billingPeriod: BillingPeriod;
    country?: BillingCountry;
    postalCode?: string;
    businessCustomer?: boolean;
    taxId?: string;
}

export interface GetDefaultBlabViewForBlabViewInput {
    blabDefId: number;
}

export interface GetDefaultBlabViewsForBlabDefsInput {
    blabDefIds: number[];
}

export interface GetDialogInput {
    userId: number;
}

export interface GetFocusDueDateSectionsInput {
    completedFilterType: FocusCompletedFilteringType;
    excludeReminders: boolean;
    excludeChecklistEntries: boolean;
    excludeBlabItems: boolean;
}

export interface GetFocusEntriesFilteringInput {
    completedFilterType: FocusCompletedFilteringType;
    excludeReminders: boolean;
    excludeChecklistEntries: boolean;
    excludeBlabItems: boolean;
    hasDueAt?: boolean;
    sectionId?: number;
}

export interface GetFocusEntriesInput {
    sorting: GetFocusEntriesSortingInput;
    filtering: GetFocusEntriesFilteringInput;
}

export interface GetFocusEntriesSortingInput {
    sortType: FocusSortingType;
}

export interface GetFocusSectionInput {
    id: number;
    completedFilterType: FocusCompletedFilteringType;
    excludeReminders: boolean;
    excludeChecklistEntries: boolean;
    excludeBlabItems: boolean;
}

export interface GetFocusSectionPreviewsInput {
    _void?: boolean;
}

export interface GetFocusSectionsInput {
    completedFilterType: FocusCompletedFilteringType;
    excludeReminders: boolean;
    excludeChecklistEntries: boolean;
    excludeBlabItems: boolean;
}

export interface GetFocusSettingsInput {
    _void?: boolean;
}

export interface GetGlobalWorkflowRunsInput {
    statuses?: WorkflowRunFilteringStatusType[];
    blabDefIds?: number[];
    workflowDefIds?: number[];
    searchQuery?: string;
}

export interface GetIncomingBlabItemsInput {
    blabItemId: number;
    referencingFlimDefId: number;
}

export interface GetIncomingRelationFlimDefsInput {
    blabItemId: number;
    first: number;
}

export interface GetLastWorkflowWebhookPayloadInput {
    workflowDefId: number;
}

export interface GetLocationAutocompleteSuggestionsInput {
    locationSearchString: string;
}

export interface GetLocationDetailsInput {
    locationId: string;
}

export interface GetNotificationsInput {
    onlyUnread: boolean;
    onlyDirect: boolean;
}

export interface GetNumRemindersInput {
    status: ReminderStatus;
}

export interface GetOrganizationActivityInput {
    organizationId: number;
}

export interface GetOrganizationActivityRepliesInput {
    activityId: number;
}

export interface GetOrganizationInvitationsInput {
    void?: boolean;
}

export interface GetOrganizationUserByEmailInput {
    email: string;
}

export interface GetOrganizationUsersInput {
    void?: boolean;
}

export interface GetOrganizationWorkspacesInput {
    void?: boolean;
}

export interface GetOrgUsersInput {
    _void?: boolean;
}

export interface GetReminderFocusSectionInput {
    reminderId: number;
}

export interface GetReminderInput {
    id: number;
}

export interface GetRemindersInput {
    status: ReminderStatus;
    blabItemId?: number;
}

export interface GetSharedBlabDefInput {
    blabDefId: number;
}

export interface GetSharedBlabViewInput {
    blabViewId: number;
}

export interface GetSharedBlabViewsInput {
    blabDefId: number;
}

export interface GetSharedWorkspaceInput {
    sharelinkToken?: string;
}

export interface GetSingleBlabItemActivityInput {
    id: number;
}

export interface GetSubscriptionActivateRenwalInvoicePreviewInput {
    country?: BillingCountry;
    postalCode?: string;
    businessCustomer?: boolean;
    taxId?: string;
}

export interface GetUserActivitiesInput {
    userId: number;
}

export interface GetUserActivityInput {
    id: number;
}

export interface GetUserActivityRepliesInput {
    activityId: number;
}

export interface GetUserFollowersInput {
    userId: number;
}

export interface GetUserFollowInfoInput {
    userId: number;
}

export interface GetUserFollowingInput {
    userId: number;
}

export interface GetUserTrashInput {
    _void?: boolean;
}

export interface GetWorkflowCenterWebhooksInput {
    _void?: boolean;
}

export interface GetWorkflowDefFollowersInput {
    workflowDefId: number;
}

export interface GetWorkflowDefLookupInput {
    workflowDefId: number;
    flimDefIds: number[];
    blabDefPreviewIds: number[];
    blabDefWithFlimDefsIds: number[];
    blabDefWithRelationsIds: number[];
    blabItemPreviewIds: number[];
    userProfilePreviewIds: number[];
    workspaceUsersBlabDefIds: number[];
    workspaceUsersWorkspaceIds: number[];
    workflowDefPreviewIds: number[];
    blabViewPreviewIds: number[];
    authenticationProviderIds: number[];
}

export interface GetWorkflowDefUniverseBlabViewsInput {
    blabDefIds: number[];
}

export interface GetWorkflowDefUniverseCallableWorkflowDefsInput {
    blabDefIds: number[];
}

export interface GetWorkflowDefWithLookupInput {
    id: number;
}

export interface GetWorkflowHomeCurrentInfoInput {
    _void?: boolean;
}

export interface GetWorkflowHomeTimeIntervalInfoInput {
    interval: WorkflowHomeTimeInterval;
}

export interface GetWorkflowRunsOfWorkflowDefInput {
    workflowDefId: number;
}

export interface GetWorkspaceActivityInput {
    workspaceId: number;
}

export interface GetWorkspaceActivityRepliesInput {
    activityId: number;
}

export interface GetWorkspaceBlabDefsInput {
    workspaceId: number;
}

export interface GetWorkspaceMembershipsInput {
    workspaceId: number;
}

export interface GetWorkspaceSettingsInput {
    workspaceId: number;
}

export interface GetWorkspaceShareSettingsInput {
    workspaceId: number;
}

export interface GetWorkspaceTemplateInput {
    workspaceTemplateId: number;
}

export interface GetWorkspaceTemplatePublishInfoInput {
    workspaceId: number;
}

export interface GetWorkspaceTemplatesInput {
    _void?: boolean;
}

export interface IdMetaFilterInput {
    ids: number[];
    match: IdFilterMatchType;
}

export interface ImageReferenceCreateInput {
    imageId: number;
    position: string;
    cardThumbnailPositionX?: number;
    cardThumbnailPositionY?: number;
}

export interface ImageReferenceUpsertInput {
    imageId: number;
    position: string;
    cardThumbnailPositionX?: number;
    cardThumbnailPositionY?: number;
}

export interface ImportBlabDefDataToExistingBlabDefInput {
    config: JSONObject;
    createSingleCategoryOptionDefs?: BlabDefDataImportCreateSingleCategoryOptionDefInput[];
    createMultiCategoryOptionDefs?: BlabDefDataImportCreateMultiCategoryOptionDefInput[];
    createStatusOptionDefs?: BlabDefDataImportCreateStatusOptionDefInput[];
}

export interface ImportBlabDefDataToNewBlabDefInput {
    config: JSONObject;
    workspaceId: number;
    blabDefCreateInput: BlabDefDataImportCreateBlabDefInput;
    flimDefsCreateInput: BlabDefDataImportCreateFlimDefsInput;
}

export interface ImportSpreadsheetInput {
    worksheetId: number;
    workspaceId: number;
    spreadsheetColumnFlimDefMappings: SpreadsheetColumnFlimDefMappingInput[];
}

export interface InitializeAudioRoomConsumersInput {
    roomId: number;
    rtpCapabilities: string;
    userIds: number[];
}

export interface InitializeAudioRoomProducerInput {
    roomId: number;
    direction: AudioRoomTransportVoiceDirection;
    rtpParameters: string;
    rtpCapabilities: string;
}

export interface JoinAudioRoomInput {
    roomId: number;
}

export interface LastModifiedAtFilterInput {
    flimDefId: number;
    position?: string;
    lastModifiedAtMatchType: LastModifiedAtFilterMatchType;
    exactDate?: DateTime;
    exactEndDate?: DateTime;
    weekday?: Weekday;
    quarterOfYear?: QuarterOfYear;
    monthOfYear?: MonthOfYear;
    lastModifiedAtRelativeDateRange?: LastModifiedAtRelativeDateRangeType;
    lastModifiedAtRelativeDate?: LastModifiedAtRelativeDateType;
    numDays?: number;
    numWeeks?: number;
    numMonths?: number;
}

export interface LastModifiedAtFlimStatInput {
    statType: LastModifiedAtFlimStatType;
    flimDefId: number;
}

export interface LastModifiedByFilterInput {
    flimDefId: number;
    position?: string;
    referencedUserIds: number[];
    includeActiveUser?: boolean;
    includeActiveUserIndex?: number;
    lastModifiedByMatchType: LastModifiedByFilterMatchType;
}

export interface LastModifiedByFlimStatInput {
    statType: LastModifiedByFlimStatType;
    flimDefId: number;
}

export interface LeaveAudioRoomInput {
    roomId: number;
}

export interface LeaveChatGroupInput {
    conversationId: number;
}

export interface MarkChecklistFocusEntryCompletedInput {
    id: number;
}

export interface MarkChecklistFocusEntryIncompleteInput {
    id: number;
}

export interface MarkConversationAsReadInput {
    conversationId: number;
}

export interface MarkNotificationAsReadInput {
    notificationId: number;
    onlyDirect?: boolean;
}

export interface MarkNotificationAsUnreadInput {
    notificationId: number;
    onlyDirect?: boolean;
}

export interface MarkReminderFocusEntryCompletedInput {
    id: number;
}

export interface MarkReminderFocusEntryIncompleteInput {
    id: number;
}

export interface MoveActiveUserQuickAddBlabDefInput {
    blabDefId: number;
    position: string;
}

export interface MoveAllFocusSectionEntriesFilteringInput {
    completedFilterType: FocusCompletedFilteringType;
    excludeReminders: boolean;
    excludeChecklistEntries: boolean;
    excludeBlabItems: boolean;
}

export interface MoveAllFocusSectionEntriesInput {
    filtering: MoveAllFocusSectionEntriesFilteringInput;
    sectionId: number;
    targetSectionId: number;
}

export interface MoveBlabDefToWorkspaceInput {
    blabDefId: number;
    targetWorkspaceId: number;
}

export interface MoveFocusEntryInput {
    id: number;
    sectionId: number;
    position?: string;
}

export interface MultiAttachmentFilterInput {
    flimDefId: number;
    position?: string;
    searchText?: string;
    multiAttachmentMatchType: MultiAttachmentFilterMatchType;
}

export interface MultiAttachmentFlimStatInput {
    statType: MultiAttachmentFlimStatType;
    flimDefId: number;
}

export interface MultiCategoryFilterInput {
    flimDefId: number;
    categoryOptionDefIds: number[];
    position?: string;
    multiCategoryMatchType: MultiCategoryFilterMatchType;
}

export interface MultiCategoryFlimStatInput {
    statType: MultiCategoryFlimStatType;
    flimDefId: number;
}

export interface MultiEmailFilterInput {
    flimDefId: number;
    position?: string;
    searchText?: string;
    multiEmailMatchType: MultiEmailFilterMatchType;
}

export interface MultiEmailFlimStatInput {
    statType: MultiEmailFlimStatType;
    flimDefId: number;
}

export interface MultiImageFilterInput {
    flimDefId: number;
    position?: string;
    searchText?: string;
    multiImageMatchType: MultiImageFilterMatchType;
}

export interface MultiImageFlimStatInput {
    statType: MultiImageFlimStatType;
    flimDefId: number;
}

export interface MultiLinkFilterInput {
    flimDefId: number;
    position?: string;
    searchText?: string;
    multiLinkMatchType: MultiLinkFilterMatchType;
}

export interface MultiLinkFlimStatInput {
    statType: MultiLinkFlimStatType;
    flimDefId: number;
}

export interface MultiPhoneFilterInput {
    flimDefId: number;
    position?: string;
    searchText?: string;
    multiPhoneMatchType: MultiPhoneFilterMatchType;
}

export interface MultiPhoneFlimStatInput {
    statType: MultiPhoneFlimStatType;
    flimDefId: number;
}

export interface MultiRelationFilterInput {
    flimDefId: number;
    position?: string;
    referencedBlabItemIds: number[];
    multiRelationMatchType: MultiRelationFilterMatchType;
}

export interface MultiRelationFlimStatInput {
    statType: MultiRelationFlimStatType;
    flimDefId: number;
}

export interface MultiTextFilterInput {
    flimDefId: number;
    position?: string;
    searchText?: string;
    multiTextMatchType: MultiTextFilterMatchType;
}

export interface MultiTextFlimStatInput {
    statType: MultiTextFlimStatType;
    flimDefId: number;
}

export interface MultiUserFilterInput {
    flimDefId: number;
    position?: string;
    referencedUserIds: number[];
    includeActiveUser?: boolean;
    includeActiveUserIndex?: number;
    multiUserMatchType: MultiUserFilterMatchType;
}

export interface MultiUserFlimStatInput {
    statType: MultiUserFlimStatType;
    flimDefId: number;
}

export interface MuteActiveUserInAudioRoomInput {
    roomId: number;
}

export interface MuteConversationInput {
    conversationId: number;
}

export interface NotifyOfInboundEmailInput {
    spfCheckFailed: boolean;
    dkimCheckFailed: boolean;
    spamCheckFailed: boolean;
    virusCheckFailed: boolean;
    dmarcCheckFailed: boolean;
    s3Key: string;
    timestamp: DateTime;
    source: string;
    destination: string;
    messageId: string;
    from: string;
    to?: string;
    cc: string[];
    returnPath?: string;
    subject: string;
}

export interface NumberFilterInput {
    flimDefId: number;
    position?: string;
    decimal?: number;
    numberMatchType: NumberFilterMatchType;
}

export interface NumberFlimStatInput {
    statType: NumberFlimStatType;
    flimDefId: number;
}

export interface PauseWorkflowDefInput {
    id: number;
}

export interface PerformChangeUserEmailInput {
    sessionId: string;
    code: string;
}

export interface PerformOrganizationSetupForIntentionInput {
    intention?: OrganizationSetupIntentionType;
}

export interface PerformOrganizationSignupInput {
    sessionId: string;
    code: string;
    organizationName: string;
    language: Language;
    timezone?: Timezone;
    preserveExistingSessions?: boolean;
}

export interface PostChatMessageInput {
    conversationId: number;
    content: string;
    clientSideId?: string;
    attachmentIds?: number[];
    linkPreviewId?: number;
}

export interface PublishWorkspaceTemplateInput {
    workspaceId: number;
    templateName: string;
    templateSubtitle?: string;
    templateDescription?: string;
    downloadCountOffset: number;
    language: Language;
    previewImageThumbnailId?: number;
    titleImageThumbnailId?: number;
    image3ThumbnailId?: number;
    image4ThumbnailId?: number;
    categories: WorkspaceTemplateCategory[];
    templateShareLink?: string;
    publicationDate: DateTime;
}

export interface RangeDateFilterInput {
    flimDefId: number;
    rangeDateMatchType: RangeDateFilterMatchType;
    exactDate?: DateTime;
    exactEndDate?: DateTime;
    weekday?: Weekday;
    quarterOfYear?: QuarterOfYear;
    monthOfYear?: MonthOfYear;
    position?: string;
    rangeDateRelativeDateRange?: RangeDateRelativeDateRangeType;
    rangeDateRelativeDate?: RangeDateRelativeDateType;
    numDays?: number;
    numWeeks?: number;
    numMonths?: number;
}

export interface RangeDateFlimStatInput {
    statType: RangeDateFlimStatType;
    flimDefId: number;
}

export interface ReactivateBillingSubscriptionRenewalInput {
    paymentMethodId: string;
    billingCustomer: UpdateBillingCustomerInput;
}

export interface RemoveActiveUserCalendarSubscriptionInput {
    id: number;
}

export interface RemoveActiveUserQuickAddBlabDefInput {
    blabDefId: number;
}

export interface RemoveAllDefaultBlabItemTemplatesInput {
    blabDefId: number;
}

export interface RemoveBlabDefActivityReactionInput {
    activityId: number;
    emoji: string;
}

export interface RemoveBlabDefActivityReplyInput {
    activityReplyId: number;
}

export interface RemoveBlabDefActivityReplyReactionInput {
    activityReplyId: number;
    emoji: string;
}

export interface RemoveBlabItemActivityReactionInput {
    activityId: number;
    emoji: string;
}

export interface RemoveBlabItemActivityReplyInput {
    activityReplyId: number;
}

export interface RemoveBlabItemActivityReplyReactionInput {
    activityReplyId: number;
    emoji: string;
}

export interface RemoveChatActivityReactionInput {
    activityId: number;
    emoji: string;
}

export interface RemoveFocusSectionInput {
    id: number;
}

export interface RemoveFromUserTrashInput {
    id: number;
}

export interface RemoveNewsfeedActivityInput {
    id: number;
}

export interface RemoveOrganizationActivityReactionInput {
    activityId: number;
    emoji: string;
}

export interface RemoveOrganizationActivityReplyInput {
    activityReplyId: number;
}

export interface RemoveOrganizationActivityReplyReactionInput {
    activityReplyId: number;
    emoji: string;
}

export interface RemoveOrganizationAuthenticationProviderInput {
    id: number;
}

export interface RemoveOrganizationSmtpSettingsInput {
    id: number;
}

export interface RemovePaymentMethodInput {
    id: string;
}

export interface RemoveReminderInput {
    id: number;
}

export interface RemoveUserActivityReactionInput {
    activityId: number;
    emoji: string;
}

export interface RemoveUserActivityReplyInput {
    activityReplyId: number;
}

export interface RemoveUserActivityReplyReactionInput {
    activityReplyId: number;
    emoji: string;
}

export interface RemoveUserSessionOptionsInput {
    preserveCookie?: boolean;
}

export interface RemoveWorkspaceActivityReactionInput {
    activityId: number;
    emoji: string;
}

export interface RemoveWorkspaceActivityReplyInput {
    activityReplyId: number;
}

export interface RemoveWorkspaceActivityReplyReactionInput {
    activityReplyId: number;
    emoji: string;
}

export interface RemoveWorkspaceMembershipInput {
    workspaceId: number;
    userId?: number;
    organizationInvitationId?: number;
}

export interface RemoveWorkspaceTemplateInput {
    workspaceId: number;
}

export interface RemoveWorkspaceUserExplicitRoleInput {
    workspaceId: number;
    userId: number;
}

export interface RenameBlabViewInput {
    id: number;
    name: string;
}

export interface ReportActiveUserAudioPresenceInput {
    roomId: number;
}

export interface ReportActiveUserPresenceInput {
    _void?: boolean;
}

export interface RequestBlabDefWebhookVerificationInput {
    id: number;
}

export interface RequestChangeUserEmailVerficiationInput {
    password: string;
    newEmail: string;
}

export interface RequestForgotPasswordEmailInput {
    organizationId: number;
    email: string;
}

export interface RequestOrganizationSignupVerificationInput {
    name: string;
    email: string;
    password: string;
    language: Language;
    optInPromotionalEmails: boolean;
}

export interface RequestWorkflowCenterWebhookVerificationInput {
    id: number;
}

export interface ResendOrganizationInvitationInput {
    id: number;
}

export interface ResetPrivateUrlShareUrlInput {
    _void?: boolean;
}

export interface ResetPublicUrlShareUrlInput {
    _void?: boolean;
}

export interface RestoreBlabItemInput {
    blabItemId: number;
}

export interface RestoreFromUserTrashInput {
    id: number;
}

export interface RetryInvoicePaymentInput {
    id: string;
}

export interface RevertBlabItemRevisionInput {
    blabItemRevisionId: number;
}

export interface RevokeOrganizationInvitationInput {
    id: number;
}

export interface RunWorkflowManuallyInput {
    workflowDefId: number;
    manualRunUuId: string;
    executionType: WorkflowExecutionType;
    selectedBlabItemIds?: number[];
    numMostRecentBlabItems?: number;
    customWebhookPayload?: string;
}

export interface SearchBlabDefsInput {
    query?: string;
}

export interface SearchBlabItemsForRelationFlimFilterInput {
    flimDefId: number;
    query?: string;
    excludeBlabItemIds?: number[];
}

export interface SearchBlabItemsInput {
    query?: string;
    excludeBlabItemIds?: number[];
}

export interface SearchExecutionContextBlabItemsInput {
    query?: string;
    blabDefId: number;
}

export interface SearchExistingAndNewConversationsInput {
    query?: string;
}

export interface SearchInput {
    searchResultTypes?: SearchResultType[];
    query: string;
}

export interface SearchRelationFlimDefBlabDefsInput {
    query?: string;
    excludeBlabDefIds?: number[];
}

export interface SearchUserFlimValUsersInput {
    query?: string;
    flimDefId: number;
    excludeUserIds?: number[];
}

export interface SearchUsersForUserFlimFilterInput {
    query?: string;
    excludeUserIds?: number[];
}

export interface SearchUsersInput {
    query?: string;
    excludeUserIds?: number[];
}

export interface SearchUsersInWorkspaceInput {
    query?: string;
    workspaceId: number;
    excludeUserIds?: number[];
}

export interface SearchUsersWithBlabItemAccessInput {
    query?: string;
    blabItemId: number;
    excludeUserIds?: number[];
}

export interface SearchWorkflowPreviewsForGlobalRunsFilteringInput {
    query: string;
}

export interface SearchWorkflowPreviewsInput {
    query: string;
}

export interface SearchWorkspaceMembershipCandidatesInput {
    query: string;
    workspaceId: number;
}

export interface SelectBlabViewInput {
    blabViewId: number;
    splitBySection?: SelectBlabViewSplitBySectionInput;
    splitByCompleted?: boolean;
}

export interface SelectBlabViewSplitBySectionInput {
    singleCategorySplitBySection?: SelectSingleCategorySplitBySectionInput;
    multiCategorySplitBySection?: SelectMultiCategorySplitBySectionInput;
    singleUserSplitBySection?: SelectSingleUserSplitBySectionInput;
    multiUserSplitBySection?: SelectMultiUserSplitBySectionInput;
    singleRelationSplitBySection?: SelectSingleRelationSplitBySectionInput;
    multiRelationSplitBySection?: SelectMultiRelationSplitBySectionInput;
    statusSplitBySection?: SelectStatusSplitBySectionInput;
    singleDateSplitBySection?: SelectSingleDateSplitBySectionInput;
    rangeDateSplitBySection?: SelectRangeDateSplitBySectionInput;
    createdAtSplitBySection?: SelectCreatedAtSplitBySectionInput;
    lastModifiedAtSplitBySection?: SelectLastModifiedAtSplitBySectionInput;
    calculationSplitBySection?: SelectCalculationSplitBySectionInput;
}

export interface SelectCalculationSplitBySectionInput {
    startDate?: DateTime;
    endDate?: DateTime;
    weekday?: Weekday;
}

export interface SelectCreatedAtSplitBySectionInput {
    startDate?: DateTime;
    endDate?: DateTime;
    weekday?: Weekday;
}

export interface SelectLastModifiedAtSplitBySectionInput {
    startDate?: DateTime;
    endDate?: DateTime;
    weekday?: Weekday;
}

export interface SelectMultiCategorySplitBySectionInput {
    multiCategoryOptionDefId: number;
}

export interface SelectMultiRelationSplitBySectionInput {
    blabItemId: number;
}

export interface SelectMultiUserSplitBySectionInput {
    userId: number;
}

export interface SelectRangeDateSplitBySectionInput {
    startDate?: DateTime;
    endDate?: DateTime;
    weekday?: Weekday;
}

export interface SelectSingleCategorySplitBySectionInput {
    singleCategoryOptionDefId: number;
}

export interface SelectSingleDateSplitBySectionInput {
    startDate?: DateTime;
    endDate?: DateTime;
    weekday?: Weekday;
}

export interface SelectSingleRelationSplitBySectionInput {
    blabItemId: number;
}

export interface SelectSingleUserSplitBySectionInput {
    userId: number;
}

export interface SelectStatusSplitBySectionInput {
    statusOptionDefId: number;
}

export interface SetActiveUserDoNotDisturbInput {
    until?: DateTime;
}

export interface SetActiveUserIncognitoInput {
    _void?: boolean;
}

export interface SetActiveUserMetadataInput {
    key: string;
    value: string;
}

export interface SetActiveUserStatusInput {
    emoji: string;
    note?: string;
    until?: DateTime;
}

export interface SetActiveUserWorkflowDefLastViewedAtInput {
    workflowDefId: number;
}

export interface SetBlabDefInboundEmailFieldMappingsInput {
    blabDefId: number;
    inboundEmailFieldFromFlimDefId?: number;
    inboundEmailFieldCcFlimDefId?: number;
    inboundEmailFieldSubjectFlimDefId?: number;
    inboundEmailFieldBodyFlimDefId?: number;
    inboundEmailFieldAttachmentsFlimDefId?: number;
}

export interface SetDefaultOrganizationSmtpSettingsInput {
    id: number;
}

export interface SetDefaultPaymentMethodInput {
    id: string;
}

export interface SetReminderStatusInput {
    id: number;
    status: ReminderStatus;
}

export interface SetUserClientAppMetaDataInput {
    platform: UserClientAppPlatform;
}

export interface SetWorkspaceSharePermissionsInput {
    workspaceId: number;
    type: WorkspaceType;
    defaultRole?: WorkspaceRole;
}

export interface SimulateWorkflowInput {
    contextBlabItemIds?: number[];
    useLastReceivedWebhookPayload?: boolean;
    customWebhookPayload?: string;
    workflowDefId: number;
    triggerType?: WorkflowTriggerType;
    filterDefinition?: JSONObject;
    actionDefinition?: JSONObject;
    triggerDefinition?: JSONObject;
    previewForActionId?: string;
}

export interface SingleAttachmentFilterInput {
    flimDefId: number;
    searchText?: string;
    position?: string;
    singleAttachmentMatchType: SingleAttachmentFilterMatchType;
}

export interface SingleAttachmentFlimStatInput {
    statType: SingleAttachmentFlimStatType;
    flimDefId: number;
}

export interface SingleCategoryFilterInput {
    flimDefId: number;
    categoryOptionDefIds: number[];
    position?: string;
    singleCategoryMatchType: SingleCategoryFilterMatchType;
}

export interface SingleCategoryFlimStatInput {
    statType: SingleCategoryFlimStatType;
    flimDefId: number;
}

export interface SingleDateFilterInput {
    flimDefId: number;
    position?: string;
    singleDateMatchType: SingleDateFilterMatchType;
    exactDate?: DateTime;
    exactEndDate?: DateTime;
    singleDateRelativeDateRange?: SingleDateRelativeDateRangeType;
    singleDateRelativeDate?: SingleDateRelativeDateType;
    numDays?: number;
    numWeeks?: number;
    numMonths?: number;
    weekday?: Weekday;
    quarterOfYear?: QuarterOfYear;
    monthOfYear?: MonthOfYear;
}

export interface SingleDateFlimStatInput {
    statType: SingleDateFlimStatType;
    flimDefId: number;
}

export interface SingleLocationFilterInput {
    flimDefId: number;
    position?: string;
    searchText?: string;
    singleLocationMatchType: SingleLocationFilterMatchType;
}

export interface SingleLocationFlimStatInput {
    statType: SingleLocationFlimStatType;
    flimDefId: number;
}

export interface SingleRelationFilterInput {
    flimDefId: number;
    position?: string;
    referencedBlabItemIds: number[];
    singleRelationMatchType: SingleRelationFilterMatchType;
}

export interface SingleRelationFlimStatInput {
    statType: SingleRelationFlimStatType;
    flimDefId: number;
}

export interface SingleTextFilterInput {
    flimDefId: number;
    position?: string;
    searchText?: string;
    singleTextMatchType: SingleTextFilterMatchType;
}

export interface SingleTextFlimStatInput {
    statType: SingleTextFlimStatType;
    flimDefId: number;
}

export interface SingleUserFilterInput {
    flimDefId: number;
    position?: string;
    referencedUserIds: number[];
    includeActiveUser?: boolean;
    includeActiveUserIndex?: number;
    singleUserMatchType: SingleUserFilterMatchType;
}

export interface SingleUserFlimStatInput {
    statType: SingleUserFlimStatType;
    flimDefId: number;
}

export interface SpreadsheetColumnFlimDefMappingInput {
    spreadsheetColumnKey: string;
    flimType: FlimType;
    flimDefName: string;
}

export interface StartChatVideoCallInput {
    conversationId: number;
}

export interface StartOrContinueDialogInput {
    userId: number;
    content?: string;
    clientSideId?: string;
    attachmentIds?: number[];
    linkPreviewId?: number;
}

export interface StatusFilterInput {
    flimDefId: number;
    statusOptionDefIds: number[];
    position?: string;
    statusMatchType: StatusFilterMatchType;
}

export interface StatusFlimStatInput {
    statType: StatusFlimStatType;
    flimDefId: number;
}

export interface SubscribeToActiveUserChangesInput {
    _void?: boolean;
}

export interface SubscribeToAudioRoomInput {
    roomId: number;
}

export interface SubscribeToAudioRoomPreviewsInput {
    _void?: boolean;
}

export interface SubscribeToBlabDefDeveloperInfoInput {
    blabDefId: number;
}

export interface SubscribeToBulkFollowUnfollowBlabItemsInput {
    jobId: string;
}

export interface SubscribeToDuplicateBlabDefProgressInput {
    jobId: string;
}

export interface SubscribeToDuplicateWorkspaceProgressInput {
    jobId: string;
}

export interface SubscribeToExportBlabDefDataProgressInput {
    dataExportId: number;
}

export interface SubscribeToFocusEntriesInput {
    _void?: boolean;
}

export interface SubscribeToImportBlabDefDataProgressInput {
    jobId: number;
}

export interface SubscribeToManualRunsCompletionInput {
    workflowDefId: number;
}

export interface SubscribeToOauthIntegrationAuthenticationStatusInput {
    integrationId: number;
}

export interface SubscribeToOrganizationChangesInput {
    _void?: boolean;
}

export interface SubscribeToUpdateBlabItemsFlimValInput {
    jobId: string;
}

export interface SubscribeToUserClientApplicationChangesInput {
    _void?: boolean;
}

export interface SubscribeToUserStatusesInput {
    _void?: boolean;
}

export interface SubscribeToWorkflowCenterWebhooksInput {
    _void?: boolean;
}

export interface SubscribeToWorkflowRunsOfWorkflowDefInput {
    workflowDefId: number;
}

export interface TimestampMetaFilterInput {
    from?: DateTime;
    to?: DateTime;
}

export interface ToggleBlabDefActivityReactionInput {
    activityId: number;
    emoji: string;
}

export interface ToggleBlabDefActivityReplyReactionInput {
    activityReplyId: number;
    emoji: string;
}

export interface ToggleBlabItemActivityReactionInput {
    activityId: number;
    emoji: string;
}

export interface ToggleBlabItemActivityReplyReactionInput {
    activityReplyId: number;
    emoji: string;
}

export interface ToggleOrganizationActivityReactionInput {
    activityId: number;
    emoji: string;
}

export interface ToggleOrganizationActivityReplyReactionInput {
    activityReplyId: number;
    emoji: string;
}

export interface ToggleUserActivityReactionInput {
    activityId: number;
    emoji: string;
}

export interface ToggleUserActivityReplyReactionInput {
    activityReplyId: number;
    emoji: string;
}

export interface ToggleWorkspaceActivityReactionInput {
    activityId: number;
    emoji: string;
}

export interface ToggleWorkspaceActivityReplyReactionInput {
    activityReplyId: number;
    emoji: string;
}

export interface UnfollowBlabDefInput {
    blabDefId: number;
}

export interface UnfollowBlabItemInput {
    blabItemId: number;
}

export interface UnfollowUserInput {
    userId: number;
}

export interface UnfollowWorkflowDefInput {
    workflowDefId: number;
}

export interface UniqueIdFilterInput {
    flimDefId: number;
    position?: string;
    decimal?: number;
    uniqueIdMatchType: UniqueIdFilterMatchType;
}

export interface UniqueIdFlimStatInput {
    statType: UniqueIdFlimStatType;
    flimDefId: number;
}

export interface UnmuteActiveUserInAudioRoomInput {
    roomId: number;
}

export interface UnmuteConversationInput {
    conversationId: number;
}

export interface UnpauseWorkflowDefInput {
    id: number;
}

export interface UnsupportedPlatformRequestMagicLinkInput {
    _void?: boolean;
}

export interface UpdateActiveUserBlabDefInfoInput {
    blabDefId: number;
    viewSidebarCollapsed?: boolean;
    privateViewsInViewSidebarCollapsed?: boolean;
    publicViewsInViewSidebarCollapsed?: boolean;
}

export interface UpdateActiveUserCalendarSettingsInput {
    showCompletedReminders: boolean;
    showCompletedChecklistEntries: boolean;
    showCompletedBlabItems: boolean;
}

export interface UpdateActiveUserCalendarSubscriptionInput {
    id: number;
    color?: SelectableColor;
}

export interface UpdateActiveUserNotificationSettingsInput {
    emailNotificationsActive?: boolean;
}

export interface UpdateActiveUserProfileInput {
    name?: string;
    shortname?: string;
    jobDescription?: string;
    phoneNumber?: string;
    location?: string;
    website?: string;
    birthDate?: DateTime;
    timezone?: Timezone;
}

export interface UpdateActiveUserWorkspaceInfoLastSelectedBlabDefInput {
    lastSelectedBlabDefId: number;
}

export interface UpdateBillingCustomerInput {
    email: string;
    firstName: string;
    lastName: string;
    companyName?: string;
    businessCustomer: boolean;
    taxId?: string;
    country: BillingCountry;
    stateProvinceRegion?: string;
    city: string;
    postalCode: string;
    streetAddress: string;
    suiteUnit?: string;
    invoiceNote?: string;
}

export interface UpdateBlabDefInput {
    id: number;
    blabName?: string;
    itemName?: string;
    icon?: BlabDefIcon;
}

export interface UpdateBlabDefSettingsInput {
    blabDefId: number;
    blabName?: string;
    itemName?: string;
    icon?: BlabDefIcon;
    description?: string;
}

export interface UpdateBlabDefWebhookInput {
    id: number;
    url?: string;
    webhookType?: BlabDefWebhookType;
}

export interface UpdateBlabItemFocusEntryDueAtInput {
    id: number;
    dueAt?: DateTime;
    dueAtEnd?: DateTime;
    dueAtHasTime?: boolean;
}

export interface UpdateBlabItemFocusEntryStatusInput {
    id: number;
    statusOptionDefId: number;
}

export interface UpdateBlabItemFocusSectionInput {
    blabItemId: number;
    sectionId: number;
}

export interface UpdateBlabItemsFilteringInput {
    singleTextFilters?: UpsertSingleTextFilterInput[];
    multiTextFilters?: UpsertMultiTextFilterInput[];
    singleCategoryFilters?: UpsertSingleCategoryFilterInput[];
    multiCategoryFilters?: UpsertMultiCategoryFilterInput[];
    singleRelationFilters?: UpsertSingleRelationFilterInput[];
    multiRelationFilters?: UpsertMultiRelationFilterInput[];
    singleUserFilters?: UpsertSingleUserFilterInput[];
    multiUserFilters?: UpsertMultiUserFilterInput[];
    singleAttachmentFilters?: UpsertSingleAttachmentFilterInput[];
    multiAttachmentFilters?: UpsertMultiAttachmentFilterInput[];
    rangeDateFilters?: UpsertRangeDateFilterInput[];
    singleDateFilters?: UpsertSingleDateFilterInput[];
    createdAtFilters?: UpsertCreatedAtFilterInput[];
    lastModifiedAtFilters?: UpsertLastModifiedAtFilterInput[];
    numberFilters?: UpsertNumberFilterInput[];
    statusFilters?: UpsertStatusFilterInput[];
    calculationFilters?: UpsertCalculationFilterInput[];
    multiEmailFilters?: UpsertMultiEmailFilterInput[];
    multiPhoneFilters?: UpsertMultiPhoneFilterInput[];
    multiImageFilters?: UpsertMultiImageFilterInput[];
    createdByFilters?: UpsertCreatedByFilterInput[];
    lastModifiedByFilters?: UpsertLastModifiedByFilterInput[];
    checklistFilters?: UpsertChecklistFilterInput[];
    uniqueIdFilters?: UpsertUniqueIdFilterInput[];
    multiLinkFilters?: UpsertMultiLinkFilterInput[];
    singleLocationFilters?: UpsertSingleLocationFilterInput[];
}

export interface UpdateBlabItemsFlimValInput {
    blabDefId: number;
    filtering: BlabItemsFilteringInput;
    sorting?: BlabItemsSortingInput;
    singleCategoryFlimValUpdateInput?: UpdateBlabItemsSingleCategoryFlimValInput;
    statusFlimValUpdateInput?: UpdateBlabItemsStatusFlimValInput;
}

export interface UpdateBlabItemsSingleCategoryFlimValInput {
    flimDefId: number;
    categoryOptionDefId?: number;
}

export interface UpdateBlabItemsStatusFlimValInput {
    flimDefId: number;
    statusOptionDefId: number;
}

export interface UpdateBlabItemTemplateInput {
    id: number;
    title?: string;
    position: string;
    isPrivate: boolean;
    isDefault?: boolean;
}

export interface UpdateBlabItemTemplateTitleInput {
    id: number;
    title?: string;
}

export interface UpdateBlabItemViewPositionInput {
    blabItemId: number;
    blabDefId: number;
    viewPosition: string;
    blabViewId?: number;
}

export interface UpdateBlabViewInput {
    id: number;
    tableRowHeight?: TableRowHeight;
    tableWrapCells?: boolean;
    sorting?: BlabItemsSortingInput;
    filtering?: UpdateBlabItemsFilteringInput;
    columns?: BlabViewColumnInput[];
    listProperties?: BlabViewListPropertyInput[];
    flimStatDefs?: UpsertBlabViewFlimStatDefInput;
    boardGroupByFlimDefId?: number;
    boardCardPreviewFlimDefId?: number;
    boardProperties?: BlabViewBoardPropertyInput[];
    boardColumns?: BlabViewBoardColumnInput;
    boardCardSize?: BlabViewBoardCardSizeType;
    boardCardPreviewFitImage?: boolean;
    boardLargeText?: boolean;
    boardHideEmptyColumns?: boolean;
    boardColorColumns?: boolean;
}

export interface UpdateBlabViewPositionInput {
    blabViewId: number;
    position: string;
}

export interface UpdateBlabViewSettingsInput {
    id: number;
    layout?: BlabViewLayout;
    isPublic?: boolean;
    isDefault?: boolean;
    showProgress?: BlabViewShowProgressType;
}

export interface UpdateBlabViewSplitByInput {
    blabViewId: number;
    flimDefId?: number;
    period?: BlabViewSplitByPeriod;
    sorting?: BlabViewSplitBySorting;
    limit?: number;
}

export interface UpdateCalculationFlimDefInput {
    id: number;
    name?: string;
    position?: string;
    required?: boolean;
    showInRecord?: boolean;
    hiddenIfEmpty?: boolean;
    alwaysHidden?: boolean;
    showHelpText?: boolean;
    helpText?: string;
    largeEditor?: boolean;
    script: string;
    scriptResultType?: ScriptResultType;
    precision?: number;
    unitLocation?: NumberFlimDefUnitLocation;
    unitLabel?: string;
    hideThousandSeparator?: boolean;
    showInCalendar?: boolean;
    timeSetting?: TimeSetting;
}

export interface UpdateChatGroupInfoInput {
    conversationId: number;
    name?: string;
}

export interface UpdateChecklistFlimDefInput {
    id: number;
    name?: string;
    position?: string;
    required?: boolean;
    hiddenIfEmpty?: boolean;
    alwaysHidden?: boolean;
    showHelpText?: boolean;
    helpText?: string;
    showCompletedDefault?: boolean;
    showInFocus?: boolean;
}

export interface UpdateChecklistFlimValEntryFocusSectionInput {
    checklistFlimValEntryId: number;
    sectionId: number;
}

export interface UpdateChecklistFlimValEntryInput {
    id: number;
    position: string;
    completed: boolean;
    title?: string;
    description?: string;
    dueAt?: DateTime;
    dueAtHasTime?: boolean;
    assigneeUserId?: number;
}

export interface UpdateChecklistFocusEntryDescriptionInput {
    id: number;
    description: string;
}

export interface UpdateChecklistFocusEntryDueAtInput {
    id: number;
    dueAt?: DateTime;
    dueAtHasTime?: boolean;
}

export interface UpdateChecklistFocusEntryTitleInput {
    id: number;
    title: string;
}

export interface UpdateCreatedAtFlimDefInput {
    id: number;
    name?: string;
    position?: string;
    required?: boolean;
    showInRecord?: boolean;
    hiddenIfEmpty?: boolean;
    alwaysHidden?: boolean;
    showHelpText?: boolean;
    helpText?: string;
    showTime: boolean;
}

export interface UpdateCreatedByFlimDefInput {
    id: number;
    name?: string;
    position?: string;
    required?: boolean;
    showInRecord?: boolean;
    hiddenIfEmpty?: boolean;
    alwaysHidden?: boolean;
    showHelpText?: boolean;
    helpText?: string;
}

export interface UpdateFlimDefsInput {
    singleText: UpdateSingleTextFlimDefInput[];
    multiText: UpdateMultiTextFlimDefInput[];
    singleCategory: UpdateSingleCategoryFlimDefInput[];
    multiCategory: UpdateMultiCategoryFlimDefInput[];
    singleRelation: UpdateSingleRelationFlimDefInput[];
    multiRelation: UpdateMultiRelationFlimDefInput[];
    singleAttachment: UpdateSingleAttachmentFlimDefInput[];
    multiAttachment: UpdateMultiAttachmentFlimDefInput[];
    singleUser: UpdateSingleUserFlimDefInput[];
    multiUser: UpdateMultiUserFlimDefInput[];
    singleDate: UpdateSingleDateFlimDefInput[];
    rangeDate: UpdateRangeDateFlimDefInput[];
    checklist: UpdateChecklistFlimDefInput[];
    multiLink: UpdateMultiLinkFlimDefInput[];
    createdAt: UpdateCreatedAtFlimDefInput[];
    createdBy: UpdateCreatedByFlimDefInput[];
    lastModifiedAt: UpdateLastModifiedAtFlimDefInput[];
    lastModifiedBy: UpdateLastModifiedByFlimDefInput[];
    number: UpdateNumberFlimDefInput[];
    status: UpdateStatusFlimDefInput[];
    calculation: UpdateCalculationFlimDefInput[];
    multiEmail: UpdateMultiEmailFlimDefInput[];
    multiPhone: UpdateMultiPhoneFlimDefInput[];
    multiImage: UpdateMultiImageFlimDefInput[];
    singleLocation: UpdateSingleLocationFlimDefInput[];
    uniqueId: UpdateUniqueIdFlimDefInput[];
}

export interface UpdateFlimValAttachmentInput {
    attachmentId: number;
    name?: string;
}

export interface UpdateFlimValImageInput {
    imageId: number;
    name?: string;
}

export interface UpdateFocusEntrySectionInput {
    id: number;
    sectionId: number;
}

export interface UpdateFocusSectionNameInput {
    id: number;
    name: string;
}

export interface UpdateFocusSectionPositionInput {
    id: number;
    position: string;
}

export interface UpdateFocusSettingsInput {
    sortWithinSections: boolean;
    sortType: FocusSortingType;
    completedFilterType: FocusCompletedFilteringType;
    excludeReminders: boolean;
    excludeChecklistEntries: boolean;
    excludeBlabItems: boolean;
}

export interface UpdateLastModifiedAtFlimDefInput {
    id: number;
    name?: string;
    position?: string;
    required?: boolean;
    showInRecord?: boolean;
    hiddenIfEmpty?: boolean;
    alwaysHidden?: boolean;
    showHelpText?: boolean;
    helpText?: string;
    showTime?: boolean;
}

export interface UpdateLastModifiedByFlimDefInput {
    id: number;
    name?: string;
    position?: string;
    required?: boolean;
    showInRecord?: boolean;
    hiddenIfEmpty?: boolean;
    alwaysHidden?: boolean;
    showHelpText?: boolean;
    helpText?: string;
}

export interface UpdateMultiAttachmentFlimDefInput {
    id: number;
    name?: string;
    position?: string;
    required?: boolean;
    hiddenIfEmpty?: boolean;
    alwaysHidden?: boolean;
    showHelpText?: boolean;
    helpText?: string;
}

export interface UpdateMultiAttachmentFlimValFileReferenceInput {
    blabItemId: number;
    flimDefId: number;
    attachmentId: number;
    position: string;
    cardThumbnailPositionX?: number;
    cardThumbnailPositionY?: number;
}

export interface UpdateMultiCategoryFlimDefInput {
    id: number;
    name?: string;
    position?: string;
    required?: boolean;
    hiddenIfEmpty?: boolean;
    alwaysHidden?: boolean;
    showHelpText?: boolean;
    helpText?: string;
    layout?: CategoryFlimLayout;
    categoryOptionDefs?: UpdateMultiCategoryOptionDefInput[];
}

export interface UpdateMultiCategoryOptionDefInput {
    id?: number;
    label?: string;
    color?: SelectableColor;
    position: string;
}

export interface UpdateMultiEmailFlimDefInput {
    id: number;
    name?: string;
    position?: string;
    required?: boolean;
    hiddenIfEmpty?: boolean;
    alwaysHidden?: boolean;
    showHelpText?: boolean;
    helpText?: string;
    allowOnlySingleEntry?: boolean;
}

export interface UpdateMultiEmailFlimValEntryInput {
    id: number;
    position: string;
    emailAddress: string;
    emailAddressType: EmailAddressType;
}

export interface UpdateMultiImageFlimDefInput {
    id: number;
    name?: string;
    position?: string;
    required?: boolean;
    hiddenIfEmpty?: boolean;
    alwaysHidden?: boolean;
    showHelpText?: boolean;
    helpText?: string;
    imageFlimLayout?: ImageFlimLayout;
}

export interface UpdateMultiImageFlimValFileReferenceInput {
    blabItemId: number;
    flimDefId: number;
    imageId: number;
    position: string;
    cardThumbnailPositionX?: number;
    cardThumbnailPositionY?: number;
}

export interface UpdateMultiLinkFlimDefInput {
    id: number;
    name?: string;
    position?: string;
    required?: boolean;
    hiddenIfEmpty?: boolean;
    alwaysHidden?: boolean;
    showHelpText?: boolean;
    helpText?: string;
}

export interface UpdateMultiPhoneFlimDefInput {
    id: number;
    name?: string;
    position?: string;
    required?: boolean;
    hiddenIfEmpty?: boolean;
    alwaysHidden?: boolean;
    showHelpText?: boolean;
    helpText?: string;
    callLinkProtocol: CallLinkProtocol;
    allowOnlySingleEntry?: boolean;
}

export interface UpdateMultiPhoneFlimValEntryInput {
    id: number;
    position: string;
    phoneNumber: string;
    phoneNumberType: PhoneNumberType;
}

export interface UpdateMultiRelationFlimDefInput {
    id: number;
    name?: string;
    position?: string;
    required?: boolean;
    hiddenIfEmpty?: boolean;
    alwaysHidden?: boolean;
    showHelpText?: boolean;
    helpText?: string;
    blabReferences?: BlabReferenceInput[];
}

export interface UpdateMultiTextFlimDefInput {
    id: number;
    name?: string;
    position?: string;
    required?: boolean;
    hiddenIfEmpty?: boolean;
    alwaysHidden?: boolean;
    showHelpText?: boolean;
    helpText?: string;
    disableRichTextFormatting?: boolean;
    defaultValue?: string;
}

export interface UpdateMultiUserFlimDefInput {
    id: number;
    name?: string;
    position?: string;
    required?: boolean;
    hiddenIfEmpty?: boolean;
    alwaysHidden?: boolean;
    showHelpText?: boolean;
    helpText?: string;
    doNotNotify?: boolean;
    showInFocus?: boolean;
}

export interface UpdateNumberFlimDefInput {
    id: number;
    name?: string;
    position?: string;
    precision: number;
    required?: boolean;
    hiddenIfEmpty?: boolean;
    alwaysHidden?: boolean;
    showHelpText?: boolean;
    helpText?: string;
    unitLocation?: NumberFlimDefUnitLocation;
    unitLabel?: string;
    hideThousandSeparator?: boolean;
}

export interface UpdateOauthIntegrationAccessTokenInput {
    oauthIntegrationId: number;
    accessToken: string;
    refreshToken: string;
}

export interface UpdateOauthIntegrationTokenExpirationInput {
    oauthIntegrationId: number;
    accessTokenValidSince: DateTime;
    accessTokenExpiresInSeconds: number;
}

export interface UpdateOrganizationAuthenticationProviderInput {
    id: number;
    hostAddress?: string;
    hostPort?: number;
    userName?: string;
    userPassword?: string;
    encryption?: SmtpEncryptionType;
    label: string;
    isTapeInternalSmtp: boolean;
}

export interface UpdateOrganizationProfileInput {
    name?: string;
    description?: string;
    location?: string;
    website?: string;
    email?: string;
    phoneNumber?: string;
}

export interface UpdateOrganizationSettingsInput {
    defaultLanguage?: Language;
    preferFullnameInUserProfile?: boolean;
    requireInvitationApproval?: boolean;
}

export interface UpdateOrganizationSlugInput {
    slug: string;
}

export interface UpdateOrganizationSmtpSettingsInput {
    id: number;
    hostAddress?: string;
    hostPort?: number;
    userName?: string;
    userPassword?: string;
    encryption?: SmtpEncryptionType;
    label: string;
    isTapeInternalSmtp: boolean;
}

export interface UpdateOrganizationUserInfoInput {
    id: number;
    name?: string;
    shortname?: string;
    jobDescription?: string;
    email?: string;
}

export interface UpdateOrganizationWorkspacePosition {
    id: number;
    position: string;
}

export interface UpdatePrivateUrlShareInput {
    calendarSubscriptionIds: number[];
    permissionType: PrivateUrlSharePermissionType;
}

export interface UpdatePublicUrlShareInput {
    calendarSubscriptionIds: number[];
    permissionType: PublicUrlSharePermissionType;
}

export interface UpdateRangeDateFlimDefInput {
    id: number;
    name: string;
    position: string;
    required: boolean;
    hiddenIfEmpty: boolean;
    alwaysHidden: boolean;
    showHelpText?: boolean;
    helpText?: string;
    defaultStartDate?: DateTime;
    defaultHasTime?: boolean;
    defaultEndDate?: DateTime;
    requireEndDate: boolean;
    timeSetting?: TimeSetting;
    showInCalendar?: boolean;
    isDueDateOfStatusField?: boolean;
}

export interface UpdateReminderFocusEntryDescriptionInput {
    id: number;
    description: string;
}

export interface UpdateReminderFocusEntryDueAtInput {
    id: number;
    dueAt?: DateTime;
    dueAtHasTime?: boolean;
}

export interface UpdateReminderFocusEntryTitleInput {
    id: number;
    title: string;
}

export interface UpdateReminderFocusSectionInput {
    reminderId: number;
    sectionId: number;
}

export interface UpdateSingleAttachmentFlimDefInput {
    id: number;
    name?: string;
    position?: string;
    required?: boolean;
    hiddenIfEmpty?: boolean;
    alwaysHidden?: boolean;
    showHelpText?: boolean;
    helpText?: string;
}

export interface UpdateSingleCategoryFlimDefInput {
    id: number;
    name?: string;
    position?: string;
    required?: boolean;
    hiddenIfEmpty?: boolean;
    alwaysHidden?: boolean;
    showHelpText?: boolean;
    helpText?: string;
    layout?: CategoryFlimLayout;
    categoryOptionDefs?: UpdateSingleCategoryOptionDefInput[];
    showColorInCalendar?: boolean;
    defaultCategoryOptionDefId?: number;
}

export interface UpdateSingleCategoryOptionDefInput {
    id?: number;
    label?: string;
    color?: SelectableColor;
    position: string;
}

export interface UpdateSingleDateFlimDefInput {
    id: number;
    name: string;
    position: string;
    required: boolean;
    hiddenIfEmpty: boolean;
    alwaysHidden: boolean;
    showHelpText?: boolean;
    helpText?: string;
    defaultDate?: DateTime;
    defaultHasTime?: boolean;
    timeSetting?: TimeSetting;
    showInCalendar?: boolean;
    isDueDateOfStatusField?: boolean;
}

export interface UpdateSingleLocationFlimDefInput {
    id: number;
    name?: string;
    position?: string;
    required?: boolean;
    hiddenIfEmpty?: boolean;
    alwaysHidden?: boolean;
    showHelpText?: boolean;
    helpText?: string;
    showMap: boolean;
}

export interface UpdateSingleRelationFlimDefInput {
    id: number;
    name?: string;
    position?: string;
    required?: boolean;
    hiddenIfEmpty?: boolean;
    alwaysHidden?: boolean;
    showHelpText?: boolean;
    helpText?: string;
    blabReferences?: BlabReferenceInput[];
}

export interface UpdateSingleTextFlimDefInput {
    id: number;
    name?: string;
    position?: string;
    required?: boolean;
    hiddenIfEmpty?: boolean;
    alwaysHidden?: boolean;
    showHelpText?: boolean;
    helpText?: string;
    defaultValue?: string;
}

export interface UpdateSingleUserFlimDefInput {
    id: number;
    name?: string;
    position?: string;
    required?: boolean;
    hiddenIfEmpty?: boolean;
    alwaysHidden?: boolean;
    showHelpText?: boolean;
    helpText?: string;
    doNotNotify?: boolean;
    showInFocus?: boolean;
}

export interface UpdateStatusFlimDefInput {
    id: number;
    name?: string;
    position?: string;
    required?: boolean;
    hiddenIfEmpty?: boolean;
    alwaysHidden?: boolean;
    showHelpText?: boolean;
    helpText?: string;
    statusLayout?: StatusFlimLayout;
    statusOptionDefs?: UpdateStatusOptionDefInput[];
    showColorInCalendar?: boolean;
    defaultStatusOptionDefId?: number;
}

export interface UpdateStatusOptionDefInput {
    id?: number;
    label?: string;
    color?: SelectableColor;
    meansCompleted?: boolean;
    position: string;
}

export interface UpdateUniqueIdFlimDefInput {
    id: number;
    name?: string;
    position?: string;
    required?: boolean;
    hiddenIfEmpty?: boolean;
    alwaysHidden?: boolean;
    showHelpText?: boolean;
    helpText?: string;
    minDigits: number;
    uniqueIdPrefix?: string;
}

export interface UpdateUserSettingsInput {
    language?: Language;
    dateFormat?: DateFormat;
    timeFormat?: TimeFormat;
    firstWeekday?: FirstWeekday;
    measurementSystem?: MeasurementSystem;
    timezone?: Timezone;
    autoApplyTimezone?: boolean;
    decimalFormat?: DecimalFormat;
    optInPromotionalEmails?: boolean;
}

export interface UpdateWorkflowCenterWebhookInput {
    id: number;
    url?: string;
    webhookType?: WorkflowCenterWebhookType;
}

export interface UpdateWorkflowDefInput {
    id: number;
    name: string;
    description?: string;
    showDescription?: boolean;
    triggerType?: WorkflowTriggerType;
    filterDefinition: JSONObject;
    actionDefinition: JSONObject;
    triggerDefinition: JSONObject;
    validationErrors?: JSONObject;
    blabDefId: number;
    timezone: Timezone;
    templateSelected?: boolean;
    fullWidth?: boolean;
    isPaused: boolean;
    isBroken: boolean;
}

export interface UpdateWorkspaceBlabDefPositionInput {
    blabDefId: number;
    position: string;
}

export interface UpdateWorkspaceSettingsInput {
    workspaceId: number;
    name?: string;
    icon?: WorkspaceIcon;
    description?: string;
    type?: WorkspaceType;
}

export interface UpdateWorkspaceSlugInput {
    workspaceId: number;
    slug: string;
}

export interface UpdateWorkspaceTemplateInfoInput {
    id: number;
    templateName: string;
    templateSubtitle?: string;
    templateDescription?: string;
    language: Language;
    previewImageThumbnailId?: number;
    titleImageThumbnailId?: number;
    image3ThumbnailId?: number;
    image4ThumbnailId?: number;
    downloadCountOffset: number;
    categories: WorkspaceTemplateCategory[];
    templateShareLink?: string;
    publicationDate: DateTime;
}

export interface UploadBlabItemActivityReplyAttachmentInput {
    _void?: boolean;
}

export interface UploadBlabItemCommentAttachmentInput {
    _void?: boolean;
}

export interface UploadChatMessageAttachmentInput {
    _void?: boolean;
}

export interface UploadUserPostAttachmentInput {
    _void?: boolean;
}

export interface UpsertActiveUserAutoSaveBlabViewInput {
    blabDefId: number;
    originBlabViewId?: number;
    tableRowHeight: TableRowHeight;
    tableWrapCells?: boolean;
    layout: BlabViewLayout;
    sorting?: BlabItemsSortingInput;
    filtering?: UpdateBlabItemsFilteringInput;
    columns?: BlabViewColumnInput[];
    listProperties?: BlabViewListPropertyInput[];
    flimStatDefs?: UpsertBlabViewFlimStatDefInput;
    boardGroupByFlimDefId?: number;
    boardCardPreviewFlimDefId?: number;
    boardProperties?: BlabViewBoardPropertyInput[];
    boardColumns?: BlabViewBoardColumnInput;
    boardCardSize?: BlabViewBoardCardSizeType;
    boardCardPreviewFitImage?: boolean;
    boardLargeText?: boolean;
    boardHideEmptyColumns?: boolean;
    boardColorColumns?: boolean;
}

export interface UpsertActiveUserGlobalWorkflowRunsFilteringSettingsInput {
    statuses?: WorkflowRunFilteringStatusType[];
    blabDefIds?: number[];
    workflowDefIds?: number[];
    searchQuery?: string;
}

export interface UpsertBlabViewFlimStatDefInput {
    id?: number;
    singleTextFlimStats?: UpsertSingleTextFlimStatDefInput[];
    multiTextFlimStats?: UpsertMultiTextFlimStatDefInput[];
    singleCategoryFlimStats?: UpsertSingleCategoryFlimStatDefInput[];
    multiCategoryFlimStats?: UpsertMultiCategoryFlimStatDefInput[];
    singleUserFlimStats?: UpsertSingleUserFlimStatDefInput[];
    multiUserFlimStats?: UpsertMultiUserFlimStatDefInput[];
    singleAttachmentFlimStats?: UpsertSingleAttachmentFlimStatDefInput[];
    multiAttachmentFlimStats?: UpsertMultiAttachmentFlimStatDefInput[];
    singleRelationFlimStats?: UpsertSingleRelationFlimStatDefInput[];
    multiRelationFlimStats?: UpsertMultiRelationFlimStatDefInput[];
    singleDateFlimStats?: UpsertSingleDateFlimStatDefInput[];
    rangeDateFlimStats?: UpsertRangeDateFlimStatDefInput[];
    statusFlimStats?: UpsertStatusFlimStatDefInput[];
    multiLinkFlimStats?: UpsertMultiLinkFlimStatDefInput[];
    numberFlimStats?: UpsertNumberFlimStatDefInput[];
    uniqueIdFlimStats?: UpsertUniqueIdFlimStatDefInput[];
    checklistFlimStats?: UpsertChecklistFlimStatDefInput[];
    lastModifiedAtFlimStats?: UpsertLastModifiedAtFlimStatDefInput[];
    lastModifiedByFlimStats?: UpsertLastModifiedByFlimStatDefInput[];
    createdAtFlimStats?: UpsertCreatedAtFlimStatDefInput[];
    createdByFlimStats?: UpsertCreatedByFlimStatDefInput[];
    calculationFlimStats?: UpsertCalculationFlimStatDefInput[];
    multiEmailFlimStats?: UpsertMultiEmailFlimStatDefInput[];
    multiPhoneFlimStats?: UpsertMultiPhoneFlimStatDefInput[];
    multiImageFlimStats?: UpsertMultiImageFlimStatDefInput[];
    singleLocationFlimStats?: UpsertSingleLocationFlimStatDefInput[];
}

export interface UpsertCalculationFilterInput {
    id?: number;
    flimDefId: number;
    position: string;
    derivedFromSplitBy?: boolean;
    searchText?: string;
    decimal?: number;
    calculationMatchType: CalculationFilterMatchType;
    calculationRelativeDate?: CalculationRelativeDateType;
    calculationRelativeDateRange?: CalculationRelativeDateRangeType;
    numDays?: number;
    numWeeks?: number;
    numMonths?: number;
    exactDate?: DateTime;
    exactEndDate?: DateTime;
    weekday?: Weekday;
    quarterOfYear?: QuarterOfYear;
    monthOfYear?: MonthOfYear;
}

export interface UpsertCalculationFlimStatDefInput {
    id?: number;
    blabViewId?: number;
    flimDefId: number;
    statType: CalculationFlimStatType;
}

export interface UpsertChecklistFilterInput {
    id?: number;
    flimDefId: number;
    position: string;
    derivedFromSplitBy?: boolean;
    checklistMatchType: ChecklistFilterMatchType;
    checklistEntryProperty?: ChecklistFilterEntryProperty;
    searchText?: string;
    completed?: boolean;
    checklistDueDateRelativeDateType?: ChecklistRelativeDateType;
    checklistDueDateRelativeDateRangeType?: ChecklistRelativeDateRangeType;
    date?: DateTime;
    numDays?: number;
    numWeeks?: number;
    numMonths?: number;
    referencedUserIds?: number[];
    includeActiveUser?: boolean;
    includeActiveUserIndex?: number;
}

export interface UpsertChecklistFlimStatDefInput {
    id?: number;
    blabViewId?: number;
    flimDefId: number;
    statType: ChecklistFlimStatType;
}

export interface UpsertCreatedAtFilterInput {
    id?: number;
    flimDefId: number;
    position: string;
    derivedFromSplitBy?: boolean;
    createdAtMatchType: CreatedAtFilterMatchType;
    exactDate?: DateTime;
    exactEndDate?: DateTime;
    createdAtRelativeDateRange?: CreatedAtRelativeDateRangeType;
    createdAtRelativeDate?: CreatedAtRelativeDateType;
    numDays?: number;
    numWeeks?: number;
    numMonths?: number;
    weekday?: Weekday;
    quarterOfYear?: QuarterOfYear;
    monthOfYear?: MonthOfYear;
}

export interface UpsertCreatedAtFlimStatDefInput {
    id?: number;
    blabViewId?: number;
    flimDefId: number;
    statType: CreatedAtFlimStatType;
}

export interface UpsertCreatedByFilterInput {
    id?: number;
    flimDefId: number;
    position: string;
    derivedFromSplitBy?: boolean;
    referencedUserIds: number[];
    createdByMatchType: CreatedByFilterMatchType;
    includeActiveUser?: boolean;
    includeActiveUserIndex?: number;
}

export interface UpsertCreatedByFlimStatDefInput {
    id?: number;
    blabViewId?: number;
    flimDefId: number;
    statType: CreatedByFlimStatType;
}

export interface UpsertLastModifiedAtFilterInput {
    id?: number;
    flimDefId: number;
    position: string;
    derivedFromSplitBy?: boolean;
    lastModifiedAtMatchType: LastModifiedAtFilterMatchType;
    exactDate?: DateTime;
    exactEndDate?: DateTime;
    lastModifiedAtRelativeDateRange?: LastModifiedAtRelativeDateRangeType;
    lastModifiedAtRelativeDate?: LastModifiedAtRelativeDateType;
    numDays?: number;
    numWeeks?: number;
    numMonths?: number;
    weekday?: Weekday;
    quarterOfYear?: QuarterOfYear;
    monthOfYear?: MonthOfYear;
}

export interface UpsertLastModifiedAtFlimStatDefInput {
    id?: number;
    blabViewId?: number;
    flimDefId: number;
    statType: LastModifiedAtFlimStatType;
}

export interface UpsertLastModifiedByFilterInput {
    id?: number;
    flimDefId: number;
    position: string;
    derivedFromSplitBy?: boolean;
    referencedUserIds: number[];
    lastModifiedByMatchType: LastModifiedByFilterMatchType;
    includeActiveUser?: boolean;
    includeActiveUserIndex?: number;
}

export interface UpsertLastModifiedByFlimStatDefInput {
    id?: number;
    blabViewId?: number;
    flimDefId: number;
    statType: LastModifiedByFlimStatType;
}

export interface UpsertMultiAttachmentFilterInput {
    id?: number;
    flimDefId: number;
    position: string;
    derivedFromSplitBy?: boolean;
    searchText?: string;
    multiAttachmentMatchType: MultiAttachmentFilterMatchType;
}

export interface UpsertMultiAttachmentFlimStatDefInput {
    id?: number;
    blabViewId?: number;
    flimDefId: number;
    statType: MultiAttachmentFlimStatType;
}

export interface UpsertMultiAttachmentFlimValInput {
    blabItemId: number;
    flimDefId: number;
    attachmentReferences: AttachmentReferenceUpsertInput[];
}

export interface UpsertMultiCategoryFilterInput {
    id?: number;
    flimDefId: number;
    position: string;
    derivedFromSplitBy?: boolean;
    categoryOptionDefIds: number[];
    multiCategoryMatchType: MultiCategoryFilterMatchType;
}

export interface UpsertMultiCategoryFlimStatDefInput {
    id?: number;
    blabViewId?: number;
    flimDefId: number;
    statType: MultiCategoryFlimStatType;
}

export interface UpsertMultiCategoryFlimValInput {
    blabItemId: number;
    flimDefId: number;
    categoryOptionDefIds?: number[];
}

export interface UpsertMultiEmailFilterInput {
    id?: number;
    flimDefId: number;
    position: string;
    derivedFromSplitBy?: boolean;
    searchText?: string;
    multiEmailMatchType: MultiEmailFilterMatchType;
}

export interface UpsertMultiEmailFlimStatDefInput {
    id?: number;
    blabViewId?: number;
    flimDefId: number;
    statType: MultiEmailFlimStatType;
}

export interface UpsertMultiImageFilterInput {
    id?: number;
    flimDefId: number;
    position: string;
    derivedFromSplitBy?: boolean;
    searchText?: string;
    multiImageMatchType: MultiImageFilterMatchType;
}

export interface UpsertMultiImageFlimStatDefInput {
    id?: number;
    blabViewId?: number;
    flimDefId: number;
    statType: MultiImageFlimStatType;
}

export interface UpsertMultiImageFlimValInput {
    blabItemId: number;
    flimDefId: number;
    imageReferences: ImageReferenceUpsertInput[];
}

export interface UpsertMultiLinkFilterInput {
    id?: number;
    flimDefId: number;
    position: string;
    derivedFromSplitBy?: boolean;
    searchText?: string;
    multiLinkMatchType: MultiLinkFilterMatchType;
}

export interface UpsertMultiLinkFlimStatDefInput {
    id?: number;
    blabViewId?: number;
    flimDefId: number;
    statType: MultiLinkFlimStatType;
}

export interface UpsertMultiPhoneFilterInput {
    id?: number;
    flimDefId: number;
    position: string;
    derivedFromSplitBy?: boolean;
    searchText?: string;
    multiPhoneMatchType: MultiPhoneFilterMatchType;
}

export interface UpsertMultiPhoneFlimStatDefInput {
    id?: number;
    blabViewId?: number;
    flimDefId: number;
    statType: MultiPhoneFlimStatType;
}

export interface UpsertMultiRelationFilterInput {
    id?: number;
    flimDefId: number;
    position: string;
    derivedFromSplitBy?: boolean;
    referencedBlabItemIds: number[];
    multiRelationMatchType: MultiRelationFilterMatchType;
}

export interface UpsertMultiRelationFlimStatDefInput {
    id?: number;
    blabViewId?: number;
    flimDefId: number;
    statType: MultiRelationFlimStatType;
}

export interface UpsertMultiRelationFlimValInput {
    blabItemId: number;
    flimDefId: number;
    blabItemReferences?: BlabItemReferenceUpsertInput[];
}

export interface UpsertMultiTextFilterInput {
    id?: number;
    flimDefId: number;
    position: string;
    derivedFromSplitBy?: boolean;
    searchText?: string;
    multiTextMatchType: MultiTextFilterMatchType;
}

export interface UpsertMultiTextFlimStatDefInput {
    id?: number;
    blabViewId?: number;
    flimDefId: number;
    statType: MultiTextFlimStatType;
}

export interface UpsertMultiTextFlimValInput {
    blabItemId: number;
    flimDefId: number;
    value?: string;
}

export interface UpsertMultiUserFilterInput {
    id?: number;
    flimDefId: number;
    position: string;
    derivedFromSplitBy?: boolean;
    referencedUserIds: number[];
    multiUserMatchType: MultiUserFilterMatchType;
    includeActiveUser?: boolean;
    includeActiveUserIndex?: number;
}

export interface UpsertMultiUserFlimStatDefInput {
    id?: number;
    blabViewId?: number;
    flimDefId: number;
    statType: MultiUserFlimStatType;
}

export interface UpsertMultiUserFlimValInput {
    blabItemId: number;
    flimDefId: number;
    userReferences?: UserReferenceUpsertInput[];
}

export interface UpsertNumberFilterInput {
    id?: number;
    flimDefId: number;
    position: string;
    derivedFromSplitBy?: boolean;
    decimal?: number;
    numberMatchType: NumberFilterMatchType;
}

export interface UpsertNumberFlimStatDefInput {
    id?: number;
    blabViewId?: number;
    flimDefId: number;
    statType: NumberFlimStatType;
}

export interface UpsertNumberFlimValInput {
    blabItemId: number;
    flimDefId: number;
    decimal?: number;
}

export interface UpsertRangeDateFilterInput {
    id?: number;
    flimDefId: number;
    position: string;
    derivedFromSplitBy?: boolean;
    rangeDateMatchType: RangeDateFilterMatchType;
    exactDate?: DateTime;
    exactEndDate?: DateTime;
    rangeDateRelativeDateRange?: RangeDateRelativeDateRangeType;
    rangeDateRelativeDate?: RangeDateRelativeDateType;
    numDays?: number;
    numWeeks?: number;
    numMonths?: number;
    weekday?: Weekday;
    quarterOfYear?: QuarterOfYear;
    monthOfYear?: MonthOfYear;
}

export interface UpsertRangeDateFlimStatDefInput {
    id?: number;
    blabViewId?: number;
    flimDefId: number;
    statType: RangeDateFlimStatType;
}

export interface UpsertRangeDateFlimValInput {
    blabItemId: number;
    flimDefId: number;
    startDate?: DateTime;
    hasTime?: boolean;
    endDate?: DateTime;
}

export interface UpsertSingleAttachmentFilterInput {
    id?: number;
    flimDefId: number;
    position: string;
    derivedFromSplitBy?: boolean;
    searchText?: string;
    singleAttachmentMatchType: SingleAttachmentFilterMatchType;
}

export interface UpsertSingleAttachmentFlimStatDefInput {
    id?: number;
    blabViewId?: number;
    flimDefId: number;
    statType: SingleAttachmentFlimStatType;
}

export interface UpsertSingleAttachmentFlimValInput {
    blabItemId: number;
    flimDefId: number;
    attachmentId?: number;
    cardThumbnailPositionX?: number;
    cardThumbnailPositionY?: number;
}

export interface UpsertSingleCategoryFilterInput {
    id?: number;
    flimDefId: number;
    position: string;
    derivedFromSplitBy?: boolean;
    categoryOptionDefIds: number[];
    singleCategoryMatchType: SingleCategoryFilterMatchType;
}

export interface UpsertSingleCategoryFlimStatDefInput {
    id?: number;
    blabViewId?: number;
    flimDefId: number;
    statType: SingleCategoryFlimStatType;
}

export interface UpsertSingleCategoryFlimValInput {
    blabItemId: number;
    flimDefId: number;
    categoryOptionDefId?: number;
}

export interface UpsertSingleDateFilterInput {
    id?: number;
    flimDefId: number;
    position: string;
    derivedFromSplitBy?: boolean;
    singleDateMatchType: SingleDateFilterMatchType;
    exactDate?: DateTime;
    exactEndDate?: DateTime;
    singleDateRelativeDateRange?: SingleDateRelativeDateRangeType;
    singleDateRelativeDate?: SingleDateRelativeDateType;
    numDays?: number;
    numWeeks?: number;
    numMonths?: number;
    weekday?: Weekday;
    quarterOfYear?: QuarterOfYear;
    monthOfYear?: MonthOfYear;
}

export interface UpsertSingleDateFlimStatDefInput {
    id?: number;
    blabViewId?: number;
    flimDefId: number;
    statType: SingleDateFlimStatType;
}

export interface UpsertSingleDateFlimValInput {
    blabItemId: number;
    flimDefId: number;
    date?: DateTime;
    hasTime?: boolean;
}

export interface UpsertSingleLocationFilterInput {
    id?: number;
    flimDefId: number;
    position: string;
    derivedFromSplitBy?: boolean;
    searchText?: string;
    singleLocationMatchType: SingleLocationFilterMatchType;
}

export interface UpsertSingleLocationFlimStatDefInput {
    id?: number;
    blabViewId?: number;
    flimDefId: number;
    statType: SingleLocationFlimStatType;
}

export interface UpsertSingleLocationFlimValInput {
    blabItemId: number;
    flimDefId: number;
    originalFormattedAddress?: string;
    city?: string;
    country?: string;
    state?: string;
    postalCode?: string;
    streetAddress?: string;
    hideMapEmbed?: boolean;
    locationId?: string;
    latitude?: number;
    longitude?: number;
}

export interface UpsertSingleRelationFilterInput {
    id?: number;
    flimDefId: number;
    position: string;
    derivedFromSplitBy?: boolean;
    referencedBlabItemIds: number[];
    singleRelationMatchType: SingleRelationFilterMatchType;
}

export interface UpsertSingleRelationFlimStatDefInput {
    id?: number;
    blabViewId?: number;
    flimDefId: number;
    statType: SingleRelationFlimStatType;
}

export interface UpsertSingleRelationFlimValInput {
    blabItemId: number;
    flimDefId: number;
    referencedBlabItemId?: number;
}

export interface UpsertSingleTextFilterInput {
    id?: number;
    flimDefId: number;
    position: string;
    derivedFromSplitBy?: boolean;
    searchText?: string;
    singleTextMatchType: SingleTextFilterMatchType;
}

export interface UpsertSingleTextFlimStatDefInput {
    id?: number;
    blabViewId?: number;
    flimDefId: number;
    statType: SingleTextFlimStatType;
}

export interface UpsertSingleTextFlimValInput {
    blabItemId: number;
    flimDefId: number;
    value?: string;
}

export interface UpsertSingleUserFilterInput {
    id?: number;
    flimDefId: number;
    position: string;
    derivedFromSplitBy?: boolean;
    referencedUserIds: number[];
    singleUserMatchType: SingleUserFilterMatchType;
    includeActiveUser?: boolean;
    includeActiveUserIndex?: number;
}

export interface UpsertSingleUserFlimStatDefInput {
    id?: number;
    blabViewId?: number;
    flimDefId: number;
    statType: SingleUserFlimStatType;
}

export interface UpsertSingleUserFlimValInput {
    blabItemId: number;
    flimDefId: number;
    userId?: number;
}

export interface UpsertStatusFilterInput {
    id?: number;
    flimDefId: number;
    position: string;
    derivedFromSplitBy?: boolean;
    statusOptionDefIds: number[];
    statusMatchType: StatusFilterMatchType;
}

export interface UpsertStatusFlimStatDefInput {
    id?: number;
    blabViewId?: number;
    flimDefId: number;
    statType: StatusFlimStatType;
}

export interface UpsertStatusFlimValInput {
    blabItemId: number;
    flimDefId: number;
    statusOptionDefId?: number;
}

export interface UpsertUniqueIdFilterInput {
    id?: number;
    flimDefId: number;
    position: string;
    derivedFromSplitBy?: boolean;
    decimal?: number;
    uniqueIdMatchType: UniqueIdFilterMatchType;
}

export interface UpsertUniqueIdFlimStatDefInput {
    id?: number;
    blabViewId?: number;
    flimDefId: number;
    statType: UniqueIdFlimStatType;
}

export interface UserCredentialsInput {
    email: string;
    password: string;
}

export interface UserReferenceCreateInput {
    userId: number;
    position: string;
}

export interface UserReferenceUpsertInput {
    userId: number;
    position: string;
}

export interface ValidateOrganizationSignupVerificationCodeInput {
    sessionId: string;
    code: string;
}

export interface WebPushSubscriptionKeysInput {
    p256dh: string;
    auth: string;
}

export interface BillingSubscriptionDto {
    active: boolean;
    type: BillingSubscriptionType;
    plan: OrganizationPlan;
    billingPeriod: BillingPeriod;
    billingCycleAnchor: DateTime;
    currentPeriodStart: DateTime;
    currentPeriodEnd: DateTime;
    createdAt: DateTime;
    cancelAtPeriodEnd: boolean;
    requiresAction?: BillingSubscriptionAction;
    requiresPaymentMethod?: boolean;
    upcomingInvoice?: SubscriptionInvoicePreviewDto;
    quantity: number;
}

export interface BlabDefActivityDto {
    id: number;
    blabDefId: number;
    blabDef?: BlabDefPreviewDto;
    type: BlabDefActivityType;
    createdAt: DateTime;
    author?: UserProfilePreviewDto;
    authorId?: number;
    numReplies: number;
    reactions: ReactionDto[];
    replies: BlabDefActivityReplyEdge[];
}

export interface BlabItemActivityDto {
    id: number;
    blabItemId: number;
    blabItem?: BlabItemPreviewDto;
    type: BlabItemActivityType;
    createdAt: DateTime;
    createdVia: BlabItemActivityCreatedViaType;
    author?: UserProfilePreviewDto;
    authorId?: number;
    authorWorkflowDef?: WorkflowDefPreviewDto;
    authorWorkflowDefId?: number;
    numReplies: number;
    reactions: ReactionDto[];
    replies: BlabItemActivityReplyEdge[];
}

export interface BlabViewApplicableConfiguration {
    blabDefId: number;
    layout: BlabViewLayout;
    sorting?: BlabViewSortDto;
    flimFilters: FlimFilterDto[];
    columns: BlabViewColumnDto[];
    flimStatDefs: FlimStatDefUnionType[];
    listProperties: BlabViewListPropertyDto[];
    tableRowHeight: TableRowHeight;
    tableWrapCells: boolean;
}

export interface BlabViewBoardColumnDto {
    blabViewId?: number;
    flimDefId: number;
    flimType: FlimType;
    position: string;
    hidden: boolean;
}

export interface CalendarEventDto {
    id: string;
    type: CalendarEventType;
    startDate: DateTime;
    endDate?: DateTime;
    hasTime: boolean;
    title?: string;
    calendarSubscriptionId: number;
    calendarSubscription: ActiveUserCalendarSubscriptionDto;
}

export interface CategoryFlimDefDto {
    layout: CategoryFlimLayout;
    categoryOptionDefs: CategoryOptionDefDto[];
}

export interface CategoryOptionDefDto {
    id: number;
    label: string;
    color?: SelectableColor;
    position: string;
}

export interface ChatActivityDto {
    id: number;
    type: ChatActivityType;
    createdAt: DateTime;
    reactions: ReactionDto[];
    conversationId: number;
}

export interface ConversationDto {
    type: ConversationType;
    conversationId: number;
    lastChatActivity: ChatActivityDto;
    userReadUntil?: DateTime;
    mutedByUser: boolean;
    numUnreadActivities: number;
}

export interface ConvertFlimResultDto {
    flimDef: FlimDefDto;
    flimDefId: number;
}

export interface FileDto {
    id: number;
    key: string;
    viewUrl: string;
    downloadUrl: string;
    createdAt: DateTime;
    name: string;
    extension?: string;
    mimetype?: string;
    size: number;
    thumbnailId?: number;
    thumbnail?: ThumbnailDto;
}

export interface FlimDefDto {
    id: number;
    blabDefId: number;
    blabDef?: BlabDefPreviewDto;
    slug: string;
    name: string;
    type: FlimType;
    position: string;
    required: boolean;
    hiddenIfEmpty: boolean;
    alwaysHidden: boolean;
    showHelpText: boolean;
    helpText?: string;
    showInRecord?: boolean;
}

export interface FlimFilterDto {
    id: number;
    type: FlimType;
    blabViewId?: number;
    flimDefId: number;
    position: string;
    derivedFromSplitBy?: boolean;
}

export interface FlimStatDefDto {
    id: number;
    type: FlimType;
    blabViewId?: number;
    flimDefId: number;
}

export interface FlimStatResultDto {
    flimDefId: number;
    result?: number;
    flimType: FlimType;
}

export interface FlimValDto {
    type: FlimType;
    blabItemId: number;
    flimDefId: number;
}

export interface FlimValRevisionDto {
    id: number;
    type: BlabItemRevisionType;
    flimType: FlimType;
    createdAt: DateTime;
    blabItemId: number;
    flimDefId: number;
    flimDef?: FlimDefRevisionPreviewUnionType;
}

export interface FocusEntryDto {
    id: number;
    type: FocusEntryType;
    position: string;
    sectionId: number;
}

export interface I18nSettings {
    language: Language;
    dateFormat: DateFormat;
    timeFormat: TimeFormat;
    firstWeekday: FirstWeekday;
    decimalFormat: DecimalFormat;
    measurementSystem: MeasurementSystem;
}

export interface InvoiceItemDto {
    id: string;
    type: InvoiceItemType;
}

export interface MetaFilterDto {
    id: number;
    blabViewId: number;
    metaType: BlabItemMetaType;
}

export interface NotificationActivityDto {
    notificationId: number;
    direct?: DirectNotificationType;
}

export interface OrganizationActivityDto {
    id: number;
    organizationId: number;
    organization?: OrganizationProfileDto;
    type: OrganizationActivityType;
    createdAt: DateTime;
    author?: UserProfilePreviewDto;
    authorId?: number;
    numReplies: number;
    reactions: ReactionDto[];
    replies: OrganizationActivityReplyEdge[];
}

export interface PaymentMethodDto {
    id: string;
    type: PaymentMethodType;
    createdAt: DateTime;
    createdByUser?: UserProfilePreviewDto;
    createdByUserId?: number;
    isDefault: boolean;
    error?: PaymentMethodErrorType;
    name: string;
    postalCode?: string;
}

export interface RelationFlimDefDto {
    blabReferences?: BlabReferenceDto[];
}

export interface SearchHistoryItemDto {
    id: number;
    type: SearchHistoryItemType;
}

export interface SearchResultDto {
    type: SearchResultType;
}

export interface ShareContextDto {
    type: ShareContextType;
    userSessions: ShareContextUserSessionDto[];
}

export interface SplitBySectionDto {
    id: string;
    type: FlimType;
    flimDefId: number;
    total: number;
}

export interface UserActivityDto {
    id: number;
    userId: number;
    user?: UserProfileDto;
    type: UserActivityType;
    createdAt: DateTime;
    author?: UserProfilePreviewDto;
    authorId?: number;
    numReplies: number;
    reactions: ReactionDto[];
    replies: UserActivityReplyEdge[];
}

export interface WorkflowRunActionLogEvent {
    type: WorkflowRunLogEventType;
    workflowRunId: number;
    loggedAt: DateTime;
    sortIndex?: number;
    actionId: string;
    actionExecutionId: string;
}

export interface WorkflowRunLogEvent {
    type: WorkflowRunLogEventType;
    workflowRunId: number;
    loggedAt: DateTime;
    sortIndex?: number;
}

export interface WorkspaceActivityDto {
    id: number;
    workspaceId: number;
    workspace?: WorkspacePreviewDto;
    type: WorkspaceActivityType;
    createdAt: DateTime;
    author?: UserProfilePreviewDto;
    authorId?: number;
    numReplies: number;
    reactions: ReactionDto[];
    replies: WorkspaceActivityReplyEdge[];
}

export interface _BlabItemPersistedTitleDto {
    blabItemId: number;
    blabItemPersistedTitle: string;
}

export interface _DatabaseVersionDto {
    databaseVersionStr: string;
}

export interface _DateArrivedJobDto {
    id: number;
    executedAt?: DateTime;
    blabItemId: number;
    scheduleAt: DateTime;
    workflowDefId: number;
    skippedAt?: DateTime;
}

export interface _DatePeriodicJobDto {
    id: number;
    executedAt?: DateTime;
    scheduleAt: DateTime;
    workflowDefId: number;
    skippedAt?: DateTime;
}

export interface _GetOrganizationJobStatusResultDto {
    jobId: number;
    status: _OrganizationJobStatus;
}

export interface _GetWorkflowOfOrganizationAreThrottled {
    isThrottled: boolean;
}

export interface _OrganizationWorkflowUsageReportDto {
    organizationId: number;
    numConsumedActions: number;
    numSentEmails: number;
    createdAt: DateTime;
}

export interface _testJobQueueDto {
    job: TestJobQueue;
}

export interface _TestJobQueueExistsResult {
    exists: boolean;
}

export interface _testTimeMockDto {
    currentTime: DateTime;
    isKeepingTime: boolean;
}

export interface ActiveBillingSubscriptionDto extends BillingSubscriptionDto {
    active: boolean;
    type: BillingSubscriptionType;
    plan: OrganizationPlan;
    billingPeriod: BillingPeriod;
    billingCycleAnchor: DateTime;
    currentPeriodStart: DateTime;
    currentPeriodEnd: DateTime;
    createdAt: DateTime;
    cancelAtPeriodEnd: boolean;
    requiresAction?: BillingSubscriptionAction;
    requiresPaymentMethod?: boolean;
    upcomingInvoice?: SubscriptionInvoicePreviewDto;
    quantity: number;
}

export interface ActiveUserApiKeyDto {
    userApiKey: string;
}

export interface ActiveUserAutoSaveBlabViewDto extends BlabViewApplicableConfiguration {
    blabDefId: number;
    originBlabViewId?: number;
    originBlabView?: BlabViewPreviewDto;
    layout: BlabViewLayout;
    sorting?: BlabViewSortDto;
    flimFilters: FlimFilterDto[];
    flimStatDefs: FlimStatDefUnionType[];
    columns: BlabViewColumnDto[];
    tableRowHeight: TableRowHeight;
    tableWrapCells: boolean;
    listProperties: BlabViewListPropertyDto[];
    boardGroupByFlimDefId?: number;
    boardGroupByFlimDef?: FlimDefUnionType;
    boardProperties: BlabViewBoardPropertyDto[];
    boardColumns: BlabViewBoardColumnUnionType[];
    boardFlimStatDef?: FlimStatDefUnionType;
    boardCardSize: BlabViewBoardCardSizeType;
    boardCardPreviewFitImage: boolean;
    boardCardPreviewFlimDefId?: number;
    boardCardPreviewFlimDef?: FlimDefUnionType;
    boardLargeText: boolean;
    boardHideEmptyColumns: boolean;
    boardColorColumns: boolean;
}

export interface ActiveUserBlabDefFollowInfoDto {
    blabDefId: number;
    isFollowing: boolean;
}

export interface ActiveUserBlabDefInboundEmailSettingsDto {
    blabDefId: number;
    publicEmail: string;
    publicEmailEnabled: boolean;
    privateEmail: string;
    privateEmailEnabled: boolean;
    inboundEmailFieldFromFlimDefId?: number;
    inboundEmailFieldCcFlimDefId?: number;
    inboundEmailFieldSubjectFlimDefId?: number;
    inboundEmailFieldBodyFlimDefId?: number;
    inboundEmailFieldAttachmentsFlimDefId?: number;
}

export interface ActiveUserBlabDefInfoDto {
    blabDefId: number;
    viewSidebarCollapsed: boolean;
    privateViewsInViewSidebarCollapsed: boolean;
    publicViewsInViewSidebarCollapsed: boolean;
    activeBlabView: ActiveUserBlabDefInfoActiveBlabViewUnionType;
    activeBlabViewSplitBySection?: ActiveUserSplitBySectionUnionType;
    activeBlabViewSplitByCompleted?: ActiveUserSplitByCompletedDto;
}

export interface ActiveUserBlabItemInfoDto {
    blabItemId: number;
    userId: number;
    isFollowing: boolean;
    isBeingNotified: boolean;
    hasReminder: boolean;
    numOverdueReminder: number;
    focusSectionId?: number;
}

export interface ActiveUserCalculationSplitBySectionDto {
    id: string;
    type: FlimType;
    flimDefId: number;
    startDate?: DateTime;
    endDate?: DateTime;
    weekday?: Weekday;
}

export interface ActiveUserCalendarPrivateUrlShareDto {
    url: string;
    calendarSubscriptionIds: number[];
    calendarSubscriptions: ActiveUserCalendarSubscriptionDto[];
    permissionType: PrivateUrlSharePermissionType;
}

export interface ActiveUserCalendarPublicUrlShareDto {
    url: string;
    calendarSubscriptionIds: number[];
    calendarSubscriptions: ActiveUserCalendarSubscriptionDto[];
    permissionType: PublicUrlSharePermissionType;
}

export interface ActiveUserCalendarSettingsDto {
    showCompletedReminders: boolean;
    showCompletedChecklistEntries: boolean;
    showCompletedBlabItems: boolean;
    userId: number;
}

export interface ActiveUserCalendarStaticSharesDto {
    userId: number;
    privateUrlShare: ActiveUserCalendarPrivateUrlShareDto;
    publicUrlShare: ActiveUserCalendarPublicUrlShareDto;
}

export interface ActiveUserCalendarSubscriptionDto {
    id: number;
    userId: number;
    type: UserCalendarSubscriptionType;
    active: boolean;
    color: SelectableColor;
}

export interface ActiveUserChangesSubscriptionEvent {
    payload: JSONObject;
}

export interface ActiveUserCreatedAtSplitBySectionDto {
    id: string;
    type: FlimType;
    flimDefId: number;
    startDate?: DateTime;
    endDate?: DateTime;
    weekday?: Weekday;
}

export interface ActiveUserDevPortalContextDto {
    userId: number;
    user: UserProfilePreviewDto;
    apiKey: string;
}

export interface ActiveUserDevPortalRejectionDto {
    message?: string;
}

export interface ActiveUserDto extends I18nSettings {
    id: number;
    primaryName: string;
    secondaryName?: string;
    email: string;
    language: Language;
    dateFormat: DateFormat;
    timeFormat: TimeFormat;
    firstWeekday: FirstWeekday;
    decimalFormat: DecimalFormat;
    measurementSystem: MeasurementSystem;
    organizationId: number;
    organization: ActiveUserOrganizationDto;
    roles: ActiveUserRolesDto;
    profilePicture?: ThumbnailDto;
    status: ActiveUserStatusDto;
    featureAccessLevel: UserFeatureAccessLevel;
    partnerStatus?: UserPartnerStatus;
    createdAt: DateTime;
    metadata?: JSONObject;
    hasCompletedSetup?: boolean;
    hasCompletedWorkspaceSetup?: boolean;
    autoApplyTimezone?: boolean;
    canPublishWorkspaceTemplates: boolean;
    activityCount: number;
    lastActivityAt?: DateTime;
}

export interface ActiveUserEngagedBlabItemPreviewDto {
    id: number;
    title?: string;
    engagementType?: ActiveUserBlabItemEngagementType;
    blabDefId: number;
    blabDef?: BlabDefPreviewDto;
}

export interface ActiveUserExploreBlabItemPreviewDto {
    id: number;
    title?: string;
    blabDefId: number;
    blabDef?: BlabDefPreviewDto;
    numActivities: number;
    isFollowing: boolean;
    authorId?: number;
    author?: UserProfilePreviewDto;
}

export interface ActiveUserExploreSuggestionDto {
    id: number;
    type: ActiveUserExploreSuggestionType;
    category: ActiveUserExploreCategory;
    blabItem?: ActiveUserExploreBlabItemPreviewDto;
    blabItemId?: number;
}

export interface ActiveUserExploreSuggestionEdge {
    node: ActiveUserExploreSuggestionDto;
    cursor: string;
}

export interface ActiveUserGlobalWorkflowRunsFilteringSettingsDto {
    statuses?: WorkflowRunFilteringStatusType[];
    blabDefIds?: number[];
    blabDefs?: BlabDefPreviewDto[];
    workflowDefIds?: number[];
    workflowDefs?: WorkflowDefPreviewDto[];
    searchQuery?: string;
}

export interface ActiveUserLastModifiedAtSplitBySectionDto {
    id: string;
    type: FlimType;
    flimDefId: number;
    startDate?: DateTime;
    endDate?: DateTime;
    weekday?: Weekday;
}

export interface ActiveUserMultiCategorySplitBySectionDto {
    id: string;
    type: FlimType;
    flimDefId: number;
    multiCategoryOptionDef?: MultiCategoryOptionDefDto;
    multiCategoryOptionDefId?: number;
}

export interface ActiveUserMultiRelationSplitBySectionDto {
    id: string;
    type: FlimType;
    flimDefId: number;
    blabItem?: BlabItemPreviewDto;
    blabItemId?: number;
}

export interface ActiveUserMultiUserSplitBySectionDto {
    id: string;
    type: FlimType;
    flimDefId: number;
    user?: UserProfilePreviewDto;
    userId?: number;
}

export interface ActiveUserNotificationSettingsDto {
    emailNotificationsActive: boolean;
}

export interface ActiveUserOnboardingTasksDto {
    dismissed: boolean;
    hasProfilePicture: boolean;
    hasInvitedUsers: boolean;
    hasCompletedProfile: boolean;
    hasInstalledMobileApp: boolean;
}

export interface ActiveUserOrganizationDto {
    id: number;
    slug: string;
    name: string;
    activityLimit: number;
    activityCount: number;
    plan?: OrganizationPlan;
    workspaces?: WorkspacePreviewDto[];
    numUsers?: number;
    logo?: ThumbnailDto;
    hasWorkflowAutomationAccess?: boolean;
    disableTracking?: boolean;
    billingCustomerId?: string;
}

export interface ActiveUserProfileDto {
    id: number;
    name: string;
    shortname?: string;
    jobDescription?: string;
    phoneNumber?: string;
    profilePicture?: ThumbnailDto;
    email?: string;
    location?: string;
    website?: string;
    birthDate?: DateTime;
    timezone?: Timezone;
}

export interface ActiveUserQuickAddBlabDefDto {
    position: string;
    blabDef: BlabDefPreviewDto;
    blabDefId: number;
}

export interface ActiveUserRangeDateSplitBySectionDto {
    id: string;
    type: FlimType;
    flimDefId: number;
    startDate?: DateTime;
    endDate?: DateTime;
    weekday?: Weekday;
}

export interface ActiveUserRolesDto {
    organization: OrganizationRole;
    workspaces: ActiveUserWorkspaceRoleDto[];
}

export interface ActiveUserSingleCategorySplitBySectionDto {
    id: string;
    type: FlimType;
    flimDefId: number;
    singleCategoryOptionDef?: SingleCategoryOptionDefDto;
    singleCategoryOptionDefId?: number;
}

export interface ActiveUserSingleDateSplitBySectionDto {
    id: string;
    type: FlimType;
    flimDefId: number;
    startDate?: DateTime;
    endDate?: DateTime;
    weekday?: Weekday;
}

export interface ActiveUserSingleRelationSplitBySectionDto {
    id: string;
    type: FlimType;
    flimDefId: number;
    blabItem?: BlabItemPreviewDto;
    blabItemId?: number;
}

export interface ActiveUserSingleUserSplitBySectionDto {
    id: string;
    type: FlimType;
    flimDefId: number;
    user?: UserProfilePreviewDto;
    userId?: number;
}

export interface ActiveUserSplitByCompletedDto {
    completed: boolean;
    statusFlimDefId: number;
}

export interface ActiveUserStatusDto {
    userId: number;
    presence: UserPresenceType;
    note?: string;
    emoji?: string;
    clearStatusAt?: DateTime;
    doNotDisturb: boolean;
    clearDoNotDisturbAt?: DateTime;
    incognito: boolean;
}

export interface ActiveUserStatusSplitBySectionDto {
    id: string;
    type: FlimType;
    flimDefId: number;
    statusOptionDef?: StatusOptionDefDto;
    statusOptionDefId?: number;
}

export interface ActiveUserSuggestedQuickAddBlabDefDto {
    userScore: number;
    score: number;
    blabDef: BlabDefPreviewDto;
    blabDefId: number;
}

export interface ActiveUserSuggestedQuickAddBlabDefDtoConnection {
    edges: ActiveUserSuggestedQuickAddBlabDefDtoEdge[];
    pageInfo: PageInfo;
}

export interface ActiveUserSuggestedQuickAddBlabDefDtoEdge {
    node: ActiveUserSuggestedQuickAddBlabDefDto;
    cursor: string;
}

export interface ActiveUserUserInfoDto {
    userId: number;
    targetUserId: number;
    isFollowing: boolean;
    isBeingNotified: boolean;
}

export interface ActiveUserWorkflowDefInfoDto {
    workflowDefId: number;
    userId: number;
    isFollowing: boolean;
    lastViewedAt?: DateTime;
}

export interface ActiveUserWorkspaceInfoDto {
    workspaceId: number;
    lastSelectedBlabDefId?: number;
}

export interface ActiveUserWorkspaceRoleDto {
    workspaceId: number;
    role: WorkspaceRole;
}

export interface AddBlabDefsFromWorkspaceTemplateResultDto {
    workspace: WorkspaceDto;
    addedBlabDefs: BlabDefDto[];
}

export interface AddBlabItemReminderResultDto {
    reminder: ReminderDto;
    blabItemInfo: ActiveUserBlabItemInfoDto;
}

export interface ApiInfoDto {
    lastBreakingApiChange: string;
}

export interface ArchiveAllCompletedRemindersResultDto {
    numArchived: number;
}

export interface AudioRoomActivityDto {
    id: number;
    audioRoomId: number;
    audioRoom?: AudioRoomPreviewDto;
    type: AudioRoomActivityType;
    createdAt: DateTime;
    author?: UserProfilePreviewDto;
    authorId?: number;
}

export interface AudioRoomConsumerOptions {
    userId: number;
    roomId: number;
    consumerOptions: JSONObject;
}

export interface AudioRoomDetailDto {
    id: number;
    name: string;
    participants: AudioRoomParticipantDetailDto[];
    author?: UserProfilePreviewDto;
    authorId: number;
}

export interface AudioRoomDto {
    id: number;
    name: string;
    participants: AudioRoomParticipantDto[];
    author?: UserProfilePreviewDto;
    authorId: number;
}

export interface AudioRoomNotificationActivityDto extends NotificationActivityDto {
    notificationId: number;
    direct?: DirectNotificationType;
    activity?: AudioRoomActivityDto;
}

export interface AudioRoomNotificationDto {
    id: number;
    type: NotificationType;
    read: boolean;
    updatedAt: DateTime;
    audioRoom: AudioRoomPreviewDto;
    audioRoomId: number;
    numActivities: number;
    activities: AudioRoomNotificationActivityDto[];
    involvedUsers: UserProfilePreviewDto[];
}

export interface AudioRoomParticipantDetailDto {
    userId: number;
    user?: UserProfilePreviewDto;
    roomId: number;
    muted: boolean;
    producerId?: string;
}

export interface AudioRoomParticipantDto {
    userId: number;
    user?: UserProfilePreviewDto;
    roomId: number;
    muted: boolean;
}

export interface AudioRoomPreviewDto {
    id: number;
    name: string;
    participants: AudioRoomParticipantDto[];
    author?: UserProfilePreviewDto;
    authorId: number;
}

export interface AudioRoomProducerOptions {
    producerId?: string;
}

export interface AuthenticateWithCredentialsRejectionDto {
    reason: AuthenticateWithCredentialsRejectionReason;
}

export interface BillingCustomerDto {
    id: string;
    balance: number;
    email?: string;
    firstName?: string;
    lastName?: string;
    companyName?: string;
    taxId?: string;
    businessCustomer?: boolean;
    country?: BillingCountry;
    stateProvinceRegion?: string;
    city?: string;
    postalCode?: string;
    streetAddress?: string;
    suiteUnit?: string;
    invoiceNote?: string;
}

export interface BillingCustomerValidationResultDto {
    taxId?: TaxIdVerificationResult;
}

export interface BillingPriceDto {
    id: string;
    currency: BillingCurrency;
    unitAmount: number;
    billingPeriod: BillingPeriod;
}

export interface BillingSubscriptionAction {
    redirectToUrl?: string;
    useStripeSdk?: string;
    clientSecret?: string;
}

export interface BlabDefActivityConnection {
    edges: BlabDefActivityEdge[];
    pageInfo: PageInfo;
}

export interface BlabDefActivityEdge {
    node: BlabDefActivityUnionType;
    cursor: string;
}

export interface BlabDefActivityReplyConnection {
    edges: BlabDefActivityReplyEdge[];
    pageInfo: PageInfo;
}

export interface BlabDefActivityReplyDto {
    id: number;
    createdAt: DateTime;
    activityId: number;
    userId: number;
    user?: UserProfilePreviewDto;
    content: string;
    reactions: ReactionDto[];
}

export interface BlabDefActivityReplyEdge {
    node: BlabDefActivityReplyDto;
    cursor: string;
}

export interface BlabDefCreatedActivityDto extends BlabDefActivityDto {
    id: number;
    blabDefId: number;
    blabDef?: BlabDefPreviewDto;
    type: BlabDefActivityType;
    createdAt: DateTime;
    author?: UserProfilePreviewDto;
    authorId?: number;
    numReplies: number;
    reactions: ReactionDto[];
    replies: BlabDefActivityReplyEdge[];
    involvedUsers: UserProfilePreviewDto[];
}

export interface BlabDefDataImportActivityDto {
    id: number;
    file: BlabDefDataImportFileDto;
    fileId: number;
    blabDefId: number;
    createdAt: DateTime;
    createdByUser?: UserProfilePreviewDto;
    createdByUserId?: number;
    config: JSONObject;
}

export interface BlabDefDataImportActivityPreviewDto {
    id: number;
    file: BlabDefDataImportFileDto;
    fileId: number;
    blabDefId: number;
    createdAt: DateTime;
    createdByUser?: UserProfilePreviewDto;
    createdByUserId?: number;
}

export interface BlabDefDataImportFileCellValuesInfoDto {
    amountTotal: number;
    amountEmpty: number;
    amountNotEmpty: number;
    maxTextLength: number;
    amountInvalidSingleTextValues: number;
    amountInvalidMultiTextValues: number;
    amountNumbers: number;
    maxNumberPrecision: number;
    numberPrefix?: string;
    numberPostfix?: string;
    amountNumbersWithPreOrPostfix: number;
    maxNumberPrecisionWithPreOrPostfix: number;
    hideThousandSeparator: boolean;
    uniqueSingleOptionValues: string[];
    amountUniqueSingleOptionValues: number;
    amountInvalidSingleOptionValues: number;
    uniqueMultiOptionValues: string[];
    amountUniqueMultiOptionValues: number;
    amountInvalidMultiOptionValues: number;
    amountSingleUserValues: number;
    amountMultiUserValues: number;
    amountDateTimeValues: number;
    amountCalendarDayValues: number;
    amountPhoneNumbers: number;
    amountInvalidPhoneNumbers: number;
    amountEmailAddresses: number;
    amountInvalidEmailAddresses: number;
    amountMultiUrlValues: number;
    amountInvalidMultiUrlValues: number;
    amountLocationValues: number;
    amountInvalidLocationValues: number;
}

export interface BlabDefDataImportFileColumnDto {
    columnIndex: number;
    columnName: string;
    cellValuesInfoSkipFirstRow: BlabDefDataImportFileCellValuesInfoDto;
    cellValuesInfoWithFirstRow: BlabDefDataImportFileCellValuesInfoDto;
}

export interface BlabDefDataImportFileDto {
    id: number;
    name: string;
    fileType: BlabDefDataImportFileType;
    numImportableRows?: number;
}

export interface BlabDefDataImportToExistingBlabDefConfigPreviewDto {
    importFile: BlabDefDataImportFileDto;
    importFileColumns: BlabDefDataImportFileColumnDto[];
    importFileRows: undefined[];
    importFileParsingError: boolean;
    lookup: BlabDefDataImportToExistingBlabDefConfigPreviewLookupDto;
    characterCode?: BlabDefDataImportCharacterCode;
    csvValueSeparator?: BlabDefDataImportCsvValueSeparator;
}

export interface BlabDefDataImportToExistingBlabDefConfigPreviewLookupDto {
    users: UserProfilePreviewDto[];
    flimDefs: FlimDefUnionType[];
    blabDefs: BlabDefPreviewDto[];
}

export interface BlabDefDataImportToNewBlabDefConfigPreviewDto {
    importFile: BlabDefDataImportFileDto;
    importFileColumns: BlabDefDataImportFileColumnDto[];
    importFileRows: undefined[];
    importFileParsingError: boolean;
    lookup: BlabDefDataImportToNewBlabDefConfigPreviewLookupDto;
    characterCode?: BlabDefDataImportCharacterCode;
    csvValueSeparator?: BlabDefDataImportCsvValueSeparator;
}

export interface BlabDefDataImportToNewBlabDefConfigPreviewLookupDto {
    users: UserProfilePreviewDto[];
}

export interface BlabDefDeveloperInfoDto {
    blabDefId: number;
    workspaceId: number;
    blabName: string;
    flimDefDeveloperInfos?: FlimDefDeveloperInfoDto[];
    blabDefWebhooks?: BlabDefWebhookDto[];
}

export interface BlabDefDeveloperInfoSubscriptionBlabDefWebhookEventDto {
    type: BlabDefDeveloperInfoSubscriptionEventType;
    blabDefId: number;
    blabDefWebhook: BlabDefWebhookDto;
}

export interface BlabDefDto {
    id: number;
    slug: string;
    blabName: string;
    itemName: string;
    description?: string;
    enablePermissions?: boolean;
    icon: BlabDefIcon;
    iconv2?: JSONObject;
    type?: string;
    itemIcon?: JSONObject;
    flimDefs?: FlimDefDto[];
    workspaceId: number;
    workspace?: WorkspaceDto;
    position: string;
    positionV2?: number;
    blabViews?: BlabViewDto[];
    blabItemIdSequence: number;
}

export interface BlabDefInvitationCreatedActivityDto extends BlabDefActivityDto {
    id: number;
    blabDefId: number;
    blabDef?: BlabDefPreviewDto;
    type: BlabDefActivityType;
    createdAt: DateTime;
    author?: UserProfilePreviewDto;
    authorId?: number;
    numReplies: number;
    reactions: ReactionDto[];
    replies: BlabDefActivityReplyEdge[];
    involvedUsers: UserProfilePreviewDto[];
    targetUser?: UserProfilePreviewDto;
    targetUserId?: number;
    invitationText?: string;
}

export interface BlabDefNotificationActivityDto extends NotificationActivityDto {
    notificationId: number;
    direct?: DirectNotificationType;
    activity?: BlabDefActivityUnionType;
    activityReply?: BlabDefActivityReplyDto;
}

export interface BlabDefNotificationDto {
    id: number;
    type: NotificationType;
    read: boolean;
    updatedAt: DateTime;
    blabDef: BlabDefPreviewDto;
    blabDefId: number;
    numActivities: number;
    activities: BlabDefNotificationActivityDto[];
    involvedUsers: UserProfilePreviewDto[];
}

export interface BlabDefPreviewDto {
    id: number;
    slug: string;
    position: string;
    positionV2?: number;
    icon: BlabDefIcon;
    iconv2?: JSONObject;
    itemIcon?: JSONObject;
    type?: string;
    blabName: string;
    itemName: string;
    workspaceId: number;
    workspace?: WorkspacePreviewDto;
}

export interface BlabDefRelationsWithFlimDefsDto {
    blabDefs: WorkflowDefBlabDefLookupDto[];
    blabDefRelations: WorkflowDefLookupBlabDefRelationDto[];
    flimDefs: FlimDefDto[];
    workspaces: WorkflowDefWorkspaceLookupDto[];
}

export interface BlabDefSearchResultDto extends SearchResultDto {
    type: SearchResultType;
    blabDef: BlabDefPreviewDto;
}

export interface BlabDefSettingsDto {
    blabDefId: number;
    blabName: string;
    itemName: string;
    icon: BlabDefIcon;
    description?: string;
    enablePermissions?: boolean;
    slug: string;
}

export interface BlabDefShareContextDto extends ShareContextDto {
    type: ShareContextType;
    userSessions: ShareContextUserSessionDto[];
    blabDefId: number;
}

export interface BlabDefWebhookDto {
    id: number;
    url: string;
    position: string;
    active: boolean;
    webhookType: BlabDefWebhookType;
    blabDefId: number;
}

export interface BlabItemActivityConnection {
    edges: BlabItemActivityEdge[];
    pageInfo: PageInfo;
}

export interface BlabItemActivityCountsDto {
    blabItemId: number;
    commentActivityCount: number;
    updateActivityCount: number;
}

export interface BlabItemActivityDeletedDto {
    id: number;
}

export interface BlabItemActivityEdge {
    node: BlabItemActivityUnionType;
    cursor: string;
}

export interface BlabItemActivityReplyAttachmentDto extends FileDto {
    id: number;
    key: string;
    url: string;
    viewUrl: string;
    downloadUrl: string;
    createdAt: DateTime;
    name: string;
    extension?: string;
    mimetype?: string;
    size: number;
    thumbnailId?: number;
    thumbnail?: ThumbnailDto;
}

export interface BlabItemActivityReplyConnection {
    edges: BlabItemActivityReplyEdge[];
    pageInfo: PageInfo;
}

export interface BlabItemActivityReplyDeletedDto {
    id: number;
}

export interface BlabItemActivityReplyDto {
    id: number;
    createdAt: DateTime;
    activityId: number;
    userId?: number;
    user?: UserProfilePreviewDto;
    authorWorkflowDef?: WorkflowDefPreviewDto;
    authorWorkflowDefId?: number;
    content: string;
    lastEditedAt?: DateTime;
    reactions: ReactionDto[];
    attachments?: BlabItemActivityReplyAttachmentDto[];
    linkPreview?: LinkPreviewDto;
    clientSideId?: string;
}

export interface BlabItemActivityReplyEdge {
    node: BlabItemActivityReplyDto;
    cursor: string;
}

export interface BlabItemCalendarEventDto extends CalendarEventDto {
    id: string;
    type: CalendarEventType;
    startDate: DateTime;
    endDate?: DateTime;
    hasTime: boolean;
    title?: string;
    calendarSubscriptionId: number;
    calendarSubscription: ActiveUserCalendarSubscriptionDto;
    blabItemId: number;
    blabDefId: number;
    blabDef: BlabDefPreviewDto;
}

export interface BlabItemCommentActivityDto extends BlabItemActivityDto {
    id: number;
    blabItemId: number;
    blabItem?: BlabItemPreviewDto;
    type: BlabItemActivityType;
    createdAt: DateTime;
    author?: UserProfilePreviewDto;
    authorId?: number;
    authorWorkflowDef?: WorkflowDefPreviewDto;
    authorWorkflowDefId?: number;
    numReplies: number;
    reactions: ReactionDto[];
    comment: BlabItemCommentDto;
    replies: BlabItemActivityReplyEdge[];
    attachments?: BlabItemCommentAttachmentDto[];
    linkPreview?: LinkPreviewDto;
    clientSideId?: string;
    isDeleted: boolean;
    createdVia: BlabItemActivityCreatedViaType;
}

export interface BlabItemCommentAttachmentDto extends FileDto {
    id: number;
    key: string;
    url: string;
    viewUrl: string;
    downloadUrl: string;
    createdAt: DateTime;
    name: string;
    extension?: string;
    mimetype?: string;
    size: number;
    thumbnailId?: number;
    thumbnail?: ThumbnailDto;
}

export interface BlabItemCommentDto {
    activityId: number;
    content: string;
    lastEditedAt?: DateTime;
}

export interface BlabItemCreatedActivityDto extends BlabItemActivityDto {
    id: number;
    blabItemId: number;
    blabItem?: BlabItemPreviewDto;
    type: BlabItemActivityType;
    createdAt: DateTime;
    author?: UserProfilePreviewDto;
    authorId?: number;
    authorWorkflowDef?: WorkflowDefPreviewDto;
    authorWorkflowDefId?: number;
    numReplies: number;
    reactions: ReactionDto[];
    replies: BlabItemActivityReplyEdge[];
    creationType?: BlabItemCreationType;
    createdVia: BlabItemActivityCreatedViaType;
}

export interface BlabItemDeletedActivityDto extends BlabItemActivityDto {
    id: number;
    blabItemId: number;
    blabItem?: BlabItemPreviewDto;
    type: BlabItemActivityType;
    createdAt: DateTime;
    author?: UserProfilePreviewDto;
    authorId?: number;
    authorWorkflowDef?: WorkflowDefPreviewDto;
    authorWorkflowDefId?: number;
    numReplies: number;
    reactions: ReactionDto[];
    replies: BlabItemActivityReplyEdge[];
    createdVia: BlabItemActivityCreatedViaType;
}

export interface BlabItemDetailDto {
    id: number;
    title?: string;
    blabDefId: number;
    blabDef?: BlabDefDto;
    createdAt?: DateTime;
    numFollowers: number;
    numAccess: number;
    flimVals?: FlimValDto[];
    numComments?: number;
    isTemplate?: BlabItemIsTemplateEnum;
    templateTitle?: string;
}

export interface BlabItemEdge {
    node: BlabItemViewDto;
    cursor: string;
}

export interface BlabItemFocusEntryDto extends FocusEntryDto {
    id: number;
    type: FocusEntryType;
    position: string;
    sectionId: number;
    blabItem: BlabItemFocusPreviewDto;
}

export interface BlabItemFocusPreviewDto {
    id: number;
    title?: string;
    blabDefId: number;
    blabDef: BlabDefPreviewDto;
    hasFocusDueAt: boolean;
    requireFocusDueAt: boolean;
    hasFocusDueAtEnd: boolean;
    requireFocusDueAtEnd: boolean;
    focusDueAtTimeSetting?: TimeSetting;
    dueAt?: DateTime;
    dueAtEnd?: DateTime;
    dueAtHasTime?: boolean;
    selectedStatusOptionDefId?: number;
    statusOptionDefs: StatusOptionDefDto[];
    numOpenChecklistEntries: number;
}

export interface BlabItemFollowersConnection {
    edges: BlabItemFollowersEdge[];
    pageInfo: PageInfo;
}

export interface BlabItemFollowersEdge {
    node: UserProfileDto;
    cursor: string;
}

export interface BlabItemInvitationCreatedActivityDto extends BlabItemActivityDto {
    id: number;
    blabItemId: number;
    blabItem?: BlabItemPreviewDto;
    type: BlabItemActivityType;
    createdAt: DateTime;
    author?: UserProfilePreviewDto;
    authorId?: number;
    authorWorkflowDef?: WorkflowDefPreviewDto;
    authorWorkflowDefId?: number;
    numReplies: number;
    reactions: ReactionDto[];
    replies: BlabItemActivityReplyEdge[];
    involvedUsers: UserProfilePreviewDto[];
    targetUser?: UserProfilePreviewDto;
    targetUserId?: number;
    createdVia: BlabItemActivityCreatedViaType;
    invitationText?: string;
}

export interface BlabItemNotificationActivityDto extends NotificationActivityDto {
    notificationId: number;
    direct?: DirectNotificationType;
    activity?: BlabItemActivityUnionType;
    activityReply?: BlabItemActivityReplyDto;
}

export interface BlabItemNotificationDto {
    id: number;
    type: NotificationType;
    read: boolean;
    updatedAt: DateTime;
    blabItem: BlabItemPreviewDto;
    blabItemId: number;
    numActivities: number;
    activities: BlabItemNotificationActivityDto[];
    involvedUsers: UserProfilePreviewDto[];
}

export interface BlabItemPreviewDto {
    id: number;
    title?: string;
    blabDefId: number;
    blabDef?: BlabDefPreviewDto;
    activeUserCanView?: boolean;
}

export interface BlabItemReferenceDto {
    blabItemId: number;
    blabItem: BlabItemPreviewDto;
    position: string;
}

export interface BlabItemRestoredActivityDto extends BlabItemActivityDto {
    id: number;
    blabItemId: number;
    blabItem?: BlabItemPreviewDto;
    type: BlabItemActivityType;
    createdAt: DateTime;
    author?: UserProfilePreviewDto;
    authorId?: number;
    authorWorkflowDef?: WorkflowDefPreviewDto;
    authorWorkflowDefId?: number;
    numReplies: number;
    reactions: ReactionDto[];
    replies: BlabItemActivityReplyEdge[];
    createdVia: BlabItemActivityCreatedViaType;
}

export interface BlabItemRevisionActivityDto extends BlabItemActivityDto {
    id: number;
    blabItemId: number;
    blabItem?: BlabItemPreviewDto;
    type: BlabItemActivityType;
    createdAt: DateTime;
    author?: UserProfilePreviewDto;
    authorId?: number;
    authorWorkflowDef?: WorkflowDefPreviewDto;
    authorWorkflowDefId?: number;
    numReplies: number;
    reactions: ReactionDto[];
    replies: BlabItemActivityReplyEdge[];
    revisions: BlabItemRevisionUnionType[];
    createdVia: BlabItemActivityCreatedViaType;
}

export interface BlabItemsConnection {
    edges: BlabItemEdge[];
    pageInfo: PageInfo;
}

export interface BlabItemSearchResultDto extends SearchResultDto {
    type: SearchResultType;
    blabItem: BlabItemPreviewDto;
}

export interface BlabItemShareContextDto extends ShareContextDto {
    type: ShareContextType;
    userSessions: ShareContextUserSessionDto[];
    blabItemId: number;
}

export interface BlabItemStatsResultDto {
    total: number;
    blabDefId: number;
    flimStats: FlimStatResultUnionType[];
}

export interface BlabItemTemplateDto {
    id: number;
    title?: string;
    position: string;
    blabDefId: number;
    isPrivate: boolean;
    isDefault?: boolean;
}

export interface BlabItemViewDto {
    id: number;
    title?: string;
    viewPosition: string;
    blabDefId: number;
    blabDef?: BlabDefDto;
    createdAt?: DateTime;
    flimVals?: FlimValDto[];
    numComments?: number;
    isTemplate?: BlabItemIsTemplateEnum;
    templateTitle?: string;
}

export interface BlabReferenceBlabDefPreviewDto {
    id: number;
    position: string;
    blabName: string;
    itemName: string;
    slug: string;
    icon: BlabDefIcon;
    iconv2?: JSONObject;
    itemIcon?: JSONObject;
    workspace?: WorkspacePreviewDto;
    workspaceId: number;
    blabViews: BlabViewPreviewDto[];
}

export interface BlabReferenceDto {
    blabDef: BlabReferenceBlabDefPreviewDto;
    blabDefId: number;
    blabView?: BlabViewPreviewDto;
    blabViewId?: number;
    position: string;
}

export interface BlabViewBoardPropertyDto {
    blabViewId?: number;
    flimDefId: number;
    position: string;
    shown: boolean;
}

export interface BlabViewBoardSingleCategoryColumnDto extends BlabViewBoardColumnDto {
    blabViewId?: number;
    flimDefId: number;
    flimType: FlimType;
    singleCategoryOptionDefId?: number;
    singleCategoryOptionDef?: SingleCategoryOptionDefDto;
    position: string;
    hidden: boolean;
}

export interface BlabViewBoardStatusColumnDto extends BlabViewBoardColumnDto {
    blabViewId?: number;
    flimDefId: number;
    flimType: FlimType;
    statusOptionDefId: number;
    statusOptionDef: StatusOptionDefDto;
    position: string;
    hidden: boolean;
}

export interface BlabViewColumnDto {
    blabViewId?: number;
    flimDefId: number;
    position: string;
    hidden: boolean;
    width?: number;
}

export interface BlabViewDto {
    id: number;
    blabDefId: number;
    name?: string;
    layout: BlabViewLayout;
    sorting?: BlabViewSortDto;
    flimFilters: FlimFilterDto[];
    flimStatDefs: FlimStatDefUnionType[];
    position: string;
    isDefault: boolean;
    isPublic: boolean;
    showProgress: BlabViewShowProgressType;
    total: number;
    numCompleted?: number;
    numIncomplete?: number;
    splitBy?: SplitByUnionType;
    columns: BlabViewColumnDto[];
    tableRowHeight: TableRowHeight;
    tableWrapCells: boolean;
    listProperties: BlabViewListPropertyDto[];
    boardGroupByFlimDefId?: number;
    boardGroupByFlimDef?: FlimDefUnionType;
    boardProperties: BlabViewBoardPropertyDto[];
    boardColumns: BlabViewBoardColumnUnionType[];
    boardFlimStatDef?: FlimStatDefUnionType;
    boardCardSize: BlabViewBoardCardSizeType;
    boardCardPreviewFitImage: boolean;
    boardCardPreviewFlimDefId?: number;
    boardCardPreviewFlimDef?: FlimDefUnionType;
    boardLargeText: boolean;
    boardHideEmptyColumns: boolean;
    boardColorColumns: boolean;
}

export interface BlabViewListPropertyDto {
    blabViewId?: number;
    flimDefId: number;
    position: string;
    shown: boolean;
}

export interface BlabViewPreviewDto {
    id: number;
    blabDefId: number;
    name?: string;
    layout: BlabViewLayout;
    position: string;
    showProgress: BlabViewShowProgressType;
    total: number;
    numCompleted?: number;
    numIncomplete?: number;
    splitBy?: SplitByUnionType;
    isPublic: boolean;
    isDefault: boolean;
}

export interface BlabViewSortDto {
    blabViewId?: number;
    flimDefId: number;
    direction: SortingDirection;
    sortingProperty?: BlabItemsSortingProperty;
}

export interface BulkFollowBlabItemsResponse {
    jobId?: string;
}

export interface BulkUnfollowBlabItemsResponse {
    jobId?: string;
}

export interface CalculationFlimDefDto extends FlimDefDto {
    id: number;
    blabDefId: number;
    blabDef?: BlabDefPreviewDto;
    name: string;
    slug: string;
    type: FlimType;
    position: string;
    required: boolean;
    showInRecord?: boolean;
    hiddenIfEmpty: boolean;
    alwaysHidden: boolean;
    showHelpText: boolean;
    helpText?: string;
    largeEditor: boolean;
    script: string;
    scriptResultType: ScriptResultType;
    isInvalid: boolean;
    precision: number;
    unitLocation?: NumberFlimDefUnitLocation;
    unitLabel?: string;
    hideThousandSeparator: boolean;
    timeSetting?: TimeSetting;
    showInCalendar: boolean;
}

export interface CalculationFlimFilterDto extends FlimFilterDto {
    id: number;
    type: FlimType;
    blabViewId?: number;
    flimDefId: number;
    position: string;
    derivedFromSplitBy?: boolean;
    searchText?: string;
    decimal?: number;
    calculationMatchType: CalculationFilterMatchType;
    calculationRelativeDate?: CalculationRelativeDateType;
    calculationRelativeDateRange?: CalculationRelativeDateRangeType;
    numDays?: number;
    numWeeks?: number;
    numMonths?: number;
    exactDate?: DateTime;
    exactEndDate?: DateTime;
    weekday?: Weekday;
    quarterOfYear?: QuarterOfYear;
    monthOfYear?: MonthOfYear;
}

export interface CalculationFlimStatDefDto extends FlimStatDefDto {
    id: number;
    type: FlimType;
    blabViewId?: number;
    flimDefId: number;
    calculationFlimStatType: CalculationFlimStatType;
}

export interface CalculationFlimStatResultDto extends FlimStatResultDto {
    calculationFlimStatType: CalculationFlimStatType;
    flimDefId: number;
    result?: number;
    flimType: FlimType;
}

export interface CalculationFlimValDto extends FlimValDto {
    type: FlimType;
    blabItemId: number;
    flimDefId: number;
    isInvalid?: boolean;
    decimal?: number;
    value?: string;
    unformatted?: string;
    date?: DateTime;
    hasTime?: boolean;
}

export interface CalculationScriptMultiTextResultPreviewDto {
    resultType: ScriptResultType;
    value: string;
    unformatted: string;
}

export interface CalculationScriptNumberResultPreviewDto {
    resultType: ScriptResultType;
    decimal: number;
}

export interface CalculationScriptSingleDateResultPreviewDto {
    resultType: ScriptResultType;
    date: DateTime;
    hasTime: boolean;
}

export interface CalculationSplitByDto {
    blabViewId: number;
    type: FlimType;
    calculationFlimDefId: number;
    limit: number;
    sorting: BlabViewSplitBySorting;
    period: BlabViewSplitByPeriod;
    sections: CalculationSplitBySectionDto[];
}

export interface CalculationSplitBySectionDto extends SplitBySectionDto {
    id: string;
    type: FlimType;
    flimDefId: number;
    total: number;
    numCompleted?: number;
    numIncomplete?: number;
    startDate?: DateTime;
    endDate?: DateTime;
    weekday?: Weekday;
}

export interface CalendarEventsDto {
    from: DateTime;
    to: DateTime;
    events: CalendarEventUnionType[];
}

export interface CancelWorkflowRunsResult {
    numCancelledRuns: number;
}

export interface CardPaymentMethodDto extends PaymentMethodDto {
    id: string;
    type: PaymentMethodType;
    createdAt: DateTime;
    createdByUser?: UserProfilePreviewDto;
    createdByUserId?: number;
    isDefault: boolean;
    error?: PaymentMethodErrorType;
    lastFour: string;
    name: string;
    postalCode?: string;
}

export interface CategoryFlimDefRevisionPreviewDto {
    id: number;
    name: string;
    type: FlimType;
    blabDefId: number;
    categoryOptionDefs: CategoryOptionDefRevisionPreviewDto[];
}

export interface CategoryOptionDefRevisionPreviewDto {
    id: number;
    label: string;
}

export interface ChangeOrganizationRoleRejectedLastOwner {
    void?: Void;
}

export interface ChangeUserEmailVerificationSessionDto {
    id: string;
}

export interface ChangeUserPasswordResultDto {
    result: ChangeUserPasswordResultType;
}

export interface ChangeWorkspaceRoleRejectedLastAdmin {
    void?: Void;
}

export interface ChatActivityClientApplicationBrowserNotificationDto {
    title: string;
    body: string;
    imageUrl?: string;
    createdAt: DateTime;
    conversationId: number;
}

export interface ChatActivityDeletedDto {
    id: number;
}

export interface ChatActivityDtoConnection {
    edges: ChatActivityDtoEdge[];
    pageInfo: PageInfo;
}

export interface ChatActivityDtoEdge {
    node: ChatActivityDto;
    cursor: string;
}

export interface ChatDialogDto extends ConversationDto {
    type: ConversationType;
    conversationId: number;
    lastChatActivity: ChatActivityDto;
    user: UserProfileDto;
    userReadUntil?: DateTime;
    mutedByUser: boolean;
    numUnreadActivities: number;
}

export interface ChatGroupDto extends ConversationDto {
    type: ConversationType;
    conversationId: number;
    lastChatActivity: ChatActivityDto;
    name: string;
    thumbnail?: ThumbnailDto;
    userReadUntil?: DateTime;
    mutedByUser: boolean;
    numUnreadActivities: number;
}

export interface ChatGroupJoinActivityDto extends ChatActivityDto {
    id: number;
    type: ChatActivityType;
    createdAt: DateTime;
    user?: UserProfilePreviewDto;
    userId?: number;
    targetUser?: UserProfilePreviewDto;
    targetUserId?: number;
    conversationId: number;
    reactions: ReactionDto[];
}

export interface ChatGroupLeaveActivityDto extends ChatActivityDto {
    id: number;
    type: ChatActivityType;
    createdAt: DateTime;
    user?: UserProfilePreviewDto;
    userId?: number;
    conversationId: number;
    reactions: ReactionDto[];
}

export interface ChatGroupNameChangeActivityDto extends ChatActivityDto {
    id: number;
    type: ChatActivityType;
    createdAt: DateTime;
    user?: UserProfilePreviewDto;
    userId?: number;
    content: string;
    conversationId: number;
    reactions: ReactionDto[];
}

export interface ChatGroupSearchResultDto extends SearchResultDto {
    type: SearchResultType;
    group: ChatGroupDto;
}

export interface ChatGroupThumbnailChangeActivityDto extends ChatActivityDto {
    id: number;
    type: ChatActivityType;
    createdAt: DateTime;
    user?: UserProfilePreviewDto;
    userId?: number;
    conversationId: number;
    reactions: ReactionDto[];
}

export interface ChatGroupUserDtoEdge {
    node: UserProfilePreviewDto;
    cursor: string;
}

export interface ChatGroupUsersConnection {
    edges: ChatGroupUserDtoEdge[];
    pageInfo: PageInfo;
}

export interface ChatMessageActivityDto extends ChatActivityDto {
    id: number;
    type: ChatActivityType;
    createdAt: DateTime;
    user?: UserProfilePreviewDto;
    userId?: number;
    conversationId: number;
    reactions: ReactionDto[];
    content: string;
    unformatted: string;
    clientSideId?: string;
    attachments?: ChatMessageAttachmentDto[];
    linkPreview?: LinkPreviewDto;
}

export interface ChatMessageAttachmentDto extends FileDto {
    id: number;
    key: string;
    url: string;
    viewUrl: string;
    downloadUrl: string;
    createdAt: DateTime;
    name: string;
    extension?: string;
    mimetype?: string;
    size: number;
    thumbnailId?: number;
    thumbnail?: ThumbnailDto;
}

export interface ChatMessageSearchResultDto extends SearchResultDto {
    type: SearchResultType;
    message: ChatMessageActivityDto;
    cursor: string;
}

export interface ChatVideoCallActivityDto extends ChatActivityDto {
    id: number;
    type: ChatActivityType;
    createdAt: DateTime;
    user?: UserProfilePreviewDto;
    userId?: number;
    conversationId: number;
    reactions: ReactionDto[];
    videoCall: VideoCallDto;
}

export interface CheckEmailResultDto {
    result: EmailResultType;
}

export interface CheckGqlHealthDto {
    void?: Void;
}

export interface ChecklistEntryCalendarEventDto extends CalendarEventDto {
    id: string;
    type: CalendarEventType;
    startDate: DateTime;
    endDate?: DateTime;
    hasTime: boolean;
    title?: string;
    calendarSubscriptionId: number;
    calendarSubscription: ActiveUserCalendarSubscriptionDto;
    completed: boolean;
    checklistFlimValEntryId: number;
    blabItemId: number;
}

export interface ChecklistFlimDefDto extends FlimDefDto {
    id: number;
    blabDefId: number;
    blabDef?: BlabDefPreviewDto;
    name: string;
    slug: string;
    type: FlimType;
    position: string;
    required: boolean;
    hiddenIfEmpty: boolean;
    alwaysHidden: boolean;
    showHelpText: boolean;
    helpText?: string;
    showCompletedDefault: boolean;
    showInRecord?: boolean;
    showInFocus: boolean;
}

export interface ChecklistFlimFilterDto extends FlimFilterDto {
    id: number;
    type: FlimType;
    blabViewId?: number;
    flimDefId: number;
    position: string;
    derivedFromSplitBy?: boolean;
    checklistMatchType: ChecklistFilterMatchType;
    checklistEntryProperty?: ChecklistFilterEntryProperty;
    searchText?: string;
    completed?: boolean;
    checklistDueDateRelativeDateType?: ChecklistRelativeDateType;
    checklistDueDateRelativeDateRangeType?: ChecklistRelativeDateRangeType;
    date?: DateTime;
    numDays?: number;
    numWeeks?: number;
    numMonths?: number;
    referencedUserIds: number[];
    referencedUsers: UserProfilePreviewDto[];
    includeActiveUser: boolean;
    includeActiveUserIndex?: number;
}

export interface ChecklistFlimStatDefDto extends FlimStatDefDto {
    id: number;
    type: FlimType;
    blabViewId?: number;
    flimDefId: number;
    checklistFlimStatType: ChecklistFlimStatType;
}

export interface ChecklistFlimStatResultDto extends FlimStatResultDto {
    checklistFlimStatType: ChecklistFlimStatType;
    flimDefId: number;
    result?: number;
    flimType: FlimType;
}

export interface ChecklistFlimValDto extends FlimValDto {
    type: FlimType;
    blabItemId: number;
    flimDefId: number;
    checklistEntries?: ChecklistFlimValEntryDto[];
}

export interface ChecklistFlimValEntryDto {
    id: number;
    blabItemId: number;
    flimDefId: number;
    position: string;
    completed: boolean;
    title?: string;
    description?: string;
    dueAt?: DateTime;
    dueAtHasTime?: boolean;
    assigneeUserId?: number;
    assigneeUser?: UserProfilePreviewDto;
}

export interface ChecklistFlimValEntryFocusPreviewDto {
    id: number;
    blabItem: BlabItemPreviewDto;
    blabItemId: number;
    flimDefId: number;
    position: string;
    completed: boolean;
    title?: string;
    description?: string;
    dueAt?: DateTime;
    dueAtHasTime?: boolean;
    assigneeUserId?: number;
}

export interface ChecklistFlimValRevisionDto extends FlimValRevisionDto {
    id: number;
    type: BlabItemRevisionType;
    flimType: FlimType;
    createdAt: DateTime;
    blabItemId: number;
    flimDefId: number;
    flimDef: FlimDefRevisionPreviewDto;
    prevChecklistEntryId?: number;
    prevChecklistEntry?: ChecklistFlimValEntryDto;
    checklistEntryId?: number;
    checklistEntry?: ChecklistFlimValEntryDto;
    prevCompleted?: boolean;
    completed?: boolean;
    prevAssigneeUserId?: number;
    prevAssigneeUser?: UserProfilePreviewDto;
    assigneeUserId?: number;
    assigneeUser?: UserProfilePreviewDto;
}

export interface ChecklistFocusEntryDto extends FocusEntryDto {
    id: number;
    type: FocusEntryType;
    position: string;
    sectionId: number;
    blabDefHasMultipleChecklistFlimDefs: boolean;
    flimDefName: string;
    checklistEntry: ChecklistFlimValEntryFocusPreviewDto;
}

export interface ChronologicalNewsfeedConnection {
    edges: NewsfeedActivityEdge[];
    pageInfo: PageInfo;
}

export interface ConversationCreateActivityDto extends ChatActivityDto {
    id: number;
    type: ChatActivityType;
    createdAt: DateTime;
    user?: UserProfilePreviewDto;
    userId?: number;
    conversationId: number;
    reactions: ReactionDto[];
}

export interface ConversationDtoConnection {
    edges: ConversationDtoEdge[];
    pageInfo: PageInfo;
}

export interface ConversationDtoEdge {
    node: ConversationDto;
    cursor: string;
}

export interface ConvertMultiAttachmentFlimToSingleAttachmentFlimResultDto extends ConvertFlimResultDto {
    flimDefId: number;
    flimDef: SingleAttachmentFlimDefDto;
}

export interface ConvertMultiCategoryFlimToSingleCategoryFlimResultDto extends ConvertFlimResultDto {
    flimDefId: number;
    flimDef: SingleCategoryFlimDefDto;
}

export interface ConvertMultiRelationFlimToSingleRelationFlimResultDto extends ConvertFlimResultDto {
    flimDefId: number;
    flimDef: SingleRelationFlimDefDto;
}

export interface ConvertMultiTextFlimToSingleTextFlimResultDto extends ConvertFlimResultDto {
    flimDefId: number;
    flimDef: SingleTextFlimDefDto;
}

export interface ConvertMultiUserFlimToSingleUserFlimResultDto extends ConvertFlimResultDto {
    flimDefId: number;
    flimDef: SingleUserFlimDefDto;
}

export interface ConvertRangeDateFlimToSingleDateFlimResultDto extends ConvertFlimResultDto {
    flimDefId: number;
    flimDef: SingleDateFlimDefDto;
}

export interface ConvertSingleAttachmentFlimToMultiAttachmentFlimResultDto extends ConvertFlimResultDto {
    flimDefId: number;
    flimDef: MultiAttachmentFlimDefDto;
}

export interface ConvertSingleCategoryFlimToMultiCategoryFlimResultDto extends ConvertFlimResultDto {
    flimDefId: number;
    flimDef: MultiCategoryFlimDefDto;
}

export interface ConvertSingleCategoryFlimToSingleTextFlimResultDto extends ConvertFlimResultDto {
    flimDefId: number;
    flimDef: SingleTextFlimDefDto;
}

export interface ConvertSingleCategoryFlimToStatusFlimResultDto extends ConvertFlimResultDto {
    flimDefId: number;
    flimDef: StatusFlimDefDto;
}

export interface ConvertSingleDateFlimToRangeDateFlimResultDto extends ConvertFlimResultDto {
    flimDefId: number;
    flimDef: RangeDateFlimDefDto;
}

export interface ConvertSingleRelationFlimToMultiRelationFlimResultDto extends ConvertFlimResultDto {
    flimDefId: number;
    flimDef: MultiRelationFlimDefDto;
}

export interface ConvertSingleTextFlimToMultiLinkFlimResultDto extends ConvertFlimResultDto {
    flimDefId: number;
    flimDef: MultiLinkFlimDefDto;
}

export interface ConvertSingleTextFlimToMultiTextFlimResultDto extends ConvertFlimResultDto {
    flimDefId: number;
    flimDef: MultiTextFlimDefDto;
}

export interface ConvertSingleTextFlimToNumberFlimResultDto extends ConvertFlimResultDto {
    flimDefId: number;
    flimDef: NumberFlimDefDto;
}

export interface ConvertSingleTextFlimToSingleCategoryFlimResultDto extends ConvertFlimResultDto {
    flimDefId: number;
    flimDef: SingleCategoryFlimDefDto;
}

export interface ConvertSingleUserFlimToMultiUserFlimResultDto extends ConvertFlimResultDto {
    flimDefId: number;
    flimDef: MultiUserFlimDefDto;
}

export interface CopyOrganizationInvitationLinkResultDto {
    link: string;
}

export interface CreatedAtFlimDefDto extends FlimDefDto {
    id: number;
    blabDefId: number;
    blabDef?: BlabDefPreviewDto;
    name: string;
    slug: string;
    type: FlimType;
    position: string;
    required: boolean;
    showInRecord?: boolean;
    hiddenIfEmpty: boolean;
    alwaysHidden: boolean;
    showHelpText: boolean;
    helpText?: string;
    showTime: boolean;
}

export interface CreatedAtFlimFilterDto extends FlimFilterDto {
    id: number;
    type: FlimType;
    blabViewId?: number;
    flimDefId: number;
    position: string;
    derivedFromSplitBy?: boolean;
    createdAtMatchType: CreatedAtFilterMatchType;
    exactDate?: DateTime;
    exactEndDate?: DateTime;
    createdAtRelativeDateRange?: CreatedAtRelativeDateRangeType;
    createdAtRelativeDate?: CreatedAtRelativeDateType;
    numDays?: number;
    numWeeks?: number;
    numMonths?: number;
    weekday?: Weekday;
    quarterOfYear?: QuarterOfYear;
    monthOfYear?: MonthOfYear;
}

export interface CreatedAtFlimStatDefDto extends FlimStatDefDto {
    id: number;
    type: FlimType;
    blabViewId?: number;
    flimDefId: number;
    createdAtFlimStatType: CreatedAtFlimStatType;
}

export interface CreatedAtFlimStatResultDto extends FlimStatResultDto {
    createdAtFlimStatType: CreatedAtFlimStatType;
    flimDefId: number;
    result?: number;
    flimType: FlimType;
}

export interface CreatedAtFlimValDto extends FlimValDto {
    type: FlimType;
    blabItemId: number;
    flimDefId: number;
    blabItemCreateDate?: DateTime;
}

export interface CreatedAtSplitByDto {
    blabViewId: number;
    type: FlimType;
    createdAtFlimDefId: number;
    limit: number;
    sorting: BlabViewSplitBySorting;
    period: BlabViewSplitByPeriod;
    sections: CreatedAtSplitBySectionDto[];
}

export interface CreatedAtSplitBySectionDto extends SplitBySectionDto {
    id: string;
    type: FlimType;
    flimDefId: number;
    total: number;
    numCompleted?: number;
    numIncomplete?: number;
    startDate?: DateTime;
    endDate?: DateTime;
    weekday?: Weekday;
}

export interface CreatedByFlimDefDto extends FlimDefDto {
    id: number;
    blabDefId: number;
    blabDef?: BlabDefPreviewDto;
    name: string;
    slug: string;
    type: FlimType;
    position: string;
    required: boolean;
    showInRecord?: boolean;
    hiddenIfEmpty: boolean;
    alwaysHidden: boolean;
    showHelpText: boolean;
    helpText?: string;
}

export interface CreatedByFlimFilterDto extends FlimFilterDto {
    id: number;
    type: FlimType;
    blabViewId?: number;
    flimDefId: number;
    position: string;
    derivedFromSplitBy?: boolean;
    referencedUsers: UserProfilePreviewDto[];
    createdByMatchType: CreatedByFilterMatchType;
    includeActiveUser: boolean;
    includeActiveUserIndex?: number;
}

export interface CreatedByFlimStatDefDto extends FlimStatDefDto {
    id: number;
    type: FlimType;
    blabViewId?: number;
    flimDefId: number;
    createdByFlimStatType: CreatedByFlimStatType;
}

export interface CreatedByFlimStatResultDto extends FlimStatResultDto {
    createdByFlimStatType: CreatedByFlimStatType;
    flimDefId: number;
    result?: number;
    flimType: FlimType;
}

export interface CreatedByFlimValDto extends FlimValDto {
    type: FlimType;
    blabItemId: number;
    flimDefId: number;
    createdByUser?: UserProfilePreviewDto;
    createdByUserId?: number;
    createdByWorkflowDef?: WorkflowDefPreviewDto;
    createdByWorkflowDefId?: number;
    createdVia: BlabItemCreatedViaType;
}

export interface CreditInvoiceItemDto extends InvoiceItemDto {
    id: string;
    type: InvoiceItemType;
    description?: string;
    amount: number;
    currency: BillingCurrency;
}

export interface DeactivateOrganizationUserRejectedLastOwner {
    void?: Void;
}

export interface DeleteBlabItemsInfo {
    numDeleted: number;
}

export interface DeleteOrganizationWorkspaceResultDto {
    jobId: number;
    workspace: OrganizationWorkspaceDto;
}

export interface DiscountInvoiceItemDto extends InvoiceItemDto {
    id: string;
    type: InvoiceItemType;
    name?: string;
    percentOff?: number;
    amountOff?: number;
    amountOffCurrency?: BillingCurrency;
}

export interface DuplicateBlabDefResultDto {
    jobId: string;
    blabDef: BlabDefDto;
}

export interface DuplicateSharedWorkspaceResultDto {
    workspace?: WorkspaceDto;
    rejectionReason?: DuplicateSharedWorkspaceResultRejectionReason;
}

export interface EmptyUserTrashResultDto {
    numRemoved: number;
}

export interface ExportBlabDefDataResultDto {
    dataExportId: number;
}

export interface ExportGlobalWorkflowRunsResultDto {
    numRuns: number;
    size?: number;
    filename?: string;
    downloadUrl?: string;
}

export interface ExportWorkflowRunsOfWorkflowDefResultDto {
    numRuns: number;
    size?: number;
    filename?: string;
    downloadUrl?: string;
}

export interface FindMyOrgUserHasPendingInvitationDto {
    acceptInvitationToken: string;
}

export interface FlimDefDeveloperInfoDto {
    flimDefId: number;
    blabDefId: number;
    position: string;
    name: string;
    slug: string;
    type: FlimType;
}

export interface FlimDefRevisionPreviewDto {
    id: number;
    name: string;
    type: FlimType;
    blabDefId: number;
    blabDef: BlabDefPreviewDto;
}

export interface FlimValAttachmentDto extends FileDto {
    id: number;
    key: string;
    url: string;
    viewUrl: string;
    downloadUrl: string;
    createdAt: DateTime;
    name: string;
    extension?: string;
    mimetype?: string;
    size: number;
    thumbnailId?: number;
    thumbnail?: ThumbnailDto;
    author?: UserProfileDto;
    authorId?: number;
    authorWorkflowDef?: WorkflowDefPreviewDto;
    authorWorkflowDefId?: number;
    flimDefId: number;
    createdVia: FlimValAttachmentCreatedViaType;
}

export interface FlimValImageDto extends FileDto {
    id: number;
    key: string;
    url: string;
    viewUrl: string;
    downloadUrl: string;
    createdAt: DateTime;
    name: string;
    extension?: string;
    mimetype?: string;
    size: number;
    thumbnailId?: number;
    thumbnail?: ThumbnailDto;
    author?: UserProfileDto;
    authorWorkflowDef?: WorkflowDefPreviewDto;
    authorWorkflowDefId?: number;
    authorId?: number;
    flimDefId: number;
    createdVia: FlimValImageCreatedViaType;
}

export interface FocusDueDateSectionDto {
    type: FocusDueDateSectionType;
    expanded: boolean;
    total: number;
}

export interface FocusDueDateSectionsDto {
    dueDate: FocusDueDateSectionDto;
    noDueDate: FocusDueDateSectionDto;
}

export interface FocusEntryAddedSubscriptionEvent {
    focusEntry: FocusEntryUnionType;
}

export interface FocusEntryConnection {
    pageInfo: PageInfo;
    edges: FocusEntryEdge[];
}

export interface FocusEntryEdge {
    node: FocusEntryUnionType;
    cursor: string;
}

export interface FocusEntryRemovedSubscriptionEvent {
    id: number;
}

export interface FocusEntryUpdatedSubscriptionEvent {
    focusEntry: FocusEntryUnionType;
}

export interface FocusSectionDto {
    id: number;
    name?: string;
    position: string;
    expanded: boolean;
    isDefault: boolean;
    initialFocusSectionType?: InitialFocusSectionType;
}

export interface FocusSectionPreviewDto {
    id: number;
    name?: string;
    position: string;
    initialFocusSectionType?: InitialFocusSectionType;
    isDefault: boolean;
}

export interface FocusSectionWithTotalDto {
    id: number;
    name?: string;
    position: string;
    expanded: boolean;
    isDefault: boolean;
    total: number;
    initialFocusSectionType?: InitialFocusSectionType;
}

export interface FocusSettingsDto {
    sortWithinSections: boolean;
    sortType: FocusSortingType;
    completedFilterType: FocusCompletedFilteringType;
    excludeReminders: boolean;
    excludeChecklistEntries: boolean;
    excludeBlabItems: boolean;
}

export interface GenerateWorkflowDefCodeResultDto {
    typeScript: string;
    javaScript: string;
    vars: JSONObject;
}

export interface GetActiveUserExploreSuggestionsForCategoryConnection {
    edges: ActiveUserExploreSuggestionEdge[];
    pageInfo: PageInfo;
}

export interface GetBlabDefWithIncomingAndOutgoingBlabDefsResultDto {
    blabDef: BlabDefDto;
    incomingBlabDefs: BlabDefDto[];
    outgoingBlabDefs: BlabDefDto[];
}

export interface GetBlabItemFocusSectionResultDto {
    focusSectionId?: number;
}

export interface GetCalculationScriptResultPreviewRejectionDto {
    reason: CalculationScriptResultRejectionReason;
    errorMsg?: string;
}

export interface GetChecklistFlimValEntryFocusSectionResultDto {
    focusSectionId?: number;
}

export interface GetLocationAutocompleteSuggestionsRejectionDto {
    message?: string;
}

export interface GetLocationDetailsRejectionDto {
    message?: string;
}

export interface GetNumRemindersResultDto {
    numReminders: number;
}

export interface GetReminderFocusSectionResultDto {
    focusSectionId: number;
}

export interface GetUserInfoByOrganizationInvitationTokenRejectedAlreadyAccepted {
    findMyOrganizationsToken: string;
}

export interface GlobalWorkflowRunDetailDto {
    id: number;
    workflowDefId: number;
    workflowDefRevisionId: number;
    workflowDefRevision: WorkflowDefRevisionDto;
    workflowDef: WorkflowDefPreviewDto;
    blabDefId: number;
    blabDef: BlabDefPreviewDto;
    triggeredOnBlabItemId?: number;
    type: WorkflowRunType;
    status: WorkflowRunStatusType;
    failureReason?: WorkflowRunFailureReason;
    errorMsg?: string;
    numActionsUsed: number;
    createdAt: DateTime;
    completedAt?: DateTime;
    previewForActionId?: string;
    logs: WorkflowRunLogEventUnion[];
}

export interface GlobalWorkflowRunPreviewDto {
    id: number;
    workflowDefId: number;
    workflowDef: WorkflowDefPreviewDto;
    workflowDefRevisionId: number;
    blabDefId: number;
    blabDef: BlabDefPreviewDto;
    type: WorkflowRunType;
    status: WorkflowRunStatusType;
    failureReason?: WorkflowRunFailureReason;
    numActionsUsed: number;
    createdAt: DateTime;
    completedAt?: DateTime;
    previewForActionId?: string;
}

export interface GlobalWorkflowRunPreviewEdge {
    node: GlobalWorkflowRunPreviewDto;
    cursor: string;
}

export interface GlobalWorkflowRunsConnection {
    edges: GlobalWorkflowRunPreviewEdge[];
    pageInfo: PageInfo;
}

export interface GlobalWorkflowRunsSubscriptionUpdateDto {
    workflowRunId: number;
    status: WorkflowRunStatusType;
}

export interface ImportBlabDefDataToExistingBlabDefResultDto {
    jobId: number;
}

export interface ImportBlabDefDataToNewBlabDefResultDto {
    jobId: number;
    blabDef: BlabDefPreviewDto;
}

export interface IncomingBlabItemEdge {
    node: BlabItemPreviewDto;
    cursor: string;
}

export interface IncomingBlabItemsConnection {
    edges: IncomingBlabItemEdge[];
    pageInfo: PageInfo;
}

export interface IncomingRelationFlimDefPreviewDto {
    referencingFlimDef: FlimDefDto;
    numReferencingBlabItems: number;
    referencingBlabItems: ReferencingBlabItemsConnection;
}

export interface IncomingRelationRevisionDto {
    id: number;
    type: BlabItemRevisionType;
    createdAt: DateTime;
    referencingBlabItemId?: number;
    referencingBlabItem?: BlabItemPreviewDto;
    referencingFlimDefId: number;
    referencingFlimDef: FlimDefRevisionPreviewDto;
    isBeingReferenced: boolean;
}

export interface IncompleteBillingSubscriptionDto extends BillingSubscriptionDto {
    active: boolean;
    type: BillingSubscriptionType;
    plan: OrganizationPlan;
    billingPeriod: BillingPeriod;
    billingCycleAnchor: DateTime;
    currentPeriodStart: DateTime;
    currentPeriodEnd: DateTime;
    createdAt: DateTime;
    cancelAtPeriodEnd: boolean;
    requiresAction?: BillingSubscriptionAction;
    requiresPaymentMethod?: boolean;
    upcomingInvoice?: SubscriptionInvoicePreviewDto;
    quantity: number;
}

export interface JoinAudioRoomResultDto {
    audioRoom?: AudioRoomDto;
    roomId: number;
    transportOptions: JoinAudioRoomTransportOptions;
}

export interface JoinAudioRoomTransportOptions {
    roomId: number;
    receiveTransportOptions: JSONObject;
    sendTransportOptions: JSONObject;
    routerRtpCapabilities: JSONObject;
}

export interface LastModifiedAtFlimDefDto extends FlimDefDto {
    id: number;
    blabDefId: number;
    blabDef?: BlabDefPreviewDto;
    name: string;
    slug: string;
    type: FlimType;
    position: string;
    required: boolean;
    showInRecord?: boolean;
    hiddenIfEmpty: boolean;
    alwaysHidden: boolean;
    showHelpText: boolean;
    helpText?: string;
    showTime: boolean;
}

export interface LastModifiedAtFlimFilterDto extends FlimFilterDto {
    id: number;
    type: FlimType;
    blabViewId?: number;
    flimDefId: number;
    position: string;
    derivedFromSplitBy?: boolean;
    lastModifiedAtMatchType: LastModifiedAtFilterMatchType;
    exactDate?: DateTime;
    exactEndDate?: DateTime;
    lastModifiedAtRelativeDateRange?: LastModifiedAtRelativeDateRangeType;
    lastModifiedAtRelativeDate?: LastModifiedAtRelativeDateType;
    numDays?: number;
    numWeeks?: number;
    numMonths?: number;
    weekday?: Weekday;
    quarterOfYear?: QuarterOfYear;
    monthOfYear?: MonthOfYear;
}

export interface LastModifiedAtFlimStatDefDto extends FlimStatDefDto {
    id: number;
    type: FlimType;
    blabViewId?: number;
    flimDefId: number;
    lastModifiedAtFlimStatType: LastModifiedAtFlimStatType;
}

export interface LastModifiedAtFlimStatResultDto extends FlimStatResultDto {
    lastModifiedAtFlimStatType: LastModifiedAtFlimStatType;
    flimDefId: number;
    result?: number;
    flimType: FlimType;
}

export interface LastModifiedAtFlimValDto extends FlimValDto {
    type: FlimType;
    blabItemId: number;
    flimDefId: number;
    lastModifiedAt?: DateTime;
}

export interface LastModifiedAtSplitByDto {
    blabViewId: number;
    type: FlimType;
    lastModifiedAtFlimDefId: number;
    limit: number;
    sorting: BlabViewSplitBySorting;
    period: BlabViewSplitByPeriod;
    sections: LastModifiedAtSplitBySectionDto[];
}

export interface LastModifiedAtSplitBySectionDto extends SplitBySectionDto {
    id: string;
    type: FlimType;
    flimDefId: number;
    total: number;
    numCompleted?: number;
    numIncomplete?: number;
    startDate?: DateTime;
    endDate?: DateTime;
    weekday?: Weekday;
}

export interface LastModifiedByFlimDefDto extends FlimDefDto {
    id: number;
    blabDefId: number;
    blabDef?: BlabDefPreviewDto;
    name: string;
    slug: string;
    type: FlimType;
    position: string;
    required: boolean;
    showInRecord?: boolean;
    hiddenIfEmpty: boolean;
    alwaysHidden: boolean;
    showHelpText: boolean;
    helpText?: string;
}

export interface LastModifiedByFlimFilterDto extends FlimFilterDto {
    id: number;
    type: FlimType;
    blabViewId?: number;
    flimDefId: number;
    position: string;
    derivedFromSplitBy?: boolean;
    referencedUsers: UserProfilePreviewDto[];
    lastModifiedByMatchType: LastModifiedByFilterMatchType;
    includeActiveUser: boolean;
    includeActiveUserIndex?: number;
}

export interface LastModifiedByFlimStatDefDto extends FlimStatDefDto {
    id: number;
    type: FlimType;
    blabViewId?: number;
    flimDefId: number;
    lastModifiedByFlimStatType: LastModifiedByFlimStatType;
}

export interface LastModifiedByFlimStatResultDto extends FlimStatResultDto {
    lastModifiedByFlimStatType: LastModifiedByFlimStatType;
    flimDefId: number;
    result?: number;
    flimType: FlimType;
}

export interface LastModifiedByFlimValDto extends FlimValDto {
    type: FlimType;
    blabItemId: number;
    flimDefId: number;
    lastModifiedByUser?: UserProfilePreviewDto;
    lastModifiedByUserId?: number;
    lastModifiedByWorkflowDef?: WorkflowDefPreviewDto;
    lastModifiedByWorkflowDefId?: number;
    lastModifiedVia?: BlabItemLastModifiedViaType;
}

export interface LicenseInvoiceItemDto extends InvoiceItemDto {
    id: string;
    type: InvoiceItemType;
    description?: string;
    amount: number;
    currency: BillingCurrency;
    unitAmount: number;
    quantity: number;
}

export interface LinkPreviewDto {
    id: number;
    url: string;
    title?: string;
    description?: string;
    previewImage?: ThumbnailDto;
}

export interface LocationAutocompleteSuggestionDto {
    locationId: string;
    description: string;
    mainText?: string;
    secondaryText?: string;
}

export interface LocationAutocompleteSuggestionsDto {
    suggestions: LocationAutocompleteSuggestionDto[];
}

export interface LocationDetailsDto {
    address?: string;
    city?: string;
    country?: string;
    state?: string;
    postalCode?: string;
    streetAddress?: string;
    locationId: string;
    embedUrl: string;
    latitude?: number;
    longitude?: number;
}

export interface ManualRunsCompletedDto {
    workflowDefId: number;
    manualRunUuId: string;
}

export interface ManualRunsTriggeredDto {
    manualRunsTriggered: boolean;
}

export interface MarkAllNotificationsAsReadResultDto {
    numAffected: number;
}

export interface MicrosoftUserAuthenticationRequiredDto {
    authenticationUrl: string;
}

export interface MoveAllFocusSectionEntriesResultDto {
    numEntriesMoved: number;
}

export interface MultiAttachmentFlimDefDto extends FlimDefDto {
    id: number;
    blabDefId: number;
    blabDef?: BlabDefPreviewDto;
    name: string;
    slug: string;
    type: FlimType;
    position: string;
    required: boolean;
    hiddenIfEmpty: boolean;
    alwaysHidden: boolean;
    showHelpText: boolean;
    helpText?: string;
    showInRecord?: boolean;
}

export interface MultiAttachmentFlimFilterDto extends FlimFilterDto {
    id: number;
    type: FlimType;
    blabViewId?: number;
    flimDefId: number;
    position: string;
    derivedFromSplitBy?: boolean;
    searchText?: string;
    multiAttachmentMatchType: MultiAttachmentFilterMatchType;
}

export interface MultiAttachmentFlimStatDefDto extends FlimStatDefDto {
    id: number;
    type: FlimType;
    blabViewId?: number;
    flimDefId: number;
    multiAttachmentFlimStatType: MultiAttachmentFlimStatType;
}

export interface MultiAttachmentFlimStatResultDto extends FlimStatResultDto {
    multiAttachmentFlimStatType: MultiAttachmentFlimStatType;
    flimDefId: number;
    result?: number;
    flimType: FlimType;
}

export interface MultiAttachmentFlimValDto extends FlimValDto {
    type: FlimType;
    blabItemId: number;
    flimDefId: number;
    attachmentReferences?: MultiAttachmentFlimValFileReferenceDto[];
}

export interface MultiAttachmentFlimValFileReferenceDto {
    blabItemId: number;
    flimDefId: number;
    attachmentId: number;
    attachment: FlimValAttachmentDto;
    position: string;
    cardThumbnailPositionX?: number;
    cardThumbnailPositionY?: number;
}

export interface MultiAttachmentFlimValRevisionDto extends FlimValRevisionDto {
    id: number;
    type: BlabItemRevisionType;
    flimType: FlimType;
    createdAt: DateTime;
    blabItemId: number;
    flimDefId: number;
    flimDef: FlimDefRevisionPreviewDto;
    prevAttachmentIds?: number[];
    prevAttachments?: FlimValAttachmentDto[];
    attachmentIds?: number[];
    attachments?: FlimValAttachmentDto[];
}

export interface MultiCategoryFlimDefDto extends FlimDefDto, CategoryFlimDefDto {
    id: number;
    blabDefId: number;
    blabDef?: BlabDefPreviewDto;
    name: string;
    slug: string;
    type: FlimType;
    position: string;
    required: boolean;
    hiddenIfEmpty: boolean;
    alwaysHidden: boolean;
    showHelpText: boolean;
    helpText?: string;
    layout: CategoryFlimLayout;
    categoryOptionDefs: MultiCategoryOptionDefDto[];
    showInRecord?: boolean;
}

export interface MultiCategoryFlimFilterDto extends FlimFilterDto {
    id: number;
    type: FlimType;
    blabViewId?: number;
    flimDefId: number;
    position: string;
    derivedFromSplitBy?: boolean;
    categoryOptionDefIds: number[];
    multiCategoryMatchType: MultiCategoryFilterMatchType;
}

export interface MultiCategoryFlimStatDefDto extends FlimStatDefDto {
    id: number;
    type: FlimType;
    blabViewId?: number;
    flimDefId: number;
    multiCategoryFlimStatType: MultiCategoryFlimStatType;
}

export interface MultiCategoryFlimStatResultDto extends FlimStatResultDto {
    multiCategoryFlimStatType: MultiCategoryFlimStatType;
    flimDefId: number;
    result?: number;
    flimType: FlimType;
}

export interface MultiCategoryFlimValDto extends FlimValDto {
    type: FlimType;
    blabItemId: number;
    flimDefId: number;
    categoryOptionDefIds?: number[];
}

export interface MultiCategoryFlimValRevisionDto extends FlimValRevisionDto {
    id: number;
    type: BlabItemRevisionType;
    flimType: FlimType;
    createdAt: DateTime;
    blabItemId: number;
    flimDefId: number;
    flimDef: CategoryFlimDefRevisionPreviewDto;
    prevCategoryOptionDefIds?: number[];
    categoryOptionDefIds?: number[];
}

export interface MultiCategoryOptionDefDto extends CategoryOptionDefDto {
    id: number;
    label: string;
    color?: SelectableColor;
    position: string;
}

export interface MultiCategorySplitByDto {
    blabViewId: number;
    type: FlimType;
    multiCategoryFlimDefId: number;
    limit: number;
    sorting: BlabViewSplitBySorting;
    sections: MultiCategorySplitBySectionDto[];
}

export interface MultiCategorySplitBySectionDto extends SplitBySectionDto {
    id: string;
    type: FlimType;
    flimDefId: number;
    total: number;
    numCompleted?: number;
    numIncomplete?: number;
    multiCategoryOptionDef?: MultiCategoryOptionDefDto;
    multiCategoryOptionDefId?: number;
}

export interface MultiEmailFlimDefDto extends FlimDefDto {
    id: number;
    blabDefId: number;
    blabDef?: BlabDefPreviewDto;
    name: string;
    slug: string;
    type: FlimType;
    position: string;
    required: boolean;
    hiddenIfEmpty: boolean;
    alwaysHidden: boolean;
    showHelpText: boolean;
    helpText?: string;
    showInRecord?: boolean;
    allowOnlySingleEntry?: boolean;
}

export interface MultiEmailFlimFilterDto extends FlimFilterDto {
    id: number;
    type: FlimType;
    blabViewId?: number;
    flimDefId: number;
    position: string;
    derivedFromSplitBy?: boolean;
    searchText?: string;
    multiEmailMatchType: MultiEmailFilterMatchType;
}

export interface MultiEmailFlimStatDefDto extends FlimStatDefDto {
    id: number;
    type: FlimType;
    blabViewId?: number;
    flimDefId: number;
    multiEmailFlimStatType: MultiEmailFlimStatType;
}

export interface MultiEmailFlimStatResultDto extends FlimStatResultDto {
    multiEmailFlimStatType: MultiEmailFlimStatType;
    flimDefId: number;
    result?: number;
    flimType: FlimType;
}

export interface MultiEmailFlimValDto extends FlimValDto {
    type: FlimType;
    blabItemId: number;
    flimDefId: number;
    multiEmailEntries?: MultiEmailFlimValEntryDto[];
}

export interface MultiEmailFlimValEntryDto {
    id: number;
    blabItemId: number;
    flimDefId: number;
    position: string;
    emailAddress: string;
    emailAddressType: EmailAddressType;
}

export interface MultiEmailFlimValRevisionDto extends FlimValRevisionDto {
    id: number;
    type: BlabItemRevisionType;
    flimType: FlimType;
    createdAt: DateTime;
    blabItemId: number;
    flimDefId: number;
    flimDef: FlimDefRevisionPreviewDto;
    prevEmailAddress?: string;
    emailAddress?: string;
    prevEmailAddressType?: EmailAddressType;
    emailAddressType?: EmailAddressType;
}

export interface MultiImageFlimDefDto extends FlimDefDto {
    id: number;
    blabDefId: number;
    blabDef?: BlabDefPreviewDto;
    name: string;
    slug: string;
    type: FlimType;
    position: string;
    required: boolean;
    hiddenIfEmpty: boolean;
    alwaysHidden: boolean;
    showHelpText: boolean;
    helpText?: string;
    imageFlimLayout: ImageFlimLayout;
    showInRecord?: boolean;
}

export interface MultiImageFlimFilterDto extends FlimFilterDto {
    id: number;
    type: FlimType;
    blabViewId?: number;
    flimDefId: number;
    position: string;
    derivedFromSplitBy?: boolean;
    searchText?: string;
    multiImageMatchType: MultiImageFilterMatchType;
}

export interface MultiImageFlimStatDefDto extends FlimStatDefDto {
    id: number;
    type: FlimType;
    blabViewId?: number;
    flimDefId: number;
    multiImageFlimStatType: MultiImageFlimStatType;
}

export interface MultiImageFlimStatResultDto extends FlimStatResultDto {
    multiImageFlimStatType: MultiImageFlimStatType;
    flimDefId: number;
    result?: number;
    flimType: FlimType;
}

export interface MultiImageFlimValDto extends FlimValDto {
    type: FlimType;
    blabItemId: number;
    flimDefId: number;
    imageReferences?: MultiImageFlimValFileReferenceDto[];
}

export interface MultiImageFlimValFileReferenceDto {
    blabItemId: number;
    flimDefId: number;
    imageId: number;
    image: FlimValImageDto;
    position: string;
    cardThumbnailPositionX?: number;
    cardThumbnailPositionY?: number;
}

export interface MultiImageFlimValRevisionDto extends FlimValRevisionDto {
    id: number;
    type: BlabItemRevisionType;
    flimType: FlimType;
    createdAt: DateTime;
    blabItemId: number;
    flimDefId: number;
    flimDef: FlimDefRevisionPreviewDto;
    prevImageIds?: number[];
    prevImages?: FlimValImageDto[];
    imageIds?: number[];
    images?: FlimValImageDto[];
}

export interface MultiLinkFlimDefDto extends FlimDefDto {
    id: number;
    blabDefId: number;
    blabDef?: BlabDefPreviewDto;
    name: string;
    slug: string;
    type: FlimType;
    position: string;
    required: boolean;
    hiddenIfEmpty: boolean;
    alwaysHidden: boolean;
    showHelpText: boolean;
    helpText?: string;
    showInRecord?: boolean;
}

export interface MultiLinkFlimFilterDto extends FlimFilterDto {
    id: number;
    type: FlimType;
    blabViewId?: number;
    flimDefId: number;
    position: string;
    derivedFromSplitBy?: boolean;
    searchText?: string;
    multiLinkMatchType: MultiLinkFilterMatchType;
}

export interface MultiLinkFlimStatDefDto extends FlimStatDefDto {
    id: number;
    type: FlimType;
    blabViewId?: number;
    flimDefId: number;
    multiLinkFlimStatType: MultiLinkFlimStatType;
}

export interface MultiLinkFlimStatResultDto extends FlimStatResultDto {
    multiLinkFlimStatType: MultiLinkFlimStatType;
    flimDefId: number;
    result?: number;
    flimType: FlimType;
}

export interface MultiLinkFlimValDto extends FlimValDto {
    type: FlimType;
    blabItemId: number;
    flimDefId: number;
    multiLinkEntries?: MultiLinkFlimValEntryDto[];
}

export interface MultiLinkFlimValEntryDto {
    id: number;
    blabItemId: number;
    flimDefId: number;
    position: string;
    linkPreview: LinkPreviewDto;
}

export interface MultiLinkFlimValRevisionDto extends FlimValRevisionDto {
    id: number;
    type: BlabItemRevisionType;
    flimType: FlimType;
    createdAt: DateTime;
    blabItemId: number;
    flimDefId: number;
    flimDef: FlimDefRevisionPreviewDto;
    prevMultiLinkEntryId?: number;
    prevMultiLinkEntry?: MultiLinkFlimValEntryDto;
    multiLinkEntryId?: number;
    multiLinkEntry?: MultiLinkFlimValEntryDto;
    prevUrl?: string;
    url?: string;
}

export interface MultiPhoneFlimDefDto extends FlimDefDto {
    id: number;
    blabDefId: number;
    blabDef?: BlabDefPreviewDto;
    name: string;
    slug: string;
    type: FlimType;
    position: string;
    required: boolean;
    hiddenIfEmpty: boolean;
    alwaysHidden: boolean;
    showHelpText: boolean;
    helpText?: string;
    showInRecord?: boolean;
    callLinkProtocol: CallLinkProtocol;
    allowOnlySingleEntry?: boolean;
}

export interface MultiPhoneFlimFilterDto extends FlimFilterDto {
    id: number;
    type: FlimType;
    blabViewId?: number;
    flimDefId: number;
    position: string;
    derivedFromSplitBy?: boolean;
    searchText?: string;
    multiPhoneMatchType: MultiPhoneFilterMatchType;
}

export interface MultiPhoneFlimStatDefDto extends FlimStatDefDto {
    id: number;
    type: FlimType;
    blabViewId?: number;
    flimDefId: number;
    multiPhoneFlimStatType: MultiPhoneFlimStatType;
}

export interface MultiPhoneFlimStatResultDto extends FlimStatResultDto {
    multiPhoneFlimStatType: MultiPhoneFlimStatType;
    flimDefId: number;
    result?: number;
    flimType: FlimType;
}

export interface MultiPhoneFlimValDto extends FlimValDto {
    type: FlimType;
    blabItemId: number;
    flimDefId: number;
    multiPhoneEntries?: MultiPhoneFlimValEntryDto[];
}

export interface MultiPhoneFlimValEntryDto {
    id: number;
    blabItemId: number;
    flimDefId: number;
    position: string;
    phoneNumber: string;
    phoneNumberType: PhoneNumberType;
}

export interface MultiPhoneFlimValRevisionDto extends FlimValRevisionDto {
    id: number;
    type: BlabItemRevisionType;
    flimType: FlimType;
    createdAt: DateTime;
    blabItemId: number;
    flimDefId: number;
    flimDef: FlimDefRevisionPreviewDto;
    prevPhoneNumber?: string;
    phoneNumber?: string;
    prevPhoneNumberType?: PhoneNumberType;
    phoneNumberType?: PhoneNumberType;
}

export interface MultiRelationFlimDefDto extends FlimDefDto, RelationFlimDefDto {
    id: number;
    blabDefId: number;
    blabDef?: BlabDefPreviewDto;
    name: string;
    slug: string;
    type: FlimType;
    position: string;
    required: boolean;
    hiddenIfEmpty: boolean;
    alwaysHidden: boolean;
    showHelpText: boolean;
    helpText?: string;
    showInRecord?: boolean;
    blabReferences?: BlabReferenceDto[];
}

export interface MultiRelationFlimFilterDto extends FlimFilterDto {
    id: number;
    type: FlimType;
    blabViewId?: number;
    flimDefId: number;
    position: string;
    derivedFromSplitBy?: boolean;
    blabItemReferences: BlabItemViewDto[];
    multiRelationMatchType: MultiRelationFilterMatchType;
}

export interface MultiRelationFlimStatDefDto extends FlimStatDefDto {
    id: number;
    type: FlimType;
    blabViewId?: number;
    flimDefId: number;
    multiRelationFlimStatType: MultiRelationFlimStatType;
}

export interface MultiRelationFlimStatResultDto extends FlimStatResultDto {
    multiRelationFlimStatType: MultiRelationFlimStatType;
    flimDefId: number;
    result?: number;
    flimType: FlimType;
}

export interface MultiRelationFlimValDto extends FlimValDto {
    type: FlimType;
    blabItemId: number;
    flimDefId: number;
    blabItemReferences?: BlabItemReferenceDto[];
}

export interface MultiRelationFlimValRevisionDto extends FlimValRevisionDto {
    id: number;
    type: BlabItemRevisionType;
    flimType: FlimType;
    createdAt: DateTime;
    blabItemId: number;
    flimDefId: number;
    flimDef: FlimDefRevisionPreviewDto;
    prevReferencedBlabItemIds?: number[];
    prevReferencedBlabItems?: BlabItemPreviewDto[];
    referencedBlabItemIds?: number[];
    referencedBlabItems?: BlabItemPreviewDto[];
}

export interface MultiRelationSplitByDto {
    blabViewId: number;
    type: FlimType;
    multiRelationFlimDefId: number;
    limit: number;
    sorting: BlabViewSplitBySorting;
    sections: MultiRelationSplitBySectionDto[];
}

export interface MultiRelationSplitBySectionDto extends SplitBySectionDto {
    id: string;
    type: FlimType;
    flimDefId: number;
    total: number;
    numCompleted?: number;
    numIncomplete?: number;
    blabItem?: BlabItemPreviewDto;
    blabItemId?: number;
}

export interface MultiTextFlimDefDto extends FlimDefDto {
    id: number;
    blabDefId: number;
    blabDef?: BlabDefPreviewDto;
    name: string;
    slug: string;
    type: FlimType;
    position: string;
    required: boolean;
    hiddenIfEmpty: boolean;
    alwaysHidden: boolean;
    showHelpText: boolean;
    helpText?: string;
    showInRecord?: boolean;
    disableRichTextFormatting: boolean;
    defaultValue?: string;
}

export interface MultiTextFlimFilterDto extends FlimFilterDto {
    id: number;
    type: FlimType;
    blabViewId?: number;
    flimDefId: number;
    position: string;
    derivedFromSplitBy?: boolean;
    searchText?: string;
    multiTextMatchType: MultiTextFilterMatchType;
}

export interface MultiTextFlimStatDefDto extends FlimStatDefDto {
    id: number;
    type: FlimType;
    blabViewId?: number;
    flimDefId: number;
    multiTextFlimStatType: MultiTextFlimStatType;
}

export interface MultiTextFlimStatResultDto extends FlimStatResultDto {
    multiTextFlimStatType: MultiTextFlimStatType;
    flimDefId: number;
    result?: number;
    flimType: FlimType;
}

export interface MultiTextFlimValDto extends FlimValDto {
    type: FlimType;
    blabItemId: number;
    flimDefId: number;
    value?: string;
    unformatted?: string;
}

export interface MultiTextFlimValRevisionDto extends FlimValRevisionDto {
    id: number;
    type: BlabItemRevisionType;
    flimType: FlimType;
    createdAt: DateTime;
    blabItemId: number;
    flimDefId: number;
    flimDef: FlimDefRevisionPreviewDto;
    prevUnformattedValue?: string;
    unformattedValue?: string;
    changeType?: MultiTextFlimValRevisionChangeType;
}

export interface MultiUserFlimDefDto extends FlimDefDto {
    id: number;
    blabDefId: number;
    blabDef?: BlabDefPreviewDto;
    name: string;
    slug: string;
    type: FlimType;
    position: string;
    required: boolean;
    hiddenIfEmpty: boolean;
    alwaysHidden: boolean;
    showHelpText: boolean;
    helpText?: string;
    showInRecord?: boolean;
    doNotNotify?: boolean;
    showInFocus: boolean;
}

export interface MultiUserFlimFilterDto extends FlimFilterDto {
    id: number;
    type: FlimType;
    blabViewId?: number;
    flimDefId: number;
    position: string;
    derivedFromSplitBy?: boolean;
    referencedUsers: UserProfilePreviewDto[];
    multiUserMatchType: MultiUserFilterMatchType;
    includeActiveUser: boolean;
    includeActiveUserIndex?: number;
}

export interface MultiUserFlimStatDefDto extends FlimStatDefDto {
    id: number;
    type: FlimType;
    blabViewId?: number;
    flimDefId: number;
    multiUserFlimStatType: MultiUserFlimStatType;
}

export interface MultiUserFlimStatResultDto extends FlimStatResultDto {
    multiUserFlimStatType: MultiUserFlimStatType;
    flimDefId: number;
    result?: number;
    flimType: FlimType;
}

export interface MultiUserFlimValDto extends FlimValDto {
    type: FlimType;
    blabItemId: number;
    flimDefId: number;
    userReferences?: UserReferenceDto[];
}

export interface MultiUserFlimValRevisionDto extends FlimValRevisionDto {
    id: number;
    type: BlabItemRevisionType;
    flimType: FlimType;
    createdAt: DateTime;
    blabItemId: number;
    flimDefId: number;
    flimDef: FlimDefRevisionPreviewDto;
    prevUserIds?: number[];
    prevUsers?: UserProfilePreviewDto[];
    userIds?: number[];
    users?: UserProfilePreviewDto[];
}

export interface MultiUserSplitByDto {
    blabViewId: number;
    type: FlimType;
    multiUserFlimDefId: number;
    limit: number;
    sorting: BlabViewSplitBySorting;
    sections: MultiUserSplitBySectionDto[];
}

export interface MultiUserSplitBySectionDto extends SplitBySectionDto {
    id: string;
    type: FlimType;
    flimDefId: number;
    total: number;
    numCompleted?: number;
    numIncomplete?: number;
    user?: UserProfilePreviewDto;
    userId?: number;
}

export interface IMutation {
    _testJobQueueTrigger(input?: _TestJobQueueTriggerInput): _testJobQueueDto | Promise<_testJobQueueDto>;
    _testJobQueueExecuteImmediately(input?: _TestJobQueueExecuteImmediatelyInput): Void | Promise<Void>;
    _updateOauthIntegrationTokenExpiration(input?: UpdateOauthIntegrationTokenExpirationInput): Void | Promise<Void>;
    _updateOauthIntegrationAccessToken(input?: UpdateOauthIntegrationAccessTokenInput): Void | Promise<Void>;
    enablePermissionV2ForOrganization(): Void | Promise<Void>;
    disablePermissionV2ForOrganization(): Void | Promise<Void>;
    _testTimeMockFreeze(input?: _TestTimeMockFreezeInput): _testTimeMockDto | Promise<_testTimeMockDto>;
    _testTimeMockReset(): _testTimeMockDto | Promise<_testTimeMockDto>;
    _testTimeMockTravel(input: _TestTimeMockTravelInput): _testTimeMockDto | Promise<_testTimeMockDto>;
    _removeDateArrivedJobs(input: _RemoveDateArrivedJobsInput): Void | Promise<Void>;
    _setScheduleAtOfDateArrivedJob(input: _SetScheduleAtOfDateArrivedJobsInput): _DateArrivedJobDto[] | Promise<_DateArrivedJobDto[]>;
    _removeDatePeriodicJobs(input: _RemoveDatePeriodicJobsInput): Void | Promise<Void>;
    _setScheduleAtOfDatePeriodicJob(input: _SetScheduleAtOfDatePeriodicJobsInput): _DatePeriodicJobDto[] | Promise<_DatePeriodicJobDto[]>;
    _triggerOrganizationThrottlingScan(): Void | Promise<Void>;
    _triggerOrganizationReportActionUsage(): Void | Promise<Void>;
    resetActiveUserApiKey(): ActiveUserApiKeyDto | Promise<ActiveUserApiKeyDto>;
    setActiveUserMetadata(input: SetActiveUserMetadataInput): ActiveUserDto | Promise<ActiveUserDto>;
    reportCompletedSetup(): ActiveUserDto | Promise<ActiveUserDto>;
    reportCompletedWorkspaceSetup(): ActiveUserDto | Promise<ActiveUserDto>;
    updateActiveUserBlabDefInfo(input: UpdateActiveUserBlabDefInfoInput): ActiveUserBlabDefInfoDto | Promise<ActiveUserBlabDefInfoDto>;
    updateActiveUserCalendarSettings(input: UpdateActiveUserCalendarSettingsInput): ActiveUserCalendarSettingsDto | Promise<ActiveUserCalendarSettingsDto>;
    resetPrivateUrlShareUrl(input: ResetPrivateUrlShareUrlInput): ActiveUserCalendarStaticSharesDto | Promise<ActiveUserCalendarStaticSharesDto>;
    updatePrivateUrlShare(input: UpdatePrivateUrlShareInput): ActiveUserCalendarStaticSharesDto | Promise<ActiveUserCalendarStaticSharesDto>;
    resetPublicUrlShareUrl(input: ResetPublicUrlShareUrlInput): ActiveUserCalendarStaticSharesDto | Promise<ActiveUserCalendarStaticSharesDto>;
    updatePublicUrlShare(input: UpdatePublicUrlShareInput): ActiveUserCalendarStaticSharesDto | Promise<ActiveUserCalendarStaticSharesDto>;
    activateActiveUserCalendarSubscription(input: ActivateActiveUserCalendarSubscriptionInput): ActiveUserCalendarSubscriptionDto | Promise<ActiveUserCalendarSubscriptionDto>;
    deactivateActiveUserCalendarSubscription(input: DeactivateActiveUserCalendarSubscriptionInput): ActiveUserCalendarSubscriptionDto | Promise<ActiveUserCalendarSubscriptionDto>;
    addActiveUserCalendarSubscription(input: AddActiveUserCalendarSubscriptionInput): ActiveUserCalendarSubscriptionDto | Promise<ActiveUserCalendarSubscriptionDto>;
    removeActiveUserCalendarSubscription(input?: RemoveActiveUserCalendarSubscriptionInput): ActiveUserCalendarSubscriptionDto | Promise<ActiveUserCalendarSubscriptionDto>;
    updateActiveUserCalendarSubscription(input?: UpdateActiveUserCalendarSubscriptionInput): ActiveUserCalendarSubscriptionDto | Promise<ActiveUserCalendarSubscriptionDto>;
    changeActiveUserFeatureAccess(input: ChangeActiveUserFeatureAccessInput): ActiveUserDto | Promise<ActiveUserDto>;
    updateActiveUserNotificationSettings(input: UpdateActiveUserNotificationSettingsInput): ActiveUserNotificationSettingsDto | Promise<ActiveUserNotificationSettingsDto>;
    unsubscribeActiveUserEmailNotifications(jwt: string): Void | Promise<Void>;
    dismissActiveUserOnboardingTasks(): ActiveUserOnboardingTasksDto | Promise<ActiveUserOnboardingTasksDto>;
    addUserPost(input: AddUserPostInput): UserPostActivityDto | Promise<UserPostActivityDto>;
    editUserPost(input: EditUserPostInput): UserPostActivityDto | Promise<UserPostActivityDto>;
    deleteUserPost(input: DeleteUserPostInput): Void | Promise<Void>;
    uploadUserPostAttachment(input: UploadUserPostAttachmentInput, attachment: Upload): UserPostAttachmentDto | Promise<UserPostAttachmentDto>;
    reportActiveUserPresence(input: ReportActiveUserPresenceInput): Void | Promise<Void>;
    updateActiveUserProfile(input: UpdateActiveUserProfileInput, profilePicture?: Upload): ActiveUserProfileDto | Promise<ActiveUserProfileDto>;
    updateActiveUserProfilePicture(profilePicture: Upload): ActiveUserProfileDto | Promise<ActiveUserProfileDto>;
    addActiveUserQuickAddBlabDef(input: AddActiveUserQuickAddBlabDefInput): ActiveUserQuickAddBlabDefDto | Promise<ActiveUserQuickAddBlabDefDto>;
    removeActiveUserQuickAddBlabDef(input: RemoveActiveUserQuickAddBlabDefInput): Void | Promise<Void>;
    moveActiveUserQuickAddBlabDef(input: MoveActiveUserQuickAddBlabDefInput): ActiveUserQuickAddBlabDefDto | Promise<ActiveUserQuickAddBlabDefDto>;
    setActiveUserIncognito(input: SetActiveUserIncognitoInput): ActiveUserStatusDto | Promise<ActiveUserStatusDto>;
    clearActiveUserIncognito(): ActiveUserStatusDto | Promise<ActiveUserStatusDto>;
    setActiveUserDoNotDisturb(input: SetActiveUserDoNotDisturbInput): ActiveUserStatusDto | Promise<ActiveUserStatusDto>;
    clearActiveUserDoNotDisturb(): ActiveUserStatusDto | Promise<ActiveUserStatusDto>;
    setActiveUserStatus(input: SetActiveUserStatusInput): ActiveUserStatusDto | Promise<ActiveUserStatusDto>;
    clearActiveUserStatus(): ActiveUserStatusDto | Promise<ActiveUserStatusDto>;
    setActiveUserWorkflowDefLastViewedAt(input?: SetActiveUserWorkflowDefLastViewedAtInput): Void | Promise<Void>;
    updateActiveUserWorkspaceInfoLastSelectedBlabDef(input: UpdateActiveUserWorkspaceInfoLastSelectedBlabDefInput): ActiveUserWorkspaceInfoDto | Promise<ActiveUserWorkspaceInfoDto>;
    createAudioRoom(input: CreateAudioRoomInput): AudioRoomDto | Promise<AudioRoomDto>;
    destroyAudioRoom(input: DestroyAudioRoomInput): Void | Promise<Void>;
    leaveAudioRoom(input: LeaveAudioRoomInput): Void | Promise<Void>;
    joinAudioRoom(input: JoinAudioRoomInput): JoinAudioRoomResultDto | Promise<JoinAudioRoomResultDto>;
    connectAudioRoomTransport(input: ConnectAudioRoomTransportInput): Void | Promise<Void>;
    initializeAudioRoomProducer(input: InitializeAudioRoomProducerInput): AudioRoomProducerOptions | Promise<AudioRoomProducerOptions>;
    initializeAudioRoomConsumers(input: InitializeAudioRoomConsumersInput): AudioRoomConsumerOptions[] | Promise<AudioRoomConsumerOptions[]>;
    muteActiveUserInAudioRoom(input: MuteActiveUserInAudioRoomInput): Void | Promise<Void>;
    unmuteActiveUserInAudioRoom(input: UnmuteActiveUserInAudioRoomInput): Void | Promise<Void>;
    reportActiveUserAudioRoomPresence(input: ReportActiveUserAudioPresenceInput): ReportActiveUserAudioRoomPresenceDto | Promise<ReportActiveUserAudioRoomPresenceDto>;
    updateBillingCustomer(input: UpdateBillingCustomerInput): BillingCustomerDto | Promise<BillingCustomerDto>;
    createBillingSubscription(input: CreateBillingSubscriptionInput): CreateBillingSubscriptionResult | Promise<CreateBillingSubscriptionResult>;
    changeBillingSubscriptionBillingPeriod(input: ChangeBillingSubscriptionBillingPeriodInput): ActiveBillingSubscriptionDto | Promise<ActiveBillingSubscriptionDto>;
    changeBillingSubscriptionPlan(input: ChangeBillingSubscriptionPlanInput): ChangeBillingSubscriptionPlanResult | Promise<ChangeBillingSubscriptionPlanResult>;
    cancelBillingSubscription(input: CancelBillingSubscriptionInput): Void | Promise<Void>;
    reactivateBillingSubscriptionRenewal(input?: ReactivateBillingSubscriptionRenewalInput): ActiveBillingSubscriptionDto | Promise<ActiveBillingSubscriptionDto>;
    addPaymentMethod(input: AddPaymentMethodInput): PaymentMethodUnionType | Promise<PaymentMethodUnionType>;
    removePaymentMethod(input: RemovePaymentMethodInput): PaymentMethodUnionType | Promise<PaymentMethodUnionType>;
    setDefaultPaymentMethod(input: SetDefaultPaymentMethodInput): PaymentMethodUnionType | Promise<PaymentMethodUnionType>;
    retryInvoicePayment(input: RetryInvoicePaymentInput): SubscriptionInvoiceDto | Promise<SubscriptionInvoiceDto>;
    createBlabDef(input: CreateBlabDefInput): BlabDefDto | Promise<BlabDefDto>;
    updateBlabDef(input: UpdateBlabDefInput): BlabDefDto | Promise<BlabDefDto>;
    deleteBlabDef(input: DeleteBlabDefInput): Void | Promise<Void>;
    archiveBlabDef(input: ArchiveBlabDefInput): Void | Promise<Void>;
    addBlabDefActivityReaction(input: AddBlabDefActivityReactionInput): BlabDefActivityUnionType | Promise<BlabDefActivityUnionType>;
    removeBlabDefActivityReaction(input: RemoveBlabDefActivityReactionInput): BlabDefActivityUnionType | Promise<BlabDefActivityUnionType>;
    addBlabDefActivityReplyReaction(input: AddBlabDefActivityReplyReactionInput): BlabDefActivityReplyDto | Promise<BlabDefActivityReplyDto>;
    removeBlabDefActivityReplyReaction(input: RemoveBlabDefActivityReplyReactionInput): BlabDefActivityReplyDto | Promise<BlabDefActivityReplyDto>;
    exportBlabDefData(input: ExportBlabDefDataInput): ExportBlabDefDataResultDto | Promise<ExportBlabDefDataResultDto>;
    importBlabDefDataToExistingBlabDef(input: ImportBlabDefDataToExistingBlabDefInput): ImportBlabDefDataToExistingBlabDefResultDto | Promise<ImportBlabDefDataToExistingBlabDefResultDto>;
    uploadBlabDefDataImportFile(file: Upload): BlabDefDataImportFileDto | Promise<BlabDefDataImportFileDto>;
    importBlabDefDataToNewBlabDef(input: ImportBlabDefDataToNewBlabDefInput): ImportBlabDefDataToNewBlabDefResultDto | Promise<ImportBlabDefDataToNewBlabDefResultDto>;
    createBlabDefWebhook(input: CreateBlabDefWebhookInput): BlabDefWebhookDto | Promise<BlabDefWebhookDto>;
    updateBlabDefWebhook(input: UpdateBlabDefWebhookInput): UpdateBlabDefWebhookResultDto | Promise<UpdateBlabDefWebhookResultDto>;
    deleteBlabDefWebhook(input: DeleteBlabDefWebhookInput): BlabDefWebhookDto | Promise<BlabDefWebhookDto>;
    requestBlabDefWebhookVerification(input: RequestBlabDefWebhookVerificationInput): BlabDefWebhookDto | Promise<BlabDefWebhookDto>;
    duplicateBlabDef(input: DuplicateBlabDefInput): BlabDefDto | Promise<BlabDefDto>;
    duplicateBlabDefV2(input: DuplicateBlabDefInput): DuplicateBlabDefResultDto | Promise<DuplicateBlabDefResultDto>;
    followBlabDef(input: FollowBlabDefInput): ActiveUserBlabDefFollowInfoDto | Promise<ActiveUserBlabDefFollowInfoDto>;
    unfollowBlabDef(input: UnfollowBlabDefInput): ActiveUserBlabDefFollowInfoDto | Promise<ActiveUserBlabDefFollowInfoDto>;
    enableBlabDefInboundPublicEmail(input: EnableBlabDefInboundPublicEmailInput): Void | Promise<Void>;
    disableBlabDefInboundPublicEmail(input: DisableBlabDefInboundPublicEmailInput): Void | Promise<Void>;
    enableActiveUserBlabDefInboundEmail(input: EnableActiveUserBlabDefInboundEmailInput): Void | Promise<Void>;
    disableActiveUserBlabDefInboundEmail(input: DisableActiveUserBlabDefInboundEmailInput): Void | Promise<Void>;
    setBlabDefInboundEmailFieldMappings(input: SetBlabDefInboundEmailFieldMappingsInput): Void | Promise<Void>;
    moveBlabDefToWorkspace(input?: MoveBlabDefToWorkspaceInput): BlabDefDto | Promise<BlabDefDto>;
    updateBlabDefSettings(input: UpdateBlabDefSettingsInput): BlabDefSettingsDto | Promise<BlabDefSettingsDto>;
    createBlabItem(input: CreateBlabItemInput): BlabItemDetailDto | Promise<BlabItemDetailDto>;
    deleteBlabItem(input: DeleteBlabItemInput): Void | Promise<Void>;
    deleteBlabItems(input: DeleteBlabItemsInput): DeleteBlabItemsInfo | Promise<DeleteBlabItemsInfo>;
    updateBlabItemViewPosition(input: UpdateBlabItemViewPositionInput): BlabItemViewDto | Promise<BlabItemViewDto>;
    updateBlabItemsFlimVal(input: UpdateBlabItemsFlimValInput): UpdateBlabItemsFlimValResponse | Promise<UpdateBlabItemsFlimValResponse>;
    addBlabItemActivityReaction(input: AddBlabItemActivityReactionInput): BlabItemActivityUnionType | Promise<BlabItemActivityUnionType>;
    removeBlabItemActivityReaction(input: RemoveBlabItemActivityReactionInput): BlabItemActivityUnionType | Promise<BlabItemActivityUnionType>;
    uploadBlabItemActivityReplyAttachment(input: UploadBlabItemActivityReplyAttachmentInput, attachment: Upload): BlabItemActivityReplyAttachmentDto | Promise<BlabItemActivityReplyAttachmentDto>;
    addBlabItemActivityReplyReaction(input: AddBlabItemActivityReplyReactionInput): BlabItemActivityReplyDto | Promise<BlabItemActivityReplyDto>;
    removeBlabItemActivityReplyReaction(input: RemoveBlabItemActivityReplyReactionInput): BlabItemActivityReplyDto | Promise<BlabItemActivityReplyDto>;
    addBlabItemActivityReply(input?: AddBlabItemActivityReplyInput): BlabItemActivityReplyDto | Promise<BlabItemActivityReplyDto>;
    editBlabItemActivityReply(input?: EditBlabItemActivityReplyInput): BlabItemActivityReplyDto | Promise<BlabItemActivityReplyDto>;
    removeBlabItemActivityReply(input?: RemoveBlabItemActivityReplyInput): Void | Promise<Void>;
    addBlabItemComment(input: AddBlabItemCommentInput): BlabItemCommentActivityDto | Promise<BlabItemCommentActivityDto>;
    editBlabItemComment(input: EditBlabItemCommentInput): BlabItemCommentActivityDto | Promise<BlabItemCommentActivityDto>;
    deleteBlabItemComment(input: DeleteBlabItemCommentInput): Void | Promise<Void>;
    uploadBlabItemCommentAttachment(input: UploadBlabItemCommentAttachmentInput, attachment: Upload): BlabItemCommentAttachmentDto | Promise<BlabItemCommentAttachmentDto>;
    updateBlabItemFocusSection(input: UpdateBlabItemFocusSectionInput): Void | Promise<Void>;
    updateChecklistFlimValEntryFocusSection(input: UpdateChecklistFlimValEntryFocusSectionInput): Void | Promise<Void>;
    followBlabItem(input: FollowBlabItemInput): ActiveUserBlabItemInfoDto | Promise<ActiveUserBlabItemInfoDto>;
    unfollowBlabItem(input: UnfollowBlabItemInput): ActiveUserBlabItemInfoDto | Promise<ActiveUserBlabItemInfoDto>;
    bulkFollowBlabItems(input: BulkFollowBlabItemsInput): BulkFollowBlabItemsResponse | Promise<BulkFollowBlabItemsResponse>;
    bulkUnfollowBlabItems(input: BulkUnfollowBlabItemsInput): BulkFollowBlabItemsResponse | Promise<BulkFollowBlabItemsResponse>;
    activateBlabItemNotifications(input: ActivateBlabItemNotificationsInput): ActiveUserBlabItemInfoDto | Promise<ActiveUserBlabItemInfoDto>;
    deactivateBlabItemNotifications(input: DeactivateBlabItemNotificationsInput): ActiveUserBlabItemInfoDto | Promise<ActiveUserBlabItemInfoDto>;
    addBlabItemReminder(input: AddBlabItemReminderInput): AddBlabItemReminderResultDto | Promise<AddBlabItemReminderResultDto>;
    addBlabItemActivityReminder(input: AddBlabItemActivityReminderInput): ReminderDto | Promise<ReminderDto>;
    revertBlabItemRevision(input: RevertBlabItemRevisionInput): Void | Promise<Void>;
    updateBlabItemTemplate(input: UpdateBlabItemTemplateInput): BlabItemTemplateDto | Promise<BlabItemTemplateDto>;
    updateBlabItemTemplateTitle(input: UpdateBlabItemTemplateTitleInput): BlabItemTemplateDto | Promise<BlabItemTemplateDto>;
    deleteBlabItemTemplate(input: DeleteBlabItemTemplateInput): Void | Promise<Void>;
    removeAllDefaultBlabItemTemplates(input: RemoveAllDefaultBlabItemTemplatesInput): Void | Promise<Void>;
    upsertActiveUserAutoSaveBlabView(input: UpsertActiveUserAutoSaveBlabViewInput): ActiveUserAutoSaveBlabViewDto | Promise<ActiveUserAutoSaveBlabViewDto>;
    createBlabView(input: CreateBlabViewInput): BlabViewDto | Promise<BlabViewDto>;
    renameBlabView(input: RenameBlabViewInput): BlabViewDto | Promise<BlabViewDto>;
    deleteBlabView(input: DeleteBlabViewInput): Void | Promise<Void>;
    duplicateBlabView(input: DuplicateBlabViewInput): BlabViewPreviewDto | Promise<BlabViewPreviewDto>;
    updateBlabViewPosition(input: UpdateBlabViewPositionInput): BlabViewDto | Promise<BlabViewDto>;
    updateBlabView(input: UpdateBlabViewInput): BlabViewDto | Promise<BlabViewDto>;
    updateBlabViewSettings(input: UpdateBlabViewSettingsInput): BlabViewDto | Promise<BlabViewDto>;
    selectBlabView(input?: SelectBlabViewInput): BlabViewDto | Promise<BlabViewDto>;
    updateBlabViewSplitBy(input?: UpdateBlabViewSplitByInput): BlabViewDto | Promise<BlabViewDto>;
    startChatVideoCall(input?: StartChatVideoCallInput): StartChatVideoCallResult | Promise<StartChatVideoCallResult>;
    findMyOrgs(input: FindMyOrgsInput): Void | Promise<Void>;
    authenticateWithFindMyOrgsToken(userId: number, token: string, input?: AuthenticateWithFindMyOrgsTokenOptionsInput): AuthenticateWithFindMyOrgsTokenResult | Promise<AuthenticateWithFindMyOrgsTokenResult>;
    createFlimValAttachment(input: CreateFlimValAttachmentInput, attachment: Upload): FlimValAttachmentDto | Promise<FlimValAttachmentDto>;
    updateFlimValAttachment(input: UpdateFlimValAttachmentInput): FlimValAttachmentDto | Promise<FlimValAttachmentDto>;
    deleteFlimValAttachment(input: DeleteFlimValAttachmentInput): Void | Promise<Void>;
    createFlimValImage(input: CreateFlimValImageInput, image: Upload): FlimValImageDto | Promise<FlimValImageDto>;
    updateFlimValImage(input: UpdateFlimValImageInput): FlimValImageDto | Promise<FlimValImageDto>;
    deleteFlimValImage(input: DeleteFlimValImageInput): Void | Promise<Void>;
    createFocusSection(input: CreateFocusSectionInput): FocusSectionDto | Promise<FocusSectionDto>;
    updateFocusSectionName(input: UpdateFocusSectionNameInput): FocusSectionDto | Promise<FocusSectionDto>;
    updateFocusSectionPosition(input: UpdateFocusSectionPositionInput): FocusSectionDto | Promise<FocusSectionDto>;
    removeFocusSection(input: RemoveFocusSectionInput): Void | Promise<Void>;
    expandFocusSection(input: ExpandFocusSectionInput): FocusSectionDto | Promise<FocusSectionDto>;
    collapseFocusSection(input: CollapseFocusSectionInput): FocusSectionDto | Promise<FocusSectionDto>;
    moveAllFocusSectionEntries(input: MoveAllFocusSectionEntriesInput): MoveAllFocusSectionEntriesResultDto | Promise<MoveAllFocusSectionEntriesResultDto>;
    updateFocusSettings(input: UpdateFocusSettingsInput): FocusSettingsDto | Promise<FocusSettingsDto>;
    requestForgotPasswordEmail(input: RequestForgotPasswordEmailInput): Void | Promise<Void>;
    changePasswordWithForgotPasswordToken(token: string, password: string, input?: ChangePasswordWithForgotPasswordTokenOptionsInput): UserSessionDto | Promise<UserSessionDto>;
    addSearchHistoryItemQuery(input: AddSearchHistoryItemQueryInput): SearchHistoryItemUnionType[] | Promise<SearchHistoryItemUnionType[]>;
    addSearchHistoryItemBlabDef(input: AddSearchHistoryItemBlabDefInput): SearchHistoryItemUnionType[] | Promise<SearchHistoryItemUnionType[]>;
    addSearchHistoryItemBlabItem(input: AddSearchHistoryItemBlabItemInput): SearchHistoryItemUnionType[] | Promise<SearchHistoryItemUnionType[]>;
    addSearchHistoryItemWorkspace(input: AddSearchHistoryItemWorkspaceInput): SearchHistoryItemUnionType[] | Promise<SearchHistoryItemUnionType[]>;
    addSearchHistoryItemUser(input: AddSearchHistoryItemUserInput): SearchHistoryItemUnionType[] | Promise<SearchHistoryItemUnionType[]>;
    removeSearchHistoryItem(id: number): SearchHistoryItemUnionType[] | Promise<SearchHistoryItemUnionType[]>;
    clearSearchHistory(): Void | Promise<Void>;
    notifyOfInboundEmail(input: NotifyOfInboundEmailInput): Void | Promise<Void>;
    createLinkPreview(input: CreateLinkPreviewInput): LinkPreviewDto | Promise<LinkPreviewDto>;
    removeNewsfeedActivity(input: RemoveNewsfeedActivityInput): Void | Promise<Void>;
    markAllNotificationsAsRead(): MarkAllNotificationsAsReadResultDto | Promise<MarkAllNotificationsAsReadResultDto>;
    markNotificationAsRead(input: MarkNotificationAsReadInput): NotificationDto | Promise<NotificationDto>;
    markNotificationAsUnread(input: MarkNotificationAsUnreadInput): NotificationDto | Promise<NotificationDto>;
    addOrganizationActivityReaction(input: AddOrganizationActivityReactionInput): OrganizationActivityUnionType | Promise<OrganizationActivityUnionType>;
    removeOrganizationActivityReaction(input: RemoveOrganizationActivityReactionInput): OrganizationActivityUnionType | Promise<OrganizationActivityUnionType>;
    addOrganizationActivityReplyReaction(input: AddOrganizationActivityReplyReactionInput): OrganizationActivityReplyDto | Promise<OrganizationActivityReplyDto>;
    removeOrganizationActivityReplyReaction(input: RemoveOrganizationActivityReplyReactionInput): OrganizationActivityReplyDto | Promise<OrganizationActivityReplyDto>;
    addOrganizationActivityReply(input?: AddOrganizationActivityReplyInput): OrganizationActivityReplyDto | Promise<OrganizationActivityReplyDto>;
    editOrganizationActivityReply(input?: EditOrganizationActivityReplyInput): OrganizationActivityReplyDto | Promise<OrganizationActivityReplyDto>;
    removeOrganizationActivityReply(input?: RemoveOrganizationActivityReplyInput): Void | Promise<Void>;
    createOrganizationInvitations(input: CreateOrganizationInvitationsInput): OrganizationInvitationDto[] | Promise<OrganizationInvitationDto[]>;
    approveOrganizationInvitation(input: ApproveOrganizationInvitationInput): OrganizationInvitationDto | Promise<OrganizationInvitationDto>;
    denyOrganizationInvitation(input: DenyOrganizationInvitationInput): OrganizationInvitationDto | Promise<OrganizationInvitationDto>;
    resendOrganizationInvitation(input: ResendOrganizationInvitationInput): OrganizationInvitationDto | Promise<OrganizationInvitationDto>;
    copyOrganizationInvitationLink(input: CopyOrganizationInvitationLinkInput): CopyOrganizationInvitationLinkResultDto | Promise<CopyOrganizationInvitationLinkResultDto>;
    revokeOrganizationInvitation(input: RevokeOrganizationInvitationInput): OrganizationInvitationDto | Promise<OrganizationInvitationDto>;
    acceptOrganizationInvitation(token: string, input: AcceptOrganizationInvitationInput): UserSessionDto | Promise<UserSessionDto>;
    updateOrganizationProfile(input: UpdateOrganizationProfileInput): OrganizationProfileEditDto | Promise<OrganizationProfileEditDto>;
    updateOrganizationSlug(input: UpdateOrganizationSlugInput): UpdateOrganizationSlugResultDto | Promise<UpdateOrganizationSlugResultDto>;
    uploadOrganizationLogo(logo: Upload): ThumbnailDto | Promise<ThumbnailDto>;
    createOrganizationAuthenticationProvider(input: CreateOrganizationAuthenticationProviderInput): OrganizationAuthenticationProviderResultUnion | Promise<OrganizationAuthenticationProviderResultUnion>;
    updateOrganizationAuthenticationProvider(input: UpdateOrganizationAuthenticationProviderInput): OrganizationAuthenticationProviderResultUnion | Promise<OrganizationAuthenticationProviderResultUnion>;
    removeOrganizationAuthenticationProvider(input: RemoveOrganizationAuthenticationProviderInput): Void | Promise<Void>;
    updateOrganizationSettings(input: UpdateOrganizationSettingsInput): OrganizationSettingsDto | Promise<OrganizationSettingsDto>;
    createOrganizationSmtpSettings(input: CreateOrganizationSmtpSettingsInput): OrganizationSmtpSettingsResultUnion | Promise<OrganizationSmtpSettingsResultUnion>;
    updateOrganizationSmtpSettings(input: UpdateOrganizationSmtpSettingsInput): OrganizationSmtpSettingsResultUnion | Promise<OrganizationSmtpSettingsResultUnion>;
    setDefaultOrganizationSmtpSettings(input: SetDefaultOrganizationSmtpSettingsInput): OrganizationSmtpSettingsDto | Promise<OrganizationSmtpSettingsDto>;
    removeOrganizationSmtpSettings(input: RemoveOrganizationSmtpSettingsInput): Void | Promise<Void>;
    performOrganizationSetupForIntention(input: PerformOrganizationSetupForIntentionInput): Void | Promise<Void>;
    requestOrganizationSignupVerification(input: RequestOrganizationSignupVerificationInput): OrganizationSignupVerificationSessionDto | Promise<OrganizationSignupVerificationSessionDto>;
    validateOrganizationSignupVerificationCode(input: ValidateOrganizationSignupVerificationCodeInput): ValidateOrganizationSignupVerificationCodeResponse | Promise<ValidateOrganizationSignupVerificationCodeResponse>;
    performOrganizationSignup(input: PerformOrganizationSignupInput): UserSessionDto | Promise<UserSessionDto>;
    changeOrganizationUserRole(input: ChangeOrganizationUserRoleInput): ChangeOrganizationUserRoleResult | Promise<ChangeOrganizationUserRoleResult>;
    updateOrganizationUserInfo(input: UpdateOrganizationUserInfoInput): UpdateOrganizationUserInfoResult | Promise<UpdateOrganizationUserInfoResult>;
    activateOrganizationUser(input: ActivateOrganizationUserInput): OrganizationUserDto | Promise<OrganizationUserDto>;
    deactivateOrganizationUser(input: DeactivateOrganizationUserInput): DeactivateOrganizationUserInfoResult | Promise<DeactivateOrganizationUserInfoResult>;
    createOrganizationWorkspace(input: CreateOrganizationWorkspaceInput): OrganizationWorkspaceDto | Promise<OrganizationWorkspaceDto>;
    gainAdminAccessToOrganizationWorkspace(input: GainAdminAccessToOrganizationWorkspaceInput): Void | Promise<Void>;
    archiveOrganizationWorkspace(input: ArchiveOrganizationWorkspaceInput): OrganizationWorkspaceDto | Promise<OrganizationWorkspaceDto>;
    deleteOrganizationWorkspace(input: DeleteOrganizationWorkspaceInput): DeleteOrganizationWorkspaceResultDto | Promise<DeleteOrganizationWorkspaceResultDto>;
    updateOrganizationWorkspacePosition(input: UpdateOrganizationWorkspacePosition): OrganizationWorkspaceDto | Promise<OrganizationWorkspaceDto>;
    addReminder(input: AddReminderInput): ReminderDto | Promise<ReminderDto>;
    editIncompleteReminder(input: EditIncompleteReminderInput): ReminderDto | Promise<ReminderDto>;
    removeReminder(input: RemoveReminderInput): Void | Promise<Void>;
    setReminderStatus(input: SetReminderStatusInput): ReminderDto | Promise<ReminderDto>;
    archiveAllCompletedReminders(): ArchiveAllCompletedRemindersResultDto | Promise<ArchiveAllCompletedRemindersResultDto>;
    updateReminderFocusSection(input: UpdateReminderFocusSectionInput): Void | Promise<Void>;
    getSpreadsheetImportFlimCandidates(table: Upload): SpreadsheetImportFlimDefCadidatesDto | Promise<SpreadsheetImportFlimDefCadidatesDto>;
    importSpreadsheetAsNewBlabDef(input: ImportSpreadsheetInput): BlabDefDto | Promise<BlabDefDto>;
    unsupportedPlatformRequestMagicLink(input?: UnsupportedPlatformRequestMagicLinkInput): Void | Promise<Void>;
    addUserActivityReaction(input: AddUserActivityReactionInput): UserActivityUnionType | Promise<UserActivityUnionType>;
    removeUserActivityReaction(input: RemoveUserActivityReactionInput): UserActivityUnionType | Promise<UserActivityUnionType>;
    addUserActivityReplyReaction(input: AddUserActivityReplyReactionInput): UserActivityReplyDto | Promise<UserActivityReplyDto>;
    removeUserActivityReplyReaction(input: RemoveUserActivityReplyReactionInput): UserActivityReplyDto | Promise<UserActivityReplyDto>;
    addUserActivityReply(input?: AddUserActivityReplyInput): UserActivityReplyDto | Promise<UserActivityReplyDto>;
    editUserActivityReply(input?: EditUserActivityReplyInput): UserActivityReplyDto | Promise<UserActivityReplyDto>;
    removeUserActivityReply(input?: RemoveUserActivityReplyInput): Void | Promise<Void>;
    setUserClientAppMetaData(input?: SetUserClientAppMetaDataInput): Void | Promise<Void>;
    followUser(input: FollowUserInput): ActiveUserUserInfoDto | Promise<ActiveUserUserInfoDto>;
    unfollowUser(input: UnfollowUserInput): ActiveUserUserInfoDto | Promise<ActiveUserUserInfoDto>;
    authenticateWithCredentials(input: AuthenticateWithCredentialsInput): AuthenticateWithCredentialsResult | Promise<AuthenticateWithCredentialsResult>;
    activateUserNotifications(input: ActivateUserNotificationsInput): ActiveUserUserInfoDto | Promise<ActiveUserUserInfoDto>;
    deactivateUserNotifications(input: DeactivateUserNotificationsInput): ActiveUserUserInfoDto | Promise<ActiveUserUserInfoDto>;
    addDeviceRegistrationToken(input: AddDeviceRegistrationTokenInput): Void | Promise<Void>;
    addWebPushSubscription(input: AddWebPushSubscriptionInput): Void | Promise<Void>;
    removeUserSession(userId: number, options?: RemoveUserSessionOptionsInput): Void | Promise<Void>;
    requestChangeUserEmailVerification(input: RequestChangeUserEmailVerficiationInput): RequestChangeUserEmailVerificationResult | Promise<RequestChangeUserEmailVerificationResult>;
    performChangeUserEmail(input: PerformChangeUserEmailInput): PerformChangeUserEmailResult | Promise<PerformChangeUserEmailResult>;
    connectMicrosoftIntegration(): MicrosoftUserAuthenticationRequiredDto | Promise<MicrosoftUserAuthenticationRequiredDto>;
    disconnectMicrosoftIntegration(): UserSettingsMicrosoftIntegrationStatusDto | Promise<UserSettingsMicrosoftIntegrationStatusDto>;
    changeUserPassword(input: ChangeUserPasswordInput): ChangeUserPasswordResultDto | Promise<ChangeUserPasswordResultDto>;
    updateUserSettings(input: UpdateUserSettingsInput): UserSettingsDto | Promise<UserSettingsDto>;
    restoreFromUserTrash(input: RestoreFromUserTrashInput): Void | Promise<Void>;
    removeFromUserTrash(input: RemoveFromUserTrashInput): Void | Promise<Void>;
    emptyUserTrash(): EmptyUserTrashResultDto | Promise<EmptyUserTrashResultDto>;
    upsertActiveUserGlobalWorkflowRunsFilteringSettings(input?: UpsertActiveUserGlobalWorkflowRunsFilteringSettingsInput): ActiveUserGlobalWorkflowRunsFilteringSettingsDto | Promise<ActiveUserGlobalWorkflowRunsFilteringSettingsDto>;
    generateWorkflowDefCode(input: GenerateWorkflowDefCodeInput): GenerateWorkflowDefCodeResultDto | Promise<GenerateWorkflowDefCodeResultDto>;
    createWorkflowDef(input: CreateWorkflowDefInput): WorkflowDefDto | Promise<WorkflowDefDto>;
    duplicateWorkflowDef(input: DuplicateWorkflowDefInput): WorkflowDefDto | Promise<WorkflowDefDto>;
    createEmptyWorkflowDef(input?: CreateEmptyWorkflowDefInput): WorkflowDefDto | Promise<WorkflowDefDto>;
    pauseWorkflowDef(input?: PauseWorkflowDefInput): WorkflowDefDto | Promise<WorkflowDefDto>;
    unpauseWorkflowDef(input?: UnpauseWorkflowDefInput): WorkflowDefDto | Promise<WorkflowDefDto>;
    updateWorkflowDef(input: UpdateWorkflowDefInput): WorkflowDefDto | Promise<WorkflowDefDto>;
    deleteWorkflowDef(input: DeleteWorkflowDefInput): Void | Promise<Void>;
    createCallableWorkflowDef(input: CreateCallableWorkflowDefInput): WorkflowDefDto | Promise<WorkflowDefDto>;
    runWorkflowManually(input?: RunWorkflowManuallyInput): ManualRunsTriggeredDto | Promise<ManualRunsTriggeredDto>;
    cancelWorkflowRunsOfWorkflowDef(input?: CancelWorkflowRunsOfWorkflowDefInput): CancelWorkflowRunsResult | Promise<CancelWorkflowRunsResult>;
    simulateWorkflow(input: SimulateWorkflowInput): Void | Promise<Void>;
    createWorkflowCenterWebhook(input: CreateWorkflowCenterWebhookInput): WorkflowCenterWebhookDto | Promise<WorkflowCenterWebhookDto>;
    updateWorkflowCenterWebhook(input: UpdateWorkflowCenterWebhookInput): UpdateWorkflowCenterWebhookResultDto | Promise<UpdateWorkflowCenterWebhookResultDto>;
    deleteWorkflowCenterWebhook(input: DeleteWorkflowCenterWebhookInput): WorkflowCenterWebhookDto | Promise<WorkflowCenterWebhookDto>;
    requestWorkflowCenterWebhookVerification(input: RequestWorkflowCenterWebhookVerificationInput): WorkflowCenterWebhookDto | Promise<WorkflowCenterWebhookDto>;
    followWorkflowDef(input: FollowWorkflowDefInput): ActiveUserWorkflowDefInfoDto | Promise<ActiveUserWorkflowDefInfoDto>;
    unfollowWorkflowDef(input: UnfollowWorkflowDefInput): ActiveUserWorkflowDefInfoDto | Promise<ActiveUserWorkflowDefInfoDto>;
    addWorkspaceActivityReaction(input: AddWorkspaceActivityReactionInput): WorkspaceActivityUnionType | Promise<WorkspaceActivityUnionType>;
    removeWorkspaceActivityReaction(input: RemoveWorkspaceActivityReactionInput): WorkspaceActivityUnionType | Promise<WorkspaceActivityUnionType>;
    addWorkspaceActivityReplyReaction(input: AddWorkspaceActivityReplyReactionInput): WorkspaceActivityReplyDto | Promise<WorkspaceActivityReplyDto>;
    removeWorkspaceActivityReplyReaction(input: RemoveWorkspaceActivityReplyReactionInput): WorkspaceActivityReplyDto | Promise<WorkspaceActivityReplyDto>;
    updateWorkspaceBlabDefPosition(input: UpdateWorkspaceBlabDefPositionInput): WorkspaceBlabDefDto | Promise<WorkspaceBlabDefDto>;
    duplicateWorkspace(input: DuplicateWorkspaceInput): WorkspaceDto | Promise<WorkspaceDto>;
    removeWorkspaceMembership(input: RemoveWorkspaceMembershipInput): RemoveWorkspaceMembershipResult | Promise<RemoveWorkspaceMembershipResult>;
    changeWorkspaceMembershipRole(input: ChangeWorkspaceMembershipRoleInput): ChangeWorkspaceMembershipRoleResult | Promise<ChangeWorkspaceMembershipRoleResult>;
    addWorkspaceMemberships(input?: AddWorkspaceMembershipsInput): WorkspaceMembershipUnionType[] | Promise<WorkspaceMembershipUnionType[]>;
    updateWorkspaceSettings(input: UpdateWorkspaceSettingsInput): WorkspaceSettingsDto | Promise<WorkspaceSettingsDto>;
    updateWorkspaceSlug(input: UpdateWorkspaceSlugInput): UpdateWorkspaceSlugResultDto | Promise<UpdateWorkspaceSlugResultDto>;
    enableWorkspaceShareLink(input: EnableWorkspaceShareLinkInput): WorkspaceShareSettingsDto | Promise<WorkspaceShareSettingsDto>;
    disableWorkspaceShareLink(input: DisableWorkspaceShareLinkInput): WorkspaceShareSettingsDto | Promise<WorkspaceShareSettingsDto>;
    enableWorkspaceShareLinkDuplication(input: EnableWorkspaceShareLinkDuplicationInput): WorkspaceShareSettingsDto | Promise<WorkspaceShareSettingsDto>;
    disableWorkspaceShareLinkDuplication(input: DisableWorkspaceShareLinkDuplicationInput): WorkspaceShareSettingsDto | Promise<WorkspaceShareSettingsDto>;
    generateWorkspaceShareLinkToken(input: GenerateWorkspaceShareLinkTokenInput): WorkspaceShareSettingsDto | Promise<WorkspaceShareSettingsDto>;
    setWorkspaceSharePermissions(input: SetWorkspaceSharePermissionsInput): WorkspaceShareSettingsDto | Promise<WorkspaceShareSettingsDto>;
    changeWorkspaceUserExplicitRole(input: ChangeWorkspaceUserExplicitRoleInput): WorkspaceShareSettingsDto | Promise<WorkspaceShareSettingsDto>;
    removeWorkspaceUserExplicitRole(input: RemoveWorkspaceUserExplicitRoleInput): WorkspaceShareSettingsDto | Promise<WorkspaceShareSettingsDto>;
    duplicateSharedWorkspace(input?: DuplicateSharedWorkspaceInput): DuplicateSharedWorkspaceResultDto | Promise<DuplicateSharedWorkspaceResultDto>;
    addWorkspaceTemplate(input: AddWorkspaceTemplateInput): WorkspaceDto | Promise<WorkspaceDto>;
    addWorkspaceFromWorkspaceTemplate(input: AddWorkspaceFromWorkspaceTemplateInput): WorkspaceDto | Promise<WorkspaceDto>;
    addBlabDefsFromWorkspaceTemplate(input: AddBlabDefsFromWorkspaceTemplateInput): AddBlabDefsFromWorkspaceTemplateResultDto | Promise<AddBlabDefsFromWorkspaceTemplateResultDto>;
    publishWorkspaceAsTemplate(input: PublishWorkspaceTemplateInput): WorkspaceTemplateDto | Promise<WorkspaceTemplateDto>;
    updateWorkspaceTemplateInfo(input: UpdateWorkspaceTemplateInfoInput): WorkspaceTemplateDto | Promise<WorkspaceTemplateDto>;
    uploadWorkspaceTemplatePreviewImage(upload: Upload): ThumbnailDto | Promise<ThumbnailDto>;
    uploadWorkspaceTemplateTitleImage(upload: Upload): ThumbnailDto | Promise<ThumbnailDto>;
    removeWorkspaceTemplate(input: RemoveWorkspaceTemplateInput): Void | Promise<Void>;
    deleteFlimDef(input: DeleteFlimDefInput): Void | Promise<Void>;
    addChatActivityReaction(input: AddChatActivityReactionInput): ChatActivityDto | Promise<ChatActivityDto>;
    removeChatActivityReaction(input: RemoveChatActivityReactionInput): ChatActivityDto | Promise<ChatActivityDto>;
    postChatMessage(input: PostChatMessageInput): ChatMessageActivityDto | Promise<ChatMessageActivityDto>;
    deleteChatMessage(input: DeleteChatMessageInput): Void | Promise<Void>;
    uploadChatMessageAttachment(input: UploadChatMessageAttachmentInput, attachment: Upload): ChatMessageAttachmentDto | Promise<ChatMessageAttachmentDto>;
    startOrContinueDialog(input: StartOrContinueDialogInput): ChatDialogDto | Promise<ChatDialogDto>;
    createChatGroup(input: CreateChatGroupInput, logo?: Upload): ChatGroupDto | Promise<ChatGroupDto>;
    leaveChatGroup(input?: LeaveChatGroupInput): Void | Promise<Void>;
    addUserToChatGroup(input: AddUserToChatGroupInput): Void | Promise<Void>;
    updateChatGroupInfo(input: UpdateChatGroupInfoInput, logo?: Upload): ChatGroupDto | Promise<ChatGroupDto>;
    muteConversation(input: MuteConversationInput): Void | Promise<Void>;
    unmuteConversation(input: UnmuteConversationInput): Void | Promise<Void>;
    markConversationAsRead(input: MarkConversationAsReadInput): Void | Promise<Void>;
    convertMultiAttachmentFlimToSingleAttachmentFlim(input: ConvertMultiAttachmentFlimToSingleAttachmentFlimInput): ConvertMultiAttachmentFlimToSingleAttachmentFlimResultDto | Promise<ConvertMultiAttachmentFlimToSingleAttachmentFlimResultDto>;
    convertSingleAttachmentFlimToMultiAttachmentFlim(input: ConvertSingleAttachmentFlimToMultiAttachmentFlimInput): ConvertSingleAttachmentFlimToMultiAttachmentFlimResultDto | Promise<ConvertSingleAttachmentFlimToMultiAttachmentFlimResultDto>;
    convertMultiCategoryFlimToSingleCategoryFlim(input: ConvertMultiCategoryFlimToSingleCategoryFlimInput): ConvertMultiCategoryFlimToSingleCategoryFlimResultDto | Promise<ConvertMultiCategoryFlimToSingleCategoryFlimResultDto>;
    convertSingleCategoryFlimToStatusFlim(input: ConvertSingleCategoryFlimToStatusFlimInput): ConvertSingleCategoryFlimToStatusFlimResultDto | Promise<ConvertSingleCategoryFlimToStatusFlimResultDto>;
    convertSingleCategoryFlimToSingleTextFlim(input: ConvertSingleCategoryFlimToSingleTextFlimInput): ConvertSingleCategoryFlimToSingleTextFlimResultDto | Promise<ConvertSingleCategoryFlimToSingleTextFlimResultDto>;
    convertSingleCategoryFlimToMultiCategoryFlim(input: ConvertSingleCategoryFlimToMultiCategoryFlimInput): ConvertSingleCategoryFlimToMultiCategoryFlimResultDto | Promise<ConvertSingleCategoryFlimToMultiCategoryFlimResultDto>;
    convertRangeDateFlimToSingleDateFlim(input: ConvertRangeDateFlimToSingleDateFlimInput): ConvertRangeDateFlimToSingleDateFlimResultDto | Promise<ConvertRangeDateFlimToSingleDateFlimResultDto>;
    convertSingleDateFlimToRangeDateFlim(input: ConvertSingleDateFlimToRangeDateFlimInput): ConvertSingleDateFlimToRangeDateFlimResultDto | Promise<ConvertSingleDateFlimToRangeDateFlimResultDto>;
    convertMultiRelationFlimToSingleRelationFlim(input: ConvertMultiRelationFlimToSingleRelationFlimInput): ConvertMultiRelationFlimToSingleRelationFlimResultDto | Promise<ConvertMultiRelationFlimToSingleRelationFlimResultDto>;
    convertSingleRelationFlimToMultiRelationFlim(input: ConvertSingleRelationFlimToMultiRelationFlimInput): ConvertSingleRelationFlimToMultiRelationFlimResultDto | Promise<ConvertSingleRelationFlimToMultiRelationFlimResultDto>;
    convertMultiTextFlimToSingleTextFlim(input: ConvertMultiTextFlimToSingleTextFlimInput): ConvertMultiTextFlimToSingleTextFlimResultDto | Promise<ConvertMultiTextFlimToSingleTextFlimResultDto>;
    convertSingleTextFlimToMultiTextFlim(input: ConvertSingleTextFlimToMultiTextFlimInput): ConvertSingleTextFlimToMultiTextFlimResultDto | Promise<ConvertSingleTextFlimToMultiTextFlimResultDto>;
    convertSingleTextFlimToSingleCategoryFlim(input: ConvertSingleTextFlimToSingleCategoryFlimInput): ConvertSingleTextFlimToSingleCategoryFlimResultDto | Promise<ConvertSingleTextFlimToSingleCategoryFlimResultDto>;
    convertSingleTextFlimToNumberFlim(input: ConvertSingleTextFlimToNumberFlimInput): ConvertSingleTextFlimToNumberFlimResultDto | Promise<ConvertSingleTextFlimToNumberFlimResultDto>;
    convertSingleTextFlimToMultiLinkFlim(input: ConvertSingleTextFlimToMultiLinkFlimInput): ConvertSingleTextFlimToMultiLinkFlimResultDto | Promise<ConvertSingleTextFlimToMultiLinkFlimResultDto>;
    convertMultiUserFlimToSingleUserFlim(input: ConvertMultiUserFlimToSingleUserFlimInput): ConvertMultiUserFlimToSingleUserFlimResultDto | Promise<ConvertMultiUserFlimToSingleUserFlimResultDto>;
    convertSingleUserFlimToMultiUserFlim(input: ConvertSingleUserFlimToMultiUserFlimInput): ConvertSingleUserFlimToMultiUserFlimResultDto | Promise<ConvertSingleUserFlimToMultiUserFlimResultDto>;
    updateBlabItemFocusEntryStatus(input: UpdateBlabItemFocusEntryStatusInput): BlabItemFocusEntryDto | Promise<BlabItemFocusEntryDto>;
    updateBlabItemFocusEntryDueAt(input: UpdateBlabItemFocusEntryDueAtInput): BlabItemFocusEntryDto | Promise<BlabItemFocusEntryDto>;
    updateChecklistFocusEntryTitle(input: UpdateChecklistFocusEntryTitleInput): ChecklistFocusEntryDto | Promise<ChecklistFocusEntryDto>;
    updateChecklistFocusEntryDescription(input: UpdateChecklistFocusEntryDescriptionInput): ChecklistFocusEntryDto | Promise<ChecklistFocusEntryDto>;
    markChecklistFocusEntryCompleted(input: MarkChecklistFocusEntryCompletedInput): ChecklistFocusEntryDto | Promise<ChecklistFocusEntryDto>;
    markChecklistFocusEntryIncomplete(input: MarkChecklistFocusEntryIncompleteInput): ChecklistFocusEntryDto | Promise<ChecklistFocusEntryDto>;
    updateChecklistFocusEntryDueAt(input: UpdateChecklistFocusEntryDueAtInput): ChecklistFocusEntryDto | Promise<ChecklistFocusEntryDto>;
    moveFocusEntry(input: MoveFocusEntryInput): FocusEntryUnionType | Promise<FocusEntryUnionType>;
    updateFocusEntrySection(input: UpdateFocusEntrySectionInput): FocusEntryUnionType | Promise<FocusEntryUnionType>;
    addReminderFocusEntry(input: AddReminderFocusEntryInput): ReminderFocusEntryDto | Promise<ReminderFocusEntryDto>;
    updateReminderFocusEntryTitle(input: UpdateReminderFocusEntryTitleInput): ReminderFocusEntryDto | Promise<ReminderFocusEntryDto>;
    updateReminderFocusEntryDescription(input: UpdateReminderFocusEntryDescriptionInput): ReminderFocusEntryDto | Promise<ReminderFocusEntryDto>;
    markReminderFocusEntryCompleted(input: MarkReminderFocusEntryCompletedInput): ReminderFocusEntryDto | Promise<ReminderFocusEntryDto>;
    markReminderFocusEntryIncomplete(input: MarkReminderFocusEntryIncompleteInput): ReminderFocusEntryDto | Promise<ReminderFocusEntryDto>;
    updateReminderFocusEntryDueAt(input: UpdateReminderFocusEntryDueAtInput): ReminderFocusEntryDto | Promise<ReminderFocusEntryDto>;
    createMultiAttachmentFlimDef(input: CreateMultiAttachmentFlimDefInput): MultiAttachmentFlimDefDto | Promise<MultiAttachmentFlimDefDto>;
    updateMultiAttachmentFlimDef(input: UpdateMultiAttachmentFlimDefInput): MultiAttachmentFlimDefDto | Promise<MultiAttachmentFlimDefDto>;
    createSingleAttachmentFlimDef(input: CreateSingleAttachmentFlimDefInput): SingleAttachmentFlimDefDto | Promise<SingleAttachmentFlimDefDto>;
    updateSingleAttachmentFlimDef(input: UpdateSingleAttachmentFlimDefInput): SingleAttachmentFlimDefDto | Promise<SingleAttachmentFlimDefDto>;
    createCalculationFlimDef(input: CreateCalculationFlimDefInput): CalculationFlimDefDto | Promise<CalculationFlimDefDto>;
    updateCalculationFlimDef(input: UpdateCalculationFlimDefInput): CalculationFlimDefDto | Promise<CalculationFlimDefDto>;
    createMultiCategoryFlimDef(input: CreateMultiCategoryFlimDefInput): MultiCategoryFlimDefDto | Promise<MultiCategoryFlimDefDto>;
    updateMultiCategoryFlimDef(input?: UpdateMultiCategoryFlimDefInput): MultiCategoryFlimDefDto | Promise<MultiCategoryFlimDefDto>;
    createSingleCategoryFlimDef(input: CreateSingleCategoryFlimDefInput): SingleCategoryFlimDefDto | Promise<SingleCategoryFlimDefDto>;
    updateSingleCategoryFlimDef(input?: UpdateSingleCategoryFlimDefInput): SingleCategoryFlimDefDto | Promise<SingleCategoryFlimDefDto>;
    createChecklistFlimDef(input: CreateChecklistFlimDefInput): ChecklistFlimDefDto | Promise<ChecklistFlimDefDto>;
    updateChecklistFlimDef(input: UpdateChecklistFlimDefInput): ChecklistFlimDefDto | Promise<ChecklistFlimDefDto>;
    createCreatedAtFlimDef(input: CreateCreatedAtFlimDefInput): CreatedAtFlimDefDto | Promise<CreatedAtFlimDefDto>;
    updateCreatedAtFlimDef(input: UpdateCreatedAtFlimDefInput): CreatedAtFlimDefDto | Promise<CreatedAtFlimDefDto>;
    createCreatedByFlimDef(input: CreateCreatedByFlimDefInput): CreatedByFlimDefDto | Promise<CreatedByFlimDefDto>;
    updateCreatedByFlimDef(input: UpdateCreatedByFlimDefInput): CreatedByFlimDefDto | Promise<CreatedByFlimDefDto>;
    createRangeDateFlimDef(input: CreateRangeDateFlimDefInput): RangeDateFlimDefDto | Promise<RangeDateFlimDefDto>;
    updateRangeDateFlimDef(input: UpdateRangeDateFlimDefInput): RangeDateFlimDefDto | Promise<RangeDateFlimDefDto>;
    createSingleDateFlimDef(input: CreateSingleDateFlimDefInput): SingleDateFlimDefDto | Promise<SingleDateFlimDefDto>;
    updateSingleDateFlimDef(input: UpdateSingleDateFlimDefInput): SingleDateFlimDefDto | Promise<SingleDateFlimDefDto>;
    createMultiEmailFlimDef(input: CreateMultiEmailFlimDefInput): MultiEmailFlimDefDto | Promise<MultiEmailFlimDefDto>;
    updateMultiEmailFlimDef(input: UpdateMultiEmailFlimDefInput): MultiEmailFlimDefDto | Promise<MultiEmailFlimDefDto>;
    createMultiImageFlimDef(input: CreateMultiImageFlimDefInput): MultiImageFlimDefDto | Promise<MultiImageFlimDefDto>;
    updateMultiImageFlimDef(input: UpdateMultiImageFlimDefInput): MultiImageFlimDefDto | Promise<MultiImageFlimDefDto>;
    createLastModifiedAtFlimDef(input: CreateLastModifiedAtFlimDefInput): LastModifiedAtFlimDefDto | Promise<LastModifiedAtFlimDefDto>;
    updateLastModifiedAtFlimDef(input: UpdateLastModifiedAtFlimDefInput): LastModifiedAtFlimDefDto | Promise<LastModifiedAtFlimDefDto>;
    createLastModifiedByFlimDef(input: CreateLastModifiedByFlimDefInput): LastModifiedByFlimDefDto | Promise<LastModifiedByFlimDefDto>;
    updateLastModifiedByFlimDef(input: UpdateLastModifiedByFlimDefInput): LastModifiedByFlimDefDto | Promise<LastModifiedByFlimDefDto>;
    createMultiLinkFlimDef(input: CreateMultiLinkFlimDefInput): MultiLinkFlimDefDto | Promise<MultiLinkFlimDefDto>;
    updateMultiLinkFlimDef(input: UpdateMultiLinkFlimDefInput): MultiLinkFlimDefDto | Promise<MultiLinkFlimDefDto>;
    createSingleLocationFlimDef(input: CreateSingleLocationFlimDefInput): SingleLocationFlimDefDto | Promise<SingleLocationFlimDefDto>;
    updateSingleLocationFlimDef(input: UpdateSingleLocationFlimDefInput): SingleLocationFlimDefDto | Promise<SingleLocationFlimDefDto>;
    createNumberFlimDef(input: CreateNumberFlimDefInput): NumberFlimDefDto | Promise<NumberFlimDefDto>;
    updateNumberFlimDef(input: UpdateNumberFlimDefInput): NumberFlimDefDto | Promise<NumberFlimDefDto>;
    createMultiPhoneFlimDef(input: CreateMultiPhoneFlimDefInput): MultiPhoneFlimDefDto | Promise<MultiPhoneFlimDefDto>;
    updateMultiPhoneFlimDef(input: UpdateMultiPhoneFlimDefInput): MultiPhoneFlimDefDto | Promise<MultiPhoneFlimDefDto>;
    createMultiRelationFlimDef(input: CreateMultiRelationFlimDefInput): MultiRelationFlimDefDto | Promise<MultiRelationFlimDefDto>;
    updateMultiRelationFlimDef(input: UpdateMultiRelationFlimDefInput): MultiRelationFlimDefDto | Promise<MultiRelationFlimDefDto>;
    createSingleRelationFlimDef(input: CreateSingleRelationFlimDefInput): SingleRelationFlimDefDto | Promise<SingleRelationFlimDefDto>;
    updateSingleRelationFlimDef(input: UpdateSingleRelationFlimDefInput): SingleRelationFlimDefDto | Promise<SingleRelationFlimDefDto>;
    createStatusFlimDef(input: CreateStatusFlimDefInput): StatusFlimDefDto | Promise<StatusFlimDefDto>;
    updateStatusFlimDef(input?: UpdateStatusFlimDefInput): StatusFlimDefDto | Promise<StatusFlimDefDto>;
    createMultiTextFlimDef(input: CreateMultiTextFlimDefInput): MultiTextFlimDefDto | Promise<MultiTextFlimDefDto>;
    updateMultiTextFlimDef(input: UpdateMultiTextFlimDefInput): MultiTextFlimDefDto | Promise<MultiTextFlimDefDto>;
    createSingleTextFlimDef(input: CreateSingleTextFlimDefInput): SingleTextFlimDefDto | Promise<SingleTextFlimDefDto>;
    updateSingleTextFlimDef(input: UpdateSingleTextFlimDefInput): SingleTextFlimDefDto | Promise<SingleTextFlimDefDto>;
    createUniqueIdFlimDef(input: CreateUniqueIdFlimDefInput): UniqueIdFlimDefDto | Promise<UniqueIdFlimDefDto>;
    updateUniqueIdFlimDef(input: UpdateUniqueIdFlimDefInput): UniqueIdFlimDefDto | Promise<UniqueIdFlimDefDto>;
    createMultiUserFlimDef(input: CreateMultiUserFlimDefInput): MultiUserFlimDefDto | Promise<MultiUserFlimDefDto>;
    updateMultiUserFlimDef(input: UpdateMultiUserFlimDefInput): MultiUserFlimDefDto | Promise<MultiUserFlimDefDto>;
    createSingleUserFlimDef(input: CreateSingleUserFlimDefInput): SingleUserFlimDefDto | Promise<SingleUserFlimDefDto>;
    updateSingleUserFlimDef(input: UpdateSingleUserFlimDefInput): SingleUserFlimDefDto | Promise<SingleUserFlimDefDto>;
    upsertMultiAttachmentFlimVal(input: UpsertMultiAttachmentFlimValInput): MultiAttachmentFlimValDto | Promise<MultiAttachmentFlimValDto>;
    updateMultiAttachmentFlimValFileReference(input: UpdateMultiAttachmentFlimValFileReferenceInput): MultiAttachmentFlimValDto | Promise<MultiAttachmentFlimValDto>;
    upsertSingleAttachmentFlimVal(input: UpsertSingleAttachmentFlimValInput): SingleAttachmentFlimValDto | Promise<SingleAttachmentFlimValDto>;
    upsertMultiCategoryFlimVal(input: UpsertMultiCategoryFlimValInput): MultiCategoryFlimValDto | Promise<MultiCategoryFlimValDto>;
    upsertSingleCategoryFlimVal(input: UpsertSingleCategoryFlimValInput): SingleCategoryFlimValDto | Promise<SingleCategoryFlimValDto>;
    createChecklistFlimValEntry(input: CreateChecklistFlimValEntryInput): ChecklistFlimValEntryDto | Promise<ChecklistFlimValEntryDto>;
    updateChecklistFlimValEntry(input: UpdateChecklistFlimValEntryInput): ChecklistFlimValEntryDto | Promise<ChecklistFlimValEntryDto>;
    deleteChecklistFlimValEntry(input: DeleteChecklistFlimValEntryInput): Void | Promise<Void>;
    upsertRangeDateFlimVal(input: UpsertRangeDateFlimValInput): RangeDateFlimValDto | Promise<RangeDateFlimValDto>;
    upsertSingleDateFlimVal(input: UpsertSingleDateFlimValInput): SingleDateFlimValDto | Promise<SingleDateFlimValDto>;
    createMultiEmailFlimValEntry(input: CreateMultiEmailFlimValEntryInput): MultiEmailFlimValEntryDto | Promise<MultiEmailFlimValEntryDto>;
    updateMultiEmailFlimValEntry(input: UpdateMultiEmailFlimValEntryInput): MultiEmailFlimValEntryDto | Promise<MultiEmailFlimValEntryDto>;
    deleteMultiEmailFlimValEntry(input: DeleteMultiEmailFlimValEntryInput): Void | Promise<Void>;
    upsertMultiImageFlimVal(input: UpsertMultiImageFlimValInput): MultiImageFlimValDto | Promise<MultiImageFlimValDto>;
    updateMultiImageFlimValFileReference(input: UpdateMultiImageFlimValFileReferenceInput): MultiImageFlimValDto | Promise<MultiImageFlimValDto>;
    createMultiLinkFlimValEntry(input: CreateMultiLinkFlimValEntryInput): MultiLinkFlimValEntryDto | Promise<MultiLinkFlimValEntryDto>;
    deleteMultiLinkFlimValEntry(input: DeleteMultiLinkFlimValEntryInput): Void | Promise<Void>;
    upsertSingleLocationFlimVal(input: UpsertSingleLocationFlimValInput): SingleLocationFlimValDto | Promise<SingleLocationFlimValDto>;
    upsertNumberFlimVal(input: UpsertNumberFlimValInput): NumberFlimValDto | Promise<NumberFlimValDto>;
    createMultiPhoneFlimValEntry(input: CreateMultiPhoneFlimValEntryInput): MultiPhoneFlimValEntryDto | Promise<MultiPhoneFlimValEntryDto>;
    updateMultiPhoneFlimValEntry(input: UpdateMultiPhoneFlimValEntryInput): MultiPhoneFlimValEntryDto | Promise<MultiPhoneFlimValEntryDto>;
    deleteMultiPhoneFlimValEntry(input: DeleteMultiPhoneFlimValEntryInput): Void | Promise<Void>;
    upsertMultiRelationFlimVal(input: UpsertMultiRelationFlimValInput): MultiRelationFlimValDto | Promise<MultiRelationFlimValDto>;
    upsertSingleRelationFlimVal(input: UpsertSingleRelationFlimValInput): SingleRelationFlimValDto | Promise<SingleRelationFlimValDto>;
    upsertStatusFlimVal(input: UpsertStatusFlimValInput): StatusFlimValDto | Promise<StatusFlimValDto>;
    upsertMultiTextFlimVal(input: UpsertMultiTextFlimValInput): MultiTextFlimValDto | Promise<MultiTextFlimValDto>;
    upsertSingleTextFlimVal(input: UpsertSingleTextFlimValInput): SingleTextFlimValDto | Promise<SingleTextFlimValDto>;
    upsertMultiUserFlimVal(input: UpsertMultiUserFlimValInput): MultiUserFlimValDto | Promise<MultiUserFlimValDto>;
    upsertSingleUserFlimVal(input: UpsertSingleUserFlimValInput): SingleUserFlimValDto | Promise<SingleUserFlimValDto>;
}

export interface NewsfeedActivityDto {
    id: number;
    following?: boolean;
    type: NewsfeedActivityType;
    blabItemActivity?: BlabItemActivityUnionType;
    blabDefActivity?: BlabDefActivityUnionType;
    workspaceActivity?: WorkspaceActivityUnionType;
    organizationActivity?: OrganizationActivityUnionType;
    userActivity?: UserActivityUnionType;
}

export interface NewsfeedActivityEdge {
    node: NewsfeedActivityDto;
    cursor: string;
}

export interface NoActiveOrIncompleteBillingSubscriptionDto {
    active: boolean;
    type: BillingSubscriptionType;
    periodEnd?: DateTime;
}

export interface NotificationClientApplicationBrowserNotificationDto {
    _void?: boolean;
}

export interface NotificationConnection {
    pageInfo: PageInfo;
    edges: NotificationEdge[];
}

export interface NotificationDto {
    type: NotificationType;
    userNotification?: UserNotificationDto;
    blabItemNotification?: BlabItemNotificationDto;
    workspaceNotification?: WorkspaceNotificationDto;
    audioRoomNotification?: AudioRoomNotificationDto;
    workflowDefNotification?: WorkflowDefNotificationDto;
    blabDefNotification?: BlabDefNotificationDto;
    userGroupNotification?: UserGroupNotificationDto;
}

export interface NotificationEdge {
    node: NotificationDto;
    cursor: string;
}

export interface NumberFlimDefDto extends FlimDefDto {
    id: number;
    blabDefId: number;
    blabDef?: BlabDefPreviewDto;
    name: string;
    slug: string;
    type: FlimType;
    position: string;
    required: boolean;
    hiddenIfEmpty: boolean;
    alwaysHidden: boolean;
    showHelpText: boolean;
    helpText?: string;
    showInRecord?: boolean;
    precision: number;
    unitLocation?: NumberFlimDefUnitLocation;
    unitLabel?: string;
    hideThousandSeparator: boolean;
}

export interface NumberFlimDefRevisionPreviewDto {
    id: number;
    name: string;
    type: FlimType;
    blabDefId: number;
    unitLocation?: NumberFlimDefUnitLocation;
    unitLabel?: string;
}

export interface NumberFlimFilterDto extends FlimFilterDto {
    id: number;
    type: FlimType;
    blabViewId?: number;
    flimDefId: number;
    position: string;
    derivedFromSplitBy?: boolean;
    decimal?: number;
    numberMatchType: NumberFilterMatchType;
}

export interface NumberFlimStatDefDto extends FlimStatDefDto {
    id: number;
    type: FlimType;
    blabViewId?: number;
    flimDefId: number;
    numberFlimStatType: NumberFlimStatType;
}

export interface NumberFlimStatResultDto extends FlimStatResultDto {
    numberFlimStatType: NumberFlimStatType;
    flimDefId: number;
    result?: number;
    flimType: FlimType;
}

export interface NumberFlimValDto extends FlimValDto {
    type: FlimType;
    blabItemId: number;
    flimDefId: number;
    decimal?: number;
}

export interface NumberFlimValRevisionDto extends FlimValRevisionDto {
    id: number;
    type: BlabItemRevisionType;
    flimType: FlimType;
    createdAt: DateTime;
    blabItemId: number;
    flimDefId: number;
    flimDef: NumberFlimDefRevisionPreviewDto;
    prevDecimal?: number;
    prevPrecision?: number;
    decimal?: number;
    precision?: number;
}

export interface OauthIntegrationDto {
    id: number;
    name: string;
    clientId: string;
    clientSecret: string;
    authorizationUrl: string;
    accessTokenUrl: string;
    resourceOwnerDetailsUrl?: string;
    hasValidAccessToken: boolean;
    accessTokenIssuedAt?: DateTime;
    scope: string[];
}

export interface OrganizationActivityConnection {
    edges: OrganizationActivityEdge[];
    pageInfo: PageInfo;
}

export interface OrganizationActivityEdge {
    node: OrganizationActivityUnionType;
    cursor: string;
}

export interface OrganizationActivityReplyConnection {
    edges: OrganizationActivityReplyEdge[];
    pageInfo: PageInfo;
}

export interface OrganizationActivityReplyDto {
    id: number;
    createdAt: DateTime;
    activityId: number;
    userId: number;
    user?: UserProfilePreviewDto;
    content: string;
    reactions: ReactionDto[];
}

export interface OrganizationActivityReplyEdge {
    node: OrganizationActivityReplyDto;
    cursor: string;
}

export interface OrganizationAuthenticationProviderDto {
    id: number;
    organizationId: number;
    hostAddress?: string;
    hostPort?: number;
    userName?: string;
    encryption?: SmtpEncryptionType;
    label: string;
    isTapeInternalSmtp: boolean;
    isDefault: boolean;
    fallbackSmtpAccountIds: number[];
}

export interface OrganizationAuthenticationProviderRejectionDto {
    rejectionReason: OrganizationAuthenticationProviderRejectionReason;
    errorMessage: string;
}

export interface OrganizationChangesSubscriptionEvent {
    payload: JSONObject;
}

export interface OrganizationCreatedActivityDto extends OrganizationActivityDto {
    id: number;
    organizationId: number;
    organization?: OrganizationProfileDto;
    type: OrganizationActivityType;
    createdAt: DateTime;
    author?: UserProfilePreviewDto;
    authorId?: number;
    numReplies: number;
    reactions: ReactionDto[];
    replies: OrganizationActivityReplyEdge[];
}

export interface OrganizationDto {
    id: number;
    name: string;
    slug: string;
    plan?: OrganizationPlan;
    workspaces: WorkspaceDto[];
    numUsers?: number;
    logo?: ThumbnailDto;
}

export interface OrganizationInvitationCreatedDto {
    type: CreateOrganizationInvitationResult;
    invitation: OrganizationInvitationDto;
}

export interface OrganizationInvitationDto {
    id: number;
    email: string;
    role: OrganizationRole;
    status: OrganizationInvitationStatus;
    createdAt: DateTime;
    acceptedAt?: DateTime;
    user?: UserProfilePreviewDto;
    userId?: number;
    author: UserProfilePreviewDto;
    authorId: number;
    approvedOrDeniedBy?: UserProfilePreviewDto;
    approvedOrDeniedById?: number;
}

export interface OrganizationInvitationEdgeDto {
    node: OrganizationInvitationDto;
    cursor: string;
}

export interface OrganizationInvitationEmailAlreadyInUseDto {
    type: CreateOrganizationInvitationResult;
    userId: number;
    user: UserProfilePreviewDto;
}

export interface OrganizationInvitationEmailAlreadyInvitedDto {
    type: CreateOrganizationInvitationResult;
}

export interface OrganizationInvitationsConnection {
    edges: OrganizationInvitationEdgeDto[];
    pageInfo: PageInfo;
}

export interface OrganizationInvitationSignupUserInfoDto {
    organizationId: number;
    organization: OrganizationProfileDto;
}

export interface OrganizationLogoChangedActivityDto extends OrganizationActivityDto {
    id: number;
    organizationId: number;
    organization?: OrganizationProfileDto;
    type: OrganizationActivityType;
    createdAt: DateTime;
    author?: UserProfilePreviewDto;
    authorId?: number;
    numReplies: number;
    reactions: ReactionDto[];
    replies: OrganizationActivityReplyEdge[];
}

export interface OrganizationNotificationActivityDto extends NotificationActivityDto {
    notificationId: number;
    direct?: DirectNotificationType;
    activity?: OrganizationActivityUnionType;
    activityReply?: OrganizationActivityReplyDto;
}

export interface OrganizationNotificationDto {
    id: number;
    type: NotificationType;
    read: boolean;
    updatedAt: DateTime;
    organization: OrganizationProfileDto;
    organizationId: number;
    numActivities: number;
    activities: OrganizationNotificationActivityDto[];
    involvedUsers: UserProfilePreviewDto[];
}

export interface OrganizationPlanDto {
    id: string;
    plan: OrganizationPlan;
    prices: BillingPriceDto[];
}

export interface OrganizationProfileDto {
    id: number;
    slug: string;
    name: string;
    logo?: ThumbnailDto;
    location?: string;
    website?: string;
    email?: string;
    phoneNumber?: string;
}

export interface OrganizationProfileEditDto {
    id: number;
    slug: string;
    name: string;
    description?: string;
    logo?: ThumbnailDto;
    location?: string;
    website?: string;
    email?: string;
    phoneNumber?: string;
}

export interface OrganizationSettingsDto {
    organizationId: number;
    defaultLanguage?: Language;
    preferFullnameInUserProfile: boolean;
    requireInvitationApproval: boolean;
}

export interface OrganizationShareContextDto extends ShareContextDto {
    type: ShareContextType;
    userSessions: ShareContextUserSessionDto[];
    canDuplicate: boolean;
}

export interface OrganizationSignupVerificationSessionDto {
    id: string;
}

export interface OrganizationSlugNoExistDto {
    void?: Void;
}

export interface OrganizationSmtpSettingsDto {
    id: number;
    organizationId: number;
    hostAddress?: string;
    hostPort?: number;
    userName?: string;
    encryption?: SmtpEncryptionType;
    label: string;
    isTapeInternalSmtp: boolean;
    isDefault: boolean;
    fallbackSmtpAccountIds: number[];
}

export interface OrganizationSmtpSettingsRejectionDto {
    rejectionReason: OrganizationSmtpSettingsRejectionReason;
    errorMessage: string;
}

export interface OrganizationUserDto {
    id: number;
    name: string;
    shortname?: string;
    email: string;
    organizationId: number;
    jobDescription?: string;
    role: OrganizationRole;
    deactivated: boolean;
    isBilled: boolean;
    profilePicture?: ThumbnailDto;
    invitationId?: number;
    invitation?: OrganizationInvitationDto;
}

export interface OrganizationUserEdgeDto {
    node: OrganizationUserDto;
    cursor: string;
}

export interface OrganizationUsersConnection {
    edges: OrganizationUserEdgeDto[];
    pageInfo: PageInfo;
}

export interface OrganizationWorkflowStatusDto {
    organizationIsThrottled: boolean;
    organizationIsDisabled: boolean;
}

export interface OrganizationWorkspaceDto {
    id: number;
    organizationId: number;
    name: string;
    path?: string[];
    icon: WorkspaceIcon;
    slug: string;
    position: string;
    type: WorkspaceType;
    archived: boolean;
    numUsers: number;
    createdAt: DateTime;
    activeUserRole?: WorkspaceRole;
}

export interface OrganizationWorkspaceEdgeDto {
    node: OrganizationWorkspaceDto;
    cursor: string;
}

export interface OrganizationWorkspacesConnection {
    edges: OrganizationWorkspaceEdgeDto[];
    pageInfo: PageInfo;
}

export interface OrgUserEdge {
    node: UserProfileDto;
    cursor: string;
}

export interface OrgUsersConnection {
    edges: OrgUserEdge[];
    pageInfo: PageInfo;
}

export interface PageInfo {
    hasPreviousPage: boolean;
    hasNextPage: boolean;
}

export interface PerformChangeUserEmailResult {
    result: PerformChangeUserEmailResultType;
}

export interface ProrationInvoiceItemDto extends InvoiceItemDto {
    id: string;
    type: InvoiceItemType;
    amount: number;
    currency: BillingCurrency;
}

export interface IQuery {
    _getBlabItemPersistedTitle(input: _GetBlabItemPersistedTitleInput): _BlabItemPersistedTitleDto | Promise<_BlabItemPersistedTitleDto>;
    _getDatabaseVersion(): _DatabaseVersionDto | Promise<_DatabaseVersionDto>;
    _testJobQueueExists(input?: _TestJobQueueExistsInput): _TestJobQueueExistsResult | Promise<_TestJobQueueExistsResult>;
    _getOrganizationJobStatus(input?: _GetOrganizationJobStatusInput): _GetOrganizationJobStatusResultDto | Promise<_GetOrganizationJobStatusResultDto>;
    _getPermissionV2EnabledForOrganization(): boolean | Promise<boolean>;
    _testTimeMockGetStatus(): _testTimeMockDto | Promise<_testTimeMockDto>;
    _getDateArrivedJobsBetweenDates(input: _GetDateArrivedJobsBetweenDatesInput): _DateArrivedJobDto[] | Promise<_DateArrivedJobDto[]>;
    _getDatePeriodicJobsBetweenDates(input: _GetDatePeriodicJobsBetweenDatesInput): _DatePeriodicJobDto[] | Promise<_DatePeriodicJobDto[]>;
    _getWorkflowOfOrganizationAreThrottled(): _GetWorkflowOfOrganizationAreThrottled | Promise<_GetWorkflowOfOrganizationAreThrottled>;
    _getOrganizationWorkflowUsageReports(input?: _OrganizationWorkflowUsageReportInput): _OrganizationWorkflowUsageReportDto[] | Promise<_OrganizationWorkflowUsageReportDto[]>;
    getActiveUserApiKey(): ActiveUserApiKeyDto | Promise<ActiveUserApiKeyDto>;
    getActiveUser(): ActiveUserDto | Promise<ActiveUserDto>;
    getActiveUserBlabDefFollowInfo(input: GetBlabDefFollowingInput): ActiveUserBlabDefFollowInfoDto | Promise<ActiveUserBlabDefFollowInfoDto>;
    getActiveUserBlabDefInfo(input: GetActiveUserBlabDefInfoInput): ActiveUserBlabDefInfoDto | Promise<ActiveUserBlabDefInfoDto>;
    getActiveUserBlabItemInfo(input: GetActiveUserBlabItemInfoInput): ActiveUserBlabItemInfoDto | Promise<ActiveUserBlabItemInfoDto>;
    getCalendarEvents(input: GetCalendarEventsInput): CalendarEventsDto | Promise<CalendarEventsDto>;
    getActiveUserCalendarSettings(input: GetActiveUserCalendarSettingsInput): ActiveUserCalendarSettingsDto | Promise<ActiveUserCalendarSettingsDto>;
    getActiveUserCalendarStaticShares(input: GetActiveUserCalendarStaticSharesInput): ActiveUserCalendarStaticSharesDto | Promise<ActiveUserCalendarStaticSharesDto>;
    getActiveUserCalendarSubscriptions(input?: GetActiveUserCalendarSubscriptionsInput): ActiveUserCalendarSubscriptionDto[] | Promise<ActiveUserCalendarSubscriptionDto[]>;
    getActiveUserDevPortalContext(): ActiveUserDevPortalContextUnionType | Promise<ActiveUserDevPortalContextUnionType>;
    getActiveUserDevPortalDemoBlabItem(): BlabItemPreviewDto | Promise<BlabItemPreviewDto>;
    getActiveUserEngagedBlabItems(): ActiveUserEngagedBlabItemPreviewDto[] | Promise<ActiveUserEngagedBlabItemPreviewDto[]>;
    getActiveUserExploreSuggestions(input: GetActiveUserExploreSuggestionsInput): ActiveUserExploreSuggestionDto[] | Promise<ActiveUserExploreSuggestionDto[]>;
    getActiveUserExploreSuggestionsForCategory(first: number, input?: GetActiveUserExploreSuggestionsForCategoryInput, after?: string): GetActiveUserExploreSuggestionsForCategoryConnection | Promise<GetActiveUserExploreSuggestionsForCategoryConnection>;
    getBlabItemExploreSuggestions(): ActiveUserExploreBlabItemPreviewDto[] | Promise<ActiveUserExploreBlabItemPreviewDto[]>;
    getActiveUserNotificationSettings(): ActiveUserNotificationSettingsDto | Promise<ActiveUserNotificationSettingsDto>;
    getActiveUserOnboardingTasks(): ActiveUserOnboardingTasksDto | Promise<ActiveUserOnboardingTasksDto>;
    getOrgUsers(first: number, input?: GetOrgUsersInput, after?: string): OrgUsersConnection | Promise<OrgUsersConnection>;
    getActiveOrganizationUserProfilesByEmailPrefix(input?: GetOrganizationUserByEmailInput): UserProfileDto[] | Promise<UserProfileDto[]>;
    getActiveUserProfile(): ActiveUserProfileDto | Promise<ActiveUserProfileDto>;
    getActiveUserQuickAddBlabDefs(input: GetActiveUserQuickAddBlabDefsInput): ActiveUserQuickAddBlabDefDto[] | Promise<ActiveUserQuickAddBlabDefDto[]>;
    getActiveUserSuggestedQuickAddBlabDefs(first: number, input?: GetActiveUserSuggestedQuickAddBlabDefsInput, after?: string): ActiveUserSuggestedQuickAddBlabDefDtoConnection | Promise<ActiveUserSuggestedQuickAddBlabDefDtoConnection>;
    getActiveUserUserInfo(input: GetActiveUserUserInfoInput): ActiveUserUserInfoDto | Promise<ActiveUserUserInfoDto>;
    getActiveUserWorkflowDefInfo(input: GetActiveUserWorkflowDefInfoInput): ActiveUserWorkflowDefInfoDto | Promise<ActiveUserWorkflowDefInfoDto>;
    getActiveUserRecentWorkflowDefs(): WorkflowCenterRecentWorkflowPreviewDto[] | Promise<WorkflowCenterRecentWorkflowPreviewDto[]>;
    getActiveUserWorkspaceInfo(input: GetActiveUserWorkspaceInfoInput): ActiveUserWorkspaceInfoDto | Promise<ActiveUserWorkspaceInfoDto>;
    getApiInfo(): ApiInfoDto | Promise<ApiInfoDto>;
    getAudioRoom(input?: GetAudioRoomInput): AudioRoomDto | Promise<AudioRoomDto>;
    getAudioRoomPreviews(input?: GetAudioRoomPreviewsInput): AudioRoomPreviewDto[] | Promise<AudioRoomPreviewDto[]>;
    getBillingCustomer(): BillingCustomerDto | Promise<BillingCustomerDto>;
    validateBillingCustomerInput(input: UpdateBillingCustomerInput): BillingCustomerValidationResultDto | Promise<BillingCustomerValidationResultDto>;
    getBillingSubscription(): GetBillingSubscriptionResult | Promise<GetBillingSubscriptionResult>;
    getPaymentMethods(): PaymentMethodUnionType[] | Promise<PaymentMethodUnionType[]>;
    getDefaultPaymentMethod(): PaymentMethodUnionType | Promise<PaymentMethodUnionType>;
    getSubscriptionInvoiceHistory(): SubscriptionInvoiceDto[] | Promise<SubscriptionInvoiceDto[]>;
    getSubscriptionCheckoutInvoicePreview(input: GetCreateSubscriptionInvoicePreviewInput): SubscriptionInvoicePreviewDto | Promise<SubscriptionInvoicePreviewDto>;
    getChangeSubscriptionBillingPeriodInvoicePreview(input: GetChangeSubscriptionBillingPeriodInvoicePreviewInput): SubscriptionInvoicePreviewDto | Promise<SubscriptionInvoicePreviewDto>;
    getChangeSubscriptionPlanInvoicePreview(input: GetChangeSubscriptionPlanInvoicePreviewInput): SubscriptionInvoicePreviewDto | Promise<SubscriptionInvoicePreviewDto>;
    getSubscriptionUpcomingInvoicePreview(): SubscriptionInvoicePreviewDto | Promise<SubscriptionInvoicePreviewDto>;
    getSubscriptionActivateRenewalInvoicePreview(input: GetSubscriptionActivateRenwalInvoicePreviewInput): SubscriptionInvoicePreviewDto | Promise<SubscriptionInvoicePreviewDto>;
    getBlabDef(id: number): BlabDefDto | Promise<BlabDefDto>;
    getBlabDefs(workspaceId: number): BlabDefPreviewDto[] | Promise<BlabDefPreviewDto[]>;
    getBlabDefDataImportActivities(input?: GetBlabDefDataImportActivitiesInput): BlabDefDataImportActivityPreviewDto[] | Promise<BlabDefDataImportActivityPreviewDto[]>;
    getBlabDefDataImportActivity(input?: GetBlabDefDataImportActivityInput): BlabDefDataImportActivityDto | Promise<BlabDefDataImportActivityDto>;
    getBlabDefDataImportToExistingBlabDefConfigPreview(input: GetBlabDefDataImportToExistingBlabDefConfigPreviewInput): BlabDefDataImportToExistingBlabDefConfigPreviewDto | Promise<BlabDefDataImportToExistingBlabDefConfigPreviewDto>;
    getBlabDefDataImportToExistingBlabDefConfigPreviewLookup(input: GetBlabDefDataImportToExistingBlabDefConfigPreviewLookupInput): BlabDefDataImportToExistingBlabDefConfigPreviewLookupDto | Promise<BlabDefDataImportToExistingBlabDefConfigPreviewLookupDto>;
    getBlabDefDataImportToNewBlabDefConfigPreview(input?: GetBlabDefDataImportToNewBlabDefConfigPreviewInput): BlabDefDataImportToNewBlabDefConfigPreviewDto | Promise<BlabDefDataImportToNewBlabDefConfigPreviewDto>;
    getBlabDefDataImportToNewBlabDefConfigPreviewLookup(input: GetBlabDefDataImportToNewBlabDefConfigPreviewLookupInput): BlabDefDataImportToNewBlabDefConfigPreviewLookupDto | Promise<BlabDefDataImportToNewBlabDefConfigPreviewLookupDto>;
    getBlabDefDeveloperInfo(input: GetBlabDefDeveloperInfoInput): BlabDefDeveloperInfoDto | Promise<BlabDefDeveloperInfoDto>;
    getActiveUserBlabDefInboundEmailSettings(input: GetActiveUserBlabDefInboundEmailSettingsInput): ActiveUserBlabDefInboundEmailSettingsDto | Promise<ActiveUserBlabDefInboundEmailSettingsDto>;
    searchBlabDefs(input: SearchBlabDefsInput): BlabDefSearchResultDto[] | Promise<BlabDefSearchResultDto[]>;
    getBlabDefSettings(input: GetBlabDefSettingsInput): BlabDefSettingsDto | Promise<BlabDefSettingsDto>;
    getSharedBlabDef(input: GetSharedBlabDefInput): SharedBlabDefDto | Promise<SharedBlabDefDto>;
    getBlabItem(id: number): BlabItemDetailDto | Promise<BlabItemDetailDto>;
    getBlabItemsForBlabDef(first: number, input?: GetBlabItemsForBlabDefInput, after?: string): BlabItemsConnection | Promise<BlabItemsConnection>;
    getSingleBlabItemActivity(input?: GetSingleBlabItemActivityInput): BlabItemActivityUnionType | Promise<BlabItemActivityUnionType>;
    getBlabItemActivity(first: number, input?: GetBlabItemActivityInput, after?: string): BlabItemActivityConnection | Promise<BlabItemActivityConnection>;
    getBlabItemActivityCounts(input: GetBlabItemActivityCountsInput): BlabItemActivityCountsDto | Promise<BlabItemActivityCountsDto>;
    getBlabItemActivityReplies(first: number, input?: GetBlabItemActivityRepliesInput, after?: string): BlabItemActivityReplyConnection | Promise<BlabItemActivityReplyConnection>;
    getBlabItemFocusSection(input: GetBlabItemFocusSectionInput): GetBlabItemFocusSectionResultDto | Promise<GetBlabItemFocusSectionResultDto>;
    getChecklistFlimValEntryFocusSection(input: GetChecklistFlimValEntryFocusSectionInput): GetChecklistFlimValEntryFocusSectionResultDto | Promise<GetChecklistFlimValEntryFocusSectionResultDto>;
    getBlabItemFollowers(first: number, input?: GetBlabItemFollowersInput, after?: string): BlabItemFollowersConnection | Promise<BlabItemFollowersConnection>;
    getIncomingRelationFlimDefs(input: GetIncomingRelationFlimDefsInput): IncomingRelationFlimDefPreviewDto[] | Promise<IncomingRelationFlimDefPreviewDto[]>;
    getIncomingBlabItems(first: number, input?: GetIncomingBlabItemsInput, after?: string): IncomingBlabItemsConnection | Promise<IncomingBlabItemsConnection>;
    getBlabItemReminders(first: number, input?: GetBlabItemRemindersInput, after?: string): ReminderConnection | Promise<ReminderConnection>;
    searchBlabItems(input: SearchBlabItemsInput): BlabItemSearchResultDto[] | Promise<BlabItemSearchResultDto[]>;
    getBlabItemsForSharedBlabDef(first: number, input?: GetBlabItemsForBlabDefInput, after?: string): BlabItemsConnection | Promise<BlabItemsConnection>;
    getBlabItemStatsForSharedBlabDef(input: GetBlabItemStatsInput): BlabItemStatsResultDto | Promise<BlabItemStatsResultDto>;
    getBlabItemStats(input: GetBlabItemStatsInput): BlabItemStatsResultDto | Promise<BlabItemStatsResultDto>;
    getActiveUserBlabItemTemplates(input: GetActiveUserBlabItemTemplatesInput): BlabItemTemplateDto[] | Promise<BlabItemTemplateDto[]>;
    getBlabView(input: GetBlabViewInput): BlabViewDto | Promise<BlabViewDto>;
    getBlabViews(blabDefId: number): BlabViewPreviewDto[] | Promise<BlabViewPreviewDto[]>;
    getActiveUserBlabViewPreviews(input: GetActiveUserBlabViewPreviewsInput): BlabViewPreviewDto[] | Promise<BlabViewPreviewDto[]>;
    getSharedBlabView(input: GetSharedBlabViewInput): BlabViewDto | Promise<BlabViewDto>;
    getSharedBlabViews(input: GetSharedBlabViewsInput): BlabViewPreviewDto[] | Promise<BlabViewPreviewDto[]>;
    searchExistingAndNewConversations(input: SearchExistingAndNewConversationsInput): ConversationSearchResultUnionType[] | Promise<ConversationSearchResultUnionType[]>;
    checkEmail(input: CheckEmailInput): CheckEmailResultDto | Promise<CheckEmailResultDto>;
    getUsersForFindMyOrgsToken(token: string): UserProfileDto[] | Promise<UserProfileDto[]>;
    getFocusDueDateSections(input: GetFocusDueDateSectionsInput): FocusDueDateSectionsDto | Promise<FocusDueDateSectionsDto>;
    getFocusSectionPreviews(input: GetFocusSectionPreviewsInput): FocusSectionPreviewDto[] | Promise<FocusSectionPreviewDto[]>;
    getFocusSection(input: GetFocusSectionInput): FocusSectionWithTotalDto | Promise<FocusSectionWithTotalDto>;
    getFocusSections(input: GetFocusSectionsInput): FocusSectionWithTotalDto[] | Promise<FocusSectionWithTotalDto[]>;
    getFocusSettings(input: GetFocusSettingsInput): FocusSettingsDto | Promise<FocusSettingsDto>;
    search(input: SearchInput): SearchResultUnionType[] | Promise<SearchResultUnionType[]>;
    getSearchHistory(): SearchHistoryItemUnionType[] | Promise<SearchHistoryItemUnionType[]>;
    checkGqlHealth(): CheckGqlHealthDto | Promise<CheckGqlHealthDto>;
    getLocationAutocompleteSuggestions(input: GetLocationAutocompleteSuggestionsInput): GetLocationAutocompleteSuggestionsResultUnionType | Promise<GetLocationAutocompleteSuggestionsResultUnionType>;
    getLocationDetails(input: GetLocationDetailsInput): GetLocationDetailsResultUnionType | Promise<GetLocationDetailsResultUnionType>;
    getChronologicalNewsfeed(first: number, input?: GetChronologicalNewsfeedInput, after?: string): ChronologicalNewsfeedConnection | Promise<ChronologicalNewsfeedConnection>;
    getNotifications(first: number, input?: GetNotificationsInput, after?: string): NotificationConnection | Promise<NotificationConnection>;
    getOrganizationActivity(first: number, input?: GetOrganizationActivityInput, after?: string): OrganizationActivityConnection | Promise<OrganizationActivityConnection>;
    getOrganizationActivityReplies(first: number, input?: GetOrganizationActivityRepliesInput, after?: string): OrganizationActivityReplyConnection | Promise<OrganizationActivityReplyConnection>;
    getOrganizationInvitations(first: number, input: GetOrganizationInvitationsInput, after?: string): OrganizationInvitationsConnection | Promise<OrganizationInvitationsConnection>;
    getUserInfoByOrganizationInvitationToken(token: string): GetUserInfoByOrganizationInvitationTokenResult | Promise<GetUserInfoByOrganizationInvitationTokenResult>;
    getOrganizationPlans(): OrganizationPlanDto[] | Promise<OrganizationPlanDto[]>;
    getOrganizationProfileById(orgId: number): OrganizationProfileDto | Promise<OrganizationProfileDto>;
    getOrganizationProfileBySlug(orgSlug: string): GetOrganizationProfileBySlugResult | Promise<GetOrganizationProfileBySlugResult>;
    getOrganizationProfileToEdit(): OrganizationProfileEditDto | Promise<OrganizationProfileEditDto>;
    getOrganizationAuthenticationProvider(): OrganizationAuthenticationProviderDto[] | Promise<OrganizationAuthenticationProviderDto[]>;
    getOrganizationSettings(): OrganizationSettingsDto | Promise<OrganizationSettingsDto>;
    getOrganizationSmtpSettings(): OrganizationSmtpSettingsDto[] | Promise<OrganizationSmtpSettingsDto[]>;
    getOrganizationUsers(first: number, input: GetOrganizationUsersInput, after?: string): OrganizationUsersConnection | Promise<OrganizationUsersConnection>;
    getOrganizationWorkspaces(first: number, input: GetOrganizationWorkspacesInput, after?: string): OrganizationWorkspacesConnection | Promise<OrganizationWorkspacesConnection>;
    searchRelationFlimDefBlabDefs(input: SearchRelationFlimDefBlabDefsInput): RelationFlimDefBlabDefSearchResultDto[] | Promise<RelationFlimDefBlabDefSearchResultDto[]>;
    getReminders(first: number, input?: GetRemindersInput, after?: string): ReminderConnection | Promise<ReminderConnection>;
    getReminder(input: GetReminderInput): ReminderDto | Promise<ReminderDto>;
    getNumReminders(input: GetNumRemindersInput): GetNumRemindersResultDto | Promise<GetNumRemindersResultDto>;
    getReminderFocusSection(input: GetReminderFocusSectionInput): GetReminderFocusSectionResultDto | Promise<GetReminderFocusSectionResultDto>;
    getShareContext(): ShareContextResultDto | Promise<ShareContextResultDto>;
    getStripeClientConfig(): StripeClientConfigDto | Promise<StripeClientConfigDto>;
    getUserActivity(input?: GetUserActivityInput): UserActivityUnionType | Promise<UserActivityUnionType>;
    getUserActivities(first: number, input?: GetUserActivitiesInput, after?: string): UserActivityConnection | Promise<UserActivityConnection>;
    getUserActivityReplies(first: number, input?: GetUserActivityRepliesInput, after?: string): UserActivityReplyConnection | Promise<UserActivityReplyConnection>;
    getUserClientAppBubbles(): UserClientAppBubbleDto[] | Promise<UserClientAppBubbleDto[]>;
    getUserFollowers(first: number, input?: GetUserFollowersInput, after?: string): UserFollowersConnection | Promise<UserFollowersConnection>;
    getUserFollowing(first: number, input?: GetUserFollowingInput, after?: string): UserFollowingConnection | Promise<UserFollowingConnection>;
    getUserFollowInfo(input?: GetUserFollowInfoInput): UserFollowInfoDto | Promise<UserFollowInfoDto>;
    checkUserPasswordValidity(input: CheckUserPasswordValidityInput): UserPasswordValidityResultDto | Promise<UserPasswordValidityResultDto>;
    getUserProfile(userId: number): UserProfileDto | Promise<UserProfileDto>;
    getServerVapidPubkey(): VapidKey | Promise<VapidKey>;
    searchUsers(input: SearchUsersInput): UserSearchResultDto[] | Promise<UserSearchResultDto[]>;
    searchUsersInWorkspace(input: SearchUsersInWorkspaceInput): UserSearchResultDto[] | Promise<UserSearchResultDto[]>;
    searchUsersWithBlabItemAccess(input: SearchUsersWithBlabItemAccessInput): UserSearchResultDto[] | Promise<UserSearchResultDto[]>;
    getUserSessions(): UserSessionDto[] | Promise<UserSessionDto[]>;
    getUserSettingsMicrosoftIntegrationStatus(): UserSettingsMicrosoftIntegrationStatusDto | Promise<UserSettingsMicrosoftIntegrationStatusDto>;
    getUserSettings(): UserSettingsDto | Promise<UserSettingsDto>;
    getUserTrash(first: number, input: GetUserTrashInput, after?: string): UserTrashConnection | Promise<UserTrashConnection>;
    getActiveUserGlobalWorkflowRunsFilteringSettings(): ActiveUserGlobalWorkflowRunsFilteringSettingsDto | Promise<ActiveUserGlobalWorkflowRunsFilteringSettingsDto>;
    getOrganizationWorkflowStatus(): OrganizationWorkflowStatusDto | Promise<OrganizationWorkflowStatusDto>;
    getWorkflowDefLookup(input: GetWorkflowDefLookupInput): WorkflowDefLookupDto | Promise<WorkflowDefLookupDto>;
    getWorkflowDefUniverseUsers(): UserProfilePreviewDto[] | Promise<UserProfilePreviewDto[]>;
    getWorkflowDefUniverseBlabDefRelationsWithFlimDefs(input?: GetBlabDefRelationsWithFlimDefsInput): BlabDefRelationsWithFlimDefsDto | Promise<BlabDefRelationsWithFlimDefsDto>;
    searchWorkflowExecutionContextBlabItems(input: SearchExecutionContextBlabItemsInput): BlabItemPreviewDto[] | Promise<BlabItemPreviewDto[]>;
    getWorkflowDefUniverseCallableWorkflowDefs(input: GetWorkflowDefUniverseCallableWorkflowDefsInput): WorkflowDefUniverseCallableWorkflowDefDto[] | Promise<WorkflowDefUniverseCallableWorkflowDefDto[]>;
    getWorkflowDefUniverseBlabViews(input: GetWorkflowDefUniverseBlabViewsInput): WorkflowDefUniverseBlabViewDto[] | Promise<WorkflowDefUniverseBlabViewDto[]>;
    getDefaultBlabViewsForBlabDefs(input: GetDefaultBlabViewsForBlabDefsInput): WorkflowDefLookupBlabViewDto[] | Promise<WorkflowDefLookupBlabViewDto[]>;
    getWorkflowDefWithLookup(input: GetWorkflowDefWithLookupInput): WorkflowDefWithLookupDto | Promise<WorkflowDefWithLookupDto>;
    getGlobalWorkflowRuns(first: number, input?: GetGlobalWorkflowRunsInput, after?: string): GlobalWorkflowRunsConnection | Promise<GlobalWorkflowRunsConnection>;
    exportGlobalWorkflowRuns(first: number, input: GetGlobalWorkflowRunsInput): ExportGlobalWorkflowRunsResultDto | Promise<ExportGlobalWorkflowRunsResultDto>;
    searchWorkflowPreviewsForGlobalRunsFiltering(input?: SearchWorkflowPreviewsForGlobalRunsFilteringInput): WorkflowCenterSearchWorkflowPreviewDto[] | Promise<WorkflowCenterSearchWorkflowPreviewDto[]>;
    getGlobalWorkflowRunDetail(id: number): GlobalWorkflowRunDetailDto | Promise<GlobalWorkflowRunDetailDto>;
    getWorkflowHomeCurrentInfo(input?: GetWorkflowHomeCurrentInfoInput): WorkflowHomeCurrentInfoDto | Promise<WorkflowHomeCurrentInfoDto>;
    getWorkflowHomeTimeIntervalInfo(input: GetWorkflowHomeTimeIntervalInfoInput): WorkflowHomeTimeIntervalInfoDto | Promise<WorkflowHomeTimeIntervalInfoDto>;
    getWorkflowRunBlabItem(id: number): BlabItemPreviewDto | Promise<BlabItemPreviewDto>;
    getWorkflowRunsOfWorkflowDef(first: number, input?: GetWorkflowRunsOfWorkflowDefInput, after?: string): WorkflowRunsConnection | Promise<WorkflowRunsConnection>;
    exportWorkflowRunsOfWorkflowDef(first: number, input: GetWorkflowRunsOfWorkflowDefInput): ExportWorkflowRunsOfWorkflowDefResultDto | Promise<ExportWorkflowRunsOfWorkflowDefResultDto>;
    getWorkflowRunDetail(id: number): WorkflowRunDetailDto | Promise<WorkflowRunDetailDto>;
    getLastWorkflowWebhookPayload(input: GetLastWorkflowWebhookPayloadInput): WorkflowWebhookLastPayloadDto | Promise<WorkflowWebhookLastPayloadDto>;
    getWorkflowCenterWebhooks(input: GetWorkflowCenterWebhooksInput): WorkflowCenterWebhookDto[] | Promise<WorkflowCenterWebhookDto[]>;
    getActiveUserWorkflowCenterWorkspaceOverview(): WorkflowCenterWorkspaceDto[] | Promise<WorkflowCenterWorkspaceDto[]>;
    searchWorkflowPreviews(input?: SearchWorkflowPreviewsInput): WorkflowCenterSearchWorkflowPreviewDto[] | Promise<WorkflowCenterSearchWorkflowPreviewDto[]>;
    getConfiguredWorkflowPreviewsByBlabDefId(input?: GetConfiguredWorkflowPreviewsByBlabDefIdInput): WorkflowCenterWorkflowPreviewDto[] | Promise<WorkflowCenterWorkflowPreviewDto[]>;
    getWorkspaceBySlug(wsSlug: string): WorkspaceDto | Promise<WorkspaceDto>;
    getWorkspaceById(id: number): WorkspaceDto | Promise<WorkspaceDto>;
    getWorkspaceActivityReplies(first: number, input?: GetWorkspaceActivityRepliesInput, after?: string): WorkspaceActivityReplyConnection | Promise<WorkspaceActivityReplyConnection>;
    getWorkspaceBlabDefs(first: number, input?: GetWorkspaceBlabDefsInput, after?: string): WorkspaceBlabDefConnection | Promise<WorkspaceBlabDefConnection>;
    getWorkspaceMemberships(first: number, input?: GetWorkspaceMembershipsInput, after?: string): WorkspaceMembershipConnection | Promise<WorkspaceMembershipConnection>;
    searchWorkspaceMembershipCandidates(input: SearchWorkspaceMembershipCandidatesInput): WorkspaceMembershipCandidateSearchResultDto | Promise<WorkspaceMembershipCandidateSearchResultDto>;
    getWorkspaceSettings(input: GetWorkspaceSettingsInput): WorkspaceSettingsDto | Promise<WorkspaceSettingsDto>;
    getWorkspaceShareSettings(input: GetWorkspaceShareSettingsInput): WorkspaceShareSettingsDto | Promise<WorkspaceShareSettingsDto>;
    getSharedWorkspace(input: GetSharedWorkspaceInput): SharedWorkspaceDto | Promise<SharedWorkspaceDto>;
    getWorkspaceTemplates(input: GetWorkspaceTemplatesInput): WorkspaceTemplateDto[] | Promise<WorkspaceTemplateDto[]>;
    getWorkspaceTemplate(input: GetWorkspaceTemplateInput): WorkspaceTemplateDto | Promise<WorkspaceTemplateDto>;
    getWorkspaceTemplatePublishInfo(input: GetWorkspaceTemplatePublishInfoInput): WorkspaceTemplatePublishInfoDto | Promise<WorkspaceTemplatePublishInfoDto>;
    getActiveUserTeamUserProfiles(): UserProfileDto[] | Promise<UserProfileDto[]>;
    getFlimDefs(blabDefId: number): FlimDefDto[] | Promise<FlimDefDto[]>;
    getFlimVals(blabItemId: number): FlimValDto[] | Promise<FlimValDto[]>;
    getChatActivities(first: number, input?: GetChatActivitiesInput, after?: string): ChatActivityDtoConnection | Promise<ChatActivityDtoConnection>;
    getDialog(input: GetDialogInput): ChatDialogDto | Promise<ChatDialogDto>;
    getChatGroup(input: GetChatGroupInput): ChatGroupDto | Promise<ChatGroupDto>;
    getChatGroupUsers(first: number, input?: GetChatGroupUsersInput, after?: string): ChatGroupUsersConnection | Promise<ChatGroupUsersConnection>;
    getConversation(input: GetConversationInput): ConversationDto | Promise<ConversationDto>;
    getConversations(first: number, input?: GetConversationsInput, after?: string): ConversationDtoConnection | Promise<ConversationDtoConnection>;
    getFocusEntries(first: number, input?: GetFocusEntriesInput, after?: string): FocusEntryConnection | Promise<FocusEntryConnection>;
    getBlabDefWithIncomingAndOutgoingBlabDefs(input: GetAllIncomingAndOutgoingBlabDefsInput): GetBlabDefWithIncomingAndOutgoingBlabDefsResultDto | Promise<GetBlabDefWithIncomingAndOutgoingBlabDefsResultDto>;
    getCalculationScriptResultPreview(input?: GetCalculationScriptResultPreviewInput): GetCalculationScriptResultPreviewResultUnionType | Promise<GetCalculationScriptResultPreviewResultUnionType>;
    searchBlabItemsForRelationFlimFilter(input: SearchBlabItemsForRelationFlimFilterInput): BlabItemSearchResultDto[] | Promise<BlabItemSearchResultDto[]>;
    searchUsersForUserFlimFilter(input: SearchUsersForUserFlimFilterInput): UserSearchResultDto[] | Promise<UserSearchResultDto[]>;
    searchUserFlimValUsers(input: SearchUserFlimValUsersInput): UserProfileDto[] | Promise<UserProfileDto[]>;
}

export interface RangeDateFlimDefDto extends FlimDefDto {
    id: number;
    blabDefId: number;
    blabDef?: BlabDefPreviewDto;
    name: string;
    slug: string;
    type: FlimType;
    position: string;
    required: boolean;
    hiddenIfEmpty: boolean;
    alwaysHidden: boolean;
    showHelpText: boolean;
    helpText?: string;
    showInRecord?: boolean;
    defaultStartDate?: DateTime;
    defaultHasTime?: boolean;
    defaultEndDate?: DateTime;
    requireEndDate: boolean;
    timeSetting?: TimeSetting;
    showInCalendar: boolean;
    isDueDateOfStatusField: boolean;
}

export interface RangeDateFlimFilterDto extends FlimFilterDto {
    id: number;
    type: FlimType;
    blabViewId?: number;
    flimDefId: number;
    position: string;
    derivedFromSplitBy?: boolean;
    rangeDateMatchType: RangeDateFilterMatchType;
    exactDate?: DateTime;
    exactEndDate?: DateTime;
    rangeDateRelativeDateRange?: RangeDateRelativeDateRangeType;
    rangeDateRelativeDate?: RangeDateRelativeDateType;
    numDays?: number;
    numWeeks?: number;
    numMonths?: number;
    weekday?: Weekday;
    quarterOfYear?: QuarterOfYear;
    monthOfYear?: MonthOfYear;
}

export interface RangeDateFlimStatDefDto extends FlimStatDefDto {
    id: number;
    type: FlimType;
    blabViewId?: number;
    flimDefId: number;
    rangeDateFlimStatType: RangeDateFlimStatType;
}

export interface RangeDateFlimStatResultDto extends FlimStatResultDto {
    rangeDateFlimStatType: RangeDateFlimStatType;
    flimDefId: number;
    result?: number;
    flimType: FlimType;
}

export interface RangeDateFlimValDto extends FlimValDto {
    type: FlimType;
    blabItemId: number;
    flimDefId: number;
    startDate?: DateTime;
    hasTime?: boolean;
    endDate?: DateTime;
}

export interface RangeDateFlimValRevisionDto extends FlimValRevisionDto {
    id: number;
    type: BlabItemRevisionType;
    flimType: FlimType;
    createdAt: DateTime;
    blabItemId: number;
    flimDefId: number;
    flimDef: FlimDefRevisionPreviewDto;
    prevStartDate?: DateTime;
    prevHasTime?: boolean;
    prevEndDate?: DateTime;
    startDate?: DateTime;
    hasTime?: boolean;
    endDate?: DateTime;
}

export interface RangeDateSplitByDto {
    blabViewId: number;
    type: FlimType;
    rangeDateFlimDefId: number;
    period: BlabViewSplitByPeriod;
    limit: number;
    sorting: BlabViewSplitBySorting;
    sections: RangeDateSplitBySectionDto[];
}

export interface RangeDateSplitBySectionDto extends SplitBySectionDto {
    id: string;
    type: FlimType;
    flimDefId: number;
    total: number;
    numCompleted?: number;
    numIncomplete?: number;
    startDate?: DateTime;
    endDate?: DateTime;
    weekday?: Weekday;
}

export interface ReactionDto {
    emoji: string;
    users: UserProfileDto[];
}

export interface ReferencingBlabItemEdge {
    node: BlabItemPreviewDto;
    cursor: string;
}

export interface ReferencingBlabItemsConnection {
    edges: ReferencingBlabItemEdge[];
    pageInfo: PageInfo;
}

export interface RelationFlimDefBlabDefSearchResultBlabDefPreviewDto {
    id: number;
    position: string;
    blabName: string;
    itemName: string;
    slug: string;
    icon: BlabDefIcon;
    iconv2?: JSONObject;
    itemIcon?: JSONObject;
    workspace?: WorkspacePreviewDto;
    workspaceId: number;
    blabViews?: BlabViewPreviewDto[];
}

export interface RelationFlimDefBlabDefSearchResultDto extends SearchResultDto {
    type: SearchResultType;
    blabDef: RelationFlimDefBlabDefSearchResultBlabDefPreviewDto;
}

export interface ReminderCalendarEventDto extends CalendarEventDto {
    id: string;
    type: CalendarEventType;
    startDate: DateTime;
    endDate?: DateTime;
    hasTime: boolean;
    title?: string;
    calendarSubscriptionId: number;
    calendarSubscription: ActiveUserCalendarSubscriptionDto;
    completed: boolean;
    reminderId: number;
}

export interface ReminderConnection {
    edges: ReminderEdge[];
    pageInfo: PageInfo;
}

export interface ReminderDto {
    id: number;
    statusUpdatedAt: DateTime;
    title?: string;
    description?: string;
    status: ReminderStatus;
    dueAt?: DateTime;
    dueAtHasTime?: boolean;
    isBeingNotified?: boolean;
    referencedBlabItem?: BlabItemPreviewDto;
    referencedBlabItemId?: number;
}

export interface ReminderEdge {
    node: ReminderUnionType;
    cursor: string;
}

export interface ReminderFocusEntryDto extends FocusEntryDto {
    id: number;
    type: FocusEntryType;
    position: string;
    sectionId: number;
    reminder: ReminderDto;
}

export interface RemoveWorkspaceMembershipRejected {
    void?: Void;
    rejectionReason: RemoveWorkspaceMembershipRejectionReason;
}

export interface RemoveWorkspaceMembershipSuccess {
    void?: Void;
}

export interface ReportActiveUserAudioRoomPresenceDto {
    result: ActiveUserAudioRoomPresenceType;
    audioRoom?: AudioRoomDetailDto;
}

export interface RequestChangeUserEmailVerificationRejectionDto {
    result: RequestChangeUserEmailVerificationRejectionType;
}

export interface SearchHistoryItemQueryDto extends SearchHistoryItemDto {
    id: number;
    type: SearchHistoryItemType;
    query: string;
}

export interface SearchHistoryItemResultDto extends SearchHistoryItemDto {
    id: number;
    type: SearchHistoryItemType;
    result: SearchResultUnionType;
}

export interface SepaDebitPaymentMethodDto extends PaymentMethodDto {
    id: string;
    type: PaymentMethodType;
    createdAt: DateTime;
    createdByUser?: UserProfilePreviewDto;
    createdByUserId?: number;
    isDefault: boolean;
    error?: PaymentMethodErrorType;
    lastFour: string;
    name: string;
    postalCode?: string;
}

export interface ShareContextResultDto {
    rejection?: ShareContextRejectionReason;
    context?: ShareContextUnionType;
}

export interface ShareContextUserSessionDto {
    active: boolean;
    organizationRole: OrganizationRole;
    organizationSlug: string;
    userId: number;
    createdAt: DateTime;
}

export interface SharedBlabDefDto {
    id: number;
    blabName: string;
    itemName: string;
    description?: string;
    icon: BlabDefIcon;
    flimDefs?: FlimDefDto[];
    workspaceId: number;
    position: string;
    type?: string;
    blabViews?: BlabViewPreviewDto[];
}

export interface SharedWorkspaceDto {
    id: number;
    name: string;
    description?: string;
    icon: WorkspaceIcon;
    blabDefs: SharedBlabDefDto[];
}

export interface SingleAttachmentFlimDefDto extends FlimDefDto {
    id: number;
    blabDefId: number;
    blabDef?: BlabDefPreviewDto;
    name: string;
    slug: string;
    type: FlimType;
    position: string;
    required: boolean;
    hiddenIfEmpty: boolean;
    alwaysHidden: boolean;
    showHelpText: boolean;
    helpText?: string;
    showInRecord?: boolean;
}

export interface SingleAttachmentFlimFilterDto extends FlimFilterDto {
    id: number;
    type: FlimType;
    blabViewId?: number;
    flimDefId: number;
    position: string;
    derivedFromSplitBy?: boolean;
    searchText?: string;
    singleAttachmentMatchType: SingleAttachmentFilterMatchType;
}

export interface SingleAttachmentFlimStatDefDto extends FlimStatDefDto {
    id: number;
    type: FlimType;
    blabViewId?: number;
    flimDefId: number;
    singleAttachmentFlimStatType: SingleAttachmentFlimStatType;
}

export interface SingleAttachmentFlimStatResultDto extends FlimStatResultDto {
    singleAttachmentFlimStatType: SingleAttachmentFlimStatType;
    flimDefId: number;
    result?: number;
    flimType: FlimType;
}

export interface SingleAttachmentFlimValDto extends FlimValDto {
    type: FlimType;
    blabItemId: number;
    flimDefId: number;
    attachmentId?: number;
    attachment?: FlimValAttachmentDto;
    cardThumbnailPositionX?: number;
    cardThumbnailPositionY?: number;
}

export interface SingleAttachmentFlimValRevisionDto extends FlimValRevisionDto {
    id: number;
    type: BlabItemRevisionType;
    flimType: FlimType;
    createdAt: DateTime;
    blabItemId: number;
    flimDefId: number;
    flimDef: FlimDefRevisionPreviewDto;
    prevAttachmentId?: number;
    prevAttachment?: FlimValAttachmentDto;
    attachmentId?: number;
    attachment?: FlimValAttachmentDto;
}

export interface SingleCategoryFlimDefDto extends FlimDefDto, CategoryFlimDefDto {
    id: number;
    blabDefId: number;
    blabDef?: BlabDefPreviewDto;
    name: string;
    slug: string;
    type: FlimType;
    position: string;
    required: boolean;
    hiddenIfEmpty: boolean;
    alwaysHidden: boolean;
    showHelpText: boolean;
    helpText?: string;
    layout: CategoryFlimLayout;
    categoryOptionDefs: SingleCategoryOptionDefDto[];
    showColorInCalendar: boolean;
    defaultCategoryOptionDefId?: number;
    showInRecord?: boolean;
}

export interface SingleCategoryFlimFilterDto extends FlimFilterDto {
    id: number;
    type: FlimType;
    blabViewId?: number;
    flimDefId: number;
    position: string;
    derivedFromSplitBy?: boolean;
    categoryOptionDefIds: number[];
    singleCategoryMatchType: SingleCategoryFilterMatchType;
}

export interface SingleCategoryFlimStatDefDto extends FlimStatDefDto {
    id: number;
    type: FlimType;
    blabViewId?: number;
    flimDefId: number;
    singleCategoryFlimStatType: SingleCategoryFlimStatType;
}

export interface SingleCategoryFlimStatResultDto extends FlimStatResultDto {
    singleCategoryFlimStatType: SingleCategoryFlimStatType;
    flimDefId: number;
    result?: number;
    flimType: FlimType;
}

export interface SingleCategoryFlimValDto extends FlimValDto {
    type: FlimType;
    blabItemId: number;
    flimDefId: number;
    categoryOptionDefId?: number;
}

export interface SingleCategoryFlimValRevisionDto extends FlimValRevisionDto {
    id: number;
    type: BlabItemRevisionType;
    flimType: FlimType;
    createdAt: DateTime;
    blabItemId: number;
    flimDefId: number;
    flimDef: CategoryFlimDefRevisionPreviewDto;
    prevCategoryOptionDefId?: number;
    categoryOptionDefId?: number;
}

export interface SingleCategoryOptionDefDto extends CategoryOptionDefDto {
    id: number;
    label: string;
    color?: SelectableColor;
    position: string;
}

export interface SingleCategorySplitByDto {
    blabViewId: number;
    type: FlimType;
    singleCategoryFlimDefId: number;
    limit: number;
    sorting: BlabViewSplitBySorting;
    sections: SingleCategorySplitBySectionDto[];
}

export interface SingleCategorySplitBySectionDto extends SplitBySectionDto {
    id: string;
    type: FlimType;
    flimDefId: number;
    total: number;
    numCompleted?: number;
    numIncomplete?: number;
    singleCategoryOptionDef?: SingleCategoryOptionDefDto;
    singleCategoryOptionDefId?: number;
}

export interface SingleDateFlimDefDto extends FlimDefDto {
    id: number;
    blabDefId: number;
    blabDef?: BlabDefPreviewDto;
    name: string;
    slug: string;
    type: FlimType;
    position: string;
    required: boolean;
    hiddenIfEmpty: boolean;
    alwaysHidden: boolean;
    showHelpText: boolean;
    helpText?: string;
    showInRecord?: boolean;
    defaultDate?: DateTime;
    defaultHasTime?: boolean;
    timeSetting?: TimeSetting;
    showInCalendar: boolean;
    isDueDateOfStatusField: boolean;
}

export interface SingleDateFlimFilterDto extends FlimFilterDto {
    id: number;
    type: FlimType;
    blabViewId?: number;
    flimDefId: number;
    position: string;
    derivedFromSplitBy?: boolean;
    singleDateMatchType: SingleDateFilterMatchType;
    exactDate?: DateTime;
    exactEndDate?: DateTime;
    singleDateRelativeDateRange?: SingleDateRelativeDateRangeType;
    singleDateRelativeDate?: SingleDateRelativeDateType;
    numDays?: number;
    numWeeks?: number;
    numMonths?: number;
    weekday?: Weekday;
    quarterOfYear?: QuarterOfYear;
    monthOfYear?: MonthOfYear;
}

export interface SingleDateFlimStatDefDto extends FlimStatDefDto {
    id: number;
    type: FlimType;
    blabViewId?: number;
    flimDefId: number;
    singleDateFlimStatType: SingleDateFlimStatType;
}

export interface SingleDateFlimStatResultDto extends FlimStatResultDto {
    singleDateFlimStatType: SingleDateFlimStatType;
    flimDefId: number;
    result?: number;
    flimType: FlimType;
}

export interface SingleDateFlimValDto extends FlimValDto {
    type: FlimType;
    blabItemId: number;
    flimDefId: number;
    date?: DateTime;
    hasTime?: boolean;
}

export interface SingleDateFlimValRevisionDto extends FlimValRevisionDto {
    id: number;
    type: BlabItemRevisionType;
    flimType: FlimType;
    createdAt: DateTime;
    blabItemId: number;
    flimDefId: number;
    flimDef: FlimDefRevisionPreviewDto;
    prevDate?: DateTime;
    prevHasTime?: boolean;
    date?: DateTime;
    hasTime?: boolean;
}

export interface SingleDateSplitByDto {
    blabViewId: number;
    type: FlimType;
    singleDateFlimDefId: number;
    period: BlabViewSplitByPeriod;
    limit: number;
    sorting: BlabViewSplitBySorting;
    sections: SingleDateSplitBySectionDto[];
}

export interface SingleDateSplitBySectionDto extends SplitBySectionDto {
    id: string;
    type: FlimType;
    flimDefId: number;
    total: number;
    numCompleted?: number;
    numIncomplete?: number;
    startDate?: DateTime;
    endDate?: DateTime;
    weekday?: Weekday;
}

export interface SingleLocationFlimDefDto extends FlimDefDto {
    id: number;
    blabDefId: number;
    blabDef?: BlabDefPreviewDto;
    name: string;
    slug: string;
    type: FlimType;
    position: string;
    required: boolean;
    hiddenIfEmpty: boolean;
    alwaysHidden: boolean;
    showHelpText: boolean;
    helpText?: string;
    showInRecord?: boolean;
    showMap: boolean;
}

export interface SingleLocationFlimFilterDto extends FlimFilterDto {
    id: number;
    type: FlimType;
    blabViewId?: number;
    flimDefId: number;
    position: string;
    derivedFromSplitBy?: boolean;
    searchText?: string;
    singleLocationMatchType: SingleLocationFilterMatchType;
}

export interface SingleLocationFlimStatDefDto extends FlimStatDefDto {
    id: number;
    type: FlimType;
    blabViewId?: number;
    flimDefId: number;
    singleLocationFlimStatType: SingleLocationFlimStatType;
}

export interface SingleLocationFlimStatResultDto extends FlimStatResultDto {
    singleLocationFlimStatType: SingleLocationFlimStatType;
    flimDefId: number;
    result?: number;
    flimType: FlimType;
}

export interface SingleLocationFlimValDto extends FlimValDto {
    type: FlimType;
    blabItemId: number;
    flimDefId: number;
    originalFormattedAddress?: string;
    originalAddressModified?: boolean;
    city?: string;
    country?: string;
    state?: string;
    postalCode?: string;
    streetAddress?: string;
    mapEmbedUrl?: string;
    googleMapsPlaceId?: string;
    hideMapEmbed?: boolean;
    locationId?: string;
    latitude?: number;
    longitude?: number;
}

export interface SingleLocationFlimValRevisionDto extends FlimValRevisionDto {
    id: number;
    type: BlabItemRevisionType;
    flimType: FlimType;
    createdAt: DateTime;
    blabItemId: number;
    flimDefId: number;
    flimDef: FlimDefRevisionPreviewDto;
    city?: string;
    prevCity?: string;
    state?: string;
    prevState?: string;
    country?: string;
    prevCountry?: string;
    streetAddress?: string;
    prevStreetAddress?: string;
    postalCode?: string;
    prevPostalCode?: string;
    originalFormattedAddress?: string;
    prevOriginalFormattedAddress?: string;
}

export interface SingleRelationFlimDefDto extends FlimDefDto, RelationFlimDefDto {
    id: number;
    blabDefId: number;
    blabDef?: BlabDefPreviewDto;
    name: string;
    slug: string;
    type: FlimType;
    position: string;
    required: boolean;
    hiddenIfEmpty: boolean;
    alwaysHidden: boolean;
    showHelpText: boolean;
    helpText?: string;
    showInRecord?: boolean;
    blabReferences?: BlabReferenceDto[];
}

export interface SingleRelationFlimFilterDto extends FlimFilterDto {
    id: number;
    type: FlimType;
    blabViewId?: number;
    flimDefId: number;
    position: string;
    derivedFromSplitBy?: boolean;
    blabItemReferences: BlabItemViewDto[];
    singleRelationMatchType: SingleRelationFilterMatchType;
}

export interface SingleRelationFlimStatDefDto extends FlimStatDefDto {
    id: number;
    type: FlimType;
    blabViewId?: number;
    flimDefId: number;
    singleRelationFlimStatType: SingleRelationFlimStatType;
}

export interface SingleRelationFlimStatResultDto extends FlimStatResultDto {
    singleRelationFlimStatType: SingleRelationFlimStatType;
    flimDefId: number;
    result?: number;
    flimType: FlimType;
}

export interface SingleRelationFlimValDto extends FlimValDto {
    type: FlimType;
    blabItemId: number;
    flimDefId: number;
    referencedBlabItemId?: number;
    referencedBlabItem?: BlabItemPreviewDto;
}

export interface SingleRelationFlimValRevisionDto extends FlimValRevisionDto {
    id: number;
    type: BlabItemRevisionType;
    flimType: FlimType;
    createdAt: DateTime;
    blabItemId: number;
    flimDefId: number;
    flimDef: FlimDefRevisionPreviewDto;
    prevReferencedBlabItemId?: number;
    prevReferencedBlabItem?: BlabItemPreviewDto;
    referencedBlabItemId?: number;
    referencedBlabItem?: BlabItemPreviewDto;
}

export interface SingleRelationSplitByDto {
    blabViewId: number;
    type: FlimType;
    singleRelationFlimDefId: number;
    limit: number;
    sorting: BlabViewSplitBySorting;
    sections: SingleRelationSplitBySectionDto[];
}

export interface SingleRelationSplitBySectionDto extends SplitBySectionDto {
    id: string;
    type: FlimType;
    flimDefId: number;
    total: number;
    numCompleted?: number;
    numIncomplete?: number;
    blabItem?: BlabItemPreviewDto;
    blabItemId?: number;
}

export interface SingleTextFlimDefDto extends FlimDefDto {
    id: number;
    blabDefId: number;
    blabDef?: BlabDefPreviewDto;
    name: string;
    slug: string;
    type: FlimType;
    position: string;
    required: boolean;
    hiddenIfEmpty: boolean;
    alwaysHidden: boolean;
    showHelpText: boolean;
    helpText?: string;
    showInRecord?: boolean;
    defaultValue?: string;
}

export interface SingleTextFlimFilterDto extends FlimFilterDto {
    id: number;
    type: FlimType;
    blabViewId?: number;
    flimDefId: number;
    position: string;
    derivedFromSplitBy?: boolean;
    searchText?: string;
    singleTextMatchType: SingleTextFilterMatchType;
}

export interface SingleTextFlimStatDefDto extends FlimStatDefDto {
    id: number;
    type: FlimType;
    blabViewId?: number;
    flimDefId: number;
    singleTextFlimStatType: SingleTextFlimStatType;
}

export interface SingleTextFlimStatResultDto extends FlimStatResultDto {
    singleTextFlimStatType: SingleTextFlimStatType;
    flimDefId: number;
    result?: number;
    flimType: FlimType;
}

export interface SingleTextFlimValDto extends FlimValDto {
    type: FlimType;
    blabItemId: number;
    flimDefId: number;
    value?: string;
}

export interface SingleTextFlimValRevisionDto extends FlimValRevisionDto {
    id: number;
    type: BlabItemRevisionType;
    flimType: FlimType;
    createdAt: DateTime;
    blabItemId: number;
    flimDefId: number;
    flimDef: FlimDefRevisionPreviewDto;
    prevValue?: string;
    value?: string;
}

export interface SingleUserFlimDefDto extends FlimDefDto {
    id: number;
    blabDefId: number;
    blabDef?: BlabDefPreviewDto;
    name: string;
    slug: string;
    type: FlimType;
    position: string;
    required: boolean;
    hiddenIfEmpty: boolean;
    alwaysHidden: boolean;
    showInFocus: boolean;
    showHelpText: boolean;
    helpText?: string;
    showInRecord?: boolean;
    doNotNotify?: boolean;
}

export interface SingleUserFlimFilterDto extends FlimFilterDto {
    id: number;
    type: FlimType;
    blabViewId?: number;
    flimDefId: number;
    position: string;
    derivedFromSplitBy?: boolean;
    referencedUsers: UserProfilePreviewDto[];
    singleUserMatchType: SingleUserFilterMatchType;
    includeActiveUser: boolean;
    includeActiveUserIndex?: number;
}

export interface SingleUserFlimStatDefDto extends FlimStatDefDto {
    id: number;
    type: FlimType;
    blabViewId?: number;
    flimDefId: number;
    singleUserFlimStatType: SingleUserFlimStatType;
}

export interface SingleUserFlimStatResultDto extends FlimStatResultDto {
    singleUserFlimStatType: SingleUserFlimStatType;
    flimDefId: number;
    result?: number;
    flimType: FlimType;
}

export interface SingleUserFlimValDto extends FlimValDto {
    type: FlimType;
    blabItemId: number;
    flimDefId: number;
    userId?: number;
    user?: UserProfilePreviewDto;
}

export interface SingleUserFlimValRevisionDto extends FlimValRevisionDto {
    id: number;
    type: BlabItemRevisionType;
    flimType: FlimType;
    createdAt: DateTime;
    blabItemId: number;
    flimDefId: number;
    flimDef: FlimDefRevisionPreviewDto;
    prevUserId?: number;
    prevUser?: UserProfilePreviewDto;
    userId?: number;
    user?: UserProfilePreviewDto;
}

export interface SingleUserSplitByDto {
    blabViewId: number;
    type: FlimType;
    singleUserFlimDefId: number;
    limit: number;
    sorting: BlabViewSplitBySorting;
    sections: SingleUserSplitBySectionDto[];
}

export interface SingleUserSplitBySectionDto extends SplitBySectionDto {
    id: string;
    type: FlimType;
    flimDefId: number;
    total: number;
    numCompleted?: number;
    numIncomplete?: number;
    user?: UserProfilePreviewDto;
    userId?: number;
}

export interface SpreadsheetColumnFlimDefCandidatesDto {
    suggestedFlimType: FlimType;
    flimTypeCandidates: FlimType[];
    flimDefName: string;
    spreadsheetColumnKey: string;
}

export interface SpreadsheetImportFlimDefCadidatesDto {
    worksheetId: number;
    flimCandidates: SpreadsheetColumnFlimDefCandidatesDto[];
}

export interface StatusFlimDefDto extends FlimDefDto {
    id: number;
    blabDefId: number;
    blabDef?: BlabDefPreviewDto;
    name: string;
    slug: string;
    type: FlimType;
    position: string;
    required: boolean;
    hiddenIfEmpty: boolean;
    alwaysHidden: boolean;
    showHelpText: boolean;
    helpText?: string;
    statusLayout: StatusFlimLayout;
    statusOptionDefs: StatusOptionDefDto[];
    showColorInCalendar: boolean;
    defaultStatusOptionDefId?: number;
    showInRecord?: boolean;
}

export interface StatusFlimDefRevisionPreviewDto {
    id: number;
    name: string;
    type: FlimType;
    blabDefId: number;
    statusOptionDefs: StatusOptionDefRevisionPreviewDto[];
}

export interface StatusFlimFilterDto extends FlimFilterDto {
    id: number;
    type: FlimType;
    blabViewId?: number;
    flimDefId: number;
    position: string;
    derivedFromSplitBy?: boolean;
    statusOptionDefIds: number[];
    statusMatchType: StatusFilterMatchType;
}

export interface StatusFlimStatDefDto extends FlimStatDefDto {
    id: number;
    type: FlimType;
    blabViewId?: number;
    flimDefId: number;
    statusFlimStatType: StatusFlimStatType;
}

export interface StatusFlimStatResultDto extends FlimStatResultDto {
    statusFlimStatType: StatusFlimStatType;
    flimDefId: number;
    result?: number;
    flimType: FlimType;
}

export interface StatusFlimValDto extends FlimValDto {
    type: FlimType;
    blabItemId: number;
    flimDefId: number;
    statusOptionDefId?: number;
}

export interface StatusFlimValRevisionDto extends FlimValRevisionDto {
    id: number;
    type: BlabItemRevisionType;
    flimType: FlimType;
    createdAt: DateTime;
    blabItemId: number;
    flimDefId: number;
    flimDef: StatusFlimDefRevisionPreviewDto;
    prevStatusOptionDefId?: number;
    statusOptionDefId?: number;
}

export interface StatusOptionDefDto extends CategoryOptionDefDto {
    id: number;
    label: string;
    color?: SelectableColor;
    meansCompleted: boolean;
    position: string;
}

export interface StatusOptionDefRevisionPreviewDto {
    id: number;
    label: string;
}

export interface StatusSplitByDto {
    blabViewId: number;
    type: FlimType;
    statusFlimDefId: number;
    limit: number;
    sorting: BlabViewSplitBySorting;
    sections: StatusSplitBySectionDto[];
}

export interface StatusSplitBySectionDto extends SplitBySectionDto {
    id: string;
    type: FlimType;
    flimDefId: number;
    total: number;
    numCompleted?: number;
    numIncomplete?: number;
    statusOptionDef?: StatusOptionDefDto;
    statusOptionDefId?: number;
}

export interface StripeClientConfigDto {
    publishableApiKey: string;
}

export interface SubscribeToBlabDefDuplicationJobFailureEvent {
    jobId: string;
    errorMsg: string;
}

export interface SubscribeToBlabDefDuplicationJobSuccessEvent {
    jobId: string;
}

export interface SubscribeToBulkFollowUnfollowBlabItemsFailureEvent {
    jobId: string;
    success: boolean;
}

export interface SubscribeToBulkFollowUnfollowBlabItemsSuccessEvent {
    jobId: string;
    success: boolean;
}

export interface SubscribeToExportBlabDefDataJobFailureEvent {
    dataExportId: number;
    errorType?: SubscribeToExportBlabDefDataJobFailureErrorType;
}

export interface SubscribeToExportBlabDefDataJobProgressEvent {
    dataExportId: number;
    progress: number;
}

export interface SubscribeToExportBlabDefDataJobSuccessEvent {
    dataExportId: number;
    downloadUrl: string;
    name: string;
    numExportedBlabItemRows: number;
}

export interface SubscribeToImportBlabDefDataJobFailureEvent {
    jobId: number;
}

export interface SubscribeToImportBlabDefDataJobProgressEvent {
    jobId: number;
    progress: number;
}

export interface SubscribeToImportBlabDefDataJobSuccessEvent {
    jobId: number;
    numImportedBlabItemRows: number;
}

export interface SubscribeToOauthIntegrationAuthenticationStatusFailureEvent {
    integrationId: number;
    errorMsg: string;
}

export interface SubscribeToOauthIntegrationAuthenticationStatusSuccessEvent {
    integrationId: number;
}

export interface SubscribeToUpdateBlabItemsFlimValFailureEvent {
    jobId: string;
    success: boolean;
}

export interface SubscribeToUpdateBlabItemsFlimValSuccessEvent {
    jobId: string;
    success: boolean;
}

export interface SubscribeToWorkspaceDuplicationJobFailureEvent {
    jobId: string;
    errorMsg: string;
}

export interface SubscribeToWorkspaceDuplicationJobSuccessEvent {
    jobId: string;
}

export interface ISubscription {
    subscribeToActiveUserChanges(input: SubscribeToActiveUserChangesInput): ActiveUserChangesSubscriptionEvent | Promise<ActiveUserChangesSubscriptionEvent>;
    subscribeToActiveUserBlabItemInfo(input: GetActiveUserBlabItemInfoInput): ActiveUserBlabItemInfoDto | Promise<ActiveUserBlabItemInfoDto>;
    subscribeToAudioRoom(input: SubscribeToAudioRoomInput): AudioRoomDto | Promise<AudioRoomDto>;
    subscribeToAudioRoomPreviews(input: SubscribeToAudioRoomPreviewsInput): AudioRoomPreviewDto[] | Promise<AudioRoomPreviewDto[]>;
    subscribeToExportBlabDefDataProgress(input: SubscribeToExportBlabDefDataProgressInput): SubscribeToExportBlabDefDataJobResult | Promise<SubscribeToExportBlabDefDataJobResult>;
    subscribeToImportBlabDefDataProgress(input: SubscribeToImportBlabDefDataProgressInput): SubscribeToImportBlabDefDataJobResult | Promise<SubscribeToImportBlabDefDataJobResult>;
    subscribeToBlabDefDeveloperInfo(input: SubscribeToBlabDefDeveloperInfoInput): BlabDefDeveloperInfoSubscriptionEventUnionType[] | Promise<BlabDefDeveloperInfoSubscriptionEventUnionType[]>;
    subscribeToDuplicateBlabDefProgress(input: SubscribeToDuplicateBlabDefProgressInput): SubscribeToBlabDefDuplicationJobResult | Promise<SubscribeToBlabDefDuplicationJobResult>;
    subscribeToUpdateBlabItemsFlimValProgress(input: SubscribeToUpdateBlabItemsFlimValInput): SubscribeToUpdateBlabItemsFlimValJobResult | Promise<SubscribeToUpdateBlabItemsFlimValJobResult>;
    subscribeToBlabItemActivity(blabItemId: number): BlabItemActivitySubscriptionUpdate | Promise<BlabItemActivitySubscriptionUpdate>;
    subscribeToBlabItemActivityReplies(activityId: number): BlabItemActivityReplySubscriptionUpdate | Promise<BlabItemActivityReplySubscriptionUpdate>;
    subscribeToBulkFollowUnfollowBlabItemsProgress(input: SubscribeToBulkFollowUnfollowBlabItemsInput): SubscribeToBulkFollowUnfollowBlabItemsResult | Promise<SubscribeToBulkFollowUnfollowBlabItemsResult>;
    subscribeToBlabItemData(blabItemId: number): FlimValDto | Promise<FlimValDto>;
    subscribeToClientApplicationBrowserNotifications(): ClientApplicationBrowserNotificationUnionType | Promise<ClientApplicationBrowserNotificationUnionType>;
    subscribeToNewsfeedActivity(): NewsfeedActivityDto | Promise<NewsfeedActivityDto>;
    subscribeToUserNotifications(input: GetNotificationsInput): NotificationDto | Promise<NotificationDto>;
    subscribeToOauthIntegrationAuthenticationStatus(input: SubscribeToOauthIntegrationAuthenticationStatusInput): SubscribeToOauthIntegrationAuthenticationStatusResult | Promise<SubscribeToOauthIntegrationAuthenticationStatusResult>;
    subscribeToOrganizationChanges(input: SubscribeToOrganizationChangesInput): OrganizationChangesSubscriptionEvent | Promise<OrganizationChangesSubscriptionEvent>;
    subscribeToOrganizationActivity(organizationId: number): OrganizationActivityUnionType | Promise<OrganizationActivityUnionType>;
    subscribeToOrganizationActivityReplies(activityId: number): OrganizationActivityReplyDto | Promise<OrganizationActivityReplyDto>;
    subscribeToReminders(): ReminderUnionType | Promise<ReminderUnionType>;
    subscribeToUserActivity(userId: number): UserActivityUnionType | Promise<UserActivityUnionType>;
    subscribeToUserActivityReplies(activityId: number): UserActivityReplyDto | Promise<UserActivityReplyDto>;
    subscribeToUserClientAppBubbles(): UserClientAppBubbleDto | Promise<UserClientAppBubbleDto>;
    subscribeToUserClientApplicationChanges(input: SubscribeToUserClientApplicationChangesInput): UserClientApplicationChangesSubscriptionEvent | Promise<UserClientApplicationChangesSubscriptionEvent>;
    subscribeToUserStatuses(input: SubscribeToUserStatusesInput): UserStatusDto | Promise<UserStatusDto>;
    subscribeToOrganizationWorkflowStatus(): OrganizationWorkflowStatusDto | Promise<OrganizationWorkflowStatusDto>;
    subscribeToGlobalWorkflowRuns(): GlobalWorkflowRunsSubscriptionUpdateDto | Promise<GlobalWorkflowRunsSubscriptionUpdateDto>;
    subscribeToManualRunsCompletion(input?: SubscribeToManualRunsCompletionInput): ManualRunsCompletedDto | Promise<ManualRunsCompletedDto>;
    subscribeToWorkflowRunsOfWorkflowDef(input?: SubscribeToWorkflowRunsOfWorkflowDefInput): WorkflowRunsSubscriptionUpdate | Promise<WorkflowRunsSubscriptionUpdate>;
    subscribeToWorkflowCenterWebhooks(input: SubscribeToWorkflowCenterWebhooksInput): WorkflowCenterWebhookEventUnionType[] | Promise<WorkflowCenterWebhookEventUnionType[]>;
    subscribeToDuplicateWorkspaceProgress(input: SubscribeToDuplicateWorkspaceProgressInput): SubscribeToWorkspaceDuplicationJobResult | Promise<SubscribeToWorkspaceDuplicationJobResult>;
    subscribeToUserConversationPreviews(): ConversationDto | Promise<ConversationDto>;
    subscribeToSingleConversation(conversationId: number): SingleConversationSubscriptionUpdate | Promise<SingleConversationSubscriptionUpdate>;
    subscribeToFocusEntries(input: SubscribeToFocusEntriesInput): FocusEntrySubscriptionUpdateUnionType | Promise<FocusEntrySubscriptionUpdateUnionType>;
}

export interface SubscriptionInvoiceDto {
    id: string;
    createdAt: DateTime;
    amountDue: number;
    number: string;
    currency: BillingCurrency;
    status: SubscriptionInvoiceStatus;
    pdfDownloadUrl?: string;
}

export interface SubscriptionInvoicePreviewDto {
    plan: OrganizationPlan;
    billingPeriod: BillingPeriod;
    total: number;
    amountDue: number;
    currency: BillingCurrency;
    items: InvoiceItemUnionType[];
}

export interface TaxInvoiceItemDto extends InvoiceItemDto {
    id: string;
    type: InvoiceItemType;
    reason: TaxInvoiceItemReason;
    taxId?: string;
    displayName: string;
    amount: number;
    currency: BillingCurrency;
    percentage: number;
}

export interface ThumbnailDto {
    id: number;
    small: string;
    smallWidth?: number;
    smallHeight?: number;
    medium: string;
    mediumWidth?: number;
    mediumHeight?: number;
    large: string;
    largeWidth?: number;
    largeHeight?: number;
}

export interface TimestampMetaFilterDto extends MetaFilterDto {
    id: number;
    metaType: BlabItemMetaType;
    blabViewId: number;
    from?: DateTime;
    to?: DateTime;
}

export interface UniqueIdFlimDefDto extends FlimDefDto {
    id: number;
    blabDefId: number;
    blabDef?: BlabDefPreviewDto;
    name: string;
    slug: string;
    type: FlimType;
    position: string;
    required: boolean;
    hiddenIfEmpty: boolean;
    alwaysHidden: boolean;
    showHelpText: boolean;
    helpText?: string;
    showInRecord?: boolean;
    minDigits: number;
    uniqueIdPrefix?: string;
}

export interface UniqueIdFlimFilterDto extends FlimFilterDto {
    id: number;
    type: FlimType;
    blabViewId?: number;
    flimDefId: number;
    position: string;
    derivedFromSplitBy?: boolean;
    decimal?: number;
    uniqueIdMatchType: UniqueIdFilterMatchType;
}

export interface UniqueIdFlimStatDefDto extends FlimStatDefDto {
    id: number;
    type: FlimType;
    blabViewId?: number;
    flimDefId: number;
    uniqueIdFlimStatType: UniqueIdFlimStatType;
}

export interface UniqueIdFlimStatResultDto extends FlimStatResultDto {
    uniqueIdFlimStatType: UniqueIdFlimStatType;
    flimDefId: number;
    result?: number;
    flimType: FlimType;
}

export interface UniqueIdFlimValDto extends FlimValDto {
    type: FlimType;
    blabItemId: number;
    flimDefId: number;
    uniqueId?: number;
}

export interface UpdateBlabDefWebhookResultDto {
    webhook: BlabDefWebhookDto;
    verificationRequested: boolean;
}

export interface UpdateBlabItemsFlimValResponse {
    jobId?: string;
}

export interface UpdateOrganizationInfoRejectedEmailUnavailable {
    void?: Void;
}

export interface UpdateOrganizationSlugResultDto {
    type: UpdateOrganizationSlugResultType;
    slug: string;
}

export interface UpdateWorkflowCenterWebhookResultDto {
    webhook: WorkflowCenterWebhookDto;
    verificationRequested: boolean;
}

export interface UpdateWorkspaceSlugResultDto {
    type: UpdateWorkspaceSlugResultType;
    slug: string;
}

export interface UserActivityConnection {
    edges: UserActivityEdge[];
    pageInfo: PageInfo;
}

export interface UserActivityEdge {
    node: UserActivityUnionType;
    cursor: string;
}

export interface UserActivityReplyConnection {
    edges: UserActivityReplyEdge[];
    pageInfo: PageInfo;
}

export interface UserActivityReplyDto {
    id: number;
    createdAt: DateTime;
    activityId: number;
    userId: number;
    user?: UserProfilePreviewDto;
    content: string;
    reactions: ReactionDto[];
}

export interface UserActivityReplyEdge {
    node: UserActivityReplyDto;
    cursor: string;
}

export interface UserClientAppBubbleDto {
    type: UserClientAppBubbleType;
    userId: number;
    amount: number;
}

export interface UserClientApplicationChangesSubscriptionEvent {
    payload: JSONObject;
}

export interface UserCreatedActivityDto extends UserActivityDto {
    id: number;
    userId: number;
    user?: UserProfileDto;
    type: UserActivityType;
    createdAt: DateTime;
    author?: UserProfilePreviewDto;
    authorId?: number;
    numReplies: number;
    reactions: ReactionDto[];
    replies: UserActivityReplyEdge[];
}

export interface UserDto {
    id: number;
    name?: string;
    email: string;
    timezone?: Timezone;
    language?: Language;
    organizationId: number;
    organization: OrganizationDto;
    role: OrganizationRole;
}

export interface UserFollowersConnection {
    edges: UserFollowersEdge[];
    pageInfo: PageInfo;
}

export interface UserFollowersEdge {
    node: UserProfileDto;
    cursor: string;
}

export interface UserFollowInfoDto {
    userId: number;
    followers: number;
    following: number;
}

export interface UserFollowingConnection {
    edges: UserFollowingEdge[];
    pageInfo: PageInfo;
}

export interface UserFollowingEdge {
    node: UserProfileDto;
    cursor: string;
}

export interface UserGroupActivityDto {
    id: number;
    userGroupId: number;
    type: UserGroupActivityType;
    createdAt: DateTime;
    author?: UserProfilePreviewDto;
    authorId?: number;
}

export interface UserGroupNotificationActivityDto extends NotificationActivityDto {
    notificationId: number;
    direct?: DirectNotificationType;
    activity?: UserGroupActivityDto;
}

export interface UserGroupNotificationDto {
    id: number;
    type: NotificationType;
    read: boolean;
    updatedAt: DateTime;
    userGroupId: number;
    userGroupName: string;
    numActivities: number;
    activities: UserGroupNotificationActivityDto[];
    involvedUsers: UserProfilePreviewDto[];
}

export interface UserNotificationActivityDto extends NotificationActivityDto {
    notificationId: number;
    direct?: DirectNotificationType;
    activity?: UserActivityUnionType;
    activityReply?: UserActivityReplyDto;
}

export interface UserNotificationDto {
    id: number;
    type: NotificationType;
    read: boolean;
    updatedAt: DateTime;
    user: UserProfileDto;
    userId: number;
    numActivities: number;
    activities: UserNotificationActivityDto[];
    involvedUsers: UserProfilePreviewDto[];
}

export interface UserPasswordValidityResultDto {
    result: UserPasswordValidityResultType;
}

export interface UserPostActivityDto extends UserActivityDto {
    id: number;
    userId: number;
    user?: UserProfileDto;
    type: UserActivityType;
    createdAt: DateTime;
    author?: UserProfilePreviewDto;
    authorId?: number;
    numReplies: number;
    reactions: ReactionDto[];
    replies: UserActivityReplyEdge[];
    post: UserPostDto;
    attachments?: UserPostAttachmentDto[];
    linkPreview?: LinkPreviewDto;
}

export interface UserPostAttachmentDto extends FileDto {
    id: number;
    key: string;
    url: string;
    viewUrl: string;
    downloadUrl: string;
    createdAt: DateTime;
    name: string;
    extension?: string;
    mimetype?: string;
    size: number;
    thumbnailId?: number;
    thumbnail?: ThumbnailDto;
}

export interface UserPostDto {
    activityId: number;
    content: string;
}

export interface UserProfileDto {
    id: number;
    primaryName: string;
    secondaryName?: string;
    name?: string;
    email: string;
    organizationId: number;
    organization: OrganizationProfileDto;
    profilePicture?: ThumbnailDto;
    status: UserStatusDto;
    phoneNumber?: string;
    location?: string;
    website?: string;
    jobDescription?: string;
    birthDate?: DateTime;
    tzOffset?: number;
}

export interface UserProfilePreviewDto {
    id: number;
    email: string;
    primaryName: string;
    secondaryName?: string;
    jobDescription?: string;
    organizationId: number;
    organization: OrganizationProfileDto;
    profilePicture?: ThumbnailDto;
}

export interface UserReferenceDto {
    userId: number;
    user: UserProfilePreviewDto;
    position: string;
}

export interface UserSearchResultDto extends SearchResultDto {
    type: SearchResultType;
    user: UserProfileDto;
}

export interface UserSessionDto {
    active: boolean;
    user: UserProfileDto;
    userId: number;
    createdAt: DateTime;
}

export interface UserSettingsDto extends I18nSettings {
    userId: number;
    timezone?: Timezone;
    autoApplyTimezone?: boolean;
    optInPromotionalEmails?: boolean;
    language: Language;
    dateFormat: DateFormat;
    timeFormat: TimeFormat;
    firstWeekday: FirstWeekday;
    decimalFormat: DecimalFormat;
    measurementSystem: MeasurementSystem;
}

export interface UserSettingsMicrosoftIntegrationStatusDto {
    status: UserSettingsMicrosoftIntegrationStatus;
    lastAccess?: DateTime;
}

export interface UserStatusDto {
    userId: number;
    presence: UserPresenceType;
    note?: string;
    emoji?: string;
    doNotDisturb: boolean;
    lastPresentAt?: DateTime;
}

export interface UserTrashConnection {
    edges: UserTrashEdgeDto[];
    pageInfo: PageInfo;
}

export interface UserTrashDto {
    id: number;
    createdAt: DateTime;
    deletedByUser: UserProfilePreviewDto;
    deletedByUserId: number;
    blabItem?: BlabItemPreviewDto;
    blabItemId?: number;
    blabDef?: BlabDefPreviewDto;
    blabDefId?: number;
}

export interface UserTrashEdgeDto {
    node: UserTrashDto;
    cursor: string;
}

export interface ValidateOrganizationSignupVerificationCodeResponse {
    result: ValidateOrganizationSignupVerificationCodeResponseType;
}

export interface VapidKey {
    pubkey: string;
}

export interface VideoCallDto {
    videoCallUrl: string;
}

export interface Void {
    _void?: boolean;
}

export interface WorkflowCenterBlabDefDto {
    id: number;
    blabName: string;
    itemName: string;
    position: string;
    positionV2?: number;
    description?: string;
    numWorkflows: number;
    icon: BlabDefIcon;
    iconv2?: JSONObject;
    type?: string;
    slug: string;
    workspaceId: number;
    workspace?: WorkflowCenterBlabDefNestedWorkspaceDto;
}

export interface WorkflowCenterBlabDefNestedWorkspaceDto {
    id: number;
    name: string;
    position: string;
    slug: string;
    organizationId: number;
    icon: WorkspaceIcon;
    iconv2?: JSONObject;
}

export interface WorkflowCenterRecentWorkflowPreviewDto {
    id: number;
    blabDefId: number;
    name?: string;
    description?: string;
    isPaused: boolean;
    isBroken: boolean;
    timezone: Timezone;
    triggerType?: WorkflowTriggerType;
    createdAt: DateTime;
    createdByUser?: UserProfilePreviewDto;
    createdByUserId?: number;
    lastModifiedAt: DateTime;
    lastModifiedByUser?: UserProfilePreviewDto;
    lastModifiedByUserId?: number;
    actions?: WorkflowCenterWorkflowActionPreview[];
    blabDef?: BlabDefPreviewDto;
}

export interface WorkflowCenterSearchWorkflowPreviewDto {
    id: number;
    blabDefId: number;
    name?: string;
    description?: string;
    isPaused: boolean;
    isBroken: boolean;
    timezone: Timezone;
    triggerType?: WorkflowTriggerType;
    createdAt: DateTime;
    createdByUser?: UserProfilePreviewDto;
    createdByUserId?: number;
    lastModifiedAt: DateTime;
    lastModifiedByUser?: UserProfilePreviewDto;
    lastModifiedByUserId?: number;
    actions?: WorkflowCenterWorkflowActionPreview[];
    blabDef?: BlabDefPreviewDto;
}

export interface WorkflowCenterWebhookDto {
    id: number;
    url: string;
    position: string;
    active: boolean;
    webhookType: WorkflowCenterWebhookType;
    organizationId: number;
}

export interface WorkflowCenterWebhookEventDto {
    type: WorkflowCenterWebhookSubscriptionEventType;
    webhook: WorkflowCenterWebhookDto;
}

export interface WorkflowCenterWorkflowActionPreview {
    id: string;
    actionType: string;
    customName?: string;
}

export interface WorkflowCenterWorkflowPreviewDto {
    id: number;
    blabDefId: number;
    name?: string;
    description?: string;
    isPaused: boolean;
    isBroken: boolean;
    timezone: Timezone;
    triggerType?: WorkflowTriggerType;
    createdAt: DateTime;
    createdByUser?: UserProfilePreviewDto;
    createdByUserId?: number;
    lastModifiedAt: DateTime;
    lastModifiedByUser?: UserProfilePreviewDto;
    lastModifiedByUserId?: number;
    actions?: WorkflowCenterWorkflowActionPreview[];
}

export interface WorkflowCenterWorkspaceDto {
    id: number;
    name: string;
    position: string;
    icon: WorkspaceIcon;
    iconv2?: JSONObject;
    organizationId: number;
    slug: string;
    blabDefs?: WorkflowCenterBlabDefDto[];
}

export interface WorkflowDefActivityDto {
    id: number;
    workflowDefId: number;
    workflowDef?: WorkflowDefPreviewDto;
    type: WorkflowDefActivityType;
    createdAt: DateTime;
    author?: UserProfilePreviewDto;
    authorId?: number;
}

export interface WorkflowDefBlabDefLookupDto {
    id: number;
    slug: string;
    position: string;
    icon: BlabDefIcon;
    iconv2?: JSONObject;
    itemIcon?: JSONObject;
    type?: string;
    blabName: string;
    itemName: string;
    workspaceId: number;
    userHasPermission?: boolean;
}

export interface WorkflowDefDto {
    id: number;
    name?: string;
    description?: string;
    showDescription?: boolean;
    createdAt: DateTime;
    createdByUser?: UserProfilePreviewDto;
    createdByUserId?: number;
    lastModifiedAt: DateTime;
    lastModifiedByUser?: UserProfilePreviewDto;
    lastModifiedByUserId?: number;
    actions?: WorkflowCenterWorkflowActionPreview[];
    timezone: Timezone;
    triggerType?: WorkflowTriggerType;
    filterDefinition?: JSONObject;
    actionDefinition?: JSONObject;
    triggerDefinition?: JSONObject;
    isBroken: boolean;
    isPaused: boolean;
    savedByUser: boolean;
    templateSelected: boolean;
    fullWidth: boolean;
    blabDefId: number;
    webhookUrl: string;
}

export interface WorkflowDefLookupBlabDefRelationDto {
    sourceBlabDefId: number;
    targetBlabDefId: number;
    relationFlimDefId: number;
}

export interface WorkflowDefLookupBlabViewDto {
    id: number;
    name?: string;
    blabDefId: number;
    layout: BlabViewLayout;
    isDefault: boolean;
}

export interface WorkflowDefLookupDto {
    workflowDefId: number;
    flimDefs: FlimDefDto[];
    blabDefs: WorkflowDefBlabDefLookupDto[];
    workspaces: WorkflowDefWorkspaceLookupDto[];
    users: UserProfilePreviewDto[];
    blabItems: BlabItemPreviewDto[];
    blabViews: WorkflowDefLookupBlabViewDto[];
    blabDefRelations: WorkflowDefLookupBlabDefRelationDto[];
    workspaceUsers: WorkflowDefLookupWorkspaceUserPreviewDto[];
    workflowDefs: WorkflowDefLookupWorkflowDefDto[];
    incomingWorkflowDefs: WorkflowDefLookupIncomingWorkflowDefPreviewDto[];
    blabDefWithFlimDefsIds: number[];
    authenticationProviders: OauthIntegrationDto[];
}

export interface WorkflowDefLookupIncomingWorkflowDefPreviewDto {
    id: number;
    name?: string;
    blabDefId: number;
    blabDef?: BlabDefPreviewDto;
    activeUserHasPermission: boolean;
    isDeleted: boolean;
    incomingTypes: WorkflowDefIncomingType[];
}

export interface WorkflowDefLookupWorkflowDefDto {
    id: number;
    name?: string;
    blabDefId: number;
    triggerType?: WorkflowTriggerType;
    triggerDefinition?: JSONObject;
}

export interface WorkflowDefLookupWorkspaceUserPreviewDto {
    workspaceId: number;
    userId: number;
}

export interface WorkflowDefNotificationActivityDto extends NotificationActivityDto {
    notificationId: number;
    direct?: DirectNotificationType;
    activity?: WorkflowDefActivityDto;
}

export interface WorkflowDefNotificationDto {
    id: number;
    type: NotificationType;
    read: boolean;
    updatedAt: DateTime;
    workflowDef: WorkflowDefPreviewDto;
    workflowDefId: number;
    numActivities: number;
    activities: WorkflowDefNotificationActivityDto[];
    involvedUsers: UserProfilePreviewDto[];
}

export interface WorkflowDefPreviewDto {
    id: number;
    name?: string;
    blabDefId: number;
    blabDef?: BlabDefPreviewDto;
    activeUserHasPermission: boolean;
    isDeleted: boolean;
}

export interface WorkflowDefRevisionDto {
    id: number;
    name?: string;
    triggerType?: WorkflowTriggerType;
    filterDefinition?: JSONObject;
    actionDefinition?: JSONObject;
    triggerDefinition?: JSONObject;
    isBroken: boolean;
    isPaused: boolean;
    blabDefId: number;
}

export interface WorkflowDefUniverseBlabViewDto {
    id: number;
    name?: string;
    position: string;
    blabDefId: number;
    isPublic: boolean;
    total: number;
    layout: BlabViewLayout;
    isDefault: boolean;
}

export interface WorkflowDefUniverseCallableWorkflowDefDto {
    id: number;
    name?: string;
    blabDefId: number;
    triggerType?: WorkflowTriggerType;
    triggerDefinition: JSONObject;
}

export interface WorkflowDefWithLookupDto {
    id: number;
    name?: string;
    description?: string;
    showDescription?: boolean;
    createdAt: DateTime;
    createdByUser?: UserProfilePreviewDto;
    createdByUserId?: number;
    lastModifiedAt: DateTime;
    lastModifiedByUser?: UserProfilePreviewDto;
    lastModifiedByUserId?: number;
    triggerType?: WorkflowTriggerType;
    filterDefinition?: JSONObject;
    actionDefinition?: JSONObject;
    triggerDefinition?: JSONObject;
    webhookLastPayload?: WorkflowWebhookLastPayloadDto;
    validationErrors?: JSONObject;
    isBroken: boolean;
    isPaused: boolean;
    savedByUser: boolean;
    templateSelected: boolean;
    fullWidth: boolean;
    blabDefId: number;
    timezone: Timezone;
    lookup: WorkflowDefLookupDto;
    webhookUrl: string;
}

export interface WorkflowDefWorkspaceLookupDto {
    id: number;
    slug: string;
    position: string;
    icon: WorkspaceIcon;
    name: string;
    organizationId: number;
}

export interface WorkflowExecutionConfigDto {
    blabItemExecutionType?: WorkflowExecutionType;
    webhookExecutionType?: WorkflowExecutionType;
    selectedBlabItems?: BlabItemPreviewDto[];
    numMostRecentBlabItems?: number;
    customWebhookPayload?: string;
}

export interface WorkflowHomeCurrentInfoDto {
    numActiveWorkflowDefs: number;
    numRunningWorkflowRuns: number;
    numScheduledWorkflowRuns: number;
    numActionsUsedRecently: number;
}

export interface WorkflowHomeTimeIntervalInfoDto {
    interval: WorkflowHomeTimeInterval;
    numWorkflowDefs: number;
    numWorkflowRuns: number;
    numActionsUsed: number;
    numActionsTotal: number;
    workflowRunsStatusInfo: WorkflowHomeTimeIntervalWorkflowRunsStatusInfoDto;
    workflowDefsStatusInfo: WorkflowHomeTimeIntervalWorkflowDefsStatusInfoDto;
}

export interface WorkflowHomeTimeIntervalWorkflowDefsStatusInfoDto {
    numWorkflowDefsActive: number;
    numWorkflowDefsPaused: number;
    numWorkflowDefsBroken: number;
}

export interface WorkflowHomeTimeIntervalWorkflowRunsStatusInfoDto {
    numWorkflowRunsSuccess: number;
    numWorkflowRunsFailed: number;
    numWorkflowRunsCancelled: number;
}

export interface WorkflowPreviewRunActionContextOutputLogEvent extends WorkflowRunActionLogEvent {
    type: WorkflowRunLogEventType;
    workflowRunId: number;
    loggedAt: DateTime;
    sortIndex?: number;
    actionId: string;
    actionExecutionId: string;
    contextOutput: JSONObject;
}

export interface WorkflowRunActionDebugLogEvent extends WorkflowRunActionLogEvent {
    type: WorkflowRunLogEventType;
    workflowRunId: number;
    loggedAt: DateTime;
    sortIndex?: number;
    actionId: string;
    actionExecutionId: string;
    debugMessage: string;
    level?: WorkflowRunlogMessageLevel;
}

export interface WorkflowRunActionEmailSendAttemptLogEvent extends WorkflowRunActionLogEvent {
    type: WorkflowRunLogEventType;
    workflowRunId: number;
    loggedAt: DateTime;
    sortIndex?: number;
    actionId: string;
    actionExecutionId: string;
    from: string;
    to: string[];
    subject?: string;
    blabItemId?: number;
}

export interface WorkflowRunActionExceptionCaughtLogEvent extends WorkflowRunActionLogEvent {
    type: WorkflowRunLogEventType;
    workflowRunId: number;
    loggedAt: DateTime;
    sortIndex?: number;
    actionId: string;
    actionExecutionId: string;
    exceptionMessage: string;
}

export interface WorkflowRunActionExecutionSyntaxErrorEvent extends WorkflowRunLogEvent {
    type: WorkflowRunLogEventType;
    workflowRunId: number;
    loggedAt: DateTime;
    sortIndex?: number;
    exceptionMessage: string;
}

export interface WorkflowRunActionFailedLogEvent extends WorkflowRunActionLogEvent {
    type: WorkflowRunLogEventType;
    workflowRunId: number;
    loggedAt: DateTime;
    sortIndex?: number;
    actionId: string;
    actionExecutionId: string;
    exceptionMessage: string;
}

export interface WorkflowRunActionSkippingLogEvent extends WorkflowRunActionLogEvent {
    type: WorkflowRunLogEventType;
    workflowRunId: number;
    loggedAt: DateTime;
    sortIndex?: number;
    actionId: string;
    actionExecutionId: string;
}

export interface WorkflowRunActionsSetupFailedLogEvent extends WorkflowRunLogEvent {
    type: WorkflowRunLogEventType;
    workflowRunId: number;
    loggedAt: DateTime;
    sortIndex?: number;
    exceptionMessage: string;
}

export interface WorkflowRunActionStartedLogEvent extends WorkflowRunActionLogEvent {
    type: WorkflowRunLogEventType;
    workflowRunId: number;
    loggedAt: DateTime;
    sortIndex?: number;
    actionId: string;
    actionExecutionId: string;
}

export interface WorkflowRunActionSucceededLogEvent extends WorkflowRunActionLogEvent {
    type: WorkflowRunLogEventType;
    workflowRunId: number;
    loggedAt: DateTime;
    sortIndex?: number;
    actionId: string;
    actionExecutionId: string;
}

export interface WorkflowRunActionUserLogEvent extends WorkflowRunActionLogEvent {
    type: WorkflowRunLogEventType;
    workflowRunId: number;
    loggedAt: DateTime;
    sortIndex?: number;
    actionId: string;
    actionExecutionId: string;
    userMessage: string;
    level?: WorkflowRunlogMessageLevel;
}

export interface WorkflowRunBlabItemCommentCreateActionSucceededLogEvent extends WorkflowRunActionLogEvent {
    type: WorkflowRunLogEventType;
    workflowRunId: number;
    loggedAt: DateTime;
    sortIndex?: number;
    actionId: string;
    actionExecutionId: string;
    createdBlabItemCommentMessage: string;
}

export interface WorkflowRunBlabItemCreateActionSucceededLogEvent extends WorkflowRunActionLogEvent {
    type: WorkflowRunLogEventType;
    workflowRunId: number;
    loggedAt: DateTime;
    sortIndex?: number;
    actionId: string;
    actionExecutionId: string;
    createdBlabItemId?: number;
}

export interface WorkflowRunCollectBlabItemsActionSuceededLogEvent extends WorkflowRunActionLogEvent {
    type: WorkflowRunLogEventType;
    workflowRunId: number;
    loggedAt: DateTime;
    sortIndex?: number;
    actionId: string;
    actionExecutionId: string;
    blabDefId: number;
    numCollected: number;
}

export interface WorkflowRunCollectedBlabItemsCallWorkfowActionLogEvent extends WorkflowRunActionLogEvent {
    type: WorkflowRunLogEventType;
    workflowRunId: number;
    loggedAt: DateTime;
    sortIndex?: number;
    actionId: string;
    actionExecutionId: string;
    calledWorkflowDefId?: number;
    calledWorkflowDef?: WorkflowDefPreviewDto;
}

export interface WorkflowRunCollectedRecordsCommentCreateSucceededLogEvent extends WorkflowRunActionLogEvent {
    type: WorkflowRunLogEventType;
    workflowRunId: number;
    loggedAt: DateTime;
    sortIndex?: number;
    actionId: string;
    actionExecutionId: string;
    blabItemId?: number;
}

export interface WorkflowRunCollectedRecordsUpdateActionUpdateSucceededLogEvent extends WorkflowRunActionLogEvent {
    type: WorkflowRunLogEventType;
    workflowRunId: number;
    loggedAt: DateTime;
    sortIndex?: number;
    actionId: string;
    actionExecutionId: string;
    updatedBlabItemId?: number;
}

export interface WorkflowRunConditionalActionLogEvent extends WorkflowRunActionLogEvent {
    type: WorkflowRunLogEventType;
    workflowRunId: number;
    loggedAt: DateTime;
    sortIndex?: number;
    actionId: string;
    actionExecutionId: string;
    matchConditionResult: boolean;
}

export interface WorkflowRunCreatedSubscriptionUpdate {
    updateType: WorkflowRunsSubscriptionUpdateType;
    workflowRunId: number;
    workflowDefId: number;
    workflowDefRevisionId: number;
    createdAt: DateTime;
    type: WorkflowRunType;
    status: WorkflowRunStatusType;
    failureReason?: WorkflowRunFailureReason;
    numActionsUsed: number;
    previewForActionId?: string;
}

export interface WorkflowRunCreatePdfActionSucceededLogEvent extends WorkflowRunActionLogEvent {
    type: WorkflowRunLogEventType;
    workflowRunId: number;
    loggedAt: DateTime;
    sortIndex?: number;
    actionId: string;
    actionExecutionId: string;
    filename?: string;
    filesize?: number;
    downloadLink?: string;
}

export interface WorkflowRunCurrentBlabItemCallWorkfowActionLogEvent extends WorkflowRunActionLogEvent {
    type: WorkflowRunLogEventType;
    workflowRunId: number;
    loggedAt: DateTime;
    sortIndex?: number;
    actionId: string;
    actionExecutionId: string;
    calledWorkflowDefId?: number;
    calledWorkflowDef?: WorkflowDefPreviewDto;
}

export interface WorkflowRunDetailDto {
    id: number;
    workflowDefId: number;
    workflowDefRevisionId: number;
    workflowDefRevision: WorkflowDefRevisionDto;
    triggeredOnBlabItemId?: number;
    triggeredByWorkflowDefId?: number;
    triggeredByWorkflowDef?: WorkflowDefPreviewDto;
    type: WorkflowRunType;
    status: WorkflowRunStatusType;
    failureReason?: WorkflowRunFailureReason;
    errorMsg?: string;
    numActionsUsed: number;
    createdAt: DateTime;
    completedAt?: DateTime;
    previewForActionId?: string;
    logs: WorkflowRunLogEventUnion[];
}

export interface WorkflowRunFiltersExecutionFailedLogEvent extends WorkflowRunLogEvent {
    type: WorkflowRunLogEventType;
    workflowRunId: number;
    loggedAt: DateTime;
    sortIndex?: number;
    exceptionMessage: string;
}

export interface WorkflowRunFiltersSetupFailedLogEvent extends WorkflowRunLogEvent {
    type: WorkflowRunLogEventType;
    workflowRunId: number;
    loggedAt: DateTime;
    sortIndex?: number;
    exceptionMessage: string;
}

export interface WorkflowRunFilterUserLogEvent extends WorkflowRunLogEvent {
    type: WorkflowRunLogEventType;
    workflowRunId: number;
    loggedAt: DateTime;
    sortIndex?: number;
    userMessage: string;
    level?: WorkflowRunlogMessageLevel;
}

export interface WorkflowRunForLoopActionBreakExceptionLogEvent extends WorkflowRunActionLogEvent {
    type: WorkflowRunLogEventType;
    workflowRunId: number;
    loggedAt: DateTime;
    sortIndex?: number;
    actionId: string;
    actionExecutionId: string;
    exceptionMessage: string;
}

export interface WorkflowRunForLoopActionBreakResultLogEvent extends WorkflowRunActionLogEvent {
    type: WorkflowRunLogEventType;
    workflowRunId: number;
    loggedAt: DateTime;
    sortIndex?: number;
    actionId: string;
    actionExecutionId: string;
    booleanResult?: boolean;
}

export interface WorkflowRunForLoopActionContinueExceptionLogEvent extends WorkflowRunActionLogEvent {
    type: WorkflowRunLogEventType;
    workflowRunId: number;
    loggedAt: DateTime;
    sortIndex?: number;
    actionId: string;
    actionExecutionId: string;
    exceptionMessage: string;
}

export interface WorkflowRunForLoopActionContinueResultLogEvent extends WorkflowRunActionLogEvent {
    type: WorkflowRunLogEventType;
    workflowRunId: number;
    loggedAt: DateTime;
    sortIndex?: number;
    actionId: string;
    actionExecutionId: string;
    booleanResult?: boolean;
}

export interface WorkflowRunGenerateWeblinkActionSucceededLogEvent extends WorkflowRunActionLogEvent {
    type: WorkflowRunLogEventType;
    workflowRunId: number;
    loggedAt: DateTime;
    sortIndex?: number;
    actionId: string;
    actionExecutionId: string;
    weblink?: string;
    blabItemId?: number;
    calledWorkflowDefId?: number;
    calledWorkflowDef?: WorkflowDefPreviewDto;
}

export interface WorkflowRunHttpCallActionSucceededLogEvent extends WorkflowRunActionLogEvent {
    type: WorkflowRunLogEventType;
    workflowRunId: number;
    sortIndex?: number;
    loggedAt: DateTime;
    actionId: string;
    actionExecutionId: string;
    httpResponseHeaders: JSONObject;
    httpResponseBody: string;
    httpResponseCode: string;
}

export interface WorkflowRunLogsAppendedSubscriptionUpdate {
    updateType: WorkflowRunsSubscriptionUpdateType;
    workflowRunId: number;
    workflowDefId: number;
    logs: WorkflowRunLogEventUnion[];
}

export interface WorkflowRunPreviewDto {
    id: number;
    workflowDefId: number;
    workflowDefRevisionId: number;
    type: WorkflowRunType;
    status: WorkflowRunStatusType;
    failureReason?: WorkflowRunFailureReason;
    numActionsUsed: number;
    createdAt: DateTime;
    completedAt?: DateTime;
    previewForActionId?: string;
}

export interface WorkflowRunPreviewEdge {
    node: WorkflowRunPreviewDto;
    cursor: string;
}

export interface WorkflowRunReferencedRecordsUpdateActionUpdateSucceededLogEvent extends WorkflowRunActionLogEvent {
    type: WorkflowRunLogEventType;
    workflowRunId: number;
    loggedAt: DateTime;
    sortIndex?: number;
    actionId: string;
    actionExecutionId: string;
    updatedBlabItemId?: number;
}

export interface WorkflowRunsConnection {
    edges: WorkflowRunPreviewEdge[];
    pageInfo: PageInfo;
}

export interface WorkflowRunUpdatedSubscriptionUpdate {
    updateType: WorkflowRunsSubscriptionUpdateType;
    workflowRunId: number;
    workflowDefId: number;
    type: WorkflowRunType;
    status: WorkflowRunStatusType;
    failureReason?: WorkflowRunFailureReason;
    numActionsUsed: number;
    previewForActionId?: string;
}

export interface WorkflowWebhookLastPayloadDto {
    rawPayload: string;
    parsedPayload?: JSONObject;
    contentType: PayloadContentType;
}

export interface WorkspaceActivityConnection {
    edges: WorkspaceActivityEdge[];
    pageInfo: PageInfo;
}

export interface WorkspaceActivityEdge {
    node: WorkspaceActivityUnionType;
    cursor: string;
}

export interface WorkspaceActivityReplyConnection {
    edges: WorkspaceActivityReplyEdge[];
    pageInfo: PageInfo;
}

export interface WorkspaceActivityReplyDto {
    id: number;
    createdAt: DateTime;
    activityId: number;
    userId: number;
    user?: UserProfilePreviewDto;
    content: string;
    reactions: ReactionDto[];
}

export interface WorkspaceActivityReplyEdge {
    node: WorkspaceActivityReplyDto;
    cursor: string;
}

export interface WorkspaceBlabDefConnection {
    edges: WorkspaceBlabDefEdgeDto[];
    pageInfo: PageInfo;
}

export interface WorkspaceBlabDefDto {
    id: number;
    blabName: string;
    itemName: string;
    slug: string;
    icon: BlabDefIcon;
    archived?: boolean;
    workspaceId: number;
    numBlabItems: number;
    createdAt: DateTime;
    position: string;
    blabItemIdSequence: number;
}

export interface WorkspaceBlabDefEdgeDto {
    node: WorkspaceBlabDefDto;
    cursor: string;
}

export interface WorkspaceCreatedActivityDto extends WorkspaceActivityDto {
    id: number;
    workspaceId: number;
    workspace?: WorkspacePreviewDto;
    type: WorkspaceActivityType;
    createdAt: DateTime;
    author?: UserProfilePreviewDto;
    authorId?: number;
    numReplies: number;
    reactions: ReactionDto[];
    replies: WorkspaceActivityReplyEdge[];
}

export interface WorkspaceDto {
    id: number;
    slug: string;
    name: string;
    position: string;
    type: WorkspaceType;
    createdAt: DateTime;
    description?: string;
    icon: WorkspaceIcon;
    path?: string[];
    organization?: OrganizationProfileDto;
    organizationId: number;
    blabDefs?: BlabDefPreviewDto[];
    numUsers: number;
    hasWorkspaceTemplatePublication: boolean;
}

export interface WorkspaceInvitationDto {
    role: WorkspaceRole;
    workspaceId: number;
    organizationInvitationId: number;
    email: string;
    status: OrganizationInvitationStatus;
    createdAt: DateTime;
}

export interface WorkspaceMembershipCandidateSearchResultDto {
    workspaceUsers?: WorkspaceUserDto[];
    userProfiles?: UserProfileDto[];
    deactivatedUserProfiles?: UserProfileDto[];
    workspaceInvitations?: WorkspaceInvitationDto[];
    organizationInvitations?: OrganizationInvitationDto[];
}

export interface WorkspaceMembershipConnection {
    edges: WorkspaceMembershipEdgeDto[];
    pageInfo: PageInfo;
}

export interface WorkspaceMembershipEdgeDto {
    node: WorkspaceMembershipUnionType;
    cursor: string;
}

export interface WorkspaceNotificationActivityDto extends NotificationActivityDto {
    notificationId: number;
    direct?: DirectNotificationType;
    activity?: WorkspaceActivityUnionType;
    activityReply?: WorkspaceActivityReplyDto;
}

export interface WorkspaceNotificationDto {
    id: number;
    type: NotificationType;
    read: boolean;
    updatedAt: DateTime;
    user: UserProfileDto;
    workspaceId: number;
    workspace: WorkspacePreviewDto;
    userId: number;
    numActivities: number;
    activities: WorkspaceNotificationActivityDto[];
    involvedUsers: UserProfilePreviewDto[];
}

export interface WorkspacePreviewDto {
    id: number;
    slug: string;
    position: string;
    icon: WorkspaceIcon;
    name: string;
    path?: string[];
    organizationId: number;
    organization?: OrganizationProfileDto;
}

export interface WorkspaceSearchResultDto extends SearchResultDto {
    type: SearchResultType;
    workspace: WorkspacePreviewDto;
}

export interface WorkspaceSettingsDto {
    workspaceId: number;
    name: string;
    slug: string;
    type: WorkspaceType;
    icon: WorkspaceIcon;
    description?: string;
}

export interface WorkspaceShareContextDto extends ShareContextDto {
    type: ShareContextType;
    userSessions: ShareContextUserSessionDto[];
    workspaceId: number;
    canDuplicate: boolean;
}

export interface WorkspaceShareSettingsDto {
    workspaceId: number;
    enabled: boolean;
    allowDuplication: boolean;
    token?: string;
    organization: OrganizationProfileDto;
    organizationId: number;
    workspaceType: WorkspaceType;
    workspaceDefaultRole: WorkspaceRole;
    numOrganizationUsers: number;
    workspaceMembers: WorkspaceMembershipUnionType[];
}

export interface WorkspaceTemplateDto {
    id: number;
    templateDescription?: string;
    templateName: string;
    templateSubtitle?: string;
    language: Language;
    publicationDate: DateTime;
    previewImage?: ThumbnailDto;
    previewImageThumbnailId?: number;
    titleImage?: ThumbnailDto;
    titleImageThumbnailId?: number;
    image3?: ThumbnailDto;
    image3ThumbnailId?: number;
    image4?: ThumbnailDto;
    image4ThumbnailId?: number;
    categories: WorkspaceTemplateCategory[];
    downloadCount: number;
    templateShareLink?: string;
}

export interface WorkspaceTemplatePublishInfoDto {
    id: number;
    workspaceId: number;
    templateSubtitle?: string;
    templateDescription?: string;
    templateName: string;
    language: Language;
    downloadCountOffset: number;
    realDownloadCount: number;
    categories: WorkspaceTemplateCategory[];
    previewImage?: ThumbnailDto;
    previewImageThumbnailId?: number;
    titleImage?: ThumbnailDto;
    titleImageThumbnailId?: number;
    image3?: ThumbnailDto;
    image3ThumbnailId?: number;
    image4?: ThumbnailDto;
    image4ThumbnailId?: number;
    templateShareLink?: string;
    publicationDate: DateTime;
}

export interface WorkspaceUserAddedActivityDto extends WorkspaceActivityDto {
    id: number;
    workspaceId: number;
    workspace?: WorkspacePreviewDto;
    type: WorkspaceActivityType;
    createdAt: DateTime;
    author?: UserProfilePreviewDto;
    authorId?: number;
    targetUser?: UserProfilePreviewDto;
    targetUserId?: number;
    role?: WorkspaceRole;
    numReplies: number;
    reactions: ReactionDto[];
    replies: WorkspaceActivityReplyEdge[];
}

export interface WorkspaceUserDto {
    userId: number;
    workspaceId: number;
    primaryName: string;
    secondaryName?: string;
    email: string;
    role: WorkspaceRole;
    deactivated: boolean;
    explicitRole?: WorkspaceRole;
    profilePicture?: ThumbnailDto;
}

export type DateTime = any;
export type JSONObject = any;
export type Upload = any;
export type ActiveUserBlabDefInfoActiveBlabViewUnionType = BlabViewDto | ActiveUserAutoSaveBlabViewDto;
export type ActiveUserDevPortalContextUnionType = ActiveUserDevPortalContextDto | ActiveUserDevPortalRejectionDto;
export type ActiveUserSplitBySectionUnionType = ActiveUserSingleCategorySplitBySectionDto | ActiveUserMultiCategorySplitBySectionDto | ActiveUserSingleUserSplitBySectionDto | ActiveUserMultiUserSplitBySectionDto | ActiveUserSingleRelationSplitBySectionDto | ActiveUserMultiRelationSplitBySectionDto | ActiveUserStatusSplitBySectionDto | ActiveUserSingleDateSplitBySectionDto | ActiveUserRangeDateSplitBySectionDto | ActiveUserCreatedAtSplitBySectionDto | ActiveUserLastModifiedAtSplitBySectionDto | ActiveUserCalculationSplitBySectionDto;
export type AuthenticateWithCredentialsResult = UserSessionDto | AuthenticateWithCredentialsRejectionDto;
export type AuthenticateWithFindMyOrgsTokenResult = UserSessionDto | FindMyOrgUserHasPendingInvitationDto;
export type BlabDefActivityUnionType = BlabDefInvitationCreatedActivityDto;
export type BlabDefDeveloperInfoSubscriptionEventUnionType = BlabDefDeveloperInfoSubscriptionBlabDefWebhookEventDto;
export type BlabItemActivityReplySubscriptionUpdate = BlabItemActivityReplyDto | BlabItemActivityReplyDeletedDto;
export type BlabItemActivitySubscriptionUpdate = BlabItemCommentActivityDto | BlabItemRevisionActivityDto | BlabItemCreatedActivityDto | BlabItemDeletedActivityDto | BlabItemRestoredActivityDto | BlabItemActivityDeletedDto | BlabItemInvitationCreatedActivityDto;
export type BlabItemActivityUnionType = BlabItemCommentActivityDto | BlabItemRevisionActivityDto | BlabItemCreatedActivityDto | BlabItemDeletedActivityDto | BlabItemRestoredActivityDto | BlabItemInvitationCreatedActivityDto;
export type BlabItemRevisionUnionType = SingleTextFlimValRevisionDto | MultiTextFlimValRevisionDto | SingleCategoryFlimValRevisionDto | MultiCategoryFlimValRevisionDto | SingleRelationFlimValRevisionDto | MultiRelationFlimValRevisionDto | IncomingRelationRevisionDto | SingleAttachmentFlimValRevisionDto | MultiAttachmentFlimValRevisionDto | MultiImageFlimValRevisionDto | SingleUserFlimValRevisionDto | MultiUserFlimValRevisionDto | SingleDateFlimValRevisionDto | RangeDateFlimValRevisionDto | ChecklistFlimValRevisionDto | MultiLinkFlimValRevisionDto | NumberFlimValRevisionDto | StatusFlimValRevisionDto | MultiEmailFlimValRevisionDto | MultiPhoneFlimValRevisionDto | SingleLocationFlimValRevisionDto;
export type BlabViewBoardColumnUnionType = BlabViewBoardStatusColumnDto | BlabViewBoardSingleCategoryColumnDto;
export type CalendarEventUnionType = BlabItemCalendarEventDto | ReminderCalendarEventDto | ChecklistEntryCalendarEventDto;
export type ChangeBillingSubscriptionPlanResult = ActiveBillingSubscriptionDto | IncompleteBillingSubscriptionDto;
export type ChangeOrganizationUserRoleResult = OrganizationUserDto | ChangeOrganizationRoleRejectedLastOwner;
export type ChangeWorkspaceMembershipRoleResult = WorkspaceInvitationDto | WorkspaceUserDto | ChangeWorkspaceRoleRejectedLastAdmin;
export type ChatActivityUnionType = ChatMessageActivityDto | ConversationCreateActivityDto | ChatGroupJoinActivityDto | ChatGroupLeaveActivityDto | ChatGroupNameChangeActivityDto | ChatGroupThumbnailChangeActivityDto | ChatVideoCallActivityDto;
export type ClientApplicationBrowserNotificationUnionType = ChatActivityClientApplicationBrowserNotificationDto | NotificationClientApplicationBrowserNotificationDto;
export type ConversationSearchResultUnionType = ChatGroupSearchResultDto | UserSearchResultDto;
export type CreateBillingSubscriptionResult = ActiveBillingSubscriptionDto | IncompleteBillingSubscriptionDto;
export type DeactivateOrganizationUserInfoResult = OrganizationUserDto | DeactivateOrganizationUserRejectedLastOwner;
export type FlimDefRevisionPreviewUnionType = FlimDefRevisionPreviewDto | CategoryFlimDefRevisionPreviewDto | NumberFlimDefRevisionPreviewDto | StatusFlimDefRevisionPreviewDto;
export type FlimDefUnionType = SingleTextFlimDefDto | MultiTextFlimDefDto | SingleCategoryFlimDefDto | MultiCategoryFlimDefDto | SingleRelationFlimDefDto | MultiRelationFlimDefDto | SingleAttachmentFlimDefDto | MultiAttachmentFlimDefDto | SingleUserFlimDefDto | MultiUserFlimDefDto | SingleDateFlimDefDto | RangeDateFlimDefDto | ChecklistFlimDefDto | MultiLinkFlimDefDto | CreatedAtFlimDefDto | CreatedByFlimDefDto | LastModifiedAtFlimDefDto | LastModifiedByFlimDefDto | NumberFlimDefDto | StatusFlimDefDto | CalculationFlimDefDto | MultiEmailFlimDefDto | MultiPhoneFlimDefDto | MultiImageFlimDefDto | SingleLocationFlimDefDto | UniqueIdFlimDefDto;
export type FlimStatDefUnionType = SingleTextFlimStatDefDto | SingleUserFlimStatDefDto | SingleCategoryFlimStatDefDto | SingleAttachmentFlimStatDefDto | SingleRelationFlimStatDefDto | SingleDateFlimStatDefDto | RangeDateFlimStatDefDto | MultiRelationFlimStatDefDto | NumberFlimStatDefDto | StatusFlimStatDefDto | ChecklistFlimStatDefDto | MultiLinkFlimStatDefDto | CreatedAtFlimStatDefDto | CreatedByFlimStatDefDto | LastModifiedAtFlimStatDefDto | LastModifiedByFlimStatDefDto | MultiAttachmentFlimStatDefDto | MultiImageFlimStatDefDto | MultiTextFlimStatDefDto | MultiCategoryFlimStatDefDto | MultiUserFlimStatDefDto | CalculationFlimStatDefDto | MultiEmailFlimStatDefDto | MultiPhoneFlimStatDefDto | SingleLocationFlimStatDefDto | UniqueIdFlimStatDefDto;
export type FlimStatResultUnionType = SingleTextFlimStatResultDto | MultiTextFlimStatResultDto | SingleCategoryFlimStatResultDto | MultiCategoryFlimStatResultDto | SingleUserFlimStatResultDto | MultiUserFlimStatResultDto | SingleAttachmentFlimStatResultDto | MultiAttachmentFlimStatResultDto | SingleRelationFlimStatResultDto | MultiRelationFlimStatResultDto | SingleDateFlimStatResultDto | RangeDateFlimStatResultDto | StatusFlimStatResultDto | MultiLinkFlimStatResultDto | LastModifiedAtFlimStatResultDto | LastModifiedByFlimStatResultDto | CreatedAtFlimStatResultDto | CreatedByFlimStatResultDto | NumberFlimStatResultDto | ChecklistFlimStatResultDto | CalculationFlimStatResultDto | MultiEmailFlimStatResultDto | MultiPhoneFlimStatResultDto | MultiImageFlimStatResultDto | SingleLocationFlimStatResultDto | UniqueIdFlimStatResultDto;
export type FlimValUnionType = SingleTextFlimValDto | MultiTextFlimValDto | SingleCategoryFlimValDto | MultiCategoryFlimValDto | SingleRelationFlimValDto | MultiRelationFlimValDto | SingleUserFlimValDto | MultiUserFlimValDto | SingleAttachmentFlimValDto | MultiAttachmentFlimValDto | SingleDateFlimValDto | RangeDateFlimValDto | MultiLinkFlimValDto | ChecklistFlimValDto | CreatedAtFlimValDto | CreatedByFlimValDto | LastModifiedByFlimValDto | LastModifiedAtFlimValDto | NumberFlimValDto | StatusFlimValDto | CalculationFlimValDto | MultiEmailFlimValDto | MultiPhoneFlimValDto | MultiImageFlimValDto | SingleLocationFlimValDto | UniqueIdFlimValDto;
export type FocusEntrySubscriptionUpdateUnionType = FocusEntryAddedSubscriptionEvent | FocusEntryUpdatedSubscriptionEvent | FocusEntryRemovedSubscriptionEvent;
export type FocusEntryUnionType = ReminderFocusEntryDto | ChecklistFocusEntryDto | BlabItemFocusEntryDto;
export type GetBillingSubscriptionResult = ActiveBillingSubscriptionDto | IncompleteBillingSubscriptionDto | NoActiveOrIncompleteBillingSubscriptionDto;
export type GetCalculationScriptResultPreviewResultUnionType = CalculationScriptMultiTextResultPreviewDto | CalculationScriptNumberResultPreviewDto | CalculationScriptSingleDateResultPreviewDto | GetCalculationScriptResultPreviewRejectionDto;
export type GetLocationAutocompleteSuggestionsResultUnionType = LocationAutocompleteSuggestionsDto | GetLocationAutocompleteSuggestionsRejectionDto;
export type GetLocationDetailsResultUnionType = LocationDetailsDto | GetLocationDetailsRejectionDto;
export type GetOrganizationProfileBySlugResult = OrganizationProfileDto | OrganizationSlugNoExistDto;
export type GetUserInfoByOrganizationInvitationTokenResult = OrganizationInvitationSignupUserInfoDto | GetUserInfoByOrganizationInvitationTokenRejectedAlreadyAccepted;
export type InvoiceItemUnionType = LicenseInvoiceItemDto | ProrationInvoiceItemDto | DiscountInvoiceItemDto | TaxInvoiceItemDto | CreditInvoiceItemDto;
export type NotificationUnionType = BlabItemNotificationDto | UserNotificationDto | WorkspaceNotificationDto | AudioRoomNotificationDto | WorkflowDefNotificationDto | BlabDefNotificationDto | UserGroupNotificationDto;
export type OrganizationActivityUnionType = OrganizationCreatedActivityDto | OrganizationLogoChangedActivityDto;
export type OrganizationAuthenticationProviderResultUnion = OrganizationAuthenticationProviderDto | OrganizationAuthenticationProviderRejectionDto;
export type OrganizationSmtpSettingsResultUnion = OrganizationSmtpSettingsDto | OrganizationSmtpSettingsRejectionDto;
export type PaymentMethodUnionType = CardPaymentMethodDto | SepaDebitPaymentMethodDto;
export type ReminderUnionType = ReminderDto;
export type RemoveWorkspaceMembershipResult = RemoveWorkspaceMembershipSuccess | RemoveWorkspaceMembershipRejected;
export type RequestChangeUserEmailVerificationResult = ChangeUserEmailVerificationSessionDto | RequestChangeUserEmailVerificationRejectionDto;
export type SearchHistoryItemUnionType = SearchHistoryItemQueryDto | SearchHistoryItemResultDto;
export type SearchResultUnionType = BlabDefSearchResultDto | BlabItemSearchResultDto | WorkspaceSearchResultDto | UserSearchResultDto | ChatMessageSearchResultDto | ChatGroupSearchResultDto;
export type ShareContextUnionType = OrganizationShareContextDto | WorkspaceShareContextDto | BlabDefShareContextDto | BlabItemShareContextDto;
export type SingleConversationSubscriptionUpdate = ChatMessageActivityDto | ConversationCreateActivityDto | ChatGroupJoinActivityDto | ChatGroupLeaveActivityDto | ChatGroupNameChangeActivityDto | ChatGroupThumbnailChangeActivityDto | ChatActivityDeletedDto | ChatVideoCallActivityDto;
export type SplitByUnionType = SingleCategorySplitByDto | MultiCategorySplitByDto | SingleUserSplitByDto | MultiUserSplitByDto | SingleRelationSplitByDto | MultiRelationSplitByDto | StatusSplitByDto | SingleDateSplitByDto | RangeDateSplitByDto | CreatedAtSplitByDto | LastModifiedAtSplitByDto | CalculationSplitByDto;
export type StartChatVideoCallResult = ChatVideoCallActivityDto | MicrosoftUserAuthenticationRequiredDto;
export type SubscribeToBlabDefDuplicationJobResult = SubscribeToBlabDefDuplicationJobSuccessEvent | SubscribeToBlabDefDuplicationJobFailureEvent;
export type SubscribeToBulkFollowUnfollowBlabItemsResult = SubscribeToBulkFollowUnfollowBlabItemsSuccessEvent | SubscribeToBulkFollowUnfollowBlabItemsFailureEvent;
export type SubscribeToExportBlabDefDataJobResult = SubscribeToExportBlabDefDataJobProgressEvent | SubscribeToExportBlabDefDataJobSuccessEvent | SubscribeToExportBlabDefDataJobFailureEvent;
export type SubscribeToImportBlabDefDataJobResult = SubscribeToImportBlabDefDataJobProgressEvent | SubscribeToImportBlabDefDataJobSuccessEvent | SubscribeToImportBlabDefDataJobFailureEvent;
export type SubscribeToOauthIntegrationAuthenticationStatusResult = SubscribeToOauthIntegrationAuthenticationStatusSuccessEvent | SubscribeToOauthIntegrationAuthenticationStatusFailureEvent;
export type SubscribeToUpdateBlabItemsFlimValJobResult = SubscribeToUpdateBlabItemsFlimValSuccessEvent | SubscribeToUpdateBlabItemsFlimValFailureEvent;
export type SubscribeToWorkspaceDuplicationJobResult = SubscribeToWorkspaceDuplicationJobSuccessEvent | SubscribeToWorkspaceDuplicationJobFailureEvent;
export type UpdateOrganizationUserInfoResult = OrganizationUserDto | UpdateOrganizationInfoRejectedEmailUnavailable;
export type UserActivityUnionType = UserCreatedActivityDto | UserPostActivityDto;
export type WorkflowCenterWebhookEventUnionType = WorkflowCenterWebhookEventDto;
export type WorkflowRunLogEventUnion = WorkflowRunFiltersSetupFailedLogEvent | WorkflowRunFiltersExecutionFailedLogEvent | WorkflowRunFilterUserLogEvent | WorkflowRunActionsSetupFailedLogEvent | WorkflowRunActionSkippingLogEvent | WorkflowRunActionStartedLogEvent | WorkflowRunActionSucceededLogEvent | WorkflowRunActionExecutionSyntaxErrorEvent | WorkflowRunActionFailedLogEvent | WorkflowRunActionExceptionCaughtLogEvent | WorkflowRunActionUserLogEvent | WorkflowRunActionDebugLogEvent | WorkflowRunForLoopActionBreakResultLogEvent | WorkflowRunForLoopActionContinueResultLogEvent | WorkflowRunForLoopActionBreakExceptionLogEvent | WorkflowRunForLoopActionContinueExceptionLogEvent | WorkflowRunConditionalActionLogEvent | WorkflowRunBlabItemCreateActionSucceededLogEvent | WorkflowRunBlabItemCommentCreateActionSucceededLogEvent | WorkflowRunHttpCallActionSucceededLogEvent | WorkflowRunCollectBlabItemsActionSuceededLogEvent | WorkflowRunCollectedRecordsUpdateActionUpdateSucceededLogEvent | WorkflowRunCollectedRecordsCommentCreateSucceededLogEvent | WorkflowRunReferencedRecordsUpdateActionUpdateSucceededLogEvent | WorkflowPreviewRunActionContextOutputLogEvent | WorkflowRunActionEmailSendAttemptLogEvent | WorkflowRunCreatePdfActionSucceededLogEvent | WorkflowRunGenerateWeblinkActionSucceededLogEvent | WorkflowRunCollectedBlabItemsCallWorkfowActionLogEvent | WorkflowRunCurrentBlabItemCallWorkfowActionLogEvent;
export type WorkflowRunsSubscriptionUpdate = WorkflowRunCreatedSubscriptionUpdate | WorkflowRunLogsAppendedSubscriptionUpdate | WorkflowRunUpdatedSubscriptionUpdate;
export type WorkspaceActivityUnionType = WorkspaceCreatedActivityDto | WorkspaceUserAddedActivityDto;
export type WorkspaceMembershipUnionType = WorkspaceUserDto | WorkspaceInvitationDto;
