import { css, CSSObject, injectGlobal } from '@emotion/css';

const tssClasses: { [key: string]: number } = {};

// used for debugging by exporting this as `tss`.
const tssDebug = (...args: any[]) => {
  const tssClass = css(...args);

  if (tssClasses[tssClass]) {
    tssClasses[tssClass] = tssClasses[tssClass] + 1;
  } else {
    tssClasses[tssClass] = 1;
  }

  return tssClass;
};

// excuser moir
(window as any).tssClasses = tssClasses;
(window as any).getNumTssClasses = () => Object.keys(tssClasses).length;

/** Dynamically creates a css class given input styles, returns classname as string. */
export const tss = css;

/** Globally inject the provided styles to be available anywhere. */
export const injectGlobalTss = injectGlobal;

export type TssStylesObject<MP = {}> = CSSObject;
