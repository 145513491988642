import gql from 'graphql-tag';
import {
  BlabDefDtoNoNestingFragment,
  OrganizationProfileDtoNoNestingFragment,
  WorkspaceDtoNoNestingFragment,
} from '../../generated/graphql-fragments';
import { LightFlimDefFragment } from '../flim-def';

export const LightBlabDefDtoFragment = gql`
  fragment LightBlabDefDto on BlabDefDto {
    ...BlabDefDtoNoNesting
    workspace {
      ...WorkspaceDtoNoNesting
      organization {
        ...OrganizationProfileDtoNoNesting
      }
    }
    flimDefs {
      ...LightFlimDef
    }
  }
  ${BlabDefDtoNoNestingFragment}
  ${LightFlimDefFragment}
  ${WorkspaceDtoNoNestingFragment}
  ${OrganizationProfileDtoNoNestingFragment}
`;
