import {
  ActiveUserDevPortalContextUnionType,
  ActiveUserDto,
  ActiveUserStatusDto,
  SetActiveUserDoNotDisturbInput,
  SetActiveUserIncognitoInput,
  SetActiveUserMetadataInput,
  SetActiveUserStatusInput,
} from '@t5s/shared/gql';
import { ApolloFetchPolicy, ApolloQueryOptions } from '@t5s/shared/gql-config';
import { Observable } from 'rxjs';
import {
  ActiveUserDevPortalContextFragment,
  ActiveUserFragment,
  ActiveUserOnlyMetaDataFragment,
} from '../../fragments/active-user';
import {
  clearActiveUserDoNotDisturb,
  clearActiveUserIncognito,
  clearActiveUserStatus,
  reportCompletedSetup,
  reportCompletedWorkspaceSetup,
  setActiveUserDoNotDisturb,
  setActiveUserIncognito,
  setActiveUserMetadata,
  setActiveUserStatus,
} from '../../generated/graphql-mutations';
import { getActiveUser, getActiveUserDevPortalContext } from '../../generated/graphql-queries';
import { GqlService } from '../../gql-service';

export class GqlActiveUserService extends GqlService {
  getActiveUser(options: ApolloQueryOptions = {}): Observable<ActiveUserDto> {
    return this.apollo.defaultQuery({
      ...getActiveUser([ActiveUserFragment]),
      ...options,
    });
  }

  getActiveUserMetaData(): Observable<Pick<ActiveUserDto, 'metadata'>> {
    return this.apollo.defaultQuery({
      ...getActiveUser([ActiveUserOnlyMetaDataFragment]),
      fetchPolicy: ApolloFetchPolicy.NO_CACHE,
    });
  }

  setActiveUserMetadata(input: SetActiveUserMetadataInput): Observable<ActiveUserDto> {
    return this.apollo.defaultMutate(setActiveUserMetadata(input, [ActiveUserFragment]));
  }

  getActiveUserDevPortalContext(): Observable<ActiveUserDevPortalContextUnionType> {
    return this.apollo.defaultQuery(getActiveUserDevPortalContext([ActiveUserDevPortalContextFragment]));
  }

  reportCompletedSetup(): Observable<ActiveUserDto> {
    return this.apollo.defaultMutate(reportCompletedSetup([ActiveUserFragment]));
  }

  reportCompletedWorkspaceSetup(): Observable<ActiveUserDto> {
    return this.apollo.defaultMutate(reportCompletedWorkspaceSetup([ActiveUserFragment]));
  }

  setActiveUserStatus(input: SetActiveUserStatusInput): Observable<ActiveUserStatusDto> {
    return this.apollo.defaultMutate(setActiveUserStatus(input));
  }

  clearActiveUserStatus(): Observable<ActiveUserStatusDto> {
    return this.apollo.defaultMutate(clearActiveUserStatus());
  }

  setActiveUserDoNotDisturb(input: SetActiveUserDoNotDisturbInput): Observable<ActiveUserStatusDto> {
    return this.apollo.defaultMutate(setActiveUserDoNotDisturb(input));
  }

  clearActiveUserIncognito(): Observable<ActiveUserStatusDto> {
    return this.apollo.defaultMutate(clearActiveUserIncognito());
  }

  setActiveUserIncognito(input: SetActiveUserIncognitoInput = {}): Observable<ActiveUserStatusDto> {
    return this.apollo.defaultMutate(setActiveUserIncognito(input));
  }

  clearActiveUserDoNotDisturb(): Observable<ActiveUserStatusDto> {
    return this.apollo.defaultMutate(clearActiveUserDoNotDisturb());
  }
}
