import gql from 'graphql-tag';
import { OrganizationBreadcrumbFragment } from '../organization/organization-breadcrumb.fragment';

export const WorkspaceBreadcrumbFragment = gql`
  fragment WorkspaceBreadcrumbFragment on WorkspacePreviewDto {
    id
    name
    icon
    organization {
      ...OrganizationBreadcrumbFragment
    }
  }
  ${OrganizationBreadcrumbFragment}
`;
