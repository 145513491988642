import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { border, ComponentStyle, exactHeight, getFontStyle, padding, px } from '@t5s/client/ui/style/common';
import { tss } from '@t5s/client/util/tss';
import { RxComponent, selectSlice } from '@t5s/mobile-client/ui/component/common';
import { font, FontSet, ThemeColorVar } from '@t5s/mobile-client/ui/style/theme';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

interface TextButtonState {
  fgColor: string;
  bgColor: string;
  font: FontSet;
  disabled: boolean;
}

@Component({
  selector: 't5s-text-button',
  template: `
    <button
      [class]="buttonClass$ | push"
      [t5sTouchActive]="'opacity: 0.9'"
      [disabled]="disabled$ | push"
      [t5sTouchActiveDisabled]="disabled$ | push"
      (t5sPressDisableLongpress)="emitBtnClick()"
    >
      <ng-content></ng-content>
    </button>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
  styles: [ComponentStyle.HostFitContent],
})
export class TextButtonComponent extends RxComponent<TextButtonState> {
  readonly disabled$ = this.select(selectSlice(['disabled'])).pipe(map(({ disabled }) => disabled));

  constructor() {
    super();
    this.set({
      font: font.bold14px,
      bgColor: ThemeColorVar.primary,
      fgColor: ThemeColorVar.lightest,
      disabled: false,
    });
  }

  @Input() set font(font: FontSet | Observable<FontSet>) {
    this.setProperty('font', font);
  }

  @Input() set fgColor(fgColor: string | Observable<string>) {
    this.setProperty('fgColor', fgColor);
  }

  @Input() set bgColor(bgColor: string | Observable<string>) {
    this.setProperty('bgColor', bgColor);
  }

  @Input() set disabled(disabled: boolean | Observable<boolean>) {
    this.setProperty('disabled', disabled);
  }

  @Output() btnClick = new EventEmitter<{ event: MouseEvent }>();

  readonly buttonClass$ = this.state$.pipe(
    map(({ font, fgColor, bgColor, disabled }) =>
      tss({
        color: fgColor,
        border: border(1, 'solid', bgColor),
        ...exactHeight(16 + 8 + 8),
        backgroundColor: bgColor,
        ...getFontStyle(font),
        padding: padding(8, 16),
        borderRadius: px(1000),
        opacity: disabled ? 0.5 : undefined,
        cursor: disabled ? 'initial' : undefined,
      }),
    ),
  );

  emitBtnClick() {
    const { disabled } = this.get();

    if (disabled) {
      return;
    }

    this.btnClick.emit();
  }
}
