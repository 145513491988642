export const color = {
  black: '#37352F', // Changed from #0E1318

  // Gray
  gray100: '#F6F8F8',
  gray120: '#EDEDED',
  gray150: '#EBEDEF',
  gray200: '#EEF2F3',
  gray250: '#CDCCC9',
  gray400: '#CED8DD',
  gray500: '#BCC0C8',
  gray600: '#6E7174',
  gray700: '#9096A2',
  gray900: '#526370',

  // Temporary colors
  T_black: '#37352F',
  T_blackBright1: '#626668',
  T_blackBright2: '#787B7B',
  T_black9: '#EEEEED',
  T_blackGlossy: '#0F0F0F',
  T_grey1: '#F2F1EE',
  T_grey2: '#878378',
  T_grey3: '#CECDCA',
  T_grey4: '#C4C4C4',
  T_grey5: '#F1F1EF',
  T_grey6: '#EDECE9',
  T_grey7: '#D3D1CB',
  T_grey8: '#AEACA6',
  T_grey9: '#FBFBFA',
  grey9: '#FBFBFA',

  // Brown
  brown: '#8B2E00',

  // Orange
  orange: '#F45D00',
  orange100: '#D8720D',

  // Yellow
  yellow: '#DEA700',

  // Green
  green: '#007959',
  green100: '#00866A',

  // Blue
  blue: '#1164A3', // Changed from #0077DE
  blue100: '#0077DE',

  // Purple
  purple: '#6724DD',

  // Pink
  pink: '#DC0080',

  // Red
  red: '#EB5757',
  red100: '#FE001A',

  white: '#FFFFFF',
} as const;
