import gql from 'graphql-tag';
import {
  BlabDefPreviewDtoNoNestingFragment,
  OrganizationProfileDtoNoNestingFragment,
  WorkspacePreviewDtoNoNestingFragment,
} from '../../generated/graphql-fragments';

export const BlabDefPreviewDtoFragment = gql`
  fragment BlabDefPreviewDtoFragment on BlabDefPreviewDto {
    ...BlabDefPreviewDtoNoNesting
    workspace {
      ...WorkspacePreviewDtoNoNesting
      organization {
        ...OrganizationProfileDtoNoNesting
      }
    }
  }
  ${BlabDefPreviewDtoNoNestingFragment}
  ${WorkspacePreviewDtoNoNestingFragment}
  ${OrganizationProfileDtoNoNestingFragment}
`;
